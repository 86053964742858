<script>
import {Radar} from 'vue-chartjs'

import { mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    showLegend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {
        tooltips: {
          enable: true
        },
        elements: {
          point:{
            radius: 0
          }
        },
        animation: null,
        label: 15,
        legend: {
          display: this.showLegend,
          position: 'bottom'
        },
        scale: {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100
          },
          gridLines: {
            circular: true
          },
          angleLines: {
            display: true,
            lineWidth: 0.5,
            color: 'rgba(128, 128, 128, 0.2)'
          },
          pointLabels: {
            fontSize: 14,
            fontStyle: '300',
            fontColor: 'rgb(82,82,82)',
            fontFamily: "'Lato', sans-serif"
          }
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    options: function() {
      this._data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    }
  }
}
</script>
