<template>
  <div class="row">

    <div class="col-12">
      <p class="lead">
        The chart below visualises the future readiness of the emerging markets considered - overall and by pillar. The list is in alphabetical order.
      </p>
    </div>

    <div class="col-12 text-center animate__animated animate__fadeIn mb-5 mt-3">
      <p class="font-weight-500 help-text"><i class="fa fa-lightbulb-o mr-2"/>Mouse over the bars to see each country's scores per pillar. Use the chart legend to toggle between readiness pillars</p>
    </div>

    <bar-chart :chartData="this.chartData" style="width: 100%" class="graph-view-bar-chart"></bar-chart>

  </div>
</template>

<script>
import BarChart from "@/components-2021/charts/BarChart";

export default {
  name: "GraphView",
  components: {BarChart},
  data() {
    return {
      chartData: {
        chart: {
          height: (9 / 16 * 100) + '%' // 16:9 ratio
        },
        labels: ["Argentina", "Brazil", "Chile", "China", "Colombia", "Egypt", "India", "Indonesia", "Israel", "Jordan", "Kenya", "Kuwait", "Lebanon", "Mexico", "Morocco", "Nigeria", "Peru", "Qatar", "Russia", "Saudi Arabia", "Singapore", "South Africa", "Thailand", "Turkey", "Ukraine", "United Arab Emirates", "Viet Nam"],
        datasets: [
          {
            label: "Future Readiness",
            data: [
              "38.20",
              38.39,
              44.71,
              54.71,
              37.17,
              32.19,
              39.81,
              38.05,
              61.22,
              37.23,
              30.66,
              39.12,
              32.77,
              39.13,
              33.58,
              22.82,
              32.87,
              48.71,
              42.42,
              "45.10",
              72.76,
              39.49,
              43.55,
              41.08,
              36.19,
              59.24,
              38.56
            ],
            backgroundColor: '#3ac7ffFF'
          }, {
            label: "Institutions and Infrastructure",
            data: [
              40.14,
              40.48,
              57.97,
              62.06,
              46.61,
              38.28,
              // String to force keeping of trailing 0
              52.48,
              54.03,
              60.02,
              44.78,
              41.05,
              46.84,
              35.22,
              46.18,
              48.47,
              29.98,
              42.75,
              62.21,
              45.12,
              47.82,
              "75.80",
              48.11,
              53.27,
              48.13,
              "38.00",
              67.92,
              46.66
            ],
            backgroundColor: '#4e81c4'
          },
          {
            label: "Technology",
            data: [
              48.25,
              46.88,
              50.88,
              58.49,
              45.06,
              39.59,
              40.89,
              // String to force keeping of trailing 0
              "43.70",
              64.39,
              44.14,
              32.53,
              52.96,
              37.72,
              47.39,
              38.75,
              24.82,
              37.21,
              62.43,
              50.39,
              59.69,
              83.77,
              40.93,
              52.32,
              49.53,
              43.23,
              64.18,
              48.04
            ],
            backgroundColor: '#cc4c2a'
          },
          {
            label: "Talent",
            data: [
              46.81,
              40.29,
              49.67,
              50.33,
              39.25,
              38.71,
              // String to force keeping of trailing 0
              36.57,
              37.52,
              57.86,
              45.15,
              34.91,
              42.37,
              // String to force keeping of trailing 0
              "38.30",
              39.06,
              31.92,
              29.29,
              // String to force keeping of trailing 0
              "37.90",
              "53.00",
              49.52,
              46.89,
              72.87,
              37.33,
              36.79,
              38.75,
              45.54,
              63.98,
              34.02
            ],
            backgroundColor: '#e2aa23'
          },
          {
            label: "Innovation",
            data: [
              17.57,
              25.92,
              20.32,
              47.96,
              17.75,
              // String to force keeping of trailing 0
              "12.20",
              29.28,
              16.95,
              62.59,
              14.86,
              14.14,
              // String to force keeping of trailing 0
              "14.30",
              19.86,
              23.87,
              15.18,
              7.18,
              13.61,
              // String to force keeping of trailing 0
              "17.20",
              24.67,
              "26.00",
              58.61,
              "31.60",
              31.81,
              "27.90",
              17.99,
              40.88,
              25.51
            ],
            backgroundColor: '#66ad5d'
          }
        ]
      }
    }
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>

.graph-view-bar-chart {
  width: 100% !important;
  height: 2000px !important
}

</style>
