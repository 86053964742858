<template>
  <div class="col-12">
    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Physical_Capital" :top-three="topThreePillar" scoreField="Physical_Capital"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Physical_Capital"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Human_Capital" :top-three="topThreePillar" scoreField="Human_Capital"
         scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Human_Capital"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Technology"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Technology"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Competitiveness" :top-three="topThreePillar" scoreField="Competitiveness"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Competitiveness"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Physical_Capital" :top-three="topThreePillar" scoreField="Digital_Infrastructure"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Digital_Infrastructure"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Human_Capital" :top-three="topThreePillar" scoreField="Attract"
         :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Attract"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Digital_Usage" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Digital_Usage"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Competitiveness" :top-three="topThreePillar" scoreField="Digital_Policies"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Digital_Policies"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Physical_Capital" :top-three="topThreePillar" scoreField="Transport_Infrastructure"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Transport_Infrastructure"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Human_Capital" :top-three="topThreePillar" scoreField="Grow" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Grow"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Digital_Content_Creation" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Digital_Content_Creation"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Competitiveness" :top-three="topThreePillar" scoreField="Market_Environment"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Market_Environment"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Physical_Capital" :top-three="topThreePillar" scoreField="Energy_Infrastructure"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Energy_Infrastructure"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Human_Capital" :top-three="topThreePillar" scoreField="Retain" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Retain"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Industry_4"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Industry_4"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Competitiveness" :top-three="topThreePillar" scoreField="Research_and_Development"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Research_and_Development"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking class="" scoreRank="none"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Human_Capital" :top-three="topThreePillar" scoreField="Skills" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Skills"></Ranking>
      <Ranking class="" scoreRank="none"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Competitiveness" :top-three="topThreePillar" scoreField="Innovation"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Innovation"></Ranking>
    </div>

    <div class="container colour-legend-container">
      <div class="row">
        <div class="col text-center">
          <div class="m-a text-center">
            <div class="institutions colour-legend green"></div>
            <div class="Human_Capital colour-legend green"></div>
            <div class="technology colour-legend green"></div>
            <div class="Competitiveness colour-legend green"></div>
          </div>
          <div class="row">
            <div class="col"><p>Top Performing Areas</p></div>
          </div>
        </div>
        <div class="col text-center">
          <div class="m-a text-center">
            <div class="institutions colour-legend yellow"></div>
            <div class="Human_Capital colour-legend yellow"></div>
            <div class="technology colour-legend yellow"></div>
            <div class="Competitiveness colour-legend yellow"></div>
          </div>
          <div class="row">
            <div class="col"><p>Room for improvement</p></div>
          </div>
        </div>
        <div class="col text-center">
          <div class="m-a text-center">
            <div class="institutions colour-legend red"></div>
            <div class="Human_Capital colour-legend red"></div>
            <div class="technology colour-legend red"></div>
            <div class="Competitiveness colour-legend red"></div>
          </div>
          <div class="row">
            <div class="col"><p>Requires Attention</p></div>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>

import Ranking from "@/views-2022/components-2022/Ranking";

export default {
  name: "CountryRankings",
  components: {Ranking},
  props: {
    countryRankings: {
      type: Object
    },
    selectedCountry: {
      type: String
    }
  },
  computed: {
    topThreePillar() {

      let rankings = this.countryRankings[this.selectedCountry].sub_pillars

      var sortable = [];
      for (var pillar in rankings) {
        sortable.push([pillar, rankings[pillar]]);
      }

      const sortedRankings = sortable.sort(function (a, b) {
        return a[1] - b[1];
      });

      var id = 'Human_Capital';

      for (var i = 0; i < sortedRankings.length; i++) {
        if (sortedRankings[i].id == id) {
          sortedRankings.splice(i, 1);
          break;
        }
      }
      
      return sortedRankings.slice(0, 3);

      // Technology
      // Human_Capital
      // Competitiveness
      // Future_Readiness

    }
  }
}
</script>

<style scoped>

.ranking-stat-box-header {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  background: #4a4a4a;
  margin: 0;
  min-height: 90px;
  color: white;
  border: 0.1rem solid #ffffff !important;
}

.ranking-stat-box-header p {
  font-size: 12px;
}

.ranking-stat-box-header .field-value {
  font-size: large;
}

.colour-legend {
  height: 25px;
  width: 25px;
  margin: 2px;
  border-radius: 100%;
  display: inline-block;
}

.institutions.colour-legend.red {
  background: #95b5e0 !important;
}

.institutions.colour-legend.yellow {
  background: #79a3da !important;
}

.institutions.colour-legend.green {
  background: #4e81c4 !important;
}

.technology.colour-legend.red {
  background: #e89b87 !important;
}

.technology.colour-legend.yellow {
  background: #d3684b !important;
}

.technology.colour-legend.green {
  background: #cc4c2a !important;
}

.Human_Capital.colour-legend.red {
  background: #f1c250 !important;
}

.Human_Capital.colour-legend.yellow {
  background: #e8b539 !important;
}

.Human_Capital.colour-legend.green {
  background: #e2aa23 !important;
}

.Competitiveness.colour-legend.red {
  background: #89b982 !important;
}

.Competitiveness.colour-legend.yellow {
  background: #74b46c !important;
}

.Competitiveness.colour-legend.green {
  background: #66ad5d !important;
}

.colour-legend-container {
  background: #fafafa;
  margin: 2em 0 0 0;
  padding: 3em 0 1em 0;
}

</style>
