<template>
  <section class="section-hero section-shaped my-0 bg-google" style="background-image: url('/img/brand/bg-hero-2023.png')">
    <div class="banner animate__animated animate__zoomIn" style="z-index: 2000 !important;">
      <div class="container bg-lightgrey p-3 mb-5" style="border-left: solid 5px #0d5938;">
        <div class="row">
          <div class="col-2 text-center">
            <img width="100" style="max-width: 100%;" src="/img/brand/descartes_institute.png">
          </div>
          <div class="col-10 text-left">
            <div style="position: absolute;
    right: 30px;
    top: -31px;
    background: #0a5937;
    padding: 5px 20px;
    border-radius: 37px;
    color: white;
    font-size: 14px;">2023 Report</div>
            <p class="text-left mb-2">The Future Readiness Economic Index 2023 was launched on <br><span class="font-weight-600">Monday, 11 December at 12:30 pm</span> on the occasion of the <a style="text-decoration:underline" href="https://dohaforum.org/" target="_blank">Doha Forum 2023</a></p>
            <hr class="m-0">
            <h6 class="font-weight-700 mt-3 mb-0">
              <i style="color:#0d5938;" class="fa fa-video-camera mr-2"></i><a target="_blank" style="text-decoration:underline;color:#0d5938;" href="https://youtu.be/P9-S3HAOAcQ?si=8XbwCusmR7gYamB9">View the recording</a> <span style="font-weight: 300;">of the Global Launch event</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <video autoplay muted loop id="heroVideo">
        <source src="/videos/doha.mp4" type="video/mp4">
    </video>
    <div class="shape shape-style-1 shape-portulans">
    </div>
    <div class="container shape-container d-flex align-items-center">
      <div class="col px-0" style="z-index: 1000">
        <div class="row flex-nowrap" style="margin-top: 5em">
          <div class="col-lg-6 mb-4 img-fluid animate__animated animate__fadeIn animate__delay-3s">
            <a href="https://descartesinstitute.net/" target="_blank">
              <img src="/img/brand/descartes_institute.png" style="width: 180px;"/>
            </a>  
          </div>
          <div class="col-lg-4 mb-4 text-right animate__animated animate__fadeIn animate__delay-3s ">
            <img src="/img/report_icon.png" style="width: 100px;opacity: 1"/>
            <a href="/pdfs/Global Future Readiness FREI Report Descartes Institute 2023.pdf"><p>Read Our Report</p></a>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 text-left">
            <h1 style="color:#0a3924" class="font-weight-700 animate__animated animate__fadeInUp">F<span class="animate__animated animate__fadeIn animate__delay-1s">UTURE</span> R<span class="animate__animated animate__fadeIn animate__delay-1s">EADINESS</span> E<span class="animate__animated animate__fadeIn animate__delay-1s">CONOMIC</span> I<span class="animate__animated animate__fadeIn animate__delay-1s">NDEX</span><span style="color:grey;font-weight: 400;"> <span class="animate__animated animate__fadeIn animate__delay-2s"> 2023</span></span>
            </h1>
            <h4 style="color: #777b95; font-weight: normal; font-size: 22px" class="animate__animated animate__fadeIn animate__delay-2s">Digital Policies are the Linchpin of Future Readiness</h4>

            <div class="animate__animated animate__fadeIn animate__delay-3s">

              <a @click.prevent
                 v-tooltip="{
          content: 'The contents of this report and joint website reflect the views of the authors, who are responsible for the facts and accuracy of the information presented herein. They do not represent the views of Google or CRA. Descartes Institute bears the full responsibility of country rankings.',
          class: 'tooltip-custom tooltip-other-custom'
      }"><p style="font-style: italic;" class="description mt-5 mb-2 d-inline-flex">Commissioned by Google. In collaboration with Communications Regulatory Authority of the State of Qatar (CRA)</p></a>

              <br>

              <img src="/img/brand/google-logo-colour.png" style="width: 160px;margin-bottom: 40px; margin-right: 50px;opacity: 1">

              <img src="/img/brand/CRA_logo.png" style="width: 240px;margin-bottom: 40px;opacity: 1">

            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "Hero",
  components: {
  },
  metaInfo: {
    title: 'Descartes Institute FREI 2023 | Home',
  },
  data() {
    return {
      compassHoverActive: 'none',
      countries: [
        'Argentina?',
        'Brazil?',
        'Chile?',
        'China?',
        'Colombia?',
        'Egypt?',
        'India?',
        'Indonesia?',
        'Israel?',
        'Jordan?',
        'Kenya?',
        'Kuwait?',
        'Lebanon?',
        'Mexico?',
        'Morocco?',
        'Nigeria?',
        'Peru?',
        'Qatar?',
        'Russia?',
        'Saudi Arabia?',
        'Singapore?',
        'South Africa?',
        'Thailand?',
        'Turkey?',
        'Ukraine?',
        'United Arab Emirates?',
        'Viet Nam?',
      ]
    }
  },
  methods: {
    compassHover(segment) {
      this.compassHoverActive = segment
    },
    noCompassHover() {
      this.compassHoverActive = 'none'
      // console.log('no hover')
      // let self = this
      // setTimeout(function() {
      //   console.log('hover cleared')
      //   self.compassHoverActive = null
      // }, 200);
    },
    checkHover(segment) {
      if (this.compassHoverActive === segment) {
        return 'd-block'
      } else {
        return 'd-none'
      }

    }
  }
}
</script>
<style>
.vue-typer .custom.char.typed {
  color: #0c7abf;
}
.vue-typer .custom.char.selected {
  color: #4a4a4a;
}
.compass-piece {
  transition: all 100ms;
}
.compass-piece.top:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(-10px, -10px)
}
.compass-piece.right:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(10px, 10px)
}
.compass-piece.bottom:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(-10px, 10px)
}
.compass-piece.left:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(10px, -10px)
}
.compass-text {
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px 0 10px 0;
  color: rgb(17, 20, 64);
}
#heroVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: 0.2;
}

.section-hero {
    position: relative;
    overflow: hidden;
}

</style>
