<template>
  <div>

    <div v-if="selectedPillar && showFixedSlider" class="fixed-slider">
      <label>Percentage Increase</label>
      <vue-slider v-model="percentageIncrease" :marks="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
      />
    </div>


    <div class="row">

      <div class="col">

        <div class="col-12 text-center animate__animated animate__fadeIn">
          <p class="font-weight-500 help-text text-center">
            Choose a Country from the dropdown to begin <br>then move the cursor to the level you want to consider, and see the table change
          </p>
        </div>

        <div class="row what-if-buttons mb-5 text-center" style="max-width: 600px">
          <div class="col-12 mb-3">
            <b-form-select size="md" v-model="selectedCountry" :options="countries"></b-form-select>
          </div>
          <div v-if="selectedCountry !== null" class="col-12">
            <b-form-select size="md" v-model="selectedPillar" :options="pillars"></b-form-select>
          </div>
          <div v-if="selectedCountry !== null" class="col-12 pt-3 mb-5">
            <label>Percentage Increase</label>
            <vue-slider v-model="percentageIncrease" :marks="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
            />
          </div>
        </div>

        <div class="col text-center">
          <div class="d-inline-flex">
            <h1 class="font-weight-300 mt-4 animate__animated animate__fadeInUp" style="font-size: 30px">

              <span>What if </span>
              <span v-if="getCountryName !== 'none'"><span class="font-weight-700 text-underline link-to-country"
                                                           @click="selectCountry(getCountryName)">{{ getCountryName }}</span></span>
              <span v-if="getCountryName !== 'none' && selectedPillar === 'FREI'"> improved <span
                  class="font-weight-700">all pillars</span></span>
              <span
                  v-if="getCountryName !== 'none' && selectedPillar === 'PhysicalCapital'"> improved <span
                  class="font-weight-700">Physical Capital</span></span>
              <span
                  v-if="getCountryName !== 'none' && selectedPillar && selectedPillar !== 'FREI' && selectedPillar !== 'PhysicalCapital'"> improved <span
                  class="font-weight-700">{{ selectedPillar }}</span></span>
              <span v-if="getCountryName !== 'none' && selectedPillar && percentageIncrease"> by <span
                  class="font-weight-700">{{ percentageIncrease }}%</span></span>

            </h1>
          </div>
        </div>

        <div class="col-12 mt-5" style="overflow: scroll" id="sliderContainer">
          <template>
            <div>
              <b-table id="table-transition"
                       class="rankingsTable"
                       :sortBy="sortBy"
                       :sortDesc="sortDesc"
                       :sortCompare="myCompare"
                       :items="countryWhatIfDataSetForGivenScenario"
                       :fields="fields"
                       small
                       fixed
                       primary-key="Country"
                       :tbody-transition-props="transProps">
                <template #cell()="data">

                  <div v-if="data.field.key === 'Country'" class="countryName">
                    <span :class="{'selectedCountry': data.value === getCountryName}">{{ data.value }}</span>
                  </div>

                  <div v-else>
                    <span v-if="data.value.value < 100" style="margin-bottom: 5px">
                      {{ parseFloat(data.value.value).toFixed(2) }}
                    </span>
                    <span v-else style="margin-bottom: 5px">100</span><br>
                  </div>

                </template>
              </b-table>
            </div>
          </template>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

import countryWhatIfData from '../../data_2022/what-ifs/countryWhatIfs.js'
import countryWhatIfDataMaximums from '../../data_2022/what-ifs/countryWhatIfDataMaximums.json'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {BTable} from 'bootstrap-vue'
import _ from 'lodash';

export default {
  name: "WhatIfsTable",
  components: {
    BTable,
    VueSlider
  },
  data() {
    return {
      showFixedSlider: false,
      sliderStartPosition: null,
      sliderPosition: null,
      scrollPosition: null,
      sortDesc: true,
      percentageIncrease: 0,
      selectedCountry: null,
      selectedPillar: 'FREI',
      sortBy: "FREI",
      countries: [
        {value: null, text: 'Select a country', disabled: true},
        {value: 0, text: 'Albania'},
        {value: 1, text: 'Algeria'},
        {value: 2, text: 'Angola'},
        {value: 3, text: 'Argentina'},
        {value: 4, text: 'Armenia'},
        {value: 5, text: 'Australia'},
        {value: 6, text: 'Austria'},
        {value: 7, text: 'Azerbaijan'},
        {value: 8, text: 'Bahrain'},
        {value: 9,  text: 'Bangladesh'},
        {value: 10, text: 'Belarus'},
        {value: 11, text: 'Belgium'},
        {value: 12, text: 'Benin'},
        {value: 13, text: 'Bolivia'},
        {value: 14, text: 'Bosnia and Herzegovina'},
        {value: 15, text: 'Botswana'},
        {value: 16, text: 'Brazil'},
        {value: 17, text: 'Bulgaria'},
        {value: 18, text: 'Burkina Faso'},
        {value: 19, text: 'Cambodia'},
        {value: 20, text: 'Cameroon'},
        {value: 21, text: 'Canada'},
        {value: 22, text: 'Chile'},
        {value: 23, text: 'China'},
        {value: 24, text: 'Colombia'},
        {value: 25, text: 'Costa Rica'},
        {value: 26, text: "Côte d'Ivoire"},
        {value: 27, text: 'Croatia'},
        {value: 28, text: 'Cyprus'},
        {value: 29, text: 'Czech Republic'},
        {value: 30, text: 'Denmark'},
        {value: 31, text: 'Dominican Republic'},
        {value: 32, text: 'Ecuador'},
        {value: 33, text: 'Egypt'},
        {value: 34, text: 'El Salvador'},
        {value: 35, text: 'Estonia'},
        {value: 36, text: 'Ethiopia'},
        {value: 37, text: 'Finland'},
        {value: 38, text: 'France'},
        {value: 39, text: 'Georgia'},
        {value: 40, text: 'Germany'},
        {value: 41, text: 'Ghana'},
        {value: 42, text: 'Greece'},
        {value: 43, text: 'Guatemala'},
        {value: 44, text: 'Guinea'},
        {value: 45, text: 'Honduras'},
        {value: 46, text: 'Hungary'},
        {value: 47, text: 'Iceland'},
        {value: 48, text: 'India'},
        {value: 49, text: 'Indonesia'},
        {value: 50, text: 'Iran'},
        {value: 51, text: 'Ireland'},
        {value: 52, text: 'Israel'},
        {value: 53, text: 'Italy'},
        {value: 54, text: 'Jamaica'},
        {value: 55, text: 'Japan'},
        {value: 56, text: 'Jordan'},
        {value: 57, text: 'Kazakhstan'},
        {value: 58, text: 'Kenya'},
        {value: 59, text: 'Kuwait'},
        {value: 60, text: 'Kyrgyzstan'},
        {value: 61, text: 'Laos'},
        {value: 62, text: 'Latvia'},
        {value: 63, text: 'Lebanon'},
        {value: 64, text: 'Lithuania'},
        {value: 65, text: 'Luxembourg'},
        {value: 66, text: 'Madagascar'},
        {value: 67, text: 'Malawi'},
        {value: 68, text: 'Malaysia'},
        {value: 69, text: 'Mali'},
        {value: 70, text: 'Malta'},
        {value: 71, text: 'Mauritius'},
        {value: 72, text: 'Mexico'},
        {value: 73, text: 'Moldova'},
        {value: 74, text: 'Mongolia'},
        {value: 75, text: 'Morocco'},
        {value: 76, text: 'Mozambique'},
        {value: 77, text: 'Myanmar'},
        {value: 78, text: 'Nepal'},
        {value: 79, text: 'Netherlands'},
        {value: 80, text: 'New Zealand'},
        {value: 81, text: 'Niger'},
        {value: 82, text: 'Nigeria'},
        {value: 83, text: 'North Macedonia'},
        {value: 84, text: 'Norway'},
        {value: 85, text: 'Oman'},
        {value: 86, text: 'Pakistan'},
        {value: 87, text: 'Panama'},
        {value: 88, text: 'Paraguay'},
        {value: 89, text: 'Peru'},
        {value: 90, text: 'Philippines'},
        {value: 91, text: 'Poland'},
        {value: 92, text: 'Portugal'},
        {value: 93, text: 'Qatar'},
        {value: 94, text: 'Romania'},
        {value: 95, text: 'Russia'},
        {value: 96, text: 'Rwanda'},
        {value: 97, text: 'Saudi Arabia'},
        {value: 98, text: 'Senegal'},
        {value: 99, text: 'Serbia'},
        {value: 100, text: 'Singapore'},
        {value: 101, text: 'Slovakia'},
        {value: 102, text: 'Slovenia'},
        {value: 103, text: 'South Africa'},
        {value: 104, text: 'South Korea'},
        {value: 105, text: 'Spain'},
        {value: 106, text: 'Sri Lanka'},
        {value: 107, text: 'Sweden'},
        {value: 108, text: 'Switzerland'},
        {value: 109, text: 'Tanzania'},
        {value: 110, text: 'Thailand'},
        {value: 111, text: 'Togo'},
        {value: 112, text: 'Trinidad and Tobago'},
        {value: 113, text: 'Tunisia'},
        {value: 114, text: 'Turkey'},
        {value: 115, text: 'Uganda'},
        {value: 116, text: 'Ukraine'},
        {value: 117, text: 'United Arab Emirates'},
        {value: 118, text: 'United Kingdom'},
        {value: 119, text: 'United States'},
        {value: 120, text: 'Uruguay'},
        {value: 121, text: 'Vietnam'},
        {value: 122, text: 'Zambia'},
        {value: 123, text: 'Zimbabwe'}
      ],
      pillars: [
        {value: 'FREI', text: 'Future Readiness Economic Index'},
        {value: 'Competitiveness', text: 'Competitiveness'},
        {value: 'PhysicalCapital', text: 'Physical Capital'},
        {value: 'HumanCapital', text: 'Human Capital'},
        {value: 'Technology', text: 'Technology'}
      ],
      options: [
        {text: 'Current Scores', value: 'current'},
        {text: '1% Improvement', value: '1_percent'},
        {text: '2% Improvement', value: '2_percent'},
        {text: '3% Improvement', value: '3_percent'}
      ],
      transProps: {
        name: 'flip-list'
      },
      scenario: 'current',
      countryWhatIfDataMaximums: countryWhatIfDataMaximums,
      countryWhatIfData: countryWhatIfData,
      fields: [
        {key: 'Country', sortable: false, tdClass: "countryColumn", thClass: 'comparisonHeader', sortDirection: 'desc'},
        {
          key: 'FREI',
          sortable: false,
          thClass: 'comparisonHeader',
          tdClass: "backgroundOpacity",
          sortDirection: 'desc'
        },
        {
          key: 'PhysicalCapital',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        },
        {
          key: 'HumanCapital',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        },
        {
          key: 'Technology',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        },
        {
          key: 'Competitiveness',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        }
      ],
      countryWhatIfDataSetForGivenScenarioStartValues: countryWhatIfData,
      startValues: _.cloneDeep(countryWhatIfData)
    }
  },
  watch: {
    percentageIncrease() {
      if (this.selectedPillar === 'FREI') {

        const percentage = 0.01 * this.percentageIncrease

        const startValue = this.startValues[this.scenario][this.selectedCountry][this.selectedPillar].value
        const newValue = startValue * (1 + percentage)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry][this.selectedPillar].value = (Math.round(newValue * 100) / 100)

        // Get Starting Pillar Values

        const startValuePhysicalCapital = this.startValues[this.scenario][this.selectedCountry].PhysicalCapital.value
        const startValueTechnology = this.startValues[this.scenario][this.selectedCountry].Technology.value
        const startValueHumanCapital = this.startValues[this.scenario][this.selectedCountry].HumanCapital.value
        const startValueCompetitiveness = this.startValues[this.scenario][this.selectedCountry].Competitiveness.value

        // Calculate New Pillar Values

        const newStartValuePhysicalCapital = startValuePhysicalCapital * (1 + percentage)
        const newStartValueTechnology = startValueTechnology * (1 + percentage)
        const newStartValueHumanCapital = startValueHumanCapital * (1 + percentage)
        const newStartValueCompetitiveness = startValueCompetitiveness * (1 + percentage)

        // Set New Pillar Values

        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].PhysicalCapital.value = (Math.round(newStartValuePhysicalCapital * 100) / 100).toFixed(2)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Technology.value = (Math.round(newStartValueTechnology * 100) / 100).toFixed(2)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].HumanCapital.value = (Math.round(newStartValueHumanCapital * 100) / 100).toFixed(2)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Competitiveness.value = (Math.round(newStartValueCompetitiveness * 100) / 100).toFixed(2)

        // Calculate New Future Readiness Value

        const newFREI = ((newStartValuePhysicalCapital + newStartValueTechnology + newStartValueHumanCapital + newStartValueCompetitiveness) / 4)

        // Set New Future Readiness Value

        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].FREI.value = Number.parseFloat((Math.round(newFREI * 100) / 100))

        console.log('Country', this.selectedCountry)
        console.log('Scenario', this.scenario)
        console.log('Object', this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry])
        console.log('FREI', newFREI)

      } else {

        // Set New Value for Selected Pillar

        const percentage = 0.01 * this.percentageIncrease
        const startValue = this.startValues[this.scenario][this.selectedCountry][this.selectedPillar].value
        const newValue = startValue * (1 + percentage)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry][this.selectedPillar].value = Number.parseFloat((Math.round(newValue * 100) / 100))

        // Get New Pillar Values

        const newPhysicalCapital = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].PhysicalCapital.value)
        const newTechnology = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Technology.value)
        const newHumanCapital = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].HumanCapital.value)
        const newCompetitiveness = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Competitiveness.value)

        const newFREI = ((newPhysicalCapital + newTechnology + newHumanCapital + newCompetitiveness) / 4)

        // Set New Future Readiness Value

        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].FREI.value = (Math.round(newFREI * 100) / 100).toFixed(2)

        console.log('Country', this.selectedCountry)
        console.log('Scenario', this.scenario)
        console.log('Object', this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry])
        console.log('notFREI', newFREI)
      }
    },
    selectedPillar() {
      this.sortDesc = true
      this.sortBy = 'FREI'
      this.percentageIncrease = 0
    },
    selectedCountry() {
      this.percentageIncrease = 0
    }
  },
  computed: {
    getCountryName() {
      let selectedCountryName = 'none'
      if (this.selectedCountry !== null) {
        const countries = [
          {name: 'Albania'},
          {name: 'Algeria'},
          {name: 'Angola'},
          {name: 'Argentina'},
          {name: 'Armenia'},
          {name: 'Australia'},
          {name: 'Austria'},
          {name: 'Azerbaijan'},
          {name: 'Bahrain'},
          {name: 'Bangladesh'},
          {name: 'Belarus'},
          {name: 'Belgium'},
          {name: 'Benin'},
          {name: 'Bolivia'},
          {name: 'Bosnia and Herzegovina'},
          {name: 'Botswana'},
          {name: 'Brazil'},
          {name: 'Bulgaria'},
          {name: 'Burkina Faso'},
          {name: 'Cambodia'},
          {name: 'Cameroon'},
          {name: 'Canada'},
          {name: 'Chile'},
          {name: 'China'},
          {name: 'Colombia'},
          {name: 'Costa Rica'},
          {name: "Côte d'Ivoire"},
          {name: 'Croatia'},
          {name: 'Cyprus'},
          {name: 'Czech Republic'},
          {name: 'Denmark'},
          {name: 'Dominican Republic'},
          {name: 'Ecuador'},
          {name: 'Egypt'},
          {name: 'El Salvador'},
          {name: 'Estonia'},
          {name: 'Ethiopia'},
          {name: 'Finland'},
          {name: 'France'},
          {name: 'Georgia'},
          {name: 'Germany'},
          {name: 'Ghana'},
          {name: 'Greece'},
          {name: 'Guatemala'},
          {name: 'Guinea'},
          {name: 'Honduras'},
          {name: 'Hungary'},
          {name: 'Iceland'},
          {name: 'India'},
          {name: 'Indonesia'},
          {name: 'Iran'},
          {name: 'Ireland'},
          {name: 'Israel'},
          {name: 'Italy'},
          {name: 'Jamaica'},
          {name: 'Japan'},
          {name: 'Jordan'},
          {name: 'Kazakhstan'},
          {name: 'Kenya'},
          {name: 'Kuwait'},
          {name: 'Kyrgyzstan'},
          {name: 'Laos'},
          {name: 'Latvia'},
          {name: 'Lebanon'},
          {name: 'Lithuania'},
          {name: 'Luxembourg'},
          {name: 'Madagascar'},
          {name: 'Malawi'},
          {name: 'Malaysia'},
          {name: 'Mali'},
          {name: 'Malta'},
          {name: 'Mauritius'},
          {name: 'Mexico'},
          {name: 'Moldova'},
          {name: 'Mongolia'},
          {name: 'Morocco'},
          {name: 'Mozambique'},
          {name: 'Myanmar'},
          {name: 'Nepal'},
          {name: 'Netherlands'},
          {name: 'New Zealand'},
          {name: 'Niger'},
          {name: 'Nigeria'},
          {name: 'North Macedonia'},
          {name: 'Norway'},
          {name: 'Oman'},
          {name: 'Pakistan'},
          {name: 'Panama'},
          {name: 'Paraguay'},
          {name: 'Peru'},
          {name: 'Philippines'},
          {name: 'Poland'},
          {name: 'Portugal'},
          {name: 'Qatar'},
          {name: 'Romania'},
          {name: 'Russia'},
          {name: 'Rwanda'},
          {name: 'Saudi Arabia'},
          {name: 'Senegal'},
          {name: 'Serbia'},
          {name: 'Singapore'},
          {name: 'Slovakia'},
          {name: 'Slovenia'},
          {name: 'South Africa'},
          {name: 'South Korea'},
          {name: 'Spain'},
          {name: 'Sri Lanka'},
          {name: 'Sweden'},
          {name: 'Switzerland'},
          {name: 'Tanzania'},
          {name: 'Thailand'},
          {name: 'Togo'},
          {name: 'Trinidad and Tobago'},
          {name: 'Tunisia'},
          {name: 'Turkey'},
          {name: 'Uganda'},
          {name: 'Ukraine'},
          {name: 'United Arab Emirates'},
          {name: 'United Kingdom'},
          {name: 'United States'},
          {name: 'Uruguay'},
          {name: 'Vietnam'},
          {name: 'Zambia'},
          {name: 'Zimbabwe'}
        ]
        selectedCountryName = countries[this.selectedCountry].name
      }
      return selectedCountryName
    },
    countryWhatIfDataSetForGivenScenario() {
      return this.countryWhatIfData[this.scenario]
    },
    countryWhatIfDataMaximumsForGivenScenario() {
      return this.countryWhatIfDataMaximums[this.scenario]
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    getCenteredDivPosition() {
      this.sliderStartPosition = this.sliderPosition
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
      this.setSliderState()
    },
    setSliderState() {
      if (this.scrollPosition > 750) {
        this.showFixedSlider = true
      } else {
        this.showFixedSlider = false
      }
    },
    myCompare(aRow, bRow, key) {
      const aValue = aRow[key].value
      const bValue = bRow[key].value
      if (aValue > bValue) {
        return 1
      }
      if (aValue < bValue) {
        return -1
      }
      return 0
    },
    backgroundOpacity(value, key) {
      const maxValue = this.countryWhatIfDataMaximumsForGivenScenario[key]
      const opacityValue = value.value / maxValue

      if (Math.round(opacityValue * 10) >= 10) {
        return 'comparisonCell ' + key + 'Opacity10'
      } else {
        return 'comparisonCell ' + key + 'Opacity' + Math.round(opacityValue * 10)
      }
    },
    getFREI(countryPillars) {
      const countryTotal = Number(countryPillars.Competitiveness.value) + Number(countryPillars.PhysicalCapital.value) + Number(countryPillars.HumanCapital.value) + Number(countryPillars.Technology.value)
      // return Math.round(countryTotal/4 * 100) / 100
      return (countryTotal / 4).toFixed(2)
    },
    selectCountry(country) {
      this.$emit('clickedCountry', country)
    }
  }
}

</script>

<style>

.what-if-buttons {
  margin: auto !important;
}

.what-if-buttons .form-group {
  width: 100%;
}

.rankingsTable th:focus {
  outline: 0;
}

.rank {
  background: none !important;
  font-weight: bold;
  margin-top: 5px;
}

.rankingsTable th {
  border-top: solid 5px #FFF !important;
  text-align: center;
}

.rankingsTable th:nth-child(3)[aria-sort='descending'] {
  border-top: solid 5px #cc4c2a !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='ascending'] {
  border-top: solid 5px #cc4c2a !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='descending'] {
  border-top: solid 5px #66ad5d !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='ascending'] {
  border-top: solid 5px #66ad5d !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='descending'] {
  border-top: solid 5px #e2aa23 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='ascending'] {
  border-top: solid 5px #e2aa23 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='descending'] {
  border-top: solid 5px #73339b !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='ascending'] {
  border-top: solid 5px #73339b !important;
  background: #fbfbfb;
}

.countryName {
  padding: 1em !important;
}

.countryName:hover {
  color: white;
  cursor: initial;
}

.countryName span {
  font-weight: bold;
  padding: 12px;
  border-radius: 5px;
  margin-left: -10px;
  color: #4b4b4b;
}

.countryName span.selectedCountry {
  font-weight: bold;
  background: #4b4b4b;
  padding: 12px;
  border-radius: 5px;
  margin-left: -10px;
  color: white;
}

.in-text-select {
  border: none !important;
  box-shadow: none !important;
  font-size: 30px !important;
  color: black
}

.link-to-country:hover {
  color: #0c7abf;
  cursor: pointer;
}

.fixed-slider {
  background: white;
  position: fixed;
  top: 0;
  width: 1024px;
  margin: auto;
  z-index: 100000;
  padding: 1em 2em 3em 2em;
  border-radius: 0px 0px 5px 5px;
  box-shadow: #00000033 -1px 5px 9px 0px;
}

</style>
