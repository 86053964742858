<template>
  <div>

    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-portulans shape-skew"></div>
        <div class="container shape-container d-flex" style="padding-bottom: 7rem;">
          <div class="col px-0">
            <div class="row">
              <div class="col-12" style="display: flex;
    align-items: center;
    flex-wrap: wrap;">
                <img src="/img/brand/descartes_institute.png" style="width: 100px;margin-bottom: 40px;margin-right: 40px"
                     class="float-left">
                <img src="/img/brand/google-logo-colour.png" style="width: 200px;margin-bottom: 40px;margin-right: 45px;"
                     class="float-left">
                <img src="/img/brand/CRA_logo.png" style="width: 240px;margin-bottom: 40px;">
              </div>
              <div class="col-lg-12">
                <h1 class="font-weight-700  mt-4">Assessing Future Readiness</h1>
                <h4 class="">Future Readiness Economic Index</h4>
                <hr>
                <p class="lead  ">
                  When historians look back on the early part of the 21st century, it is likely that one of the distinguishing features they identify will be the rapid wave of digitalisation that swept the planet.
                  <br>
                  <br>
                  Recognising that our current time presents a unique chance to widen and deepen digital transformation throughout the world, Google launched an initiative called Digital Sprinters in November 2020. In it, the company proposed a novel framework that can facilitate digital transformation in emerging markets and assist them in becoming digital sprinters.
                  <br>
                  <br>
                  The present report builds on that initiative to provide countries with a tool for action: the Future Readiness Economic Index (FREI). With the Digital Sprinters framework as its foundation, FREI has been developed to support countries in assessing the state of their future readiness and their efforts to stimulate greater sustainable, inclusive growth with digital transformation as an integral factor. Countries will be able to use the tool to measure themselves against certain benchmarks or objectives and evaluate their progress towards reaching those goals.
                  <br>
                  <br>
                  The 2023 report enhances FREI’s focus on Digital Policies, making them the central pillar of the index and special focus of this year’s edition.
                </p>
              </div>
            </div>
            <br>
            <div class="col-lg-12 text-left mt-4">
              <div class="btn-wrapper">
                <base-button tag="a"
                             href="/pdfs/Global Future Readiness FREI Report Descartes Institute 2023.pdf"
                             target="_blank"
                             class="mb-3"
                             type="primary-green"
                             icon="fa fa-download">
                  Future Readiness Economic Index Report
                </base-button>
                <base-button tag="a"
                             @click="modalShow = !modalShow"
                             class="mb-3 text-white"
                             type="primary-green"
                             icon="fa fa-file">
                  View Executive Summary
                </base-button>
               <base-button tag="a"
                            href="/pdfs/FREI Infographics 2023.pdf"
                            target="_blank"
                            class="mb-3"
                            type="primary-green"
                            icon="fa fa-download">
                  View Infrographics
                </base-button>
                <p class="mt-4">See our previous editions of the FREI here:</p>
                <base-button tag="a"
                            href="/2021"
                            target="_blank"
                            class="mb-3"
                            type="primary">
                            View Our <strong>2021</strong> Website & Report
                </base-button>
                <base-button tag="a"
                            href="/2022"
                            target="_blank"
                            class="mb-3"
                            type="primary">
                            View Our <strong>2022</strong> Website & Report
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <template>
      <div>
        <b-modal v-model="modalShow" hide-footer size="xl" dialog-class="document-modal" title="Executive Summary">

          <p>Digital transformation is at the core of the Future Readiness Economic Index (FREI), and for good reason. Digital technologies present an opportunity for greater prosperity, and are considered essential to achieving the UN Sustainable Development Goals (SDGs). This has been recognised by world leaders, who will seek to develop a Global Digital Compact when they meet at the multi-stakeholder Summit of the Future in September 2024.</p>

          <p>Critical components of digital transformation are digital policies and regulations. This is also reflected in the FREI model, which includes a specific focus on digital policies. However, the digital sector is notoriously fast-changing-just consider the emergence of ChatGPT and other generative Al tools since the launch of the last FREI report in September 2022-and it is imperative that digital governance remain up to date, while continuously looking forward.</p>

          <p>In an effort to stay abreast of developments, this year's FREI includes a more comprehensive examination of digital policies, which extends into areas at the forefront of digital technologies. FREI 2023 is a unique assessment of the future readiness of countries that includes how well they are adapting and updating their governance on issues such as data privacy, Al, and cloud computing.</p>

          <p>Main results and findings Singapore continues to be the world's most future- ready country, while Denmark and Switzerland come in second and third, respectively. They are followed, in order, by the United States, Sweden, Finland, Norway, the Netherlands, the United Kingdom, and Australia.</p>

          <p>Smaller, more developed economies dominate the top 10, although several large economies are also able to achieve a high degree of future readiness. A case in point is the United States, the world's largest economy, which climbs to fourth this year (up from sixth in 2022). Another example is China: ranked 38th this year, it is the most future-ready country among economies outside the high-income group.</p>

          <p>Few countries perform extremely well across all areas. Singapore is one exception and is the global leader in two of the four main pillars: Technology and Competitiveness. Denmark also stands out in this regard, with 2nd-place positions in the Human Capital and Technology pillars. The top performer in Physical Capital is Norway, while Australia leads the rankings for Human Capital. </p>

          <p>As for Digital Policies, the United Kingdom, Australia, and Estonia make up the top three (in that order). All three countries perform impressively well across various aspects of digital governance, including regulation of information and communication technology (ICT), cybersecurity measures, and policies related to emerging technologies. Although there is a clear, positive relationship between digital governance and income levels, the economies with the most advanced digital policies and regulations come from different regions and vary notably in size. </p>

          <p>At an aggregate level, Europe and Northern America are the most future-ready regions in the world. Asia Pacific includes several countries with a high degree of future readiness, not least Singapore, the global leader. But it is also the region that exhibits the starkest contrasts. In fact, Asia Pacific is behind the Middle East and North Africa in most summary statistics. Elsewhere, levels of future readiness remain similar throughout much of the Latin America and Caribbean region, while Sub-Saharan Africa is the least future-ready region of all. </p>

          <h4 class="my-4 font-weight-700 text-primary">Key messages</h4>

          <p>One of the features that the three most future-ready countries share is that they do well across various areas covered by the Index. In fact, they feature in the top 10 in all four of the main pillars. To achieve future readiness, countries have to find the sweet spot that combines more traditional measures for promoting growth, such as investment in infrastructure or strengthening of education, with more forward-looking policies and initiatives, especially as they relate to digital technologies. </p>

          <p>A key takeaway from the analysis of digital governance is that advanced digital policies and regulations have been adopted by countries of various sizes. Small or big, rich or poor, countries have considerable leeway to shape their own institutions and policies to become Digital Sprinters and ready for the future.</p>

          <p>Future readiness is inextricably linked to the ability to tap into the wealth of opportunities provided by digital technologies, while acknowledging and addressing their associated challenges. The potential of digital technologies can only be realised to the fullest if countries work together on issues that range from strengthening digital infrastructure and improving digital access, to embracing emerging technologies and adopting forward-looking digital policies. </p>

          <p>In other words, multilateralism in digital technologies is key for greater future readiness. Indeed, as highlighted by UN Member States: improved international digital co-operation is one priority as we work towards "the sustainability of our planet as well as the welfare of generations for decades to come."</p>

          <b-button class="mt-3 btn-primary-green" variant="primary" block @click="modalShow = !modalShow">Close</b-button>

        </b-modal>

      </div>
    </template>

    <section class="section section-lg digital-sprinters bg-google" data-aos="fade-in" data-aos-offset="400">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="row">
              <div class="col-12 p-0 text-center">
                <img src="/img/brand/digital_sprinters_logo.png"
                     style="width:90%;max-width:300px;margin: 0 auto 40px auto"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0">
                <h2 class="display-3">The initial stage, Digital Sprinters, a Google initiative</h2>
              </div>
            </div>
            <p class="lead"><span class="font-weight-600">Google</span> and <span
                class="font-weight-600">Descartes Institute</span> are proud to announce their collaboration on this
              FREI
              study, following the publication of the Digital Sprinters report by Google on November 16, 2020 revealing
              that emerging markets are “facing a digital crossroad that was apparent even prior to the pandemic”.</p>
            <img src="/img/brand/digital-sprinters-diagram.png" style="width:100%; max-width: 600px;" class="mb-5 mt-5">
          </div>
          <div class="col-lg-12 mt-4">
            <ul>
              <li>
                <p class="lead">The two initiatives (Digital Sprinters and Future Readiness) share a number of common
                  principles and objectives. They also have distinctive traits, which reflect their respective origins
                  and rationales. Their combination hence offers exceptional possibilities in terms of impact and
                  visibility.</p>
              </li>
              <li>
                <p class="lead">Both highlight the critical role of technology and human capital / talent, and the
                  importance of imagining how they will mesh with one another in the future.</p>
              </li>
            </ul>
          </div>
          <div class="col-lg-12 text-center mt-4">
            <div class="btn-wrapper">
              <base-button tag="a"
                           href="https://blog.google/outreach-initiatives/public-policy/the-opportunity-for-digital-sprinters/"
                           target="_blank"
                           class="mb-3 mb-sm-0"
                           type="primary-green"
                           icon="fa fa-google">
                Read more about Digital Sprinters
              </base-button>
              <base-button tag="a"
                           href="https://blog.google/documents/96/Google-Whitepaper_V5.pdf"
                           class="mb-3 mb-sm-0"
                           type="primary-green"
                           icon="fa fa-download">
                Download the Digital Sprinters Report
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="row google-colour-bar">
        <div class="col google-red"></div>
        <div class="col google-blue"></div>
        <div class="col google-green"></div>
        <div class="col google-yellow"></div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-9 order-md-2" data-aos="fade-in" data-aos-offset="300">
            <div class="pr-md-5">
              <h3>"With its coverage of 124 economies and the inclusion of an online tool, the FREI 2023
Report is an important  resource for promoting sustainable and inclusive digital growth. For all practical purposes  FREI quantifies  
the Digital Sprinters Framework which suggests that, for a country to carry out a comprehensive digital transformation, it  needs  to invest  in key areas of  physical capital, human capital, state of the art digital policies  & Government services. Only then it can benefit from  the latest technologies such as  AI & Cloud  and be future ready"</h3>
              <p class="lead"><span class="font-weight-600">Selim Eddé</span><br>Director, Government Affairs & Public Policy, Emerging Markets - Google Cloud</p>
            </div>
          </div>
          <div class="col-md-3 order-md-1" data-aos="fade-right" data-aos-offset="300"
               style="margin: auto;text-align: center">
            <img src="/img/photos/selim_edde.png" class="img-fluid" style="max-width: 220px;margin: auto">
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center" data-aos="fade-in" data-aos-offset="300">
          <div class="col-md-9 order-md-1" style="text-align: right;">
            <div class="pr-md-5">
              <h3>"Future-readiness should not be reduced to a competition among more technologically-advanced economies. Governments, business and civil society at large should consider future-readiness as a global imperative. Making our planet more sustainable, less unequal and more peaceful is an urgent task, in which digital transformation has a central role to play."</h3>
              <p class="lead"><span class="font-weight-600">Bruno Lanvin</span><br>Founder and CEO, Descartes Institute
                <br>Geneva, Switzerland</p>
            </div>
          </div>
          <div class="col-md-3 order-md-2" data-aos="fade-left" data-aos-offset="300"
               style="margin: auto;text-align: center">
            <img src="/img/photos/bruno_lanvin.png" class="img-fluid" style="max-width: 220px;margin: auto">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: "home",
  components: {
    
  },
  metaInfo: {
    title: 'Descartes Institute FREI 2023 | About',
  },
  data() {
    return {
      modalShow: false,
      visible: false,
    }
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  }
};
</script>

<style scoped>

.methodology-box {
  background: white;
  padding: 1em;
  border-radius: 5px;
}

.google-colour-bar {
  height: 10px;
}

.google-red {
  background-color: #DB4437
}

.google-blue {
  background-color: #4285F4
}

.google-green {
  background-color: #0F9D58
}

.google-yellow {
  background-color: #F4B400
}


</style>
