<template>
  <div class="row">

    <div class="col-12">
      <p class="lead">The country profiles provide summaries of the future readiness performances of all 124 countries included in the FREI report. Each country profile includes data on key indicators, future readiness ranking, breakdown of performances in each of the pillars and sub-pillars of the FREI model, and comparative performances against global averages.</p>
    </div>

    <div class="col-12 my-3">
      <b-form-select size="md" v-model="selected" :options="options"></b-form-select>
    </div>

    <country-profile :selected-country="selected"></country-profile>

  </div>
</template>

<script>
import CountryProfile from "@/views-2023/components-2023/CountryProfile";

export default {
  name: "CountryProfiles",
  components: {CountryProfile},
  data() {
    return {
      selected: 'albania',
      options: [
        {text: "Albania", value: "albania"},
        {text: "Algeria", value: "algeria"},
        {text: "Angola", value: "angola"},
        {text: "Argentina", value: "argentina"},
        {text: "Armenia", value: "armenia"},
        {text: "Australia", value: "australia"},
        {text: "Austria", value: "austria"},
        {text: "Azerbaijan", value: "azerbaijan"},
        {text: "Bahrain", value: "bahrain"},
        {text: "Bangladesh", value: "bangladesh"},
        {text: "Belarus", value: "belarus"},
        {text: "Belgium", value: "belgium"},
        {text: "Benin", value: "benin"},
        {text: "Bolivia", value: "bolivia"},
        {text: "Bosnia and Herzegovina", value: "bosnia_and_herzegovina"},
        {text: "Botswana", value: "botswana"},
        {text: "Brazil", value: "brazil"},
        {text: "Bulgaria", value: "bulgaria"},
        {text: "Burkina Faso", value: "burkina_faso"},
        {text: "Cambodia", value: "cambodia"},
        {text: "Cameroon", value: "cameroon"},
        {text: "Canada", value: "canada"},
        {text: "Chile", value: "chile"},
        {text: "China", value: "china"},
        {text: "Colombia", value: "colombia"},
        {text: "Costa Rica", value: "costa_rica"},
        {text: "Côte d'Ivoire", value: "cote_divoire"},
        {text: "Croatia", value: "croatia"},
        {text: "Cyprus", value: "cyprus"},
        {text: "Czech Republic", value: "czech_republic"},
        {text: "Denmark", value: "denmark"},
        {text: "Dominican Republic", value: "dominican_republic"},
        {text: "Ecuador", value: "ecuador"},
        {text: "Egypt", value: "egypt"},
        {text: "El Salvador", value: "el_salvador"},
        {text: "Estonia", value: "estonia"},
        {text: "Ethiopia", value: "ethiopia"},
        {text: "Finland", value: "finland"},
        {text: "France", value: "france"},
        {text: "Georgia", value: "georgia"},
        {text: "Germany", value: "germany"},
        {text: "Ghana", value: "ghana"},
        {text: "Greece", value: "greece"},
        {text: "Guatemala", value: "guatemala"},
        {text: "Guinea", value: "guinea"},
        {text: "Honduras", value: "honduras"},
        {text: "Hungary", value: "hungary"},
        {text: "Iceland", value: "iceland"},
        {text: "India", value: "india"},
        {text: "Indonesia", value: "indonesia"},
        {text: "Iran", value: "iran"},
        {text: "Ireland", value: "ireland"},
        {text: "Israel", value: "israel"},
        {text: "Italy", value: "italy"},
        {text: "Jamaica", value: "jamaica"},
        {text: "Japan", value: "japan"},
        {text: "Jordan", value: "jordan"},
        {text: "Kazakhstan", value: "kazakhstan"},
        {text: "Kenya", value: "kenya"},
        {text: "Kuwait", value: "kuwait"},
        {text: "Kyrgyzstan", value: "kyrgyzstan"},
        {text: "Laos", value: "laos"},
        {text: "Latvia", value: "latvia"},
        {text: "Lebanon", value: "lebanon"},
        {text: "Lithuania", value: "lithuania"},
        {text: "Luxembourg", value: "luxembourg"},
        {text: "Madagascar", value: "madagascar"},
        {text: "Malawi", value: "malawi"},
        {text: "Malaysia", value: "malaysia"},
        {text: "Mali", value: "mali"},
        {text: "Malta", value: "malta"},
        {text: "Mauritius", value: "mauritius"},
        {text: "Mexico", value: "mexico"},
        {text: "Moldova", value: "moldova"},
        {text: "Mongolia", value: "mongolia"},
        {text: "Morocco", value: "morocco"},
        {text: "Mozambique", value: "mozambique"},
        {text: "Myanmar", value: "myanmar"},
        {text: "Nepal", value: "nepal"},
        {text: "Netherlands", value: "netherlands"},
        {text: "New Zealand", value: "new_zealand"},
        {text: "Niger", value: "niger"},
        {text: "Nigeria", value: "nigeria"},
        {text: "North Macedonia", value: "north_macedonia"},
        {text: "Norway", value: "norway"},
        {text: "Oman", value: "oman"},
        {text: "Pakistan", value: "pakistan"},
        {text: "Panama", value: "panama"},
        {text: "Paraguay", value: "paraguay"},
        {text: "Peru", value: "peru"},
        {text: "Philippines", value: "philippines"},
        {text: "Poland", value: "poland"},
        {text: "Portugal", value: "portugal"},
        {text: "Qatar", value: "qatar"},
        {text: "Romania", value: "romania"},
        {text: "Russia", value: "russia"},
        {text: "Rwanda", value: "rwanda"},
        {text: "Saudi Arabia", value: "saudi_arabia"},
        {text: "Senegal", value: "senegal"},
        {text: "Serbia", value: "serbia"},
        {text: "Singapore", value: "singapore"},
        {text: "Slovakia", value: "slovakia"},
        {text: "Slovenia", value: "slovenia"},
        {text: "South Africa", value: "south_africa"},
        {text: "South Korea", value: "south_korea"},
        {text: "Spain", value: "spain"},
        {text: "Sri Lanka", value: "sri_lanka"},
        {text: "Sweden", value: "sweden"},
        {text: "Switzerland", value: "switzerland"},
        {text: "Tanzania", value: "tanzania"},
        {text: "Thailand", value: "thailand"},
        {text: "Togo", value: "togo"},
        {text: "Trinidad and Tobago", value: "trinidad_and_tobago"},
        {text: "Tunisia", value: "tunisia"},
        {text: "Türkiye", value: "turkey"},
        {text: "Uganda", value: "uganda"},
        {text: "Ukraine", value: "ukraine"},
        {text: "United Arab Emirates", value: "united_arab_emirates"},
        {text: "United Kingdom", value: "united_kingdom"},
        {text: "United States", value: "united_states"},
        {text: "Uruguay", value: "uruguay"},
        {text: "Vietnam", value: "vietnam"},
        {text: "Zambia", value: "zambia"},
        {text: "Zimbabwe", value: "zimbabwe"},
      ]
    }
  },
  created() {
    this.fetchCountry()
  },
  methods: {
    fetchCountry() {
      if (this.$route.query.country) {
        this.selected = this.$route.query.country
      }
    }
  },
  watch: {
    selected() {
      this.point.name
    }
  }
}
</script>

<style>

.country-buttons .form-group {
  width: 100%;
}

.btn-group.special {
  display: flex !important;
}

.special .btn {
  flex: 1 !important;
}

.width-100 {
  width: 100%;
}

</style>
