<template>
  <section class="section-hero section-shaped my-0 bg-google" style="background-image: url('/img/brand/bg-hero-201.png')">
    <div class="shape shape-style-1 shape-portulans">
    </div>
    <div class="container shape-container d-flex align-items-center">
      <div class="col px-0" style="z-index: 1000">
        <div class="row ">
          <div class="col-lg-12 text-left">
            <a href="https://portulansinstitute.org/" target="_blank">
              <img src="/img/brand/portulans.png" style="width: 200px;" class="img-fluid animate__animated animate__fadeIn animate__delay-3s">
            </a>
            <h1 style="font-weight: 700" class="font-weight-700 mt-4 animate__animated animate__fadeInUp">Ready for the Future?
            </h1>
            <h4 class="animate__animated animate__fadeIn animate__delay-1s">Becoming Digital Sprinters: Talent, Technology and Innovation as game changers</h4>
          </div>
        </div>
        <hr class="animate__animated animate__fadeIn animate__delay-3s my-2 mb-0">
        <div class="animate__animated animate__fadeIn animate__delay-3s">

          <a @click.prevent
             v-tooltip="{
          content: 'The contents of this report and joint website reflect the views of the authors, who are responsible for the facts and accuracy of the information presented herein. They do not represent the views of Google. Portulans Institute bears the full responsibility of country rankings.',
          class: 'tooltip-custom tooltip-other-custom'
      }"><p class="mt-3 d-inline-flex">Commissioned by</p></a>

          <br>

          <img src="/img/brand/google-logo-colour.png" style="width: 150px;margin-bottom: 40px;opacity: 1">
        </div>
        <div class="btn-wrapper text-right animate__animated animate__fadeIn animate__delay-3s">
          <base-button tag="a"
                       href="/pdfs/Global Future Readiness FREI Report Portulans Institute 2021.pdf"
                       class="mb-3 mb-sm-0"
                       type="primary">
            Read Our Report
          </base-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "Hero",
  components: {
  },
  metaInfo: {
    title: 'Portulans Institute FREI 2021 | Home',
  },
  data() {
    return {
      countries: [
        'Argentina?',
        'Brazil?',
        'Chile?',
        'China?',
        'Colombia?',
        'Egypt?',
        'India?',
        'Indonesia?',
        'Israel?',
        'Jordan?',
        'Kenya?',
        'Kuwait?',
        'Lebanon?',
        'Mexico?',
        'Morocco?',
        'Nigeria?',
        'Peru?',
        'Qatar?',
        'Russia?',
        'Saudi Arabia?',
        'Singapore?',
        'South Africa?',
        'Thailand?',
        'Turkey?',
        'Ukraine?',
        'United Arab Emirates?',
        'Viet Nam?',
      ]
    }
  }
}
</script>
<style>
.vue-typer .custom.char.typed {
  color: #0c7abf;
}
.vue-typer .custom.char.selected {
  color: #4a4a4a;
}
</style>
