<template>
  <section class="section-hero section-shaped my-0">
    <div class="shape shape-style-1 shape-portulans"></div>
    <div class="container shape-container d-flex align-items-center ">
      <div class="col px-0">
        <div class="row">
          <div class="col-12">
          <h3 class="font-weight-700" style="margin-top: 5rem !important;">We'd love to hear your thoughts!</h3>
          <h5 class="">Please direct any questions or comments to:</h5>
          </div>
        </div>
        <hr>
        <div class="row" style="min-height: 400px;">
          <div class="col-lg-4 text-left">
            <img src="/img/brand/portulans.png" style="width: 250px;" class="img-fluid mb-4">
          </div>
          <div class="col-lg-4 text-left">
            <h4 class="m-0">Anna Henry</h4>
            <p class="lead m-0">
              <a href="mailto:anna.henry@portulansinstitute.ch">anna.henry@portulansinstitute.ch</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  metaInfo: {
    title: 'Portulans Institute FREI 2021 | Contact',
  }
};
</script>
<style>
</style>
