<template>
    <header class="header-global portulans-nav">
        <base-nav type="white" transparent effect="light" expand>
            <a target="_blank" href="https://portulansinstitute.org/" slot="brand" class="navbar-brand mr-lg-5">
                  <img src="../../public/img/brand/portulans.png" alt="logo">
            </a>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <a target="_blank" href="https://portulansinstitute.org/">
                        <img src="../../public/img/brand/portulans.png">
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center navbar-dark">
              <li class="nav-item">
                <router-link to="/2022/" title="Home Page"><a class="nav-link nav-link-icon" href="#" rel="noopener">
                  Home
                </a></router-link>
              </li>
              <li class="nav-item">
                <router-link to="/2022/about" title="About Page"><a class="nav-link nav-link-icon" href="#" rel="noopener" data-toggle="tooltip">
                  About FREI
                </a></router-link>
              </li>
              <li class="nav-item">
                <router-link to="/2022/report" title="Report Page"><a class="nav-link nav-link-icon" href="#" rel="noopener" data-toggle="tooltip">
                  Countries
                </a></router-link>
              </li>
              <li class="nav-item">
                <router-link to="/2022/what-ifs" title="Talent Positioning System Page"><a class="nav-link nav-link-icon" href="#" rel="noopener" data-toggle="tooltip">
                  What Ifs
                </a></router-link>
              </li>
              <li class="nav-item">
                <router-link to="/2022/contact-us" title="Talent Positioning System Page"><a class="nav-link nav-link-icon" href="#" rel="noopener" data-toggle="tooltip">
                  Get in Touch
                </a></router-link>
              </li>
            </ul>

        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components-2022/BaseNav";
import CloseButton from "@/components-2022/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton
  }
};
</script>
<style>
</style>
