<template>
  <div class="div">

    <div class="row">

      <div class="col-12">
        <h1 class="text-center mb-3">
          <span v-if="this.selectedCountry === 'Argentina'"><img src="img/flags/ar.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Brazil'"><img src="img/flags/br.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Chile'"><img src="img/flags/ch.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'China'"><img src="img/flags/cn.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Colombia'"><img src="img/flags/co.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Egypt'"><img src="img/flags/eg.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'India'"><img src="img/flags/in.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Indonesia'"><img src="img/flags/id.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Israel'"><img src="img/flags/is.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Jordan'"><img src="img/flags/jo.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Kenya'"><img src="img/flags/ke.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Kuwait'"><img src="img/flags/kw.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Lebanon'"><img src="img/flags/le.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Mexico'"><img src="img/flags/mx.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Morocco'"><img src="img/flags/ma.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Nigeria'"><img src="img/flags/ni.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Peru'"><img src="img/flags/pe.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Qatar'"><img src="img/flags/qa.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Russia'"><img src="img/flags/ru.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Saudi Arabia'"><img src="img/flags/sa.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Singapore'"><img src="img/flags/sg.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'South Africa'"><img src="img/flags/za.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Thailand'"><img src="img/flags/th.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Turkey'"><img src="img/flags/tu.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Ukraine'"><img src="img/flags/ua.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'United Arab Emirates'"><img src="img/flags/ae.png" width="90"/></span>
          <span v-if="this.selectedCountry === 'Viet Nam'"><img src="img/flags/vn.png" width="90"/></span>
          {{ this.selectedCountry }}
        </h1>
      </div>

    </div>

    <div class="row">

      <div class="col-12 text-center mb-4">
        <b-button @click="selectCountry('none')" class="btn-link border-0">Back to <span class="font-weight-700">all countries</span>
        </b-button>
      </div>

    </div>

    <div class="row row-grid align-items-center mt-0">

      <div class="col-md-5 order-md-2 text-center">
        <img :src="this.countryProfiles.countries[this.selectedCountryItem].map_image" class=""
             style="padding: 3em; max-width:400px; width: 100%">
      </div>

      <div class="col-md-7 order-md-1">


        <div class="bg-lightgrey country-container">

          <div class=""
               :style="{ 'background-image': 'url(' + this.countryProfiles.countries[this.selectedCountryItem].country_image + ')' }"
               style="margin: 0px 1em 0px 0px;top: 0px;right: 0px;height: 100%;width: 250px;position: absolute;background-size: cover;background-repeat: no-repeat;">
            <div
                style="    background: linear-gradient(83deg, rgb(250, 250, 250) 22%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0) 100%);height: 100%;"></div>
          </div>

          <ul class="list-unstyled mt-2">
            <li class="py-2">
              <div class="d-flex align-items-center country-stat">
                <div>
                  <p class="stat-header font-weight-900 text-uppercase">future readiness ranking</p>
                  <h6 class="mb-0">
                    {{ this.countryProfiles.countries[this.selectedCountryItem].stats.future_readiness_ranking }}</h6>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center country-stat">
                <div>
                  <p class="stat-header font-weight-900 text-uppercase">population</p>
                  <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountryItem].stats.population }}</h6>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center country-stat">
                <div>
                  <p class="stat-header font-weight-900 text-uppercase">GDP</p>
                  <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountryItem].stats.GDP }}</h6>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center country-stat">
                <div>
                  <p class="stat-header font-weight-900 text-uppercase">GDP PPP</p>
                  <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountryItem].stats.GDP_PPP }}</h6>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center country-stat">
                <div>
                  <p class="stat-header font-weight-900 text-uppercase">income group</p>
                  <h6 class="mb-0">{{
                      this.countryProfiles.countries[this.selectedCountryItem].stats.income_group
                    }}</h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-12 text-center mb-4">
      <b-button @click="selectCountry('none')" class="btn-link border-0">Click here to view the <span
          class="font-weight-700">What Ifs</span> section
      </b-button>
    </div>

    <div class="col-12 text-center" :class="'selected-pillar-' + selectedPillar">
      <div class="row">
        <div @mouseleave="hoverPillar('none')" @mouseover="hoverPillar('future_readiness')"
             class="col-12 total-future-readiness text-center pillar-total">
          <p class="pillar-name">Future Readiness Score</p>
          <p class="pillar-value">{{ countryWhatIfPillarsForGivenScenario.future_readiness_score.score }}<span
              class="font-weight-300 small"> (Rank: {{
              countryWhatIfPillarsForGivenScenario.future_readiness_score.rank
            }})</span></p>
        </div>
      </div>
      <div class="row sub-pillars">
        <div @mouseleave="hoverPillar('none')" @mouseover="hoverPillar('institutions_and_infrastructure')"
             class="col-3 text-center p-0 m-0">
          <div class="pillar-total total-institutions_and_infrastructure mx-1 my-2">
            <p class="pillar-name">Institutions & Infrastructure</p>
            <p class="pillar-value">{{
                countryWhatIfPillarsForGivenScenario.institutions_and_infrastructure.score
              }}<span class="font-weight-300 small"> (Rank: {{
                  countryWhatIfPillarsForGivenScenario.institutions_and_infrastructure.rank
                }})</span></p>
          </div>
        </div>
        <div @mouseleave="hoverPillar('none')" @mouseover="hoverPillar('technology')" class="col-3 text-center p-0 m-0">
          <div class="pillar-total total-technology mx-1 my-2">
            <p class="pillar-name">Technology</p>
            <p class="pillar-value">{{ countryWhatIfPillarsForGivenScenario.technology.score }}<span
                class="font-weight-300 small"> (Rank: {{ countryWhatIfPillarsForGivenScenario.technology.rank }})</span>
            </p>
          </div>
        </div>
        <div @mouseleave="hoverPillar('none')" @mouseover="hoverPillar('talent')" class="col-3 text-center p-0 m-0">
          <div class="pillar-total total-talent mx-1 my-2">
            <p class="pillar-name">Talent</p>
            <p class="pillar-value">{{ countryWhatIfPillarsForGivenScenario.talent.score }}<span
                class="font-weight-300 small"> (Rank: {{ countryWhatIfPillarsForGivenScenario.talent.rank }})</span></p>
          </div>
        </div>
        <div @mouseleave="hoverPillar('none')" @mouseover="hoverPillar('innovation')" class="col-3 text-center p-0 m-0">
          <div class="pillar-total total-innovation mx-1 my-2">
            <p class="pillar-name">Innovation</p>
            <p class="pillar-value">{{ countryWhatIfPillarsForGivenScenario.innovation.score }}<span
                class="font-weight-300 small"> (Rank: {{ countryWhatIfPillarsForGivenScenario.innovation.rank }})</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 p-0" style="overflow: scroll">
      <template>
        <div>
          <b-table id="table-transition"
                   class="rankingsTable subPillarTable"
                   :class="'selected-pillar-' + selectedPillar"
                   :items="countryWhatIfSubPillarsForGivenScenario"
                   :fields="fields"
                   small
                   fixed
                   primary-key="sub_pillar"
                   :tbody-transition-props="transProps">

            <template #row-details="row" :class="'test' + row">

            </template>


          </b-table>
        </div>
      </template>
    </div>

  </div>

</template>

<script>

import countryWhatIfData from '../../data-2021/CountryWhatIfs.json'
import countryWhatIfDataMaximums from '../../data-2021/countryWhatIfDataMaximums.json'
import countryProfiles from '../../data-2021/countryProfiles.json'
import {BTable} from 'bootstrap-vue'

export default {
  name: "WhatIfsTable",
  components: {
    BTable
  },
  props: {
    selectedCountry: {
      default: null
    }
  },
  data() {
    return {
      countryProfiles: countryProfiles,
      selectedPillar: 'none',
      transProps: {
        name: 'flip-list'
      },
      scenario: 'current',
      countryWhatIfDataMaximums: countryWhatIfDataMaximums,
      countryWhatIfData: countryWhatIfData,
      fields: [
        {
          key: 'sub_pillar',
          sortable: false,
          tdClass: "countryColumn",
          thClass: 'th-header pl-3',
          sortDirection: 'desc'
        },
        {key: 'score', sortable: false, tdClass: "", thClass: 'th-header', sortDirection: 'desc'},
        {key: 'rank', sortable: false, tdClass: "", thClass: 'th-header', sortDirection: 'desc'}
      ]
    }
  },

  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    selectedCountryItem() {
      return this.selectedCountry.toLowerCase().replace(/\s/g, '_')
    },
    selectedCountryWhatIfData() {
      return this.countryWhatIfData[this.selectedCountryItem]
    },
    countryWhatIfPillarsForGivenScenario() {
      return this.countryWhatIfData[this.selectedCountryItem][this.scenario].pillars
    },
    countryWhatIfSubPillarsForGivenScenario() {
      return this.countryWhatIfData[this.selectedCountryItem][this.scenario].sub_pillars
    },
    countryWhatIfDataMaximumsForGivenScenario() {
      return this.countryWhatIfDataMaximums[this.scenario]
    },
    sortedArray: function () {
      let arrays = countryWhatIfData

      function compare(a, b) {
        if (a.Country < b.Country)
          return -1;
        if (a.Country > b.Country)
          return 1;
        return 0;
      }

      return arrays.sort(compare);
    }
  },
  methods: {
    backgroundColor(value, key) {
      return 'pillar ' + key
    },
    hoverPillar(pillar) {
      this.selectedPillar = pillar
    },
    selectCountry(country) {
      this.$emit('clickedCountry', country)
    }
  }
}
</script>

<style>

.what-if-buttons {
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

.what-if-buttons .form-group {
  width: 100%;
}

.rankingsTable th:focus {
  outline: 0;
}

.rank {
  background: none !important;
  font-weight: bold;
  margin-top: 5px;
}

.rankingsTable th {
  border-top: solid 5px #FFF !important;
  text-align: center;
}

.rankingsTable th:nth-child(2)[aria-sort='descending'] {
  border-top: solid 5px #3ac7ffFF !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(2)[aria-sort='ascending'] {
  border-top: solid 5px #3ac7ffFF !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='descending'] {
  border-top: solid 5px #4e81c4 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='ascending'] {
  border-top: solid 5px #4e81c4 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='descending'] {
  border-top: solid 5px #cc4c2a !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='ascending'] {
  border-top: solid 5px #cc4c2a !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='descending'] {
  border-top: solid 5px #e2aa23 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='ascending'] {
  border-top: solid 5px #e2aa23 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='descending'] {
  border-top: solid 5px #66ad5d !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='ascending'] {
  border-top: solid 5px #66ad5d !important;
  background: #fbfbfb;
}

.pillar-total {
  transition: all .1s ease-in-out;
  padding: 1em 0 1em 0;
  min-height: 98px;
}

.th-header {
  text-align: left !important;
  border: none !important;
  background: #fafafa;
}

.pillar-name {
  margin: 0;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
}

.pillar-value {
  font-size: larger;
  font-weight: bold;
  margin: 0;
}

.total-future-readiness {
  color: #4a4a4a;
  background: #fafafa;
  padding: 1.5em 0 1.5em 0;
}

.selected-pillar-future_readiness .total-institutions_and_infrastructure {
  background: rgba(78, 129, 196, 1);
  cursor: pointer;
  color: white;
}

.selected-pillar-future_readiness .total-technology {
  background: rgba(102, 173, 93, 1);
  cursor: pointer;
  color: white;
}

.selected-pillar-future_readiness .total-talent {
  background: rgba(226, 170, 35, 1);
  cursor: pointer;
  color: white;
}

.selected-pillar-future_readiness .total-innovation {
  background: rgba(204, 76, 42, 1);
  cursor: pointer;
  color: white;
}

.total-institutions_and_infrastructure {
  color: rgba(78, 129, 196, 1);
  background: #fafafa;
}

.total-technology {
  color: rgba(102, 173, 93, 1);
  background: #fafafa;
}

.total-talent {
  color: rgba(226, 170, 35, 1);
  background: #fafafa;
}

.total-innovation {
  color: rgba(204, 76, 42, 1);
  background: #fafafa;
}

.total-future-readiness:hover {
  background: #3ac7ffFF;
  cursor: pointer;
  color: white;
}

.total-institutions_and_infrastructure:hover {
  background: rgba(78, 129, 196, 1);
  cursor: pointer;
  color: white;
}

.total-technology:hover {
  background: rgba(102, 173, 93, 1);
  cursor: pointer;
  color: white;
}

.total-talent:hover {
  background: rgba(226, 170, 35, 1);
  cursor: pointer;
  color: white;
}

.total-innovation:hover {
  background: rgba(204, 76, 42, 1);
  cursor: pointer;
  color: white;
}

.selected-pillar-institutions_and_infrastructure .table-institutions_and_infrastructure {
  border-left: solid 10px rgba(78, 129, 196, 1);
  border-right: solid 10px rgba(78, 129, 196, 1);
  background: rgba(164, 161, 243, 0.1);
  opacity: 1;
  transition: all .3s ease-in-out;
  color: rgba(78, 129, 196, 1)
}

.table-institutions_and_infrastructure {
  opacity: 0.4;
  border-left: solid 10px #FFFFFF;
}

.selected-pillar-technology .table-technology {
  border-left: solid 10px rgba(102, 173, 93, 1);
  border-right: solid 10px rgba(102, 173, 93, 1);
  background: rgba(102, 173, 93, 0.1);
  opacity: 1;
  transition: all .3s ease-in-out;
}

.table-technology {
  opacity: 0.4;
  border-left: solid 10px #FFFFFF;
  /*background: rgba(102, 173, 93, 0.1);*/
}

.selected-pillar-talent .table-talent {
  border-left: solid 10px rgba(226, 170, 35, 1);
  border-right: solid 10px rgba(226, 170, 35, 1);
  background: rgba(226, 170, 35, 0.1);
  opacity: 1;
  transition: all .3s ease-in-out;
}

.table-talent {
  opacity: 0.4;
  border-left: solid 10px #FFFFFF;
  /*background: rgba(226, 170, 35, 0.1);*/
}


.selected-pillar-innovation .table-innovation {
  border-left: solid 10px rgba(204, 76, 42, 1);
  border-right: solid 10px rgba(204, 76, 42, 1);
  background: rgba(204, 76, 42, 0.1);
  opacity: 1;
  transition: all .3s ease-in-out;
}

.table-innovation {
  opacity: 0.4;
  border-left: solid 10px #FFFFFF;
  border-right: solid 10px #FFFFFF;
  /*background: rgba(204, 76, 42, 0.1);*/
}

.selected-pillar-none .table-institutions_and_infrastructure {
  opacity: 1 !important;
  background-color: #FFFFFF;
  transition: all .3s ease-in-out;
}

.selected-pillar-none .table-technology {
  opacity: 1 !important;
  background-color: #FFFFFF;
  transition: all .3s ease-in-out;
}

.selected-pillar-none .table-talent {
  opacity: 1 !important;
  background-color: #FFFFFF;
  transition: all .3s ease-in-out;
}

.selected-pillar-none .table-innovation {
  opacity: 1 !important;
  background-color: #FFFFFF;
  transition: all .3s ease-in-out;
}


.subPillarTable td {
  border-top: 1px solid #fafafa !important;
  border-bottom: 1px solid #fafafa !important;
  transition: all .1s ease-in-out;
}

.stat-header {
  margin-bottom: 0px;
  font-size: small;
  font-weight: 700 !important;
  color: #4a4a4a !important;
}

.country-container {
  padding: 1em;
  border-radius: 0.25rem;
}

.report-stat-box {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 15px 0;
  text-align: center;
  background: #fafafa;
  margin: 5px 0 5px 0;
  min-height: 90px;
  border-radius: 0.25em;
}

.report-stat-box .field-name {
  /*font-size: 10px;*/
}

.report-stat-box .field-value {
  font-size: larger;
}

.report-stat-box-header {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10px 0;
  text-align: center;
  background: #4a4a4a;
  margin: 5px 0 5px 0;
  min-height: 90px;
  border-radius: 0.25em;
  color: white;
}

.report-stat-box-header .field-value {
  font-size: large;
}

</style>
