<template>
  <div class="row country-profile-buttons">

    <div class="col-12">
      <p class="lead">The country profiles provide summaries of the future readiness performances of each of the twenty-seven Emerging Markets countries included in the FREI report. Each country profile includes data on key indicators, future readiness ranking, breakdown of performances in each of the components of the TTI triangle, and comparative performances against global averages.</p>
    </div>

    <div class="col-12 mt-5">

    <b-form-group v-slot="{ ariaDescribedby }" class="width-100 mb-0">
      <b-form-radio-group
          class="btn-group special"
          id="checkbox-group-1"
          v-model="selected"
          :options="optionsA"
          :aria-describedby="ariaDescribedby"
          buttons
          button-variant="outline-secondary"
          name="buttons2"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group v-slot="{ ariaDescribedby }" class="width-100 mb-0">
      <b-form-radio-group
          class="btn-group special"
          id="checkbox-group-1"
          v-model="selected"
          :options="optionsB"
          :aria-describedby="ariaDescribedby"
          buttons
          button-variant="outline-secondary"
          name="buttons2"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group v-slot="{ ariaDescribedby }" class="width-100 mb-0">
      <b-form-radio-group
          class="btn-group special"
          id="checkbox-group-1"
          v-model="selected"
          :options="optionsC"
          :aria-describedby="ariaDescribedby"
          buttons
          button-variant="outline-secondary"
          name="buttons2"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group v-slot="{ ariaDescribedby }" class="width-100">
      <b-form-radio-group
          class="btn-group special"
          id="checkbox-group-1"
          v-model="selected"
          :options="optionsD"
          :aria-describedby="ariaDescribedby"
          buttons
          button-variant="outline-secondary"
          name="buttons2"
      ></b-form-radio-group>
    </b-form-group>

    </div>

    <country-profile :selected-country="selected"></country-profile>

  </div>
</template>

<script>
import CountryProfile from "@/views-2021/components-2021/CountryProfile";

export default {
  name: "CountryProfiles",
  components: {CountryProfile},
  data() {
    return {
      selected: 'argentina',
      optionsA: [
        {text: "Argentina", value: "argentina"},
        {text: "Brazil", value: "brazil"},
        {text: "Chile", value: "chile"},
        {text: "China", value: "china"},
        {text: "Colombia", value: "colombia"},
        {text: "Egypt", value: "egypt"},
        {text: "India", value: "india"},
      ],
      optionsB: [
        {text: "Indonesia", value: "indonesia"},
        {text: "Israel", value: "israel"},
        {text: "Jordan", value: "jordan"},
        {text: "Kenya", value: "kenya"},
        {text: "Kuwait", value: "kuwait"},
        {text: "Lebanon", value: "lebanon"},
        {text: "Mexico", value: "mexico"},
      ],
      optionsC: [
        {text: "Morocco", value: "morocco"},
        {text: "Nigeria", value: "nigeria"},
        {text: "Peru", value: "peru"},
        {text: "Qatar", value: "qatar"},
        {text: "Russia", value: "russia"},
        {text: "Saudi Arabia", value: "saudi_arabia"},
        {text: "Singapore", value: "singapore"},
      ],
      optionsD: [
        {text: "South Africa", value: "south_africa"},
        {text: "Thailand", value: "thailand"},
        {text: "Turkey", value: "turkey"},
        {text: "Ukraine", value: "ukraine"},
        {text: "United Arab Emirates", value: "united_arab_emirates"},
        {text: "Viet Nam", value: "vietnam"},
      ]
    }
  }
}
</script>

<style>

.country-buttons .form-group {
  width: 100%;
}

.btn-group.special {
  display: flex !important;
}

.special .btn {
  flex: 1 !important;
}

.width-100 {
  width: 100%;
}

</style>
