import data from '../data.json'

const countryMapData = {
    "futureReadiness": [
        {
            "name": "Future Readiness",
            "states": {
                "hover": {
                    "color": "#3ac7ff",
                    "borderColor": "#3ac7ff"
                }
            },
            "borderWidth": 0.2,
            "allAreas": true,
            "joinBy": [
                "hc-key",
                "key"
            ],
            "data": [
                {
                    "key": "al",
                    "name": "al",
                    "rank": data.algeria.future_readiness_rank,
                    "value": data.algeria.future_readiness_score,
                    "score": data.algeria.future_readiness_score,
                    "customText": ""
                },
                {
                    "name": "dz",
                    "key": "dz",
                    "rank": data.albania.future_readiness_rank,
                    "value": data.albania.future_readiness_score,
                    "score": data.albania.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ao",
                    "name": "ao",
                    "rank": data.angola.future_readiness_rank,
                    "value": data.angola.future_readiness_score,
                    "score": data.angola.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ar",
                    "name": "ar",
                    "rank": data.argentina.future_readiness_rank,
                    "value": data.argentina.future_readiness_score,
                    "score": data.argentina.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "am",
                    "name": "am",
                    "rank": data.armenia.future_readiness_rank,
                    "value": data.armenia.future_readiness_score,
                    "score": data.armenia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "au",
                    "name": "au",
                    "rank": data.australia.future_readiness_rank,
                    "value": data.australia.future_readiness_score,
                    "score": data.australia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "at",
                    "name": "at",
                    "rank": data.austria.future_readiness_rank,
                    "value": data.austria.future_readiness_score,
                    "score": data.austria.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "az",
                    "name": "az",
                    "rank": data.azerbaijan.future_readiness_rank,
                    "value": data.azerbaijan.future_readiness_score,
                    "score": data.azerbaijan.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bh",
                    "name": "bh",
                    "rank": data.bahrain.future_readiness_rank,
                    "value": data.bahrain.future_readiness_score,
                    "score": data.bahrain.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bd",
                    "name": "bd",
                    "rank": data.bangladesh.future_readiness_rank,
                    "value": data.bangladesh.future_readiness_score,
                    "score": data.bangladesh.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "by",
                    "name": "by",
                    "rank": data.belarus.future_readiness_rank,
                    "value": data.belarus.future_readiness_score,
                    "score": data.belarus.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "be",
                    "name": "be",
                    "rank": data.belgium.future_readiness_rank,
                    "value": data.belgium.future_readiness_score,
                    "score": data.belgium.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bj",
                    "name": "bj",
                    "rank": data.benin.future_readiness_rank,
                    "value": data.benin.future_readiness_score,
                    "score": data.benin.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bo",
                    "name": "bo",
                    "rank": data.bolivia.future_readiness_rank,
                    "value": data.bolivia.future_readiness_score,
                    "score": data.bolivia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ba",
                    "name": "ba",
                    "rank": data.bosnia_and_herzegovina.future_readiness_rank,
                    "value": data.bosnia_and_herzegovina.future_readiness_score,
                    "score": data.bosnia_and_herzegovina.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bw",
                    "name": "bw",
                    "rank": data.botswana.future_readiness_rank,
                    "value": data.botswana.future_readiness_score,
                    "score": data.botswana.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "br",
                    "name": "br",
                    "rank": data.brazil.future_readiness_rank,
                    "value": data.brazil.future_readiness_score,
                    "score": data.brazil.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bg",
                    "name": "bg",
                    "rank": data.bulgaria.future_readiness_rank,
                    "value": data.bulgaria.future_readiness_score,
                    "score": data.bulgaria.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "bf",
                    "name": "bf",
                    "rank": data.burkina_faso.future_readiness_rank,
                    "value": data.burkina_faso.future_readiness_score,
                    "score": data.burkina_faso.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "kh",
                    "name": "kh",
                    "rank": data.cambodia.future_readiness_rank,
                    "value": data.cambodia.future_readiness_score,
                    "score": data.cambodia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "cm",
                    "name": "cm",
                    "rank": data.cameroon.future_readiness_rank,
                    "value": data.cameroon.future_readiness_score,
                    "score": data.cameroon.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ca",
                    "name": "ca",
                    "rank": data.canada.future_readiness_rank,
                    "value": data.canada.future_readiness_score,
                    "score": data.canada.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "cl",
                    "name": "cl",
                    "rank": data.chile.future_readiness_rank,
                    "value": data.chile.future_readiness_score,
                    "score": data.chile.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "cn",
                    "name": "cn",
                    "rank": data.china.future_readiness_rank,
                    "value": data.china.future_readiness_score,
                    "score": data.china.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "co",
                    "name": "co",
                    "rank": data.colombia.future_readiness_rank,
                    "value": data.colombia.future_readiness_score,
                    "score": data.colombia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "cr",
                    "name": "cr",
                    "rank": data.costa_rica.future_readiness_rank,
                    "value": data.costa_rica.future_readiness_score,
                    "score": data.costa_rica.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ci",
                    "name": "ci",
                    "rank": data.cote_divoire.future_readiness_rank,
                    "value": data.cote_divoire.future_readiness_score,
                    "score": data.cote_divoire.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "hr",
                    "name": "hr",
                    "rank": data.croatia.future_readiness_rank,
                    "value": data.croatia.future_readiness_score,
                    "score": data.croatia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "cy",
                    "name": "cy",
                    "rank": data.cyprus.future_readiness_rank,
                    "value": data.cyprus.future_readiness_score,
                    "score": data.cyprus.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "cz",
                    "name": "cz",
                    "rank": data.czech_republic.future_readiness_rank,
                    "value": data.czech_republic.future_readiness_score,
                    "score": data.czech_republic.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "dk",
                    "name": "dk",
                    "rank": data.denmark.future_readiness_rank,
                    "value": data.denmark.future_readiness_score,
                    "score": data.denmark.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "do",
                    "name": "do",
                    "rank": data.dominican_republic.future_readiness_rank,
                    "value": data.dominican_republic.future_readiness_score,
                    "score": data.dominican_republic.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ec",
                    "name": "ec",
                    "rank": data.ecuador.future_readiness_rank,
                    "value": data.ecuador.future_readiness_score,
                    "score": data.ecuador.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "eg",
                    "name": "eg",
                    "rank": data.egypt.future_readiness_rank,
                    "value": data.egypt.future_readiness_score,
                    "score": data.egypt.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "sv",
                    "name": "sv",
                    "rank": data.el_salvador.future_readiness_rank,
                    "value": data.el_salvador.future_readiness_score,
                    "score": data.el_salvador.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ee",
                    "name": "ee",
                    "rank": data.estonia.future_readiness_rank,
                    "value": data.estonia.future_readiness_score,
                    "score": data.estonia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "et",
                    "name": "et",
                    "rank": data.ethiopia.future_readiness_rank,
                    "value": data.ethiopia.future_readiness_score,
                    "score": data.ethiopia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "fi",
                    "name": "fi",
                    "rank": data.finland.future_readiness_rank,
                    "value": data.finland.future_readiness_score,
                    "score": data.finland.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "fr",
                    "name": "fr",
                    "rank": data.france.future_readiness_rank,
                    "value": data.france.future_readiness_score,
                    "score": data.france.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ge",
                    "name": "ge",
                    "rank": data.georgia.future_readiness_rank,
                    "value": data.georgia.future_readiness_score,
                    "score": data.georgia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "de",
                    "name": "de",
                    "rank": data.germany.future_readiness_rank,
                    "value": data.germany.future_readiness_score,
                    "score": data.germany.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "gh",
                    "name": "gh",
                    "rank": data.ghana.future_readiness_rank,
                    "value": data.ghana.future_readiness_score,
                    "score": data.ghana.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "gr",
                    "name": "gr",
                    "rank": data.greece.future_readiness_rank,
                    "value": data.greece.future_readiness_score,
                    "score": data.greece.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "gl",
                    "name": "gl",
                    "rank": data.guatemala.future_readiness_rank,
                    "value": data.guatemala.future_readiness_score,
                    "score": data.guatemala.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "gn",
                    "name": "gn",
                    "rank": data.guinea.future_readiness_rank,
                    "value": data.guinea.future_readiness_score,
                    "score": data.guinea.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "hn",
                    "name": "hn",
                    "rank": data.honduras.future_readiness_rank,
                    "value": data.honduras.future_readiness_score,
                    "score": data.honduras.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "hu",
                    "name": "hu",
                    "rank": data.hungary.future_readiness_rank,
                    "value": data.hungary.future_readiness_score,
                    "score": data.hungary.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "is",
                    "name": "is",
                    "rank": data.iceland.future_readiness_rank,
                    "value": data.iceland.future_readiness_score,
                    "score": data.iceland.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "in",
                    "name": "in",
                    "rank": data.india.future_readiness_rank,
                    "value": data.india.future_readiness_score,
                    "score": data.india.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "id",
                    "name": "id",
                    "rank": data.indonesia.future_readiness_rank,
                    "value": data.indonesia.future_readiness_score,
                    "score": data.indonesia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "iq",
                    "name": "iq",
                    "rank": data.iran.future_readiness_rank,
                    "value": data.iran.future_readiness_score,
                    "score": data.iran.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ie",
                    "name": "ie",
                    "rank": data.ireland.future_readiness_rank,
                    "value": data.ireland.future_readiness_score,
                    "score": data.ireland.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "il",
                    "name": "il",
                    "rank": data.israel.future_readiness_rank,
                    "value": data.israel.future_readiness_score,
                    "score": data.israel.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "it",
                    "name": "it",
                    "rank": data.italy.future_readiness_rank,
                    "value": data.italy.future_readiness_score,
                    "score": data.italy.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "jm",
                    "name": "jm",
                    "rank": data.jamaica.future_readiness_rank,
                    "value": data.jamaica.future_readiness_score,
                    "score": data.jamaica.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "jp",
                    "name": "jp",
                    "rank": data.japan.future_readiness_rank,
                    "value": data.japan.future_readiness_score,
                    "score": data.japan.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "jo",
                    "name": "jo",
                    "rank": data.jordan.future_readiness_rank,
                    "value": data.jordan.future_readiness_score,
                    "score": data.jordan.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "kz",
                    "name": "kz",
                    "rank": data.kazakhstan.future_readiness_rank,
                    "value": data.kazakhstan.future_readiness_score,
                    "score": data.kazakhstan.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ke",
                    "name": "ke",
                    "rank": data.kenya.future_readiness_rank,
                    "value": data.kenya.future_readiness_score,
                    "score": data.kenya.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "kw",
                    "name": "kw",
                    "rank": data.kuwait.future_readiness_rank,
                    "value": data.kuwait.future_readiness_score,
                    "score": data.kuwait.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "kg",
                    "name": "kg",
                    "rank": data.kyrgyzstan.future_readiness_rank,
                    "value": data.kyrgyzstan.future_readiness_score,
                    "score": data.kyrgyzstan.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "la",
                    "name": "la",
                    "rank": data.laos.future_readiness_rank,
                    "value": data.laos.future_readiness_score,
                    "score": data.laos.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "lv",
                    "name": "lv",
                    "rank": data.latvia.future_readiness_rank,
                    "value": data.latvia.future_readiness_score,
                    "score": data.latvia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "lb",
                    "name": "lb",
                    "rank": data.lebanon.future_readiness_rank,
                    "value": data.lebanon.future_readiness_score,
                    "score": data.lebanon.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "lt",
                    "name": "lt",
                    "rank": data.lithuania.future_readiness_rank,
                    "value": data.lithuania.future_readiness_score,
                    "score": data.lithuania.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "lu",
                    "name": "lu",
                    "rank": data.luxembourg.future_readiness_rank,
                    "value": data.luxembourg.future_readiness_score,
                    "score": data.luxembourg.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mg",
                    "name": "mg",
                    "rank": data.madagascar.future_readiness_rank,
                    "value": data.madagascar.future_readiness_score,
                    "score": data.madagascar.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mw",
                    "name": "mw",
                    "rank": data.malawi.future_readiness_rank,
                    "value": data.malawi.future_readiness_score,
                    "score": data.malawi.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "my",
                    "name": "my",
                    "rank": data.malaysia.future_readiness_rank,
                    "value": data.malaysia.future_readiness_score,
                    "score": data.malaysia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ml",
                    "name": "ml",
                    "rank": data.mali.future_readiness_rank,
                    "value": data.mali.future_readiness_score,
                    "score": data.mali.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mt",
                    "name": "mt",
                    "rank": data.malta.future_readiness_rank,
                    "value": data.malta.future_readiness_score,
                    "score": data.malta.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mu",
                    "name": "mu",
                    "rank": data.mauritius.future_readiness_rank,
                    "value": data.mauritius.future_readiness_score,
                    "score": data.mauritius.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mx",
                    "name": "mx",
                    "rank": data.mexico.future_readiness_rank,
                    "value": data.mexico.future_readiness_score,
                    "score": data.mexico.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "md",
                    "name": "md",
                    "rank": data.moldova.future_readiness_rank,
                    "value": data.moldova.future_readiness_score,
                    "score": data.moldova.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mn",
                    "name": "mn",
                    "rank": data.mongolia.future_readiness_rank,
                    "value": data.mongolia.future_readiness_score,
                    "score": data.mongolia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ma",
                    "name": "ma",
                    "rank": data.morocco.future_readiness_rank,
                    "value": data.morocco.future_readiness_score,
                    "score": data.morocco.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mz",
                    "name": "mz",
                    "rank": data.mozambique.future_readiness_rank,
                    "value": data.mozambique.future_readiness_score,
                    "score": data.mozambique.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mm",
                    "name": "mm",
                    "rank": data.myanmar.future_readiness_rank,
                    "value": data.myanmar.future_readiness_score,
                    "score": data.myanmar.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "np",
                    "name": "np",
                    "rank": data.nepal.future_readiness_rank,
                    "value": data.nepal.future_readiness_score,
                    "score": data.nepal.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "nl",
                    "name": "nl",
                    "rank": data.netherlands.future_readiness_rank,
                    "value": data.netherlands.future_readiness_score,
                    "score": data.netherlands.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "nz",
                    "name": "nz",
                    "rank": data.new_zealand.future_readiness_rank,
                    "value": data.new_zealand.future_readiness_score,
                    "score": data.new_zealand.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ne",
                    "name": "ne",
                    "rank": data.niger.future_readiness_rank,
                    "value": data.niger.future_readiness_score,
                    "score": data.niger.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ng",
                    "name": "ng",
                    "rank": data.nigeria.future_readiness_rank,
                    "value": data.nigeria.future_readiness_score,
                    "score": data.nigeria.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "mk",
                    "name": "mk",
                    "rank": data.north_macedonia.future_readiness_rank,
                    "value": data.north_macedonia.future_readiness_score,
                    "score": data.north_macedonia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "no",
                    "name": "no",
                    "rank": data.norway.future_readiness_rank,
                    "value": data.norway.future_readiness_score,
                    "score": data.norway.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "om",
                    "name": "om",
                    "rank": data.oman.future_readiness_rank,
                    "value": data.oman.future_readiness_score,
                    "score": data.oman.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "pk",
                    "name": "pk",
                    "rank": data.pakistan.future_readiness_rank,
                    "value": data.pakistan.future_readiness_score,
                    "score": data.pakistan.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "pa",
                    "name": "pa",
                    "rank": data.panama.future_readiness_rank,
                    "value": data.panama.future_readiness_score,
                    "score": data.panama.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "py",
                    "name": "py",
                    "rank": data.paraguay.future_readiness_rank,
                    "value": data.paraguay.future_readiness_score,
                    "score": data.paraguay.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "pe",
                    "name": "pe",
                    "rank": data.peru.future_readiness_rank,
                    "value": data.peru.future_readiness_score,
                    "score": data.peru.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ph",
                    "name": "ph",
                    "rank": data.philippines.future_readiness_rank,
                    "value": data.philippines.future_readiness_score,
                    "score": data.philippines.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "pl",
                    "name": "pl",
                    "rank": data.poland.future_readiness_rank,
                    "value": data.poland.future_readiness_score,
                    "score": data.poland.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "pt",
                    "name": "pt",
                    "rank": data.portugal.future_readiness_rank,
                    "value": data.portugal.future_readiness_score,
                    "score": data.portugal.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "qa",
                    "name": "qa",
                    "rank": data.qatar.future_readiness_rank,
                    "value": data.qatar.future_readiness_score,
                    "score": data.qatar.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ro",
                    "name": "ro",
                    "rank": data.romania.future_readiness_rank,
                    "value": data.romania.future_readiness_score,
                    "score": data.romania.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ru",
                    "name": "ru",
                    "rank": data.russia.future_readiness_rank,
                    "value": data.russia.future_readiness_score,
                    "score": data.russia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "rw",
                    "name": "rw",
                    "rank": data.rwanda.future_readiness_rank,
                    "value": data.rwanda.future_readiness_score,
                    "score": data.rwanda.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "sa",
                    "name": "sa",
                    "rank": data.saudi_arabia.future_readiness_rank,
                    "value": data.saudi_arabia.future_readiness_score,
                    "score": data.saudi_arabia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "sn",
                    "name": "sn",
                    "rank": data.senegal.future_readiness_rank,
                    "value": data.senegal.future_readiness_score,
                    "score": data.senegal.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "rs",
                    "name": "rs",
                    "rank": data.serbia.future_readiness_rank,
                    "value": data.serbia.future_readiness_score,
                    "score": data.serbia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "sg",
                    "name": "sg",
                    "rank": data.singapore.future_readiness_rank,
                    "value": data.singapore.future_readiness_score,
                    "score": data.singapore.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "sk",
                    "name": "sk",
                    "rank": data.slovakia.future_readiness_rank,
                    "value": data.slovakia.future_readiness_score,
                    "score": data.slovakia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "si",
                    "name": "si",
                    "rank": data.slovenia.future_readiness_rank,
                    "value": data.slovenia.future_readiness_score,
                    "score": data.slovenia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "za",
                    "name": "za",
                    "rank": data.south_africa.future_readiness_rank,
                    "value": data.south_africa.future_readiness_score,
                    "score": data.south_africa.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "kr",
                    "name": "kr",
                    "rank": data.south_korea.future_readiness_rank,
                    "value": data.south_korea.future_readiness_score,
                    "score": data.south_korea.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "es",
                    "name": "es",
                    "rank": data.spain.future_readiness_rank,
                    "value": data.spain.future_readiness_score,
                    "score": data.spain.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "lk",
                    "name": "lk",
                    "rank": data.sri_lanka.future_readiness_rank,
                    "value": data.sri_lanka.future_readiness_score,
                    "score": data.sri_lanka.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "se",
                    "name": "se",
                    "rank": data.sweden.future_readiness_rank,
                    "value": data.sweden.future_readiness_score,
                    "score": data.sweden.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ch",
                    "name": "ch",
                    "rank": data.switzerland.future_readiness_rank,
                    "value": data.switzerland.future_readiness_score,
                    "score": data.switzerland.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "tz",
                    "name": "tz",
                    "rank": data.tanzania.future_readiness_rank,
                    "value": data.tanzania.future_readiness_score,
                    "score": data.tanzania.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "th",
                    "name": "th",
                    "rank": data.thailand.future_readiness_rank,
                    "value": data.thailand.future_readiness_score,
                    "score": data.thailand.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "tg",
                    "name": "tg",
                    "rank": data.togo.future_readiness_rank,
                    "value": data.togo.future_readiness_score,
                    "score": data.togo.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "tt",
                    "name": "tt",
                    "rank": data.trinidad_and_tobago.future_readiness_rank,
                    "value": data.trinidad_and_tobago.future_readiness_score,
                    "score": data.trinidad_and_tobago.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "tn",
                    "name": "tn",
                    "rank": data.tunisia.future_readiness_rank,
                    "value": data.tunisia.future_readiness_score,
                    "score": data.tunisia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "tr",
                    "name": "tr",
                    "rank": data.turkey.future_readiness_rank,
                    "value": data.turkey.future_readiness_score,
                    "score": data.turkey.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ug",
                    "name": "ug",
                    "rank": data.uganda.future_readiness_rank,
                    "value": data.uganda.future_readiness_score,
                    "score": data.uganda.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ua",
                    "name": "ua",
                    "rank": data.ukraine.future_readiness_rank,
                    "value": data.ukraine.future_readiness_score,
                    "score": data.ukraine.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "ae",
                    "name": "ae",
                    "rank": data.united_arab_emirates.future_readiness_rank,
                    "value": data.united_arab_emirates.future_readiness_score,
                    "score": data.united_arab_emirates.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "gb",
                    "name": "gb",
                    "rank": data.united_kingdom.future_readiness_rank,
                    "value": data.united_kingdom.future_readiness_score,
                    "score": data.united_kingdom.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "us",
                    "name": "us",
                    "rank": data.united_states.future_readiness_rank,
                    "value": data.united_states.future_readiness_score,
                    "score": data.united_states.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "uy",
                    "name": "uy",
                    "rank": data.uruguay.future_readiness_rank,
                    "value": data.uruguay.future_readiness_score,
                    "score": data.uruguay.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "vn",
                    "name": "vn",
                    "rank": data.vietnam.future_readiness_rank,
                    "value": data.vietnam.future_readiness_score,
                    "score": data.vietnam.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "zm",
                    "name": "zm",
                    "rank": data.zambia.future_readiness_rank,
                    "value": data.zambia.future_readiness_score,
                    "score": data.zambia.future_readiness_score,
                    "customText": ""
                },
                {
                    "key": "zw",
                    "name": "zw",
                    "rank": data.zimbabwe.future_readiness_rank,
                    "value": data.zimbabwe.future_readiness_score,
                    "score": data.zimbabwe.future_readiness_score,
                    "customText": ""
                }
            ]
        }
    ],
    "physicalCapital": {
        "name": "Physical Capital",
        "states": {
            "hover": {
                "color": "#4e81c4",
                "borderColor": "#4e81c4"
            }
        },
        "borderWidth": 0.2,
        "allAreas": true,
        "joinBy": [
            "hc-key",
            "key"
        ],
        "data": [
            {
                "key": "al",
                "name": "al",
                "rank": data.algeria.physical_capital_rank,
                "value": data.algeria.physical_capital,
                "score": data.algeria.physical_capital,
                "customText": ""
            },
            {
                "name": "dz",
                "key": "dz",
                "rank": data.albania.physical_capital_rank,
                "value": data.albania.physical_capital,
                "score": data.albania.physical_capital,
                "customText": ""
            },
            {
                "key": "ao",
                "name": "ao",
                "rank": data.angola.physical_capital_rank,
                "value": data.angola.physical_capital,
                "score": data.angola.physical_capital,
                "customText": ""
            },
            {
                "key": "ar",
                "name": "ar",
                "rank": data.argentina.physical_capital_rank,
                "value": data.argentina.physical_capital,
                "score": data.argentina.physical_capital,
                "customText": ""
            },
            {
                "key": "am",
                "name": "am",
                "rank": data.armenia.physical_capital_rank,
                "value": data.armenia.physical_capital,
                "score": data.armenia.physical_capital,
                "customText": ""
            },
            {
                "key": "au",
                "name": "au",
                "rank": data.australia.physical_capital_rank,
                "value": data.australia.physical_capital,
                "score": data.australia.physical_capital,
                "customText": ""
            },
            {
                "key": "at",
                "name": "at",
                "rank": data.austria.physical_capital_rank,
                "value": data.austria.physical_capital,
                "score": data.austria.physical_capital,
                "customText": ""
            },
            {
                "key": "az",
                "name": "az",
                "rank": data.azerbaijan.physical_capital_rank,
                "value": data.azerbaijan.physical_capital,
                "score": data.azerbaijan.physical_capital,
                "customText": ""
            },
            {
                "key": "bh",
                "name": "bh",
                "rank": data.bahrain.physical_capital_rank,
                "value": data.bahrain.physical_capital,
                "score": data.bahrain.physical_capital,
                "customText": ""
            },
            {
                "key": "bd",
                "name": "bd",
                "rank": data.bangladesh.physical_capital_rank,
                "value": data.bangladesh.physical_capital,
                "score": data.bangladesh.physical_capital,
                "customText": ""
            },
            {
                "key": "by",
                "name": "by",
                "rank": data.belarus.physical_capital_rank,
                "value": data.belarus.physical_capital,
                "score": data.belarus.physical_capital,
                "customText": ""
            },
            {
                "key": "be",
                "name": "be",
                "rank": data.belgium.physical_capital_rank,
                "value": data.belgium.physical_capital,
                "score": data.belgium.physical_capital,
                "customText": ""
            },
            {
                "key": "bj",
                "name": "bj",
                "rank": data.benin.physical_capital_rank,
                "value": data.benin.physical_capital,
                "score": data.benin.physical_capital,
                "customText": ""
            },
            {
                "key": "bo",
                "name": "bo",
                "rank": data.bolivia.physical_capital_rank,
                "value": data.bolivia.physical_capital,
                "score": data.bolivia.physical_capital,
                "customText": ""
            },
            {
                "key": "ba",
                "name": "ba",
                "rank": data.bosnia_and_herzegovina.physical_capital_rank,
                "value": data.bosnia_and_herzegovina.physical_capital,
                "score": data.bosnia_and_herzegovina.physical_capital,
                "customText": ""
            },
            {
                "key": "bw",
                "name": "bw",
                "rank": data.botswana.physical_capital_rank,
                "value": data.botswana.physical_capital,
                "score": data.botswana.physical_capital,
                "customText": ""
            },
            {
                "key": "br",
                "name": "br",
                "rank": data.brazil.physical_capital_rank,
                "value": data.brazil.physical_capital,
                "score": data.brazil.physical_capital,
                "customText": ""
            },
            {
                "key": "bg",
                "name": "bg",
                "rank": data.bulgaria.physical_capital_rank,
                "value": data.bulgaria.physical_capital,
                "score": data.bulgaria.physical_capital,
                "customText": ""
            },
            {
                "key": "bf",
                "name": "bf",
                "rank": data.burkina_faso.physical_capital_rank,
                "value": data.burkina_faso.physical_capital,
                "score": data.burkina_faso.physical_capital,
                "customText": ""
            },
            {
                "key": "kh",
                "name": "kh",
                "rank": data.cambodia.physical_capital_rank,
                "value": data.cambodia.physical_capital,
                "score": data.cambodia.physical_capital,
                "customText": ""
            },
            {
                "key": "cm",
                "name": "cm",
                "rank": data.cameroon.physical_capital_rank,
                "value": data.cameroon.physical_capital,
                "score": data.cameroon.physical_capital,
                "customText": ""
            },
            {
                "key": "ca",
                "name": "ca",
                "rank": data.canada.physical_capital_rank,
                "value": data.canada.physical_capital,
                "score": data.canada.physical_capital,
                "customText": ""
            },
            {
                "key": "cl",
                "name": "cl",
                "rank": data.chile.physical_capital_rank,
                "value": data.chile.physical_capital,
                "score": data.chile.physical_capital,
                "customText": ""
            },
            {
                "key": "cn",
                "name": "cn",
                "rank": data.china.physical_capital_rank,
                "value": data.china.physical_capital,
                "score": data.china.physical_capital,
                "customText": ""
            },
            {
                "key": "co",
                "name": "co",
                "rank": data.colombia.physical_capital_rank,
                "value": data.colombia.physical_capital,
                "score": data.colombia.physical_capital,
                "customText": ""
            },
            {
                "key": "cr",
                "name": "cr",
                "rank": data.costa_rica.physical_capital_rank,
                "value": data.costa_rica.physical_capital,
                "score": data.costa_rica.physical_capital,
                "customText": ""
            },
            {
                "key": "ci",
                "name": "ci",
                "rank": data.cote_divoire.physical_capital_rank,
                "value": data.cote_divoire.physical_capital,
                "score": data.cote_divoire.physical_capital,
                "customText": ""
            },
            {
                "key": "hr",
                "name": "hr",
                "rank": data.croatia.physical_capital_rank,
                "value": data.croatia.physical_capital,
                "score": data.croatia.physical_capital,
                "customText": ""
            },
            {
                "key": "cy",
                "name": "cy",
                "rank": data.cyprus.physical_capital_rank,
                "value": data.cyprus.physical_capital,
                "score": data.cyprus.physical_capital,
                "customText": ""
            },
            {
                "key": "cz",
                "name": "cz",
                "rank": data.czech_republic.physical_capital_rank,
                "value": data.czech_republic.physical_capital,
                "score": data.czech_republic.physical_capital,
                "customText": ""
            },
            {
                "key": "dk",
                "name": "dk",
                "rank": data.denmark.physical_capital_rank,
                "value": data.denmark.physical_capital,
                "score": data.denmark.physical_capital,
                "customText": ""
            },
            {
                "key": "do",
                "name": "do",
                "rank": data.dominican_republic.physical_capital_rank,
                "value": data.dominican_republic.physical_capital,
                "score": data.dominican_republic.physical_capital,
                "customText": ""
            },
            {
                "key": "ec",
                "name": "ec",
                "rank": data.ecuador.physical_capital_rank,
                "value": data.ecuador.physical_capital,
                "score": data.ecuador.physical_capital,
                "customText": ""
            },
            {
                "key": "eg",
                "name": "eg",
                "rank": data.egypt.physical_capital_rank,
                "value": data.egypt.physical_capital,
                "score": data.egypt.physical_capital,
                "customText": ""
            },
            {
                "key": "sv",
                "name": "sv",
                "rank": data.el_salvador.physical_capital_rank,
                "value": data.el_salvador.physical_capital,
                "score": data.el_salvador.physical_capital,
                "customText": ""
            },
            {
                "key": "ee",
                "name": "ee",
                "rank": data.estonia.physical_capital_rank,
                "value": data.estonia.physical_capital,
                "score": data.estonia.physical_capital,
                "customText": ""
            },
            {
                "key": "et",
                "name": "et",
                "rank": data.ethiopia.physical_capital_rank,
                "value": data.ethiopia.physical_capital,
                "score": data.ethiopia.physical_capital,
                "customText": ""
            },
            {
                "key": "fi",
                "name": "fi",
                "rank": data.finland.physical_capital_rank,
                "value": data.finland.physical_capital,
                "score": data.finland.physical_capital,
                "customText": ""
            },
            {
                "key": "fr",
                "name": "fr",
                "rank": data.france.physical_capital_rank,
                "value": data.france.physical_capital,
                "score": data.france.physical_capital,
                "customText": ""
            },
            {
                "key": "ge",
                "name": "ge",
                "rank": data.georgia.physical_capital_rank,
                "value": data.georgia.physical_capital,
                "score": data.georgia.physical_capital,
                "customText": ""
            },
            {
                "key": "de",
                "name": "de",
                "rank": data.germany.physical_capital_rank,
                "value": data.germany.physical_capital,
                "score": data.germany.physical_capital,
                "customText": ""
            },
            {
                "key": "gh",
                "name": "gh",
                "rank": data.ghana.physical_capital_rank,
                "value": data.ghana.physical_capital,
                "score": data.ghana.physical_capital,
                "customText": ""
            },
            {
                "key": "gr",
                "name": "gr",
                "rank": data.greece.physical_capital_rank,
                "value": data.greece.physical_capital,
                "score": data.greece.physical_capital,
                "customText": ""
            },
            {
                "key": "gl",
                "name": "gl",
                "rank": data.guatemala.physical_capital_rank,
                "value": data.guatemala.physical_capital,
                "score": data.guatemala.physical_capital,
                "customText": ""
            },
            {
                "key": "gn",
                "name": "gn",
                "rank": data.guinea.physical_capital_rank,
                "value": data.guinea.physical_capital,
                "score": data.guinea.physical_capital,
                "customText": ""
            },
            {
                "key": "hn",
                "name": "hn",
                "rank": data.honduras.physical_capital_rank,
                "value": data.honduras.physical_capital,
                "score": data.honduras.physical_capital,
                "customText": ""
            },
            {
                "key": "hu",
                "name": "hu",
                "rank": data.hungary.physical_capital_rank,
                "value": data.hungary.physical_capital,
                "score": data.hungary.physical_capital,
                "customText": ""
            },
            {
                "key": "is",
                "name": "is",
                "rank": data.iceland.physical_capital_rank,
                "value": data.iceland.physical_capital,
                "score": data.iceland.physical_capital,
                "customText": ""
            },
            {
                "key": "in",
                "name": "in",
                "rank": data.india.physical_capital_rank,
                "value": data.india.physical_capital,
                "score": data.india.physical_capital,
                "customText": ""
            },
            {
                "key": "id",
                "name": "id",
                "rank": data.indonesia.physical_capital_rank,
                "value": data.indonesia.physical_capital,
                "score": data.indonesia.physical_capital,
                "customText": ""
            },
            {
                "key": "iq",
                "name": "iq",
                "rank": data.iran.physical_capital_rank,
                "value": data.iran.physical_capital,
                "score": data.iran.physical_capital,
                "customText": ""
            },
            {
                "key": "ie",
                "name": "ie",
                "rank": data.ireland.physical_capital_rank,
                "value": data.ireland.physical_capital,
                "score": data.ireland.physical_capital,
                "customText": ""
            },
            {
                "key": "il",
                "name": "il",
                "rank": data.israel.physical_capital_rank,
                "value": data.israel.physical_capital,
                "score": data.israel.physical_capital,
                "customText": ""
            },
            {
                "key": "it",
                "name": "it",
                "rank": data.italy.physical_capital_rank,
                "value": data.italy.physical_capital,
                "score": data.italy.physical_capital,
                "customText": ""
            },
            {
                "key": "jm",
                "name": "jm",
                "rank": data.jamaica.physical_capital_rank,
                "value": data.jamaica.physical_capital,
                "score": data.jamaica.physical_capital,
                "customText": ""
            },
            {
                "key": "jp",
                "name": "jp",
                "rank": data.japan.physical_capital_rank,
                "value": data.japan.physical_capital,
                "score": data.japan.physical_capital,
                "customText": ""
            },
            {
                "key": "jo",
                "name": "jo",
                "rank": data.jordan.physical_capital_rank,
                "value": data.jordan.physical_capital,
                "score": data.jordan.physical_capital,
                "customText": ""
            },
            {
                "key": "kz",
                "name": "kz",
                "rank": data.kazakhstan.physical_capital_rank,
                "value": data.kazakhstan.physical_capital,
                "score": data.kazakhstan.physical_capital,
                "customText": ""
            },
            {
                "key": "ke",
                "name": "ke",
                "rank": data.kenya.physical_capital_rank,
                "value": data.kenya.physical_capital,
                "score": data.kenya.physical_capital,
                "customText": ""
            },
            {
                "key": "kw",
                "name": "kw",
                "rank": data.kuwait.physical_capital_rank,
                "value": data.kuwait.physical_capital,
                "score": data.kuwait.physical_capital,
                "customText": ""
            },
            {
                "key": "kg",
                "name": "kg",
                "rank": data.kyrgyzstan.physical_capital_rank,
                "value": data.kyrgyzstan.physical_capital,
                "score": data.kyrgyzstan.physical_capital,
                "customText": ""
            },
            {
                "key": "la",
                "name": "la",
                "rank": data.laos.physical_capital_rank,
                "value": data.laos.physical_capital,
                "score": data.laos.physical_capital,
                "customText": ""
            },
            {
                "key": "lv",
                "name": "lv",
                "rank": data.latvia.physical_capital_rank,
                "value": data.latvia.physical_capital,
                "score": data.latvia.physical_capital,
                "customText": ""
            },
            {
                "key": "lb",
                "name": "lb",
                "rank": data.lebanon.physical_capital_rank,
                "value": data.lebanon.physical_capital,
                "score": data.lebanon.physical_capital,
                "customText": ""
            },
            {
                "key": "lt",
                "name": "lt",
                "rank": data.lithuania.physical_capital_rank,
                "value": data.lithuania.physical_capital,
                "score": data.lithuania.physical_capital,
                "customText": ""
            },
            {
                "key": "lu",
                "name": "lu",
                "rank": data.luxembourg.physical_capital_rank,
                "value": data.luxembourg.physical_capital,
                "score": data.luxembourg.physical_capital,
                "customText": ""
            },
            {
                "key": "mg",
                "name": "mg",
                "rank": data.madagascar.physical_capital_rank,
                "value": data.madagascar.physical_capital,
                "score": data.madagascar.physical_capital,
                "customText": ""
            },
            {
                "key": "mw",
                "name": "mw",
                "rank": data.malawi.physical_capital_rank,
                "value": data.malawi.physical_capital,
                "score": data.malawi.physical_capital,
                "customText": ""
            },
            {
                "key": "my",
                "name": "my",
                "rank": data.malaysia.physical_capital_rank,
                "value": data.malaysia.physical_capital,
                "score": data.malaysia.physical_capital,
                "customText": ""
            },
            {
                "key": "ml",
                "name": "ml",
                "rank": data.mali.physical_capital_rank,
                "value": data.mali.physical_capital,
                "score": data.mali.physical_capital,
                "customText": ""
            },
            {
                "key": "mt",
                "name": "mt",
                "rank": data.malta.physical_capital_rank,
                "value": data.malta.physical_capital,
                "score": data.malta.physical_capital,
                "customText": ""
            },
            {
                "key": "mu",
                "name": "mu",
                "rank": data.mauritius.physical_capital_rank,
                "value": data.mauritius.physical_capital,
                "score": data.mauritius.physical_capital,
                "customText": ""
            },
            {
                "key": "mx",
                "name": "mx",
                "rank": data.mexico.physical_capital_rank,
                "value": data.mexico.physical_capital,
                "score": data.mexico.physical_capital,
                "customText": ""
            },
            {
                "key": "md",
                "name": "md",
                "rank": data.moldova.physical_capital_rank,
                "value": data.moldova.physical_capital,
                "score": data.moldova.physical_capital,
                "customText": ""
            },
            {
                "key": "mn",
                "name": "mn",
                "rank": data.mongolia.physical_capital_rank,
                "value": data.mongolia.physical_capital,
                "score": data.mongolia.physical_capital,
                "customText": ""
            },
            {
                "key": "ma",
                "name": "ma",
                "rank": data.morocco.physical_capital_rank,
                "value": data.morocco.physical_capital,
                "score": data.morocco.physical_capital,
                "customText": ""
            },
            {
                "key": "mz",
                "name": "mz",
                "rank": data.mozambique.physical_capital_rank,
                "value": data.mozambique.physical_capital,
                "score": data.mozambique.physical_capital,
                "customText": ""
            },
            {
                "key": "mm",
                "name": "mm",
                "rank": data.myanmar.physical_capital_rank,
                "value": data.myanmar.physical_capital,
                "score": data.myanmar.physical_capital,
                "customText": ""
            },
            {
                "key": "np",
                "name": "np",
                "rank": data.nepal.physical_capital_rank,
                "value": data.nepal.physical_capital,
                "score": data.nepal.physical_capital,
                "customText": ""
            },
            {
                "key": "nl",
                "name": "nl",
                "rank": data.netherlands.physical_capital_rank,
                "value": data.netherlands.physical_capital,
                "score": data.netherlands.physical_capital,
                "customText": ""
            },
            {
                "key": "nz",
                "name": "nz",
                "rank": data.new_zealand.physical_capital_rank,
                "value": data.new_zealand.physical_capital,
                "score": data.new_zealand.physical_capital,
                "customText": ""
            },
            {
                "key": "ne",
                "name": "ne",
                "rank": data.niger.physical_capital_rank,
                "value": data.niger.physical_capital,
                "score": data.niger.physical_capital,
                "customText": ""
            },
            {
                "key": "ng",
                "name": "ng",
                "rank": data.nigeria.physical_capital_rank,
                "value": data.nigeria.physical_capital,
                "score": data.nigeria.physical_capital,
                "customText": ""
            },
            {
                "key": "mk",
                "name": "mk",
                "rank": data.north_macedonia.physical_capital_rank,
                "value": data.north_macedonia.physical_capital,
                "score": data.north_macedonia.physical_capital,
                "customText": ""
            },
            {
                "key": "no",
                "name": "no",
                "rank": data.norway.physical_capital_rank,
                "value": data.norway.physical_capital,
                "score": data.norway.physical_capital,
                "customText": ""
            },
            {
                "key": "om",
                "name": "om",
                "rank": data.oman.physical_capital_rank,
                "value": data.oman.physical_capital,
                "score": data.oman.physical_capital,
                "customText": ""
            },
            {
                "key": "pk",
                "name": "pk",
                "rank": data.pakistan.physical_capital_rank,
                "value": data.pakistan.physical_capital,
                "score": data.pakistan.physical_capital,
                "customText": ""
            },
            {
                "key": "pa",
                "name": "pa",
                "rank": data.panama.physical_capital_rank,
                "value": data.panama.physical_capital,
                "score": data.panama.physical_capital,
                "customText": ""
            },
            {
                "key": "py",
                "name": "py",
                "rank": data.paraguay.physical_capital_rank,
                "value": data.paraguay.physical_capital,
                "score": data.paraguay.physical_capital,
                "customText": ""
            },
            {
                "key": "pe",
                "name": "pe",
                "rank": data.peru.physical_capital_rank,
                "value": data.peru.physical_capital,
                "score": data.peru.physical_capital,
                "customText": ""
            },
            {
                "key": "ph",
                "name": "ph",
                "rank": data.philippines.physical_capital_rank,
                "value": data.philippines.physical_capital,
                "score": data.philippines.physical_capital,
                "customText": ""
            },
            {
                "key": "pl",
                "name": "pl",
                "rank": data.poland.physical_capital_rank,
                "value": data.poland.physical_capital,
                "score": data.poland.physical_capital,
                "customText": ""
            },
            {
                "key": "pt",
                "name": "pt",
                "rank": data.portugal.physical_capital_rank,
                "value": data.portugal.physical_capital,
                "score": data.portugal.physical_capital,
                "customText": ""
            },
            {
                "key": "qa",
                "name": "qa",
                "rank": data.qatar.physical_capital_rank,
                "value": data.qatar.physical_capital,
                "score": data.qatar.physical_capital,
                "customText": ""
            },
            {
                "key": "ro",
                "name": "ro",
                "rank": data.romania.physical_capital_rank,
                "value": data.romania.physical_capital,
                "score": data.romania.physical_capital,
                "customText": ""
            },
            {
                "key": "ru",
                "name": "ru",
                "rank": data.russia.physical_capital_rank,
                "value": data.russia.physical_capital,
                "score": data.russia.physical_capital,
                "customText": ""
            },
            {
                "key": "rw",
                "name": "rw",
                "rank": data.rwanda.physical_capital_rank,
                "value": data.rwanda.physical_capital,
                "score": data.rwanda.physical_capital,
                "customText": ""
            },
            {
                "key": "sa",
                "name": "sa",
                "rank": data.saudi_arabia.physical_capital_rank,
                "value": data.saudi_arabia.physical_capital,
                "score": data.saudi_arabia.physical_capital,
                "customText": ""
            },
            {
                "key": "sn",
                "name": "sn",
                "rank": data.senegal.physical_capital_rank,
                "value": data.senegal.physical_capital,
                "score": data.senegal.physical_capital,
                "customText": ""
            },
            {
                "key": "rs",
                "name": "rs",
                "rank": data.serbia.physical_capital_rank,
                "value": data.serbia.physical_capital,
                "score": data.serbia.physical_capital,
                "customText": ""
            },
            {
                "key": "sg",
                "name": "sg",
                "rank": data.singapore.physical_capital_rank,
                "value": data.singapore.physical_capital,
                "score": data.singapore.physical_capital,
                "customText": ""
            },
            {
                "key": "sk",
                "name": "sk",
                "rank": data.slovakia.physical_capital_rank,
                "value": data.slovakia.physical_capital,
                "score": data.slovakia.physical_capital,
                "customText": ""
            },
            {
                "key": "si",
                "name": "si",
                "rank": data.slovenia.physical_capital_rank,
                "value": data.slovenia.physical_capital,
                "score": data.slovenia.physical_capital,
                "customText": ""
            },
            {
                "key": "za",
                "name": "za",
                "rank": data.south_africa.physical_capital_rank,
                "value": data.south_africa.physical_capital,
                "score": data.south_africa.physical_capital,
                "customText": ""
            },
            {
                "key": "kr",
                "name": "kr",
                "rank": data.south_korea.physical_capital_rank,
                "value": data.south_korea.physical_capital,
                "score": data.south_korea.physical_capital,
                "customText": ""
            },
            {
                "key": "es",
                "name": "es",
                "rank": data.spain.physical_capital_rank,
                "value": data.spain.physical_capital,
                "score": data.spain.physical_capital,
                "customText": ""
            },
            {
                "key": "lk",
                "name": "lk",
                "rank": data.sri_lanka.physical_capital_rank,
                "value": data.sri_lanka.physical_capital,
                "score": data.sri_lanka.physical_capital,
                "customText": ""
            },
            {
                "key": "se",
                "name": "se",
                "rank": data.sweden.physical_capital_rank,
                "value": data.sweden.physical_capital,
                "score": data.sweden.physical_capital,
                "customText": ""
            },
            {
                "key": "ch",
                "name": "ch",
                "rank": data.switzerland.physical_capital_rank,
                "value": data.switzerland.physical_capital,
                "score": data.switzerland.physical_capital,
                "customText": ""
            },
            {
                "key": "tz",
                "name": "tz",
                "rank": data.tanzania.physical_capital_rank,
                "value": data.tanzania.physical_capital,
                "score": data.tanzania.physical_capital,
                "customText": ""
            },
            {
                "key": "th",
                "name": "th",
                "rank": data.thailand.physical_capital_rank,
                "value": data.thailand.physical_capital,
                "score": data.thailand.physical_capital,
                "customText": ""
            },
            {
                "key": "tg",
                "name": "tg",
                "rank": data.togo.physical_capital_rank,
                "value": data.togo.physical_capital,
                "score": data.togo.physical_capital,
                "customText": ""
            },
            {
                "key": "tt",
                "name": "tt",
                "rank": data.trinidad_and_tobago.physical_capital_rank,
                "value": data.trinidad_and_tobago.physical_capital,
                "score": data.trinidad_and_tobago.physical_capital,
                "customText": ""
            },
            {
                "key": "tn",
                "name": "tn",
                "rank": data.tunisia.physical_capital_rank,
                "value": data.tunisia.physical_capital,
                "score": data.tunisia.physical_capital,
                "customText": ""
            },
            {
                "key": "tr",
                "name": "tr",
                "rank": data.turkey.physical_capital_rank,
                "value": data.turkey.physical_capital,
                "score": data.turkey.physical_capital,
                "customText": ""
            },
            {
                "key": "ug",
                "name": "ug",
                "rank": data.uganda.physical_capital_rank,
                "value": data.uganda.physical_capital,
                "score": data.uganda.physical_capital,
                "customText": ""
            },
            {
                "key": "ua",
                "name": "ua",
                "rank": data.ukraine.physical_capital_rank,
                "value": data.ukraine.physical_capital,
                "score": data.ukraine.physical_capital,
                "customText": ""
            },
            {
                "key": "ae",
                "name": "ae",
                "rank": data.united_arab_emirates.physical_capital_rank,
                "value": data.united_arab_emirates.physical_capital,
                "score": data.united_arab_emirates.physical_capital,
                "customText": ""
            },
            {
                "key": "gb",
                "name": "gb",
                "rank": data.united_kingdom.physical_capital_rank,
                "value": data.united_kingdom.physical_capital,
                "score": data.united_kingdom.physical_capital,
                "customText": ""
            },
            {
                "key": "us",
                "name": "us",
                "rank": data.united_states.physical_capital_rank,
                "value": data.united_states.physical_capital,
                "score": data.united_states.physical_capital,
                "customText": ""
            },
            {
                "key": "uy",
                "name": "uy",
                "rank": data.uruguay.physical_capital_rank,
                "value": data.uruguay.physical_capital,
                "score": data.uruguay.physical_capital,
                "customText": ""
            },
            {
                "key": "vn",
                "name": "vn",
                "rank": data.vietnam.physical_capital_rank,
                "value": data.vietnam.physical_capital,
                "score": data.vietnam.physical_capital,
                "customText": ""
            },
            {
                "key": "zm",
                "name": "zm",
                "rank": data.zambia.physical_capital_rank,
                "value": data.zambia.physical_capital,
                "score": data.zambia.physical_capital,
                "customText": ""
            },
            {
                "key": "zw",
                "name": "zw",
                "rank": data.zimbabwe.physical_capital_rank,
                "value": data.zimbabwe.physical_capital,
                "score": data.zimbabwe.physical_capital,
                "customText": ""
            }
        ]
    },
    "technology": [
        {
            "name": "Technology",
            "states": {
                "hover": {
                    "color": "#cc4c2a",
                    "borderColor": "#cc4c2a"
                }
            },
            "borderWidth": 0.2,
            "allAreas": true,
            "joinBy": [
                "hc-key",
                "key"
            ],
            "data": [
                {
                    "key": "al",
                    "name": "al",
                    "rank": data.algeria.technology_rank,
                    "value": data.algeria.technology,
                    "score": data.algeria.technology,
                    "customText": ""
                },
                {
                    "name": "dz",
                    "key": "dz",
                    "rank": data.albania.technology_rank,
                    "value": data.albania.technology,
                    "score": data.albania.technology,
                    "customText": ""
                },
                {
                    "key": "ao",
                    "name": "ao",
                    "rank": data.angola.technology_rank,
                    "value": data.angola.technology,
                    "score": data.angola.technology,
                    "customText": ""
                },
                {
                    "key": "ar",
                    "name": "ar",
                    "rank": data.argentina.technology_rank,
                    "value": data.argentina.technology,
                    "score": data.argentina.technology,
                    "customText": ""
                },
                {
                    "key": "am",
                    "name": "am",
                    "rank": data.armenia.technology_rank,
                    "value": data.armenia.technology,
                    "score": data.armenia.technology,
                    "customText": ""
                },
                {
                    "key": "au",
                    "name": "au",
                    "rank": data.australia.technology_rank,
                    "value": data.australia.technology,
                    "score": data.australia.technology,
                    "customText": ""
                },
                {
                    "key": "at",
                    "name": "at",
                    "rank": data.austria.technology_rank,
                    "value": data.austria.technology,
                    "score": data.austria.technology,
                    "customText": ""
                },
                {
                    "key": "az",
                    "name": "az",
                    "rank": data.azerbaijan.technology_rank,
                    "value": data.azerbaijan.technology,
                    "score": data.azerbaijan.technology,
                    "customText": ""
                },
                {
                    "key": "bh",
                    "name": "bh",
                    "rank": data.bahrain.technology_rank,
                    "value": data.bahrain.technology,
                    "score": data.bahrain.technology,
                    "customText": ""
                },
                {
                    "key": "bd",
                    "name": "bd",
                    "rank": data.bangladesh.technology_rank,
                    "value": data.bangladesh.technology,
                    "score": data.bangladesh.technology,
                    "customText": ""
                },
                {
                    "key": "by",
                    "name": "by",
                    "rank": data.belarus.technology_rank,
                    "value": data.belarus.technology,
                    "score": data.belarus.technology,
                    "customText": ""
                },
                {
                    "key": "be",
                    "name": "be",
                    "rank": data.belgium.technology_rank,
                    "value": data.belgium.technology,
                    "score": data.belgium.technology,
                    "customText": ""
                },
                {
                    "key": "bj",
                    "name": "bj",
                    "rank": data.benin.technology_rank,
                    "value": data.benin.technology,
                    "score": data.benin.technology,
                    "customText": ""
                },
                {
                    "key": "bo",
                    "name": "bo",
                    "rank": data.bolivia.technology_rank,
                    "value": data.bolivia.technology,
                    "score": data.bolivia.technology,
                    "customText": ""
                },
                {
                    "key": "ba",
                    "name": "ba",
                    "rank": data.bosnia_and_herzegovina.technology_rank,
                    "value": data.bosnia_and_herzegovina.technology,
                    "score": data.bosnia_and_herzegovina.technology,
                    "customText": ""
                },
                {
                    "key": "bw",
                    "name": "bw",
                    "rank": data.botswana.technology_rank,
                    "value": data.botswana.technology,
                    "score": data.botswana.technology,
                    "customText": ""
                },
                {
                    "key": "br",
                    "name": "br",
                    "rank": data.brazil.technology_rank,
                    "value": data.brazil.technology,
                    "score": data.brazil.technology,
                    "customText": ""
                },
                {
                    "key": "bg",
                    "name": "bg",
                    "rank": data.bulgaria.technology_rank,
                    "value": data.bulgaria.technology,
                    "score": data.bulgaria.technology,
                    "customText": ""
                },
                {
                    "key": "bf",
                    "name": "bf",
                    "rank": data.burkina_faso.technology_rank,
                    "value": data.burkina_faso.technology,
                    "score": data.burkina_faso.technology,
                    "customText": ""
                },
                {
                    "key": "kh",
                    "name": "kh",
                    "rank": data.cambodia.technology_rank,
                    "value": data.cambodia.technology,
                    "score": data.cambodia.technology,
                    "customText": ""
                },
                {
                    "key": "cm",
                    "name": "cm",
                    "rank": data.cameroon.technology_rank,
                    "value": data.cameroon.technology,
                    "score": data.cameroon.technology,
                    "customText": ""
                },
                {
                    "key": "ca",
                    "name": "ca",
                    "rank": data.canada.technology_rank,
                    "value": data.canada.technology,
                    "score": data.canada.technology,
                    "customText": ""
                },
                {
                    "key": "cl",
                    "name": "cl",
                    "rank": data.chile.technology_rank,
                    "value": data.chile.technology,
                    "score": data.chile.technology,
                    "customText": ""
                },
                {
                    "key": "cn",
                    "name": "cn",
                    "rank": data.china.technology_rank,
                    "value": data.china.technology,
                    "score": data.china.technology,
                    "customText": ""
                },
                {
                    "key": "co",
                    "name": "co",
                    "rank": data.colombia.technology_rank,
                    "value": data.colombia.technology,
                    "score": data.colombia.technology,
                    "customText": ""
                },
                {
                    "key": "cr",
                    "name": "cr",
                    "rank": data.costa_rica.technology_rank,
                    "value": data.costa_rica.technology,
                    "score": data.costa_rica.technology,
                    "customText": ""
                },
                {
                    "key": "ci",
                    "name": "ci",
                    "rank": data.cote_divoire.technology_rank,
                    "value": data.cote_divoire.technology,
                    "score": data.cote_divoire.technology,
                    "customText": ""
                },
                {
                    "key": "hr",
                    "name": "hr",
                    "rank": data.croatia.technology_rank,
                    "value": data.croatia.technology,
                    "score": data.croatia.technology,
                    "customText": ""
                },
                {
                    "key": "cy",
                    "name": "cy",
                    "rank": data.cyprus.technology_rank,
                    "value": data.cyprus.technology,
                    "score": data.cyprus.technology,
                    "customText": ""
                },
                {
                    "key": "cz",
                    "name": "cz",
                    "rank": data.czech_republic.technology_rank,
                    "value": data.czech_republic.technology,
                    "score": data.czech_republic.technology,
                    "customText": ""
                },
                {
                    "key": "dk",
                    "name": "dk",
                    "rank": data.denmark.technology_rank,
                    "value": data.denmark.technology,
                    "score": data.denmark.technology,
                    "customText": ""
                },
                {
                    "key": "do",
                    "name": "do",
                    "rank": data.dominican_republic.technology_rank,
                    "value": data.dominican_republic.technology,
                    "score": data.dominican_republic.technology,
                    "customText": ""
                },
                {
                    "key": "ec",
                    "name": "ec",
                    "rank": data.ecuador.technology_rank,
                    "value": data.ecuador.technology,
                    "score": data.ecuador.technology,
                    "customText": ""
                },
                {
                    "key": "eg",
                    "name": "eg",
                    "rank": data.egypt.technology_rank,
                    "value": data.egypt.technology,
                    "score": data.egypt.technology,
                    "customText": ""
                },
                {
                    "key": "sv",
                    "name": "sv",
                    "rank": data.el_salvador.technology_rank,
                    "value": data.el_salvador.technology,
                    "score": data.el_salvador.technology,
                    "customText": ""
                },
                {
                    "key": "ee",
                    "name": "ee",
                    "rank": data.estonia.technology_rank,
                    "value": data.estonia.technology,
                    "score": data.estonia.technology,
                    "customText": ""
                },
                {
                    "key": "et",
                    "name": "et",
                    "rank": data.ethiopia.technology_rank,
                    "value": data.ethiopia.technology,
                    "score": data.ethiopia.technology,
                    "customText": ""
                },
                {
                    "key": "fi",
                    "name": "fi",
                    "rank": data.finland.technology_rank,
                    "value": data.finland.technology,
                    "score": data.finland.technology,
                    "customText": ""
                },
                {
                    "key": "fr",
                    "name": "fr",
                    "rank": data.france.technology_rank,
                    "value": data.france.technology,
                    "score": data.france.technology,
                    "customText": ""
                },
                {
                    "key": "ge",
                    "name": "ge",
                    "rank": data.georgia.technology_rank,
                    "value": data.georgia.technology,
                    "score": data.georgia.technology,
                    "customText": ""
                },
                {
                    "key": "de",
                    "name": "de",
                    "rank": data.germany.technology_rank,
                    "value": data.germany.technology,
                    "score": data.germany.technology,
                    "customText": ""
                },
                {
                    "key": "gh",
                    "name": "gh",
                    "rank": data.ghana.technology_rank,
                    "value": data.ghana.technology,
                    "score": data.ghana.technology,
                    "customText": ""
                },
                {
                    "key": "gr",
                    "name": "gr",
                    "rank": data.greece.technology_rank,
                    "value": data.greece.technology,
                    "score": data.greece.technology,
                    "customText": ""
                },
                {
                    "key": "gl",
                    "name": "gl",
                    "rank": data.guatemala.technology_rank,
                    "value": data.guatemala.technology,
                    "score": data.guatemala.technology,
                    "customText": ""
                },
                {
                    "key": "gn",
                    "name": "gn",
                    "rank": data.guinea.technology_rank,
                    "value": data.guinea.technology,
                    "score": data.guinea.technology,
                    "customText": ""
                },
                {
                    "key": "hn",
                    "name": "hn",
                    "rank": data.honduras.technology_rank,
                    "value": data.honduras.technology,
                    "score": data.honduras.technology,
                    "customText": ""
                },
                {
                    "key": "hu",
                    "name": "hu",
                    "rank": data.hungary.technology_rank,
                    "value": data.hungary.technology,
                    "score": data.hungary.technology,
                    "customText": ""
                },
                {
                    "key": "is",
                    "name": "is",
                    "rank": data.iceland.technology_rank,
                    "value": data.iceland.technology,
                    "score": data.iceland.technology,
                    "customText": ""
                },
                {
                    "key": "in",
                    "name": "in",
                    "rank": data.india.technology_rank,
                    "value": data.india.technology,
                    "score": data.india.technology,
                    "customText": ""
                },
                {
                    "key": "id",
                    "name": "id",
                    "rank": data.indonesia.technology_rank,
                    "value": data.indonesia.technology,
                    "score": data.indonesia.technology,
                    "customText": ""
                },
                {
                    "key": "iq",
                    "name": "iq",
                    "rank": data.iran.technology_rank,
                    "value": data.iran.technology,
                    "score": data.iran.technology,
                    "customText": ""
                },
                {
                    "key": "ie",
                    "name": "ie",
                    "rank": data.ireland.technology_rank,
                    "value": data.ireland.technology,
                    "score": data.ireland.technology,
                    "customText": ""
                },
                {
                    "key": "il",
                    "name": "il",
                    "rank": data.israel.technology_rank,
                    "value": data.israel.technology,
                    "score": data.israel.technology,
                    "customText": ""
                },
                {
                    "key": "it",
                    "name": "it",
                    "rank": data.italy.technology_rank,
                    "value": data.italy.technology,
                    "score": data.italy.technology,
                    "customText": ""
                },
                {
                    "key": "jm",
                    "name": "jm",
                    "rank": data.jamaica.technology_rank,
                    "value": data.jamaica.technology,
                    "score": data.jamaica.technology,
                    "customText": ""
                },
                {
                    "key": "jp",
                    "name": "jp",
                    "rank": data.japan.technology_rank,
                    "value": data.japan.technology,
                    "score": data.japan.technology,
                    "customText": ""
                },
                {
                    "key": "jo",
                    "name": "jo",
                    "rank": data.jordan.technology_rank,
                    "value": data.jordan.technology,
                    "score": data.jordan.technology,
                    "customText": ""
                },
                {
                    "key": "kz",
                    "name": "kz",
                    "rank": data.kazakhstan.technology_rank,
                    "value": data.kazakhstan.technology,
                    "score": data.kazakhstan.technology,
                    "customText": ""
                },
                {
                    "key": "ke",
                    "name": "ke",
                    "rank": data.kenya.technology_rank,
                    "value": data.kenya.technology,
                    "score": data.kenya.technology,
                    "customText": ""
                },
                {
                    "key": "kw",
                    "name": "kw",
                    "rank": data.kuwait.technology_rank,
                    "value": data.kuwait.technology,
                    "score": data.kuwait.technology,
                    "customText": ""
                },
                {
                    "key": "kg",
                    "name": "kg",
                    "rank": data.kyrgyzstan.technology_rank,
                    "value": data.kyrgyzstan.technology,
                    "score": data.kyrgyzstan.technology,
                    "customText": ""
                },
                {
                    "key": "la",
                    "name": "la",
                    "rank": data.laos.technology_rank,
                    "value": data.laos.technology,
                    "score": data.laos.technology,
                    "customText": ""
                },
                {
                    "key": "lv",
                    "name": "lv",
                    "rank": data.latvia.technology_rank,
                    "value": data.latvia.technology,
                    "score": data.latvia.technology,
                    "customText": ""
                },
                {
                    "key": "lb",
                    "name": "lb",
                    "rank": data.lebanon.technology_rank,
                    "value": data.lebanon.technology,
                    "score": data.lebanon.technology,
                    "customText": ""
                },
                {
                    "key": "lt",
                    "name": "lt",
                    "rank": data.lithuania.technology_rank,
                    "value": data.lithuania.technology,
                    "score": data.lithuania.technology,
                    "customText": ""
                },
                {
                    "key": "lu",
                    "name": "lu",
                    "rank": data.luxembourg.technology_rank,
                    "value": data.luxembourg.technology,
                    "score": data.luxembourg.technology,
                    "customText": ""
                },
                {
                    "key": "mg",
                    "name": "mg",
                    "rank": data.madagascar.technology_rank,
                    "value": data.madagascar.technology,
                    "score": data.madagascar.technology,
                    "customText": ""
                },
                {
                    "key": "mw",
                    "name": "mw",
                    "rank": data.malawi.technology_rank,
                    "value": data.malawi.technology,
                    "score": data.malawi.technology,
                    "customText": ""
                },
                {
                    "key": "my",
                    "name": "my",
                    "rank": data.malaysia.technology_rank,
                    "value": data.malaysia.technology,
                    "score": data.malaysia.technology,
                    "customText": ""
                },
                {
                    "key": "ml",
                    "name": "ml",
                    "rank": data.mali.technology_rank,
                    "value": data.mali.technology,
                    "score": data.mali.technology,
                    "customText": ""
                },
                {
                    "key": "mt",
                    "name": "mt",
                    "rank": data.malta.technology_rank,
                    "value": data.malta.technology,
                    "score": data.malta.technology,
                    "customText": ""
                },
                {
                    "key": "mu",
                    "name": "mu",
                    "rank": data.mauritius.technology_rank,
                    "value": data.mauritius.technology,
                    "score": data.mauritius.technology,
                    "customText": ""
                },
                {
                    "key": "mx",
                    "name": "mx",
                    "rank": data.mexico.technology_rank,
                    "value": data.mexico.technology,
                    "score": data.mexico.technology,
                    "customText": ""
                },
                {
                    "key": "md",
                    "name": "md",
                    "rank": data.moldova.technology_rank,
                    "value": data.moldova.technology,
                    "score": data.moldova.technology,
                    "customText": ""
                },
                {
                    "key": "mn",
                    "name": "mn",
                    "rank": data.mongolia.technology_rank,
                    "value": data.mongolia.technology,
                    "score": data.mongolia.technology,
                    "customText": ""
                },
                {
                    "key": "ma",
                    "name": "ma",
                    "rank": data.morocco.technology_rank,
                    "value": data.morocco.technology,
                    "score": data.morocco.technology,
                    "customText": ""
                },
                {
                    "key": "mz",
                    "name": "mz",
                    "rank": data.mozambique.technology_rank,
                    "value": data.mozambique.technology,
                    "score": data.mozambique.technology,
                    "customText": ""
                },
                {
                    "key": "mm",
                    "name": "mm",
                    "rank": data.myanmar.technology_rank,
                    "value": data.myanmar.technology,
                    "score": data.myanmar.technology,
                    "customText": ""
                },
                {
                    "key": "np",
                    "name": "np",
                    "rank": data.nepal.technology_rank,
                    "value": data.nepal.technology,
                    "score": data.nepal.technology,
                    "customText": ""
                },
                {
                    "key": "nl",
                    "name": "nl",
                    "rank": data.netherlands.technology_rank,
                    "value": data.netherlands.technology,
                    "score": data.netherlands.technology,
                    "customText": ""
                },
                {
                    "key": "nz",
                    "name": "nz",
                    "rank": data.new_zealand.technology_rank,
                    "value": data.new_zealand.technology,
                    "score": data.new_zealand.technology,
                    "customText": ""
                },
                {
                    "key": "ne",
                    "name": "ne",
                    "rank": data.niger.technology_rank,
                    "value": data.niger.technology,
                    "score": data.niger.technology,
                    "customText": ""
                },
                {
                    "key": "ng",
                    "name": "ng",
                    "rank": data.nigeria.technology_rank,
                    "value": data.nigeria.technology,
                    "score": data.nigeria.technology,
                    "customText": ""
                },
                {
                    "key": "mk",
                    "name": "mk",
                    "rank": data.north_macedonia.technology_rank,
                    "value": data.north_macedonia.technology,
                    "score": data.north_macedonia.technology,
                    "customText": ""
                },
                {
                    "key": "no",
                    "name": "no",
                    "rank": data.norway.technology_rank,
                    "value": data.norway.technology,
                    "score": data.norway.technology,
                    "customText": ""
                },
                {
                    "key": "om",
                    "name": "om",
                    "rank": data.oman.technology_rank,
                    "value": data.oman.technology,
                    "score": data.oman.technology,
                    "customText": ""
                },
                {
                    "key": "pk",
                    "name": "pk",
                    "rank": data.pakistan.technology_rank,
                    "value": data.pakistan.technology,
                    "score": data.pakistan.technology,
                    "customText": ""
                },
                {
                    "key": "pa",
                    "name": "pa",
                    "rank": data.panama.technology_rank,
                    "value": data.panama.technology,
                    "score": data.panama.technology,
                    "customText": ""
                },
                {
                    "key": "py",
                    "name": "py",
                    "rank": data.paraguay.technology_rank,
                    "value": data.paraguay.technology,
                    "score": data.paraguay.technology,
                    "customText": ""
                },
                {
                    "key": "pe",
                    "name": "pe",
                    "rank": data.peru.technology_rank,
                    "value": data.peru.technology,
                    "score": data.peru.technology,
                    "customText": ""
                },
                {
                    "key": "ph",
                    "name": "ph",
                    "rank": data.philippines.technology_rank,
                    "value": data.philippines.technology,
                    "score": data.philippines.technology,
                    "customText": ""
                },
                {
                    "key": "pl",
                    "name": "pl",
                    "rank": data.poland.technology_rank,
                    "value": data.poland.technology,
                    "score": data.poland.technology,
                    "customText": ""
                },
                {
                    "key": "pt",
                    "name": "pt",
                    "rank": data.portugal.technology_rank,
                    "value": data.portugal.technology,
                    "score": data.portugal.technology,
                    "customText": ""
                },
                {
                    "key": "qa",
                    "name": "qa",
                    "rank": data.qatar.technology_rank,
                    "value": data.qatar.technology,
                    "score": data.qatar.technology,
                    "customText": ""
                },
                {
                    "key": "ro",
                    "name": "ro",
                    "rank": data.romania.technology_rank,
                    "value": data.romania.technology,
                    "score": data.romania.technology,
                    "customText": ""
                },
                {
                    "key": "ru",
                    "name": "ru",
                    "rank": data.russia.technology_rank,
                    "value": data.russia.technology,
                    "score": data.russia.technology,
                    "customText": ""
                },
                {
                    "key": "rw",
                    "name": "rw",
                    "rank": data.rwanda.technology_rank,
                    "value": data.rwanda.technology,
                    "score": data.rwanda.technology,
                    "customText": ""
                },
                {
                    "key": "sa",
                    "name": "sa",
                    "rank": data.saudi_arabia.technology_rank,
                    "value": data.saudi_arabia.technology,
                    "score": data.saudi_arabia.technology,
                    "customText": ""
                },
                {
                    "key": "sn",
                    "name": "sn",
                    "rank": data.senegal.technology_rank,
                    "value": data.senegal.technology,
                    "score": data.senegal.technology,
                    "customText": ""
                },
                {
                    "key": "rs",
                    "name": "rs",
                    "rank": data.serbia.technology_rank,
                    "value": data.serbia.technology,
                    "score": data.serbia.technology,
                    "customText": ""
                },
                {
                    "key": "sg",
                    "name": "sg",
                    "rank": data.singapore.technology_rank,
                    "value": data.singapore.technology,
                    "score": data.singapore.technology,
                    "customText": ""
                },
                {
                    "key": "sk",
                    "name": "sk",
                    "rank": data.slovakia.technology_rank,
                    "value": data.slovakia.technology,
                    "score": data.slovakia.technology,
                    "customText": ""
                },
                {
                    "key": "si",
                    "name": "si",
                    "rank": data.slovenia.technology_rank,
                    "value": data.slovenia.technology,
                    "score": data.slovenia.technology,
                    "customText": ""
                },
                {
                    "key": "za",
                    "name": "za",
                    "rank": data.south_africa.technology_rank,
                    "value": data.south_africa.technology,
                    "score": data.south_africa.technology,
                    "customText": ""
                },
                {
                    "key": "kr",
                    "name": "kr",
                    "rank": data.south_korea.technology_rank,
                    "value": data.south_korea.technology,
                    "score": data.south_korea.technology,
                    "customText": ""
                },
                {
                    "key": "es",
                    "name": "es",
                    "rank": data.spain.technology_rank,
                    "value": data.spain.technology,
                    "score": data.spain.technology,
                    "customText": ""
                },
                {
                    "key": "lk",
                    "name": "lk",
                    "rank": data.sri_lanka.technology_rank,
                    "value": data.sri_lanka.technology,
                    "score": data.sri_lanka.technology,
                    "customText": ""
                },
                {
                    "key": "se",
                    "name": "se",
                    "rank": data.sweden.technology_rank,
                    "value": data.sweden.technology,
                    "score": data.sweden.technology,
                    "customText": ""
                },
                {
                    "key": "ch",
                    "name": "ch",
                    "rank": data.switzerland.technology_rank,
                    "value": data.switzerland.technology,
                    "score": data.switzerland.technology,
                    "customText": ""
                },
                {
                    "key": "tz",
                    "name": "tz",
                    "rank": data.tanzania.technology_rank,
                    "value": data.tanzania.technology,
                    "score": data.tanzania.technology,
                    "customText": ""
                },
                {
                    "key": "th",
                    "name": "th",
                    "rank": data.thailand.technology_rank,
                    "value": data.thailand.technology,
                    "score": data.thailand.technology,
                    "customText": ""
                },
                {
                    "key": "tg",
                    "name": "tg",
                    "rank": data.togo.technology_rank,
                    "value": data.togo.technology,
                    "score": data.togo.technology,
                    "customText": ""
                },
                {
                    "key": "tt",
                    "name": "tt",
                    "rank": data.trinidad_and_tobago.technology_rank,
                    "value": data.trinidad_and_tobago.technology,
                    "score": data.trinidad_and_tobago.technology,
                    "customText": ""
                },
                {
                    "key": "tn",
                    "name": "tn",
                    "rank": data.tunisia.technology_rank,
                    "value": data.tunisia.technology,
                    "score": data.tunisia.technology,
                    "customText": ""
                },
                {
                    "key": "tr",
                    "name": "tr",
                    "rank": data.turkey.technology_rank,
                    "value": data.turkey.technology,
                    "score": data.turkey.technology,
                    "customText": ""
                },
                {
                    "key": "ug",
                    "name": "ug",
                    "rank": data.uganda.technology_rank,
                    "value": data.uganda.technology,
                    "score": data.uganda.technology,
                    "customText": ""
                },
                {
                    "key": "ua",
                    "name": "ua",
                    "rank": data.ukraine.technology_rank,
                    "value": data.ukraine.technology,
                    "score": data.ukraine.technology,
                    "customText": ""
                },
                {
                    "key": "ae",
                    "name": "ae",
                    "rank": data.united_arab_emirates.technology_rank,
                    "value": data.united_arab_emirates.technology,
                    "score": data.united_arab_emirates.technology,
                    "customText": ""
                },
                {
                    "key": "gb",
                    "name": "gb",
                    "rank": data.united_kingdom.technology_rank,
                    "value": data.united_kingdom.technology,
                    "score": data.united_kingdom.technology,
                    "customText": ""
                },
                {
                    "key": "us",
                    "name": "us",
                    "rank": data.united_states.technology_rank,
                    "value": data.united_states.technology,
                    "score": data.united_states.technology,
                    "customText": ""
                },
                {
                    "key": "uy",
                    "name": "uy",
                    "rank": data.uruguay.technology_rank,
                    "value": data.uruguay.technology,
                    "score": data.uruguay.technology,
                    "customText": ""
                },
                {
                    "key": "vn",
                    "name": "vn",
                    "rank": data.vietnam.technology_rank,
                    "value": data.vietnam.technology,
                    "score": data.vietnam.technology,
                    "customText": ""
                },
                {
                    "key": "zm",
                    "name": "zm",
                    "rank": data.zambia.technology_rank,
                    "value": data.zambia.technology,
                    "score": data.zambia.technology,
                    "customText": ""
                },
                {
                    "key": "zw",
                    "name": "zw",
                    "rank": data.zimbabwe.technology_rank,
                    "value": data.zimbabwe.technology,
                    "score": data.zimbabwe.technology,
                    "customText": ""
                }
            ]
        }
    ],
    "humanCapital": [
        {
            "name": "Human Capital",
            "states": {
                "hover": {
                    "color": "#e2aa23",
                    "borderColor": "#e2aa23"
                }
            },
            "borderWidth": 0.2,
            "allAreas": true,
            "joinBy": [
                "hc-key",
                "key"
            ],
            "data": [
                {
                    "key": "al",
                    "name": "al",
                    "rank": data.algeria.human_capital_rank,
                    "value": data.algeria.human_capital,
                    "score": data.algeria.human_capital,
                    "customText": ""
                },
                {
                    "name": "dz",
                    "key": "dz",
                    "rank": data.albania.human_capital_rank,
                    "value": data.albania.human_capital,
                    "score": data.albania.human_capital,
                    "customText": ""
                },
                {
                    "key": "ao",
                    "name": "ao",
                    "rank": data.angola.human_capital_rank,
                    "value": data.angola.human_capital,
                    "score": data.angola.human_capital,
                    "customText": ""
                },
                {
                    "key": "ar",
                    "name": "ar",
                    "rank": data.argentina.human_capital_rank,
                    "value": data.argentina.human_capital,
                    "score": data.argentina.human_capital,
                    "customText": ""
                },
                {
                    "key": "am",
                    "name": "am",
                    "rank": data.armenia.human_capital_rank,
                    "value": data.armenia.human_capital,
                    "score": data.armenia.human_capital,
                    "customText": ""
                },
                {
                    "key": "au",
                    "name": "au",
                    "rank": data.australia.human_capital_rank,
                    "value": data.australia.human_capital,
                    "score": data.australia.human_capital,
                    "customText": ""
                },
                {
                    "key": "at",
                    "name": "at",
                    "rank": data.austria.human_capital_rank,
                    "value": data.austria.human_capital,
                    "score": data.austria.human_capital,
                    "customText": ""
                },
                {
                    "key": "az",
                    "name": "az",
                    "rank": data.azerbaijan.human_capital_rank,
                    "value": data.azerbaijan.human_capital,
                    "score": data.azerbaijan.human_capital,
                    "customText": ""
                },
                {
                    "key": "bh",
                    "name": "bh",
                    "rank": data.bahrain.human_capital_rank,
                    "value": data.bahrain.human_capital,
                    "score": data.bahrain.human_capital,
                    "customText": ""
                },
                {
                    "key": "bd",
                    "name": "bd",
                    "rank": data.bangladesh.human_capital_rank,
                    "value": data.bangladesh.human_capital,
                    "score": data.bangladesh.human_capital,
                    "customText": ""
                },
                {
                    "key": "by",
                    "name": "by",
                    "rank": data.belarus.human_capital_rank,
                    "value": data.belarus.human_capital,
                    "score": data.belarus.human_capital,
                    "customText": ""
                },
                {
                    "key": "be",
                    "name": "be",
                    "rank": data.belgium.human_capital_rank,
                    "value": data.belgium.human_capital,
                    "score": data.belgium.human_capital,
                    "customText": ""
                },
                {
                    "key": "bj",
                    "name": "bj",
                    "rank": data.benin.human_capital_rank,
                    "value": data.benin.human_capital,
                    "score": data.benin.human_capital,
                    "customText": ""
                },
                {
                    "key": "bo",
                    "name": "bo",
                    "rank": data.bolivia.human_capital_rank,
                    "value": data.bolivia.human_capital,
                    "score": data.bolivia.human_capital,
                    "customText": ""
                },
                {
                    "key": "ba",
                    "name": "ba",
                    "rank": data.bosnia_and_herzegovina.human_capital_rank,
                    "value": data.bosnia_and_herzegovina.human_capital,
                    "score": data.bosnia_and_herzegovina.human_capital,
                    "customText": ""
                },
                {
                    "key": "bw",
                    "name": "bw",
                    "rank": data.botswana.human_capital_rank,
                    "value": data.botswana.human_capital,
                    "score": data.botswana.human_capital,
                    "customText": ""
                },
                {
                    "key": "br",
                    "name": "br",
                    "rank": data.brazil.human_capital_rank,
                    "value": data.brazil.human_capital,
                    "score": data.brazil.human_capital,
                    "customText": ""
                },
                {
                    "key": "bg",
                    "name": "bg",
                    "rank": data.bulgaria.human_capital_rank,
                    "value": data.bulgaria.human_capital,
                    "score": data.bulgaria.human_capital,
                    "customText": ""
                },
                {
                    "key": "bf",
                    "name": "bf",
                    "rank": data.burkina_faso.human_capital_rank,
                    "value": data.burkina_faso.human_capital,
                    "score": data.burkina_faso.human_capital,
                    "customText": ""
                },
                {
                    "key": "kh",
                    "name": "kh",
                    "rank": data.cambodia.human_capital_rank,
                    "value": data.cambodia.human_capital,
                    "score": data.cambodia.human_capital,
                    "customText": ""
                },
                {
                    "key": "cm",
                    "name": "cm",
                    "rank": data.cameroon.human_capital_rank,
                    "value": data.cameroon.human_capital,
                    "score": data.cameroon.human_capital,
                    "customText": ""
                },
                {
                    "key": "ca",
                    "name": "ca",
                    "rank": data.canada.human_capital_rank,
                    "value": data.canada.human_capital,
                    "score": data.canada.human_capital,
                    "customText": ""
                },
                {
                    "key": "cl",
                    "name": "cl",
                    "rank": data.chile.human_capital_rank,
                    "value": data.chile.human_capital,
                    "score": data.chile.human_capital,
                    "customText": ""
                },
                {
                    "key": "cn",
                    "name": "cn",
                    "rank": data.china.human_capital_rank,
                    "value": data.china.human_capital,
                    "score": data.china.human_capital,
                    "customText": ""
                },
                {
                    "key": "co",
                    "name": "co",
                    "rank": data.colombia.human_capital_rank,
                    "value": data.colombia.human_capital,
                    "score": data.colombia.human_capital,
                    "customText": ""
                },
                {
                    "key": "cr",
                    "name": "cr",
                    "rank": data.costa_rica.human_capital_rank,
                    "value": data.costa_rica.human_capital,
                    "score": data.costa_rica.human_capital,
                    "customText": ""
                },
                {
                    "key": "ci",
                    "name": "ci",
                    "rank": data.cote_divoire.human_capital_rank,
                    "value": data.cote_divoire.human_capital,
                    "score": data.cote_divoire.human_capital,
                    "customText": ""
                },
                {
                    "key": "hr",
                    "name": "hr",
                    "rank": data.croatia.human_capital_rank,
                    "value": data.croatia.human_capital,
                    "score": data.croatia.human_capital,
                    "customText": ""
                },
                {
                    "key": "cy",
                    "name": "cy",
                    "rank": data.cyprus.human_capital_rank,
                    "value": data.cyprus.human_capital,
                    "score": data.cyprus.human_capital,
                    "customText": ""
                },
                {
                    "key": "cz",
                    "name": "cz",
                    "rank": data.czech_republic.human_capital_rank,
                    "value": data.czech_republic.human_capital,
                    "score": data.czech_republic.human_capital,
                    "customText": ""
                },
                {
                    "key": "dk",
                    "name": "dk",
                    "rank": data.denmark.human_capital_rank,
                    "value": data.denmark.human_capital,
                    "score": data.denmark.human_capital,
                    "customText": ""
                },
                {
                    "key": "do",
                    "name": "do",
                    "rank": data.dominican_republic.human_capital_rank,
                    "value": data.dominican_republic.human_capital,
                    "score": data.dominican_republic.human_capital,
                    "customText": ""
                },
                {
                    "key": "ec",
                    "name": "ec",
                    "rank": data.ecuador.human_capital_rank,
                    "value": data.ecuador.human_capital,
                    "score": data.ecuador.human_capital,
                    "customText": ""
                },
                {
                    "key": "eg",
                    "name": "eg",
                    "rank": data.egypt.human_capital_rank,
                    "value": data.egypt.human_capital,
                    "score": data.egypt.human_capital,
                    "customText": ""
                },
                {
                    "key": "sv",
                    "name": "sv",
                    "rank": data.el_salvador.human_capital_rank,
                    "value": data.el_salvador.human_capital,
                    "score": data.el_salvador.human_capital,
                    "customText": ""
                },
                {
                    "key": "ee",
                    "name": "ee",
                    "rank": data.estonia.human_capital_rank,
                    "value": data.estonia.human_capital,
                    "score": data.estonia.human_capital,
                    "customText": ""
                },
                {
                    "key": "et",
                    "name": "et",
                    "rank": data.ethiopia.human_capital_rank,
                    "value": data.ethiopia.human_capital,
                    "score": data.ethiopia.human_capital,
                    "customText": ""
                },
                {
                    "key": "fi",
                    "name": "fi",
                    "rank": data.finland.human_capital_rank,
                    "value": data.finland.human_capital,
                    "score": data.finland.human_capital,
                    "customText": ""
                },
                {
                    "key": "fr",
                    "name": "fr",
                    "rank": data.france.human_capital_rank,
                    "value": data.france.human_capital,
                    "score": data.france.human_capital,
                    "customText": ""
                },
                {
                    "key": "ge",
                    "name": "ge",
                    "rank": data.georgia.human_capital_rank,
                    "value": data.georgia.human_capital,
                    "score": data.georgia.human_capital,
                    "customText": ""
                },
                {
                    "key": "de",
                    "name": "de",
                    "rank": data.germany.human_capital_rank,
                    "value": data.germany.human_capital,
                    "score": data.germany.human_capital,
                    "customText": ""
                },
                {
                    "key": "gh",
                    "name": "gh",
                    "rank": data.ghana.human_capital_rank,
                    "value": data.ghana.human_capital,
                    "score": data.ghana.human_capital,
                    "customText": ""
                },
                {
                    "key": "gr",
                    "name": "gr",
                    "rank": data.greece.human_capital_rank,
                    "value": data.greece.human_capital,
                    "score": data.greece.human_capital,
                    "customText": ""
                },
                {
                    "key": "gl",
                    "name": "gl",
                    "rank": data.guatemala.human_capital_rank,
                    "value": data.guatemala.human_capital,
                    "score": data.guatemala.human_capital,
                    "customText": ""
                },
                {
                    "key": "gn",
                    "name": "gn",
                    "rank": data.guinea.human_capital_rank,
                    "value": data.guinea.human_capital,
                    "score": data.guinea.human_capital,
                    "customText": ""
                },
                {
                    "key": "hn",
                    "name": "hn",
                    "rank": data.honduras.human_capital_rank,
                    "value": data.honduras.human_capital,
                    "score": data.honduras.human_capital,
                    "customText": ""
                },
                {
                    "key": "hu",
                    "name": "hu",
                    "rank": data.hungary.human_capital_rank,
                    "value": data.hungary.human_capital,
                    "score": data.hungary.human_capital,
                    "customText": ""
                },
                {
                    "key": "is",
                    "name": "is",
                    "rank": data.iceland.human_capital_rank,
                    "value": data.iceland.human_capital,
                    "score": data.iceland.human_capital,
                    "customText": ""
                },
                {
                    "key": "in",
                    "name": "in",
                    "rank": data.india.human_capital_rank,
                    "value": data.india.human_capital,
                    "score": data.india.human_capital,
                    "customText": ""
                },
                {
                    "key": "id",
                    "name": "id",
                    "rank": data.indonesia.human_capital_rank,
                    "value": data.indonesia.human_capital,
                    "score": data.indonesia.human_capital,
                    "customText": ""
                },
                {
                    "key": "iq",
                    "name": "iq",
                    "rank": data.iran.human_capital_rank,
                    "value": data.iran.human_capital,
                    "score": data.iran.human_capital,
                    "customText": ""
                },
                {
                    "key": "ie",
                    "name": "ie",
                    "rank": data.ireland.human_capital_rank,
                    "value": data.ireland.human_capital,
                    "score": data.ireland.human_capital,
                    "customText": ""
                },
                {
                    "key": "il",
                    "name": "il",
                    "rank": data.israel.human_capital_rank,
                    "value": data.israel.human_capital,
                    "score": data.israel.human_capital,
                    "customText": ""
                },
                {
                    "key": "it",
                    "name": "it",
                    "rank": data.italy.human_capital_rank,
                    "value": data.italy.human_capital,
                    "score": data.italy.human_capital,
                    "customText": ""
                },
                {
                    "key": "jm",
                    "name": "jm",
                    "rank": data.jamaica.human_capital_rank,
                    "value": data.jamaica.human_capital,
                    "score": data.jamaica.human_capital,
                    "customText": ""
                },
                {
                    "key": "jp",
                    "name": "jp",
                    "rank": data.japan.human_capital_rank,
                    "value": data.japan.human_capital,
                    "score": data.japan.human_capital,
                    "customText": ""
                },
                {
                    "key": "jo",
                    "name": "jo",
                    "rank": data.jordan.human_capital_rank,
                    "value": data.jordan.human_capital,
                    "score": data.jordan.human_capital,
                    "customText": ""
                },
                {
                    "key": "kz",
                    "name": "kz",
                    "rank": data.kazakhstan.human_capital_rank,
                    "value": data.kazakhstan.human_capital,
                    "score": data.kazakhstan.human_capital,
                    "customText": ""
                },
                {
                    "key": "ke",
                    "name": "ke",
                    "rank": data.kenya.human_capital_rank,
                    "value": data.kenya.human_capital,
                    "score": data.kenya.human_capital,
                    "customText": ""
                },
                {
                    "key": "kw",
                    "name": "kw",
                    "rank": data.kuwait.human_capital_rank,
                    "value": data.kuwait.human_capital,
                    "score": data.kuwait.human_capital,
                    "customText": ""
                },
                {
                    "key": "kg",
                    "name": "kg",
                    "rank": data.kyrgyzstan.human_capital_rank,
                    "value": data.kyrgyzstan.human_capital,
                    "score": data.kyrgyzstan.human_capital,
                    "customText": ""
                },
                {
                    "key": "la",
                    "name": "la",
                    "rank": data.laos.human_capital_rank,
                    "value": data.laos.human_capital,
                    "score": data.laos.human_capital,
                    "customText": ""
                },
                {
                    "key": "lv",
                    "name": "lv",
                    "rank": data.latvia.human_capital_rank,
                    "value": data.latvia.human_capital,
                    "score": data.latvia.human_capital,
                    "customText": ""
                },
                {
                    "key": "lb",
                    "name": "lb",
                    "rank": data.lebanon.human_capital_rank,
                    "value": data.lebanon.human_capital,
                    "score": data.lebanon.human_capital,
                    "customText": ""
                },
                {
                    "key": "lt",
                    "name": "lt",
                    "rank": data.lithuania.human_capital_rank,
                    "value": data.lithuania.human_capital,
                    "score": data.lithuania.human_capital,
                    "customText": ""
                },
                {
                    "key": "lu",
                    "name": "lu",
                    "rank": data.luxembourg.human_capital_rank,
                    "value": data.luxembourg.human_capital,
                    "score": data.luxembourg.human_capital,
                    "customText": ""
                },
                {
                    "key": "mg",
                    "name": "mg",
                    "rank": data.madagascar.human_capital_rank,
                    "value": data.madagascar.human_capital,
                    "score": data.madagascar.human_capital,
                    "customText": ""
                },
                {
                    "key": "mw",
                    "name": "mw",
                    "rank": data.malawi.human_capital_rank,
                    "value": data.malawi.human_capital,
                    "score": data.malawi.human_capital,
                    "customText": ""
                },
                {
                    "key": "my",
                    "name": "my",
                    "rank": data.malaysia.human_capital_rank,
                    "value": data.malaysia.human_capital,
                    "score": data.malaysia.human_capital,
                    "customText": ""
                },
                {
                    "key": "ml",
                    "name": "ml",
                    "rank": data.mali.human_capital_rank,
                    "value": data.mali.human_capital,
                    "score": data.mali.human_capital,
                    "customText": ""
                },
                {
                    "key": "mt",
                    "name": "mt",
                    "rank": data.malta.human_capital_rank,
                    "value": data.malta.human_capital,
                    "score": data.malta.human_capital,
                    "customText": ""
                },
                {
                    "key": "mu",
                    "name": "mu",
                    "rank": data.mauritius.human_capital_rank,
                    "value": data.mauritius.human_capital,
                    "score": data.mauritius.human_capital,
                    "customText": ""
                },
                {
                    "key": "mx",
                    "name": "mx",
                    "rank": data.mexico.human_capital_rank,
                    "value": data.mexico.human_capital,
                    "score": data.mexico.human_capital,
                    "customText": ""
                },
                {
                    "key": "md",
                    "name": "md",
                    "rank": data.moldova.human_capital_rank,
                    "value": data.moldova.human_capital,
                    "score": data.moldova.human_capital,
                    "customText": ""
                },
                {
                    "key": "mn",
                    "name": "mn",
                    "rank": data.mongolia.human_capital_rank,
                    "value": data.mongolia.human_capital,
                    "score": data.mongolia.human_capital,
                    "customText": ""
                },
                {
                    "key": "ma",
                    "name": "ma",
                    "rank": data.morocco.human_capital_rank,
                    "value": data.morocco.human_capital,
                    "score": data.morocco.human_capital,
                    "customText": ""
                },
                {
                    "key": "mz",
                    "name": "mz",
                    "rank": data.mozambique.human_capital_rank,
                    "value": data.mozambique.human_capital,
                    "score": data.mozambique.human_capital,
                    "customText": ""
                },
                {
                    "key": "mm",
                    "name": "mm",
                    "rank": data.myanmar.human_capital_rank,
                    "value": data.myanmar.human_capital,
                    "score": data.myanmar.human_capital,
                    "customText": ""
                },
                {
                    "key": "np",
                    "name": "np",
                    "rank": data.nepal.human_capital_rank,
                    "value": data.nepal.human_capital,
                    "score": data.nepal.human_capital,
                    "customText": ""
                },
                {
                    "key": "nl",
                    "name": "nl",
                    "rank": data.netherlands.human_capital_rank,
                    "value": data.netherlands.human_capital,
                    "score": data.netherlands.human_capital,
                    "customText": ""
                },
                {
                    "key": "nz",
                    "name": "nz",
                    "rank": data.new_zealand.human_capital_rank,
                    "value": data.new_zealand.human_capital,
                    "score": data.new_zealand.human_capital,
                    "customText": ""
                },
                {
                    "key": "ne",
                    "name": "ne",
                    "rank": data.niger.human_capital_rank,
                    "value": data.niger.human_capital,
                    "score": data.niger.human_capital,
                    "customText": ""
                },
                {
                    "key": "ng",
                    "name": "ng",
                    "rank": data.nigeria.human_capital_rank,
                    "value": data.nigeria.human_capital,
                    "score": data.nigeria.human_capital,
                    "customText": ""
                },
                {
                    "key": "mk",
                    "name": "mk",
                    "rank": data.north_macedonia.human_capital_rank,
                    "value": data.north_macedonia.human_capital,
                    "score": data.north_macedonia.human_capital,
                    "customText": ""
                },
                {
                    "key": "no",
                    "name": "no",
                    "rank": data.norway.human_capital_rank,
                    "value": data.norway.human_capital,
                    "score": data.norway.human_capital,
                    "customText": ""
                },
                {
                    "key": "om",
                    "name": "om",
                    "rank": data.oman.human_capital_rank,
                    "value": data.oman.human_capital,
                    "score": data.oman.human_capital,
                    "customText": ""
                },
                {
                    "key": "pk",
                    "name": "pk",
                    "rank": data.pakistan.human_capital_rank,
                    "value": data.pakistan.human_capital,
                    "score": data.pakistan.human_capital,
                    "customText": ""
                },
                {
                    "key": "pa",
                    "name": "pa",
                    "rank": data.panama.human_capital_rank,
                    "value": data.panama.human_capital,
                    "score": data.panama.human_capital,
                    "customText": ""
                },
                {
                    "key": "py",
                    "name": "py",
                    "rank": data.paraguay.human_capital_rank,
                    "value": data.paraguay.human_capital,
                    "score": data.paraguay.human_capital,
                    "customText": ""
                },
                {
                    "key": "pe",
                    "name": "pe",
                    "rank": data.peru.human_capital_rank,
                    "value": data.peru.human_capital,
                    "score": data.peru.human_capital,
                    "customText": ""
                },
                {
                    "key": "ph",
                    "name": "ph",
                    "rank": data.philippines.human_capital_rank,
                    "value": data.philippines.human_capital,
                    "score": data.philippines.human_capital,
                    "customText": ""
                },
                {
                    "key": "pl",
                    "name": "pl",
                    "rank": data.poland.human_capital_rank,
                    "value": data.poland.human_capital,
                    "score": data.poland.human_capital,
                    "customText": ""
                },
                {
                    "key": "pt",
                    "name": "pt",
                    "rank": data.portugal.human_capital_rank,
                    "value": data.portugal.human_capital,
                    "score": data.portugal.human_capital,
                    "customText": ""
                },
                {
                    "key": "qa",
                    "name": "qa",
                    "rank": data.qatar.human_capital_rank,
                    "value": data.qatar.human_capital,
                    "score": data.qatar.human_capital,
                    "customText": ""
                },
                {
                    "key": "ro",
                    "name": "ro",
                    "rank": data.romania.human_capital_rank,
                    "value": data.romania.human_capital,
                    "score": data.romania.human_capital,
                    "customText": ""
                },
                {
                    "key": "ru",
                    "name": "ru",
                    "rank": data.russia.human_capital_rank,
                    "value": data.russia.human_capital,
                    "score": data.russia.human_capital,
                    "customText": ""
                },
                {
                    "key": "rw",
                    "name": "rw",
                    "rank": data.rwanda.human_capital_rank,
                    "value": data.rwanda.human_capital,
                    "score": data.rwanda.human_capital,
                    "customText": ""
                },
                {
                    "key": "sa",
                    "name": "sa",
                    "rank": data.saudi_arabia.human_capital_rank,
                    "value": data.saudi_arabia.human_capital,
                    "score": data.saudi_arabia.human_capital,
                    "customText": ""
                },
                {
                    "key": "sn",
                    "name": "sn",
                    "rank": data.senegal.human_capital_rank,
                    "value": data.senegal.human_capital,
                    "score": data.senegal.human_capital,
                    "customText": ""
                },
                {
                    "key": "rs",
                    "name": "rs",
                    "rank": data.serbia.human_capital_rank,
                    "value": data.serbia.human_capital,
                    "score": data.serbia.human_capital,
                    "customText": ""
                },
                {
                    "key": "sg",
                    "name": "sg",
                    "rank": data.singapore.human_capital_rank,
                    "value": data.singapore.human_capital,
                    "score": data.singapore.human_capital,
                    "customText": ""
                },
                {
                    "key": "sk",
                    "name": "sk",
                    "rank": data.slovakia.human_capital_rank,
                    "value": data.slovakia.human_capital,
                    "score": data.slovakia.human_capital,
                    "customText": ""
                },
                {
                    "key": "si",
                    "name": "si",
                    "rank": data.slovenia.human_capital_rank,
                    "value": data.slovenia.human_capital,
                    "score": data.slovenia.human_capital,
                    "customText": ""
                },
                {
                    "key": "za",
                    "name": "za",
                    "rank": data.south_africa.human_capital_rank,
                    "value": data.south_africa.human_capital,
                    "score": data.south_africa.human_capital,
                    "customText": ""
                },
                {
                    "key": "kr",
                    "name": "kr",
                    "rank": data.south_korea.human_capital_rank,
                    "value": data.south_korea.human_capital,
                    "score": data.south_korea.human_capital,
                    "customText": ""
                },
                {
                    "key": "es",
                    "name": "es",
                    "rank": data.spain.human_capital_rank,
                    "value": data.spain.human_capital,
                    "score": data.spain.human_capital,
                    "customText": ""
                },
                {
                    "key": "lk",
                    "name": "lk",
                    "rank": data.sri_lanka.human_capital_rank,
                    "value": data.sri_lanka.human_capital,
                    "score": data.sri_lanka.human_capital,
                    "customText": ""
                },
                {
                    "key": "se",
                    "name": "se",
                    "rank": data.sweden.human_capital_rank,
                    "value": data.sweden.human_capital,
                    "score": data.sweden.human_capital,
                    "customText": ""
                },
                {
                    "key": "ch",
                    "name": "ch",
                    "rank": data.switzerland.human_capital_rank,
                    "value": data.switzerland.human_capital,
                    "score": data.switzerland.human_capital,
                    "customText": ""
                },
                {
                    "key": "tz",
                    "name": "tz",
                    "rank": data.tanzania.human_capital_rank,
                    "value": data.tanzania.human_capital,
                    "score": data.tanzania.human_capital,
                    "customText": ""
                },
                {
                    "key": "th",
                    "name": "th",
                    "rank": data.thailand.human_capital_rank,
                    "value": data.thailand.human_capital,
                    "score": data.thailand.human_capital,
                    "customText": ""
                },
                {
                    "key": "tg",
                    "name": "tg",
                    "rank": data.togo.human_capital_rank,
                    "value": data.togo.human_capital,
                    "score": data.togo.human_capital,
                    "customText": ""
                },
                {
                    "key": "tt",
                    "name": "tt",
                    "rank": data.trinidad_and_tobago.human_capital_rank,
                    "value": data.trinidad_and_tobago.human_capital,
                    "score": data.trinidad_and_tobago.human_capital,
                    "customText": ""
                },
                {
                    "key": "tn",
                    "name": "tn",
                    "rank": data.tunisia.human_capital_rank,
                    "value": data.tunisia.human_capital,
                    "score": data.tunisia.human_capital,
                    "customText": ""
                },
                {
                    "key": "tr",
                    "name": "tr",
                    "rank": data.turkey.human_capital_rank,
                    "value": data.turkey.human_capital,
                    "score": data.turkey.human_capital,
                    "customText": ""
                },
                {
                    "key": "ug",
                    "name": "ug",
                    "rank": data.uganda.human_capital_rank,
                    "value": data.uganda.human_capital,
                    "score": data.uganda.human_capital,
                    "customText": ""
                },
                {
                    "key": "ua",
                    "name": "ua",
                    "rank": data.ukraine.human_capital_rank,
                    "value": data.ukraine.human_capital,
                    "score": data.ukraine.human_capital,
                    "customText": ""
                },
                {
                    "key": "ae",
                    "name": "ae",
                    "rank": data.united_arab_emirates.human_capital_rank,
                    "value": data.united_arab_emirates.human_capital,
                    "score": data.united_arab_emirates.human_capital,
                    "customText": ""
                },
                {
                    "key": "gb",
                    "name": "gb",
                    "rank": data.united_kingdom.human_capital_rank,
                    "value": data.united_kingdom.human_capital,
                    "score": data.united_kingdom.human_capital,
                    "customText": ""
                },
                {
                    "key": "us",
                    "name": "us",
                    "rank": data.united_states.human_capital_rank,
                    "value": data.united_states.human_capital,
                    "score": data.united_states.human_capital,
                    "customText": ""
                },
                {
                    "key": "uy",
                    "name": "uy",
                    "rank": data.uruguay.human_capital_rank,
                    "value": data.uruguay.human_capital,
                    "score": data.uruguay.human_capital,
                    "customText": ""
                },
                {
                    "key": "vn",
                    "name": "vn",
                    "rank": data.vietnam.human_capital_rank,
                    "value": data.vietnam.human_capital,
                    "score": data.vietnam.human_capital,
                    "customText": ""
                },
                {
                    "key": "zm",
                    "name": "zm",
                    "rank": data.zambia.human_capital_rank,
                    "value": data.zambia.human_capital,
                    "score": data.zambia.human_capital,
                    "customText": ""
                },
                {
                    "key": "zw",
                    "name": "zw",
                    "rank": data.zimbabwe.human_capital_rank,
                    "value": data.zimbabwe.human_capital,
                    "score": data.zimbabwe.human_capital,
                    "customText": ""
                }
            ]
        }
    ],
    "competitiveness": [
        {
            "name": "Competitiveness",
            "states": {
                "hover": {
                    "color": "#66ad5d",
                    "borderColor": "#66ad5d"
                }
            },
            "borderWidth": 0.2,
            "allAreas": true,
            "joinBy": [
                "hc-key",
                "key"
            ],
            "data": [
                {
                    "key": "al",
                    "name": "al",
                    "rank": data.algeria.competitiveness_rank,
                    "value": data.algeria.competitiveness,
                    "score": data.algeria.competitiveness,
                    "customText": ""
                },
                {
                    "name": "dz",
                    "key": "dz",
                    "rank": data.albania.competitiveness_rank,
                    "value": data.albania.competitiveness,
                    "score": data.albania.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ao",
                    "name": "ao",
                    "rank": data.angola.competitiveness_rank,
                    "value": data.angola.competitiveness,
                    "score": data.angola.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ar",
                    "name": "ar",
                    "rank": data.argentina.competitiveness_rank,
                    "value": data.argentina.competitiveness,
                    "score": data.argentina.competitiveness,
                    "customText": ""
                },
                {
                    "key": "am",
                    "name": "am",
                    "rank": data.armenia.competitiveness_rank,
                    "value": data.armenia.competitiveness,
                    "score": data.armenia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "au",
                    "name": "au",
                    "rank": data.australia.competitiveness_rank,
                    "value": data.australia.competitiveness,
                    "score": data.australia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "at",
                    "name": "at",
                    "rank": data.austria.competitiveness_rank,
                    "value": data.austria.competitiveness,
                    "score": data.austria.competitiveness,
                    "customText": ""
                },
                {
                    "key": "az",
                    "name": "az",
                    "rank": data.azerbaijan.competitiveness_rank,
                    "value": data.azerbaijan.competitiveness,
                    "score": data.azerbaijan.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bh",
                    "name": "bh",
                    "rank": data.bahrain.competitiveness_rank,
                    "value": data.bahrain.competitiveness,
                    "score": data.bahrain.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bd",
                    "name": "bd",
                    "rank": data.bangladesh.competitiveness_rank,
                    "value": data.bangladesh.competitiveness,
                    "score": data.bangladesh.competitiveness,
                    "customText": ""
                },
                {
                    "key": "by",
                    "name": "by",
                    "rank": data.belarus.competitiveness_rank,
                    "value": data.belarus.competitiveness,
                    "score": data.belarus.competitiveness,
                    "customText": ""
                },
                {
                    "key": "be",
                    "name": "be",
                    "rank": data.belgium.competitiveness_rank,
                    "value": data.belgium.competitiveness,
                    "score": data.belgium.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bj",
                    "name": "bj",
                    "rank": data.benin.competitiveness_rank,
                    "value": data.benin.competitiveness,
                    "score": data.benin.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bo",
                    "name": "bo",
                    "rank": data.bolivia.competitiveness_rank,
                    "value": data.bolivia.competitiveness,
                    "score": data.bolivia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ba",
                    "name": "ba",
                    "rank": data.bosnia_and_herzegovina.competitiveness_rank,
                    "value": data.bosnia_and_herzegovina.competitiveness,
                    "score": data.bosnia_and_herzegovina.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bw",
                    "name": "bw",
                    "rank": data.botswana.competitiveness_rank,
                    "value": data.botswana.competitiveness,
                    "score": data.botswana.competitiveness,
                    "customText": ""
                },
                {
                    "key": "br",
                    "name": "br",
                    "rank": data.brazil.competitiveness_rank,
                    "value": data.brazil.competitiveness,
                    "score": data.brazil.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bg",
                    "name": "bg",
                    "rank": data.bulgaria.competitiveness_rank,
                    "value": data.bulgaria.competitiveness,
                    "score": data.bulgaria.competitiveness,
                    "customText": ""
                },
                {
                    "key": "bf",
                    "name": "bf",
                    "rank": data.burkina_faso.competitiveness_rank,
                    "value": data.burkina_faso.competitiveness,
                    "score": data.burkina_faso.competitiveness,
                    "customText": ""
                },
                {
                    "key": "kh",
                    "name": "kh",
                    "rank": data.cambodia.competitiveness_rank,
                    "value": data.cambodia.competitiveness,
                    "score": data.cambodia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "cm",
                    "name": "cm",
                    "rank": data.cameroon.competitiveness_rank,
                    "value": data.cameroon.competitiveness,
                    "score": data.cameroon.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ca",
                    "name": "ca",
                    "rank": data.canada.competitiveness_rank,
                    "value": data.canada.competitiveness,
                    "score": data.canada.competitiveness,
                    "customText": ""
                },
                {
                    "key": "cl",
                    "name": "cl",
                    "rank": data.chile.competitiveness_rank,
                    "value": data.chile.competitiveness,
                    "score": data.chile.competitiveness,
                    "customText": ""
                },
                {
                    "key": "cn",
                    "name": "cn",
                    "rank": data.china.competitiveness_rank,
                    "value": data.china.competitiveness,
                    "score": data.china.competitiveness,
                    "customText": ""
                },
                {
                    "key": "co",
                    "name": "co",
                    "rank": data.colombia.competitiveness_rank,
                    "value": data.colombia.competitiveness,
                    "score": data.colombia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "cr",
                    "name": "cr",
                    "rank": data.costa_rica.competitiveness_rank,
                    "value": data.costa_rica.competitiveness,
                    "score": data.costa_rica.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ci",
                    "name": "ci",
                    "rank": data.cote_divoire.competitiveness_rank,
                    "value": data.cote_divoire.competitiveness,
                    "score": data.cote_divoire.competitiveness,
                    "customText": ""
                },
                {
                    "key": "hr",
                    "name": "hr",
                    "rank": data.croatia.competitiveness_rank,
                    "value": data.croatia.competitiveness,
                    "score": data.croatia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "cy",
                    "name": "cy",
                    "rank": data.cyprus.competitiveness_rank,
                    "value": data.cyprus.competitiveness,
                    "score": data.cyprus.competitiveness,
                    "customText": ""
                },
                {
                    "key": "cz",
                    "name": "cz",
                    "rank": data.czech_republic.competitiveness_rank,
                    "value": data.czech_republic.competitiveness,
                    "score": data.czech_republic.competitiveness,
                    "customText": ""
                },
                {
                    "key": "dk",
                    "name": "dk",
                    "rank": data.denmark.competitiveness_rank,
                    "value": data.denmark.competitiveness,
                    "score": data.denmark.competitiveness,
                    "customText": ""
                },
                {
                    "key": "do",
                    "name": "do",
                    "rank": data.dominican_republic.competitiveness_rank,
                    "value": data.dominican_republic.competitiveness,
                    "score": data.dominican_republic.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ec",
                    "name": "ec",
                    "rank": data.ecuador.competitiveness_rank,
                    "value": data.ecuador.competitiveness,
                    "score": data.ecuador.competitiveness,
                    "customText": ""
                },
                {
                    "key": "eg",
                    "name": "eg",
                    "rank": data.egypt.competitiveness_rank,
                    "value": data.egypt.competitiveness,
                    "score": data.egypt.competitiveness,
                    "customText": ""
                },
                {
                    "key": "sv",
                    "name": "sv",
                    "rank": data.el_salvador.competitiveness_rank,
                    "value": data.el_salvador.competitiveness,
                    "score": data.el_salvador.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ee",
                    "name": "ee",
                    "rank": data.estonia.competitiveness_rank,
                    "value": data.estonia.competitiveness,
                    "score": data.estonia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "et",
                    "name": "et",
                    "rank": data.ethiopia.competitiveness_rank,
                    "value": data.ethiopia.competitiveness,
                    "score": data.ethiopia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "fi",
                    "name": "fi",
                    "rank": data.finland.competitiveness_rank,
                    "value": data.finland.competitiveness,
                    "score": data.finland.competitiveness,
                    "customText": ""
                },
                {
                    "key": "fr",
                    "name": "fr",
                    "rank": data.france.competitiveness_rank,
                    "value": data.france.competitiveness,
                    "score": data.france.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ge",
                    "name": "ge",
                    "rank": data.georgia.competitiveness_rank,
                    "value": data.georgia.competitiveness,
                    "score": data.georgia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "de",
                    "name": "de",
                    "rank": data.germany.competitiveness_rank,
                    "value": data.germany.competitiveness,
                    "score": data.germany.competitiveness,
                    "customText": ""
                },
                {
                    "key": "gh",
                    "name": "gh",
                    "rank": data.ghana.competitiveness_rank,
                    "value": data.ghana.competitiveness,
                    "score": data.ghana.competitiveness,
                    "customText": ""
                },
                {
                    "key": "gr",
                    "name": "gr",
                    "rank": data.greece.competitiveness_rank,
                    "value": data.greece.competitiveness,
                    "score": data.greece.competitiveness,
                    "customText": ""
                },
                {
                    "key": "gl",
                    "name": "gl",
                    "rank": data.guatemala.competitiveness_rank,
                    "value": data.guatemala.competitiveness,
                    "score": data.guatemala.competitiveness,
                    "customText": ""
                },
                {
                    "key": "gn",
                    "name": "gn",
                    "rank": data.guinea.competitiveness_rank,
                    "value": data.guinea.competitiveness,
                    "score": data.guinea.competitiveness,
                    "customText": ""
                },
                {
                    "key": "hn",
                    "name": "hn",
                    "rank": data.honduras.competitiveness_rank,
                    "value": data.honduras.competitiveness,
                    "score": data.honduras.competitiveness,
                    "customText": ""
                },
                {
                    "key": "hu",
                    "name": "hu",
                    "rank": data.hungary.competitiveness_rank,
                    "value": data.hungary.competitiveness,
                    "score": data.hungary.competitiveness,
                    "customText": ""
                },
                {
                    "key": "is",
                    "name": "is",
                    "rank": data.iceland.competitiveness_rank,
                    "value": data.iceland.competitiveness,
                    "score": data.iceland.competitiveness,
                    "customText": ""
                },
                {
                    "key": "in",
                    "name": "in",
                    "rank": data.india.competitiveness_rank,
                    "value": data.india.competitiveness,
                    "score": data.india.competitiveness,
                    "customText": ""
                },
                {
                    "key": "id",
                    "name": "id",
                    "rank": data.indonesia.competitiveness_rank,
                    "value": data.indonesia.competitiveness,
                    "score": data.indonesia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "iq",
                    "name": "iq",
                    "rank": data.iran.competitiveness_rank,
                    "value": data.iran.competitiveness,
                    "score": data.iran.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ie",
                    "name": "ie",
                    "rank": data.ireland.competitiveness_rank,
                    "value": data.ireland.competitiveness,
                    "score": data.ireland.competitiveness,
                    "customText": ""
                },
                {
                    "key": "il",
                    "name": "il",
                    "rank": data.israel.competitiveness_rank,
                    "value": data.israel.competitiveness,
                    "score": data.israel.competitiveness,
                    "customText": ""
                },
                {
                    "key": "it",
                    "name": "it",
                    "rank": data.italy.competitiveness_rank,
                    "value": data.italy.competitiveness,
                    "score": data.italy.competitiveness,
                    "customText": ""
                },
                {
                    "key": "jm",
                    "name": "jm",
                    "rank": data.jamaica.competitiveness_rank,
                    "value": data.jamaica.competitiveness,
                    "score": data.jamaica.competitiveness,
                    "customText": ""
                },
                {
                    "key": "jp",
                    "name": "jp",
                    "rank": data.japan.competitiveness_rank,
                    "value": data.japan.competitiveness,
                    "score": data.japan.competitiveness,
                    "customText": ""
                },
                {
                    "key": "jo",
                    "name": "jo",
                    "rank": data.jordan.competitiveness_rank,
                    "value": data.jordan.competitiveness,
                    "score": data.jordan.competitiveness,
                    "customText": ""
                },
                {
                    "key": "kz",
                    "name": "kz",
                    "rank": data.kazakhstan.competitiveness_rank,
                    "value": data.kazakhstan.competitiveness,
                    "score": data.kazakhstan.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ke",
                    "name": "ke",
                    "rank": data.kenya.competitiveness_rank,
                    "value": data.kenya.competitiveness,
                    "score": data.kenya.competitiveness,
                    "customText": ""
                },
                {
                    "key": "kw",
                    "name": "kw",
                    "rank": data.kuwait.competitiveness_rank,
                    "value": data.kuwait.competitiveness,
                    "score": data.kuwait.competitiveness,
                    "customText": ""
                },
                {
                    "key": "kg",
                    "name": "kg",
                    "rank": data.kyrgyzstan.competitiveness_rank,
                    "value": data.kyrgyzstan.competitiveness,
                    "score": data.kyrgyzstan.competitiveness,
                    "customText": ""
                },
                {
                    "key": "la",
                    "name": "la",
                    "rank": data.laos.competitiveness_rank,
                    "value": data.laos.competitiveness,
                    "score": data.laos.competitiveness,
                    "customText": ""
                },
                {
                    "key": "lv",
                    "name": "lv",
                    "rank": data.latvia.competitiveness_rank,
                    "value": data.latvia.competitiveness,
                    "score": data.latvia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "lb",
                    "name": "lb",
                    "rank": data.lebanon.competitiveness_rank,
                    "value": data.lebanon.competitiveness,
                    "score": data.lebanon.competitiveness,
                    "customText": ""
                },
                {
                    "key": "lt",
                    "name": "lt",
                    "rank": data.lithuania.competitiveness_rank,
                    "value": data.lithuania.competitiveness,
                    "score": data.lithuania.competitiveness,
                    "customText": ""
                },
                {
                    "key": "lu",
                    "name": "lu",
                    "rank": data.luxembourg.competitiveness_rank,
                    "value": data.luxembourg.competitiveness,
                    "score": data.luxembourg.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mg",
                    "name": "mg",
                    "rank": data.madagascar.competitiveness_rank,
                    "value": data.madagascar.competitiveness,
                    "score": data.madagascar.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mw",
                    "name": "mw",
                    "rank": data.malawi.competitiveness_rank,
                    "value": data.malawi.competitiveness,
                    "score": data.malawi.competitiveness,
                    "customText": ""
                },
                {
                    "key": "my",
                    "name": "my",
                    "rank": data.malaysia.competitiveness_rank,
                    "value": data.malaysia.competitiveness,
                    "score": data.malaysia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ml",
                    "name": "ml",
                    "rank": data.mali.competitiveness_rank,
                    "value": data.mali.competitiveness,
                    "score": data.mali.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mt",
                    "name": "mt",
                    "rank": data.malta.competitiveness_rank,
                    "value": data.malta.competitiveness,
                    "score": data.malta.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mu",
                    "name": "mu",
                    "rank": data.mauritius.competitiveness_rank,
                    "value": data.mauritius.competitiveness,
                    "score": data.mauritius.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mx",
                    "name": "mx",
                    "rank": data.mexico.competitiveness_rank,
                    "value": data.mexico.competitiveness,
                    "score": data.mexico.competitiveness,
                    "customText": ""
                },
                {
                    "key": "md",
                    "name": "md",
                    "rank": data.moldova.competitiveness_rank,
                    "value": data.moldova.competitiveness,
                    "score": data.moldova.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mn",
                    "name": "mn",
                    "rank": data.mongolia.competitiveness_rank,
                    "value": data.mongolia.competitiveness,
                    "score": data.mongolia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ma",
                    "name": "ma",
                    "rank": data.morocco.competitiveness_rank,
                    "value": data.morocco.competitiveness,
                    "score": data.morocco.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mz",
                    "name": "mz",
                    "rank": data.mozambique.competitiveness_rank,
                    "value": data.mozambique.competitiveness,
                    "score": data.mozambique.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mm",
                    "name": "mm",
                    "rank": data.myanmar.competitiveness_rank,
                    "value": data.myanmar.competitiveness,
                    "score": data.myanmar.competitiveness,
                    "customText": ""
                },
                {
                    "key": "np",
                    "name": "np",
                    "rank": data.nepal.competitiveness_rank,
                    "value": data.nepal.competitiveness,
                    "score": data.nepal.competitiveness,
                    "customText": ""
                },
                {
                    "key": "nl",
                    "name": "nl",
                    "rank": data.netherlands.competitiveness_rank,
                    "value": data.netherlands.competitiveness,
                    "score": data.netherlands.competitiveness,
                    "customText": ""
                },
                {
                    "key": "nz",
                    "name": "nz",
                    "rank": data.new_zealand.competitiveness_rank,
                    "value": data.new_zealand.competitiveness,
                    "score": data.new_zealand.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ne",
                    "name": "ne",
                    "rank": data.niger.competitiveness_rank,
                    "value": data.niger.competitiveness,
                    "score": data.niger.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ng",
                    "name": "ng",
                    "rank": data.nigeria.competitiveness_rank,
                    "value": data.nigeria.competitiveness,
                    "score": data.nigeria.competitiveness,
                    "customText": ""
                },
                {
                    "key": "mk",
                    "name": "mk",
                    "rank": data.north_macedonia.competitiveness_rank,
                    "value": data.north_macedonia.competitiveness,
                    "score": data.north_macedonia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "no",
                    "name": "no",
                    "rank": data.norway.competitiveness_rank,
                    "value": data.norway.competitiveness,
                    "score": data.norway.competitiveness,
                    "customText": ""
                },
                {
                    "key": "om",
                    "name": "om",
                    "rank": data.oman.competitiveness_rank,
                    "value": data.oman.competitiveness,
                    "score": data.oman.competitiveness,
                    "customText": ""
                },
                {
                    "key": "pk",
                    "name": "pk",
                    "rank": data.pakistan.competitiveness_rank,
                    "value": data.pakistan.competitiveness,
                    "score": data.pakistan.competitiveness,
                    "customText": ""
                },
                {
                    "key": "pa",
                    "name": "pa",
                    "rank": data.panama.competitiveness_rank,
                    "value": data.panama.competitiveness,
                    "score": data.panama.competitiveness,
                    "customText": ""
                },
                {
                    "key": "py",
                    "name": "py",
                    "rank": data.paraguay.competitiveness_rank,
                    "value": data.paraguay.competitiveness,
                    "score": data.paraguay.competitiveness,
                    "customText": ""
                },
                {
                    "key": "pe",
                    "name": "pe",
                    "rank": data.peru.competitiveness_rank,
                    "value": data.peru.competitiveness,
                    "score": data.peru.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ph",
                    "name": "ph",
                    "rank": data.philippines.competitiveness_rank,
                    "value": data.philippines.competitiveness,
                    "score": data.philippines.competitiveness,
                    "customText": ""
                },
                {
                    "key": "pl",
                    "name": "pl",
                    "rank": data.poland.competitiveness_rank,
                    "value": data.poland.competitiveness,
                    "score": data.poland.competitiveness,
                    "customText": ""
                },
                {
                    "key": "pt",
                    "name": "pt",
                    "rank": data.portugal.competitiveness_rank,
                    "value": data.portugal.competitiveness,
                    "score": data.portugal.competitiveness,
                    "customText": ""
                },
                {
                    "key": "qa",
                    "name": "qa",
                    "rank": data.qatar.competitiveness_rank,
                    "value": data.qatar.competitiveness,
                    "score": data.qatar.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ro",
                    "name": "ro",
                    "rank": data.romania.competitiveness_rank,
                    "value": data.romania.competitiveness,
                    "score": data.romania.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ru",
                    "name": "ru",
                    "rank": data.russia.competitiveness_rank,
                    "value": data.russia.competitiveness,
                    "score": data.russia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "rw",
                    "name": "rw",
                    "rank": data.rwanda.competitiveness_rank,
                    "value": data.rwanda.competitiveness,
                    "score": data.rwanda.competitiveness,
                    "customText": ""
                },
                {
                    "key": "sa",
                    "name": "sa",
                    "rank": data.saudi_arabia.competitiveness_rank,
                    "value": data.saudi_arabia.competitiveness,
                    "score": data.saudi_arabia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "sn",
                    "name": "sn",
                    "rank": data.senegal.competitiveness_rank,
                    "value": data.senegal.competitiveness,
                    "score": data.senegal.competitiveness,
                    "customText": ""
                },
                {
                    "key": "rs",
                    "name": "rs",
                    "rank": data.serbia.competitiveness_rank,
                    "value": data.serbia.competitiveness,
                    "score": data.serbia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "sg",
                    "name": "sg",
                    "rank": data.singapore.competitiveness_rank,
                    "value": data.singapore.competitiveness,
                    "score": data.singapore.competitiveness,
                    "customText": ""
                },
                {
                    "key": "sk",
                    "name": "sk",
                    "rank": data.slovakia.competitiveness_rank,
                    "value": data.slovakia.competitiveness,
                    "score": data.slovakia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "si",
                    "name": "si",
                    "rank": data.slovenia.competitiveness_rank,
                    "value": data.slovenia.competitiveness,
                    "score": data.slovenia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "za",
                    "name": "za",
                    "rank": data.south_africa.competitiveness_rank,
                    "value": data.south_africa.competitiveness,
                    "score": data.south_africa.competitiveness,
                    "customText": ""
                },
                {
                    "key": "kr",
                    "name": "kr",
                    "rank": data.south_korea.competitiveness_rank,
                    "value": data.south_korea.competitiveness,
                    "score": data.south_korea.competitiveness,
                    "customText": ""
                },
                {
                    "key": "es",
                    "name": "es",
                    "rank": data.spain.competitiveness_rank,
                    "value": data.spain.competitiveness,
                    "score": data.spain.competitiveness,
                    "customText": ""
                },
                {
                    "key": "lk",
                    "name": "lk",
                    "rank": data.sri_lanka.competitiveness_rank,
                    "value": data.sri_lanka.competitiveness,
                    "score": data.sri_lanka.competitiveness,
                    "customText": ""
                },
                {
                    "key": "se",
                    "name": "se",
                    "rank": data.sweden.competitiveness_rank,
                    "value": data.sweden.competitiveness,
                    "score": data.sweden.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ch",
                    "name": "ch",
                    "rank": data.switzerland.competitiveness_rank,
                    "value": data.switzerland.competitiveness,
                    "score": data.switzerland.competitiveness,
                    "customText": ""
                },
                {
                    "key": "tz",
                    "name": "tz",
                    "rank": data.tanzania.competitiveness_rank,
                    "value": data.tanzania.competitiveness,
                    "score": data.tanzania.competitiveness,
                    "customText": ""
                },
                {
                    "key": "th",
                    "name": "th",
                    "rank": data.thailand.competitiveness_rank,
                    "value": data.thailand.competitiveness,
                    "score": data.thailand.competitiveness,
                    "customText": ""
                },
                {
                    "key": "tg",
                    "name": "tg",
                    "rank": data.togo.competitiveness_rank,
                    "value": data.togo.competitiveness,
                    "score": data.togo.competitiveness,
                    "customText": ""
                },
                {
                    "key": "tt",
                    "name": "tt",
                    "rank": data.trinidad_and_tobago.competitiveness_rank,
                    "value": data.trinidad_and_tobago.competitiveness,
                    "score": data.trinidad_and_tobago.competitiveness,
                    "customText": ""
                },
                {
                    "key": "tn",
                    "name": "tn",
                    "rank": data.tunisia.competitiveness_rank,
                    "value": data.tunisia.competitiveness,
                    "score": data.tunisia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "tr",
                    "name": "tr",
                    "rank": data.turkey.competitiveness_rank,
                    "value": data.turkey.competitiveness,
                    "score": data.turkey.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ug",
                    "name": "ug",
                    "rank": data.uganda.competitiveness_rank,
                    "value": data.uganda.competitiveness,
                    "score": data.uganda.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ua",
                    "name": "ua",
                    "rank": data.ukraine.competitiveness_rank,
                    "value": data.ukraine.competitiveness,
                    "score": data.ukraine.competitiveness,
                    "customText": ""
                },
                {
                    "key": "ae",
                    "name": "ae",
                    "rank": data.united_arab_emirates.competitiveness_rank,
                    "value": data.united_arab_emirates.competitiveness,
                    "score": data.united_arab_emirates.competitiveness,
                    "customText": ""
                },
                {
                    "key": "gb",
                    "name": "gb",
                    "rank": data.united_kingdom.competitiveness_rank,
                    "value": data.united_kingdom.competitiveness,
                    "score": data.united_kingdom.competitiveness,
                    "customText": ""
                },
                {
                    "key": "us",
                    "name": "us",
                    "rank": data.united_states.competitiveness_rank,
                    "value": data.united_states.competitiveness,
                    "score": data.united_states.competitiveness,
                    "customText": ""
                },
                {
                    "key": "uy",
                    "name": "uy",
                    "rank": data.uruguay.competitiveness_rank,
                    "value": data.uruguay.competitiveness,
                    "score": data.uruguay.competitiveness,
                    "customText": ""
                },
                {
                    "key": "vn",
                    "name": "vn",
                    "rank": data.vietnam.competitiveness_rank,
                    "value": data.vietnam.competitiveness,
                    "score": data.vietnam.competitiveness,
                    "customText": ""
                },
                {
                    "key": "zm",
                    "name": "zm",
                    "rank": data.zambia.competitiveness_rank,
                    "value": data.zambia.competitiveness,
                    "score": data.zambia.competitiveness,
                    "customText": ""
                },
                {
                    "key": "zw",
                    "name": "zw",
                    "rank": data.zimbabwe.competitiveness_rank,
                    "value": data.zimbabwe.competitiveness,
                    "score": data.zimbabwe.competitiveness,
                    "customText": ""
                }
            ]
        }
    ]
}

export default countryMapData
