<template>
  <div>

    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-portulans shape-skew"></div>
        <div class="container shape-container d-flex" style="padding-bottom: 7rem;">
          <div class="col px-0">
            <div class="row">
              <div class="col-12">
                <img src="/img/brand/portulans.png" style="width: 250px;margin-bottom: 40px;margin-right: 40px"
                     class="float-left">
                <img src="/img/brand/google-logo-colour.png" style="width: 200px;margin-bottom: 40px"
                     class="float-left">
              </div>
              <div class="col-lg-12">
                <h1 class="font-weight-700  mt-4">Assessing Future Readiness</h1>
                <h4 class="">Future Readiness Economic Index</h4>
                <hr>
                <p class="lead  ">
                  The COVID pandemic has made it clear to all countries how vital it is to be future-ready. Not only
                  does future readiness point to the importance of preparation and adaptability in the face of
                  uncertainty; it is also essential for longer-term sustainable development.
                  <br>
                  <br>
                  To enable countries to assess their future readiness, the present study, in line with the Digital
                  Sprinters approach initiated by Google, proposes a <a href="#" v-scroll-to="'#methodology'">novel
                  approach</a> that focuses on the nexus of three crucial elements: <span class="font-weight-700">technology, talent and innovation.</span>
                  Set against the background of <span class="font-weight-700">smart infrastructure, informed regulation, and efficient markets</span>,
                  this triangle can help countries measure their future readiness and guide them into their strategy
                  choices and policy decisions.
                </p>
              </div>
            </div>
            <br>
            <div class="text-center p-5  animate__animated animate__fadeIn animate__delay-1s">
              <h4>Scroll down to explore our Future Readiness report or view our <span id="show-btn" class="btn-link"
                                                                                       @click="modalShow = !modalShow">executive summary</span>
              </h4>
              <div class="animate__animated animate__infinite animate__fadeIn animate__slow" style="width: 100%">
                <icon name="fa fa-chevron-down" size="lg" class=""></icon>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <template>
      <div>
        <b-modal v-model="modalShow" hide-footer size="xl" dialog-class="document-modal" title="Executive Summary">

          <p>The COVID-19 pandemic has made it clear to all countries how vital it is to be future-ready and that
            digital transformation is at the core of economic development.</p>
          <p>Based on the Digital Sprinters framework developed by Google and selected data from three global indices,
            the present study attempts to measure the future readiness of 27 emerging economies in Asia-Pacific (APAC),
            Europe, Latin America, and the Middle East and Africa (MEA), thus developing a first edition of a Future
            Readiness Economic Index (FREI).</p>
          <h4 class="my-4 font-weight-700 text-primary">Quantifying Digital Sprinters efforts</h4>
          <p>The Digital Sprinters framework set forth recommendations for governments, the private sector and other
            stakeholders across <span class="font-weight-700">four pillars: (1) Physical Capital, (2) Human Capital, (3) Technology, and (4) Competitiveness.</span>
            The first part of this report seeks to assess 27 emerging economies’ performance under these pillars, using
            data accumulated over the last 20 years by three global indices (the Network Readiness Index, NRI; the
            Global Talent Competitiveness Index, GTCI; and the Global Innovation Index, GII).</p>
          <p><span class="font-weight-700 text-primary">Regarding Physical Capital</span>, the top 5 spots are occupied
            by Singapore and the smaller Gulf Cooperation Council (GCC) countries. However, Internet penetration in some
            of the 27 countries remains low, suggesting that in the larger, more populated countries, more investments
            are needed to provide affordable Internet access of good quality to all. The data indicates that investment
            in physical capital is a prerequisite to digital acceleration, but is not enough on its own.</p>
          <p><span class="font-weight-700 text-primary">Regarding Human Capital</span>, it is found that China and
            India, the two most populous countries in the world, are in the top 7 regarding ‘lifelong learning’ and
            ‘skills matching for jobs’. When it comes to entrepreneurship Chile, Viet Nam and South Africa score high,
            ranking in the top 7 alongside Singapore, China and Israel. As for bridging the gender gap, the smaller GCC
            countries have made progress and are in the top 7 with Singapore, Argentina, Chile and South Africa.
            Overall, there is however room for improvement as investment in AI female talent remains limited in the
            group of 27.</p>
          <p><span class="font-weight-700 text-primary">Regarding Technology</span>, and considering in particular the
            adoption of advanced technologies such as Cloud computing, AI and a platforms approach, it is noteworthy
            that Ukraine, Viet Nam and Thailand are in the top 7, and that China emerges as the leader in the group of
            27. Israel is in second position, and Singapore in third. India is in 7th position, after Thailand. It is
            also interesting to note that there is a clear correlation between Governance and Future Technologies
            adoption, as exemplified by Indonesia and Israel.</p>
          <p><span class="font-weight-700 text-primary">Regarding Competitiveness</span>, most of the Emerging Markets
            Group of 27 score relatively low on institutions. It is important to keep in mind that, under that heading,
            the leaders are Singapore and the UAE, both of which also score well globally. Chile, South Africa and
            Colombia complete the top 5, immediately followed by Qatar (6th) and India (7th). With respect to digital
            government, it is found that authorities in many of the emerging economies are active in using and
            encouraging digital technologies, with Singapore, Israel, and the UAE leading the way, followed by Brazil,
            Saudi Arabia, Russia, and Mexico.</p>

          <h4 class="my-4 font-weight-700 text-primary">What does this tell us about countries’ state of Future
            Readiness?</h4>
          <p>Invoking the data and times series generated by the global indices mentioned above, and applying them to
            the four pillars of the Digital Sprinters framework, the present research generates a Future Readiness
            Economic Index (FREI).</p>
          <p>The findings emerging from this measurement highlight, inter alia, that future readiness varies
            considerably around the world. <span class="font-weight-700">Singapore, China, and the Middle Eastern states of Israel, the UAE, and Qatar are the most future-ready emerging economies.</span>
            They all have in common solid institutions and infrastructure, and good all-round performances across all
            FREI pillars. Singapore occupies the pole position globally.</p>
          <p>It is also clear, and worth noticing, that countries that are not scoring high in overall future readiness
            often do so because of a weakness in a particular pillar. In such situations, a natural question for policy
            makers would be: what if we focused our investments and efforts on that particular area? This is where the
            simulation capabilities proposed as part of the FREI report come in.</p>

          <p><span class="font-weight-700">A novel simulation online tool: What if?</span> To answer this very last
            challenge posed by the Future Readiness Economic Index, a dedicated interactive online tool has been
            created. Through a simple and highly intuitive interface, policy makers and analysts can visualise what
            impact a targeted policy (e.g. focusing on one particular sector of the FREI model) would have on the
            overall future readiness (and ranking) of their economy. We expect that this tool will be the source of
            productive thinking and discussions among key decision makers, in all parts of the world.</p>

          <b-button class="mt-3" variant="primary" block @click="modalShow = !modalShow">Close</b-button>

        </b-modal>

      </div>
    </template>

    <section class="section section-lg digital-sprinters bg-google" data-aos="fade-in" data-aos-offset="400">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="row">
              <div class="col-12 p-0 text-center">
                <img src="/img/brand/digital_sprinters_logo.png"
                     style="width:90%;max-width:300px;margin: 0 auto 40px auto"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0">
                <h2 class="display-3">The initial stage, Digital Sprinters, a Google initiative</h2>
              </div>
            </div>
            <p class="lead"><span class="font-weight-600">Google</span> and <span
                class="font-weight-600">Portulans Institute</span> are proud to announce their collaboration on this
              FREI
              study, following the publication of the Digital Sprinters report by Google on November 16, 2020 revealing
              that emerging markets are “facing a digital crossroad that was apparent even prior to the pandemic”.</p>
            <img src="/img/brand/digital-sprinters-diagram.png" style="width:100%; max-width: 600px;" class="mb-5 mt-5">
          </div>
          <div class="col-lg-12 mt-4">
            <ul>
              <li>
                <p class="lead">The two initiatives (Digital Sprinters and Future Readiness) share a number of common
                  principles and objectives. They also have distinctive traits, which reflect their respective origins
                  and rationales. Their combination hence offers exceptional possibilities in terms of impact and
                  visibility.</p>
              </li>
              <li>
                <p class="lead">Both highlight the critical role of technology and human capital / talent, and the
                  importance of imagining how they will mesh with one another in the future.</p>
              </li>
            </ul>
          </div>
          <div class="col-lg-12 text-center mt-4">
            <div class="btn-wrapper">
              <base-button tag="a"
                           href="https://blog.google/outreach-initiatives/public-policy/the-opportunity-for-digital-sprinters/"
                           target="_blank"
                           class="mb-3 mb-sm-0"
                           type="primary"
                           icon="fa fa-google">
                Read more about Digital Sprinters
              </base-button>
              <base-button tag="a"
                           href="https://blog.google/documents/96/Google-Whitepaper_V5.pdf"
                           class="mb-3 mb-sm-0"
                           type="primary"
                           icon="fa fa-download">
                Download the Digital Sprinters Report
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="row google-colour-bar">
        <div class="col google-red"></div>
        <div class="col google-blue"></div>
        <div class="col google-green"></div>
        <div class="col google-yellow"></div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-9 order-md-2" data-aos="fade-in" data-aos-offset="300">
            <div class="pr-md-5">
              <h3>"Emerging markets have some of the greatest entrepreneurial energy in the world — and are poised to be
                the biggest beneficiaries of new technology in the coming years. From enabling Indian farmers to improve
                crop yields to helping Tanzanian small businesses sell to customers around the world, technology
                supports progress and economic empowerment. And with the right policy frameworks, emerging markets can
                become launching pads for future innovation."</h3>
              <p class="lead"><span class="font-weight-600">Kent Walker</span><br>President, Global Affairs, Google</p>
            </div>
          </div>
          <div class="col-md-3 order-md-1" data-aos="fade-right" data-aos-offset="300"
               style="margin: auto;text-align: center">
            <img src="/img/photos/kent_walker.png" class="img-fluid" style="max-width: 220px;margin: auto">
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center" data-aos="fade-in" data-aos-offset="300">
          <div class="col-md-9 order-md-1" style="text-align: right;">
            <div class="pr-md-5">
              <h3>"Measuring digital performance today tells only a part of the story. Assessing the future readiness of
                national economies is critical to identifying who will lead in to-morrow's interconnected economy."</h3>
              <p class="lead"><span class="font-weight-600">Bruno Lanvin</span><br>Founder and CEO, Portulans Institute
                <br>Geneva, Switzerland</p>
            </div>
          </div>
          <div class="col-md-3 order-md-2" data-aos="fade-left" data-aos-offset="300"
               style="margin: auto;text-align: center">
            <img src="/img/photos/bruno_lanvin.png" class="img-fluid" style="max-width: 220px;margin: auto">
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg bg-lightgrey">
      <div class="container shape-container d-flex" style="padding-bottom: 7rem;">
        <div class="col px-0">
          <div class="row">
            <div class="col-12">
              <img src="/img/brand/portulans.png" style="width: 200px;margin-bottom: 40px;margin-right: 40px"
                   class="float-left" data-aos="fade-in" data-aos-offset="400" data-aos-delay="0">
              <img src="/img/brand/google-logo-colour.png" style="width: 150px;margin-bottom: 40px" class="float-left"
                   data-aos="fade-in" data-aos-offset="400" data-aos-delay="250">
            </div>
            <div class="col-lg-12" data-aos="fade-in" data-aos-offset="400" data-aos-delay="500">
              <h1 id="methodology" class="font-weight-700  mt-4">FREI Methodology</h1>
              <h4 class="">Leveraging the powerful combination technology-talent-innovation (TTI triangle)</h4>
              <hr>

              <div style="float: left;max-width: 400px;" class="m-0 text-center">
                <img src="/img/brand/tti_triangle.png" class="mt-1 p-2 mw-100">
              </div>

              <p>The objective of FREI is to provide countries with a valuable tool to measure their future readiness,
                and serve as a guide to their strategy choices and policy decisions. To do so, FREI proposes a novel
                approach that focuses on the nexus of three crucial elements: technology, talent, and innovation. These
                three forces act in combination with and independently of each other, as illustrated by the TTI triangle
                that underpins the methodology.</p>

              <p><span class="font-weight-700">Technology</span> (especially digital transformation technologies - DTTs)
                offers powerful tools to make sense of the data we can collect and analyse. Through deep learning and AI
                in general, new avenues can be explored to identify new solutions to long-standing issues, from climate
                change to pandemics.</p>

              <p><span class="font-weight-700">Talent</span>, i.e. the intrinsic quality of the human capital that a
                country hosts, remains the core ingredient of future readiness. Younger generations ready to enter the
                job market, but also employed workers need to acquire and master the skills that will allow them to
                contribute to local strategies, global goals, while leading fulfilling professional and personal lives.
              </p>

              <p><span class="font-weight-700">Innovation</span> is key to allow economies to stay ahead of the curve in
                global competition, to diversify their economies, and to adapt to future shocks.</p>

              <p>The three elements of the TTI triangle, together with a foundation of institutions and infrastructure,
                constitute the key pillars of the model used to assess countries’ future readiness. The four pillars are
                comprised of a total of 15 sub-pillars that, between them, have been populated by 69 indicators. The
                conceptual basis of the sub-pillars and their associated indicators derives from three global indices
                that monitor the performances of economies in technology, talent, and innovation, respectively: <a
                    href="https://networkreadinessindex.org/" target="_blank">The Network Readiness Index (NRI)</a>, <a
                    href="https://gtcistudy.com/" target="_blank">The Global Talent Competitiveness Index (GTCI)</a>,
                and the <a href="https://www.globalinnovationindex.org/Home" target="_blank">Global Innovation Index
                  (GII).</a></p>

              <p class="lead font-weight-bold">To be future ready, emerging economies need to monitor their respective
                and collective abilities to master the TTI triangle.</p>

            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: "home",
  metaInfo: {
    title: 'Portulans Institute FREI 2021 | About',
  },
  data() {
    return {
      modalShow: false,
      visible: false,
    }
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  }
};
</script>

<style scoped>

.methodology-box {
  background: white;
  padding: 1em;
  border-radius: 5px;
}

.google-colour-bar {
  height: 10px;
}

.google-red {
  background-color: #DB4437
}

.google-blue {
  background-color: #4285F4
}

.google-green {
  background-color: #0F9D58
}

.google-yellow {
  background-color: #F4B400
}


</style>
