var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-hero section-shaped my-0 bg-google",staticStyle:{"background-image":"url('/img/brand/bg-hero-2023.png')"}},[_vm._m(0),_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"heroVideo"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/videos/doha.mp4","type":"video/mp4"}})]),_c('div',{staticClass:"shape shape-style-1 shape-portulans"}),_c('div',{staticClass:"container shape-container d-flex align-items-center"},[_c('div',{staticClass:"col px-0",staticStyle:{"z-index":"1000"}},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-left"},[_vm._m(2),_c('h4',{staticClass:"animate__animated animate__fadeIn animate__delay-2s",staticStyle:{"color":"#777b95","font-weight":"normal","font-size":"22px"}},[_vm._v("Digital Policies are the Linchpin of Future Readiness")]),_c('div',{staticClass:"animate__animated animate__fadeIn animate__delay-3s"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'The contents of this report and joint website reflect the views of the authors, who are responsible for the facts and accuracy of the information presented herein. They do not represent the views of Google or CRA. Descartes Institute bears the full responsibility of country rankings.',
        class: 'tooltip-custom tooltip-other-custom'
    }),expression:"{\n        content: 'The contents of this report and joint website reflect the views of the authors, who are responsible for the facts and accuracy of the information presented herein. They do not represent the views of Google or CRA. Descartes Institute bears the full responsibility of country rankings.',\n        class: 'tooltip-custom tooltip-other-custom'\n    }"}],on:{"click":function($event){$event.preventDefault();}}},[_c('p',{staticClass:"description mt-5 mb-2 d-inline-flex",staticStyle:{"font-style":"italic"}},[_vm._v("Commissioned by Google. In collaboration with Communications Regulatory Authority of the State of Qatar (CRA)")])]),_c('br'),_c('img',{staticStyle:{"width":"160px","margin-bottom":"40px","margin-right":"50px","opacity":"1"},attrs:{"src":"/img/brand/google-logo-colour.png"}}),_c('img',{staticStyle:{"width":"240px","margin-bottom":"40px","opacity":"1"},attrs:{"src":"/img/brand/CRA_logo.png"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner animate__animated animate__zoomIn",staticStyle:{"z-index":"2000 !important"}},[_c('div',{staticClass:"container bg-lightgrey p-3 mb-5",staticStyle:{"border-left":"solid 5px #0d5938"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 text-center"},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"width":"100","src":"/img/brand/descartes_institute.png"}})]),_c('div',{staticClass:"col-10 text-left"},[_c('div',{staticStyle:{"position":"absolute","right":"30px","top":"-31px","background":"#0a5937","padding":"5px 20px","border-radius":"37px","color":"white","font-size":"14px"}},[_vm._v("2023 Report")]),_c('p',{staticClass:"text-left mb-2"},[_vm._v("The Future Readiness Economic Index 2023 was launched on "),_c('br'),_c('span',{staticClass:"font-weight-600"},[_vm._v("Monday, 11 December at 12:30 pm")]),_vm._v(" on the occasion of the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"https://dohaforum.org/","target":"_blank"}},[_vm._v("Doha Forum 2023")])]),_c('hr',{staticClass:"m-0"}),_c('h6',{staticClass:"font-weight-700 mt-3 mb-0"},[_c('i',{staticClass:"fa fa-video-camera mr-2",staticStyle:{"color":"#0d5938"}}),_c('a',{staticStyle:{"text-decoration":"underline","color":"#0d5938"},attrs:{"target":"_blank","href":"https://youtu.be/P9-S3HAOAcQ?si=8XbwCusmR7gYamB9"}},[_vm._v("View the recording")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"300"}},[_vm._v("of the Global Launch event")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row flex-nowrap",staticStyle:{"margin-top":"5em"}},[_c('div',{staticClass:"col-lg-6 mb-4 img-fluid animate__animated animate__fadeIn animate__delay-3s"},[_c('a',{attrs:{"href":"https://descartesinstitute.net/","target":"_blank"}},[_c('img',{staticStyle:{"width":"180px"},attrs:{"src":"/img/brand/descartes_institute.png"}})])]),_c('div',{staticClass:"col-lg-4 mb-4 text-right animate__animated animate__fadeIn animate__delay-3s"},[_c('img',{staticStyle:{"width":"100px","opacity":"1"},attrs:{"src":"/img/report_icon.png"}}),_c('a',{attrs:{"href":"/pdfs/Global Future Readiness FREI Report Descartes Institute 2023.pdf"}},[_c('p',[_vm._v("Read Our Report")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"font-weight-700 animate__animated animate__fadeInUp",staticStyle:{"color":"#0a3924"}},[_vm._v("F"),_c('span',{staticClass:"animate__animated animate__fadeIn animate__delay-1s"},[_vm._v("UTURE")]),_vm._v(" R"),_c('span',{staticClass:"animate__animated animate__fadeIn animate__delay-1s"},[_vm._v("EADINESS")]),_vm._v(" E"),_c('span',{staticClass:"animate__animated animate__fadeIn animate__delay-1s"},[_vm._v("CONOMIC")]),_vm._v(" I"),_c('span',{staticClass:"animate__animated animate__fadeIn animate__delay-1s"},[_vm._v("NDEX")]),_c('span',{staticStyle:{"color":"grey","font-weight":"400"}},[_c('span',{staticClass:"animate__animated animate__fadeIn animate__delay-2s"},[_vm._v(" 2023")])])])
}]

export { render, staticRenderFns }