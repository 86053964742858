import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import './registerServiceWorker'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTyperPlugin from 'vue-typer'
import VueScrollTo from 'vue-scrollto'
import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
import CountryFlag from 'vue-country-flag'
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)

Vue.use(vueAwesomeCountdown, 'vac') // Component name, `countdown` and `vac` by default
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTyperPlugin)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(Tooltip)
Vue.use(VueMeta)
Vue.component('country-flag', CountryFlag)

import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";
import { FormSelectPlugin } from 'bootstrap-vue'

Maps(Highcharts);
Vue.use(HighchartsVue);
Vue.use(FormSelectPlugin)

Vue.use(VueAnalytics, {
    id: 'UA-241765772-1',
    router
})

Vue.config.productionTip = false;
Vue.use(Argon);
new Vue({
  created () {
    AOS.init()
  },
  router,
  render: h => h(App)
}).$mount("#app");
