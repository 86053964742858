<template>

  <div>

    <section class="bg-lightgrey mt-5">
      <div class="container shape-container d-flex">
        <div class="col px-0">
          <div class="row pb-100 pt-100 mt-5">
            <div class="col-lg-12">
              <h1 class="display-3">
                What Ifs
                <span>Achieving future readiness going forward</span>
              </h1>
              <p class="lead">Explore how a country’s future readiness would strengthen if one or several of its key
                pillars improved.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="">
        <div class="text-center mw-100 px-5 pt-5" style="margin: auto" v-if="isMobile">
          <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
          <br>
          <p class="font-weight-500">This section is best viewed in landscape mode</p>
        </div>
        <section class="section" v-if="selectedCountry === 'none'">
          <div class="container">
            <div class="row row-grid align-items-center">
              <div class="col-12">
                <WhatIfsTable @clickedCountry="selectCountry"></WhatIfsTable>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>



  </div>

</template>

<script>
import WhatIfsTable from "@/views-2023/components-2023/WhatIfsTable";
// import WhatIfsTableCountry from "@/views-2023/components-2023/WhatIfsTableCountry";

export default {
  name: "WhatIfs",
  components: {
    WhatIfsTable,
    // WhatIfsTableCountry
  },
  data() {
    return {
      selectedCountry: 'none'
    }
  },
  metaInfo: {
    title: 'Descartes Institute FREI 2023 | What Ifs',
  },
  methods: {
    selectCountry(country) {
      this.selectedCountry = country
    }
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
