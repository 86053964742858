<template>
  <div>
    <div class="container bg-lightgrey p-3 pt-4 pb-4 mb-5">
      <div class="row">
        <div class="col text-center">
          <img width="200" class="mb-4" src="/img/brand/descartes_institute.png">
          <h5 class="font-weight-300 text-center mb-0">2023 Report Launch</h5>
          <hr class="mt-4">
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <p class="text-center">The Future Readiness Economic Index 2023 will be launched on <br><span class="font-weight-600">Monday, 11 December at 12:30 pm</span> on the occasion of the <a style="text-decoration:underline" href="https://dohaforum.org/" target="_blank">Doha Forum 2023</a><p>
          <h5 class="font-weight-700 mt-3">
            Register to watch online <a style="text-decoration:underline" href="https://live.dohaforum.org/login" target="_blank">here</a></h5>
        </div>
      </div>
    </div>
    <div class="container bg-lightgrey p-3 pt-4 pb-4">
      <div class="row">
        <div class="col text-center">
          <img width="200" class="mb-4" src="/img/brand/portulans.png">
          <h5 class="font-weight-300 text-center mb-0">2022 Report Launch</h5>
          <hr class="mt-4">
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <p class="text-center">Our Digital Sprinter Initiative launched on <br><span class="font-weight-600">21 September 2022</span><p>
          <h5 class="font-weight-700 mt-3">Read more about our launch <a style="text-decoration:underline" href="https://www.concordia.net/annualsummit/2022annualsummit/future-readiness-economic-index-global-launch-a-digital-sprinters-initiative/" target="_blank">here</a></h5>
        </div>
      </div>
      <!-- <hr>
      <div class="row">
        <div class="col text-center" v-for="time in times" :key="time.id">
          <div class="card-block">
            <h4 class="card-title mb-0">{{ time.time }}</h4>
          </div>
          <div>
            {{ time.text }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  data() {
    return {
      startTime: new Date().toLocaleString("en-US", {timeZone: "America/New_York"}),
      endTime: "September 21, 2022 09:30:00",
      times:
          [
            {id: 0, text: "Days", time: 1},
            {id: 1, text: "Hours", time: 1},
            {id: 2, text: "Minutes", time: 1},
            {id: 3, text: "Seconds", time: 1}
          ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined
    }
  },
  methods: {
    updateTimer: function () {
      if (
          this.times[3].time > 0 ||
          this.times[2].time > 0 ||
          this.times[1].time > 0 ||
          this.times[0].time > 0
      ) {
        this.getTimeRemaining();
        this.updateProgressBar();
      } else {
        clearTimeout(this.timeinterval);
        // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    getTimeRemaining: function () {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
      if (t >= 0) {
        this.times[3].time = Math.floor(t / 1000 % 60); //seconds
        this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
        this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    updateProgressBar: function () {
      let startTime = Date.parse(new Date(this.startTime));
      let currentTime = Date.parse(new Date());
      let endTime = Date.parse(new Date(this.endTime));
      let interval = parseFloat(
          (currentTime - startTime) / (endTime - startTime) * 100
      ).toFixed(2);
      this.progress = 100 - interval;
    }
  },
  created: function () {
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  }
}
</script>

<style scoped>

</style>
