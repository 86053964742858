<template>
  <div class="row">

    <div class="text-center mw-100 px-5" style="margin: auto" v-if="isMobile">
      <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
      <br>
      <p class="font-weight-500">This section is best viewed in landscape mode</p>
    </div>

    <div class="col-12">
      <p class="lead" style="letter-spacing: -0.5px">
        The country rankings table below illustrates the future readiness positions of the 27 emerging markets included in the current version of the FREI Report. It lists countries in order of decreasing performance (column 1). Data inside the table (columns 2 to 5) refer to the scores and ranks of the same countries compared to the 123 economies that will be studied in the global version of the report (to be released in early 2022).</p>
      <p>How to read this table: within each pillar, different shades correspond to different relative levels of performance: darker shades indicate areas in which the country considered performs relatively well, whereas the lightest shade point at areas requiring priority attention. Intermediate shading corresponds to areas for which improvement remains important.</p>
    </div>

    <div class="col-12" style="overflow: scroll">
      <template>
        <div>
          <b-table id="table-transition"
                   class="rankingsTable"
                   :items="sortedArray"
                   :fields="fields"
                   small
                   fixed
                   primary-key="Country"
                   :tbody-transition-props="transProps">
                    <template #cell(Rank)="data">
                      <span class="font-weight-bold">{{ data.index + 1 }}</span>
                    </template>
                    <template #cell(FutureReadiness)="data">
                      <div @mouseover="showFutureReadiness = true" @mouseleave="showFutureReadiness = false" class="py-3">
                        <transition name="fade" mode="out-in">
                          <span key="futureReadinessScore" v-if="showFutureReadiness">Score: {{ data.value.score.toFixed(2) }}</span>
                          <span key="futureReadinessRank" v-else>{{ data.value.rank.toString() }}</span>
                        </transition>
                      </div>
                    </template>
                    <template #cell(InstitutionsAndInfrastructure)="data" >
                      <div @mouseover="showInstitutionsAndInfrastructure = true" @mouseleave="showInstitutionsAndInfrastructure = false" class="py-3">
                      <transition name="fade" mode="out-in">
                        <span v-if="showInstitutionsAndInfrastructure" key="institutionsAndInfrastructureScore">Score: {{ data.value.score.toFixed(2) }}</span>
                        <span v-else key="institutionsAndInfrastructureRank">{{ data.value.rank.toString() }}</span>
                      </transition>
                      </div>
                    </template>
                    <template #cell(Technology)="data" >
                      <div @mouseover="showTechnology = true" @mouseleave="showTechnology = false" class="py-3">
                      <transition name="fade" mode="out-in">
                        <span v-if="showTechnology" key="technologyScore">Score: {{ data.value.score.toFixed(2) }}</span>
                        <span v-else key="technologyRank">{{ data.value.rank.toString() }}</span>
                      </transition>
                      </div>
                    </template>
                    <template #cell(Talent)="data" >
                      <div @mouseover="showTalent = true" @mouseleave="showTalent = false" class="py-3">
                      <transition name="fade" mode="out-in">
                        <span v-if="showTalent" key="talentScore">Score: {{ data.value.score.toFixed(2) }}</span>
                        <span v-else key="talentRank">{{ data.value.rank.toString() }}</span>
                      </transition>
                      </div>
                    </template>
                    <template #cell(Innovation)="data" >
                      <div @mouseover="showInnovation = true" @mouseleave="showInnovation = false" class="py-3">
                        <transition name="fade" mode="out-in">
                          <span v-if="showInnovation" key="innovationScore">Score: {{ data.value.score.toFixed(2) }}</span>
                          <span v-else key="innovationRank">{{ data.value.rank.toString() }}</span>
                        </transition>
                      </div>
                    </template>
          </b-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import countryRankingData from '../../data-2021/countryRankingDataForRankingsTable.json'
import countryRankingDataMaximumRanks from '../../data-2021/countryRankingDataMaximumRanks.json'
import { BTable } from 'bootstrap-vue'

export default {
  name: "RankingsTable",
  components: {
    BTable
  },
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      showFutureReadiness: false,
      showInstitutionsAndInfrastructure: false,
      showTechnology: false,
      showTalent: false,
      showInnovation: false,
      countryRankingData: countryRankingData,
      countryRankingDataMaximums: countryRankingDataMaximumRanks,
      fields: [
        { key: 'Rank', sortable: false, tdClass: "countryColumn" , thClass: 'comparisonHeader text-white', sortDirection: 'desc' },
        { key: 'Country', sortable: false, tdClass: "countryColumn" , thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'FutureReadiness', sortable: false, thClass: 'comparisonHeader', tdClass: "backgroundOpacity", sortDirection: 'desc' },
        { key: 'InstitutionsAndInfrastructure', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'Technology', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'Talent', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'Innovation', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' }
      ]
    }
  },
  computed: {
    totalQuantity() {
      return this.items.reduce((total, item) => {
        return total + item.quantity
      }, 0)
    },
    sortedArray: function() {
      let arrays = countryRankingData
      function compare(a, b) {
        if (a.FutureReadiness.rank < b.FutureReadiness.rank)
          return -1;
        if (a.FutureReadiness.rank > b.FutureReadiness.rank)
          return 1;
        return 0;
      }
      return arrays.sort(compare);
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    backgroundOpacity(value, key) {
      const maxValue = this.countryRankingDataMaximums[key]
      const opacityValue = (maxValue - value.rank)/maxValue
      if (Math.round(opacityValue * 10) == 0) {
        return 'comparisonCellNoPadding ' + key + 'Opacity1'
      } else {
        return 'comparisonCellNoPadding ' + key + 'Opacity' + Math.round(opacityValue * 10)
      }
    }
    // Old colour ranking style to be used later
    // backgroundOpacity(value, key) {
    //   const maxValue = this.countryRankingDataMaximums[key]
    //   const opacityValue = value/maxValue
    //   return 'comparisonCell ' + key + 'Opacity' + Math.round(opacityValue*10)
    // }
  }
}
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.rankingsTable th:focus {
  outline: 0;
}

.rankingsTable th {
  border-top: solid 5px #FFF !important;
  text-align: center;
}

.rankingsTable th:nth-child(2)[aria-sort='descending'] {
  border-top: solid 5px #4285F4 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(2)[aria-sort='ascending'] {
  border-top: solid 5px #4285F4 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='descending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='ascending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='descending'] {
  border-top: solid 5px #F4B400 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='ascending'] {
  border-top: solid 5px #F4B400 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='descending'] {
  border-top: solid 5px #0F9D58 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='ascending'] {
  border-top: solid 5px #0F9D58 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='descending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='ascending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

</style>
