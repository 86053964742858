<template>
  <div class="col-12" style="padding-top: 3em">

    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-12">
          <country-flag :country='this.countryProfiles.countries[this.selectedCountry].code' size='big' class="pull-left mr-2"/>
          <h2>
          {{ this.countryProfiles.countries[this.selectedCountry].name }}
          </h2>
          <hr style="margin-top: 1em;margin-bottom: 1em">
        </div>
      </div>

      <div class="row row-grid align-items-center mt-0">
        <div class="col-md-6 order-md-2 text-center">
          <RadarComparison :chartData="this.chartData" :showLegend="true"></RadarComparison>
        </div>
        <div class="col-md-6 order-md-1">
          <div class="bg-lightgrey country-container">
            <div style="z-index: 1000">
              <ul class="list-unstyled mt-2">
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">FREI Rank</p>
                      <h3 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.future_readiness_ranking }} of 124</h3>
                    </div>
                  </div>
                  <hr class="w-100 mt-3 mb-1 mx-0">
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">FREI Score</p>
                      <h6 class="mb-0">{{ parseFloat(this.countryProfiles.countries[this.selectedCountry].stats.future_readiness_score).toFixed(2) }}</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">Average Score for Income Group</p>
                      <h6 class="mb-0">{{ parseFloat(this.countryProfiles.countries[this.selectedCountry].stats.future_readiness_score_income_group_average).toFixed(2) }}</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">Income Group</p>
                      <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.income_group }}</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">Regional Group</p>
                      <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.regional_group }}</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">Population (millions)</p>
                      <h6 class="mb-0">{{parseFloat(countryProfiles.countries[selectedCountry].stats.population).toFixed(2)}}</h6>
                      
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">GDP Per Capita (PPP US$)</p>
                      <h6 class="mb-0">{{ parseFloat(parseFloat(this.countryProfiles.countries[this.selectedCountry].stats.gdp_per_capita).toFixed(2)).toLocaleString() }}</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center country-stat">
                    <div>
                      <p class="stat-header font-weight-600 text-uppercase">GDP (US$ billions)</p>
                      <h6 class="mb-0">{{ parseFloat(this.countryProfiles.countries[this.selectedCountry].stats.gdp).toFixed(2).toLocaleString() }}</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-grid align-items-center">
        <div class="col-12 mt-5 p-0">
          <p class="text-center">Rank by pillar and sub-pillar in the FREI</p>
<!--          <div class="col-12 mb-5">-->
<!--            <h4 style="margin-bottom: 5px;margin-top: 30px" class="font-weight-600">How Future Ready is {{ this.countryProfiles.countries[this.selectedCountry].name }}?</h4>-->
<!--            <p class="mb-5">{{this.countryProfiles.countries[this.selectedCountry].overall_readiness }}</p>-->
<!--            <h4 style="margin-bottom: 5px;margin-top: 30px" class="font-weight-600">Technology, Talent and Innovation</h4>-->
<!--            <p class="mb-5">{{ this.countryProfiles.countries[this.selectedCountry].tti_performance }}</p>-->
<!--            <h4 style="margin-bottom: 5px;margin-top: 30px" class="font-weight-600">Global Performance</h4>-->
<!--            <p class="mb-5">{{ this.countryProfiles.countries[this.selectedCountry].global_performance }}</p>-->
<!--          </div>-->
          <CountryRankings :country-rankings="this.countryRanks" :selected-country="this.selectedCountry"></CountryRankings>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import countryProfiles from '../../data_2023/country-profiles/countryProfiles.js'
import countryRanks from '../../data_2023/country-profiles/countryRanks.js'
import RadarComparison from "@/components-2023/charts/RadarComparison";
import CountryRankings from "@/views-2023/components-2023/CountryRankings";

export default {
  name: "CountryProfile",
  components: {
    CountryRankings,
    RadarComparison
  },
  props: {
    countryData: {
      type: Object
    },
    selectedCountry: {
      type: String,
      default: "egypt"
    }
  },
  data() {
    return {
      countryProfiles: countryProfiles,
      countryRanks: countryRanks,
      visible: false
    }
  },
  computed: {
    chartData() {
      const labels = [["FREI", "Score"], ["Physical Capital"], "Human Capital", "Technology", "Competitiveness"]
      const chartDataSet = {
        labels: labels,
        datasets: this.countryProfiles.countries[this.selectedCountry].radar_data
      }
      return chartDataSet;
    }
  },
  methods: {
    rankClass(rank) {
      if (rank === 1) {
        return 'stat-rank-1'
      } else if (rank === 2) {
        return 'stat-rank-2'
      } else if (rank === 3) {
        return 'stat-rank-3'
      }
    }
  }
}
</script>

<style scoped>
.stat-header {
  margin-bottom: 0px;
  font-size: small;
  font-weight: 700 !important;
  color: #4a4a4a !important;
}

.country-container {
  background-size: cover !important;
  padding: 1em;
  border-radius: 0.25rem;
}

.report-stat-box {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 15px 0;
  text-align: center;
  background: #fafafa;
  margin: 5px 0 5px 0;
  min-height: 90px;
  border-radius: 0.25em;
}

.report-stat-box .field-value {
  font-size: larger;
}

.report-stat-box-header {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10px 0;
  text-align: center;
  background: #4a4a4a;
  margin: 5px 0 5px 0;
  min-height: 90px;
  border-radius: 0.25em;
  color: white;
}

.report-stat-box-header .field-value {
  font-size: large;
}

</style>
