<template>
  <div class="row align-middle">

    <div class="text-center mw-100 px-5" style="margin: auto" v-if="isMobile">
      <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
      <br>
      <p class="font-weight-500">This section is best viewed in landscape mode</p>
    </div>

    <div class="col-12">
      <p class="lead" style="letter-spacing: -0.5px">
        This tool allows a clear, visual comparison of the future readiness of the emerging markets by means of a radar chart. Five axes are considered here: technology, talent and innovation (the three components of the TTI triangle), but also ‘institutions and infrastructure’ (covering the quality of regulation, smart infrastructure and efficient markets), as well as the overall future readiness performance of the economies selected for comparison.
      </p>
    </div>

    <div class="col-12 text-center animate__animated animate__fadeIn mb-5 mt-3">
      <p class="font-weight-500 help-text"><i class="fa fa-lightbulb-o mr-2"/>Select countries to compare their future
        readiness scores</p>
    </div>

    <div class="col-12">
      <div class="radar-container">
        <RadarComparison :chartData="this.chartData"></RadarComparison>
      </div>
    </div>
    <div class="col-12 country-compare-buttons text-center">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
            id="checkbox-group-1"
            class="d-block"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            buttons
            button-variant="outline-secondary"
            name="buttons2"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

  </div>
</template>

<script>
import RadarComparison from "@/components-2021/charts/RadarComparison";

export default {
  name: "CountryComparison",
  components: {RadarComparison},
  data() {
    return {
      selected: [],
      options: [
        {
          text: "Argentina",
          scales: {
            r: {
              pointLabels: {
                color: 'green',
                font: {
                  size: 20,
                  style: 'italic'
                }
              }
            }
          },
          value: {
            label: "Argentina",
            data: [
              38.2,
              40.14,
              48.25,
              46.81,
              17.57,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#88D8B0",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Brazil",
          value: {
            label: "Brazil",
            data: [
              38.39,
              40.48,
              46.88,
              40.29,
              25.92,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#ce5cc5",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Chile",
          value: {
            label: "Chile",
            data: [
              44.71,
              57.97,
              50.88,
              49.67,
              20.32,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#FED06A",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "China",
          value: {
            label: "China",
            data: [
              54.71,
              62.06,
              58.49,
              50.33,
              47.96,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#F96E5A",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Colombia",
          value: {
            label: "Colombia",
            data: [
              37.17,
              46.61,
              45.06,
              39.25,
              17.75,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#1583D1",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Egypt",
          value: {
            label: "Egypt",
            data: [
              32.19,
              38.28,
              39.59,
              38.71,
              12.2,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#65CBDA",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "India",
          value: {
            label: "India",
            data: [
              39.81,
              52.48,
              40.89,
              36.57,
              29.28,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#8635D5",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Indonesia",
          value: {
            label: "Indonesia",
            data: [
              38.05,
              54.03,
              43.7,
              37.52,
              16.95,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#F24982",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Israel",
          value: {
            label: "Israel",
            data: [
              61.22,
              60.02,
              64.39,
              57.86,
              62.59,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#F98617",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Jordan",
          value: {
            label: "Jordan",
            data: [
              37.23,
              44.78,
              44.14,
              45.15,
              14.86,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#F9C823",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Kenya",
          value: {
            label: "Kenya",
            data: [
              30.66,
              41.05,
              32.53,
              34.91,
              14.14,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#2DC574",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Kuwait",
          value: {
            label: "Kuwait",
            data: [
              39.12,
              46.84,
              52.96,
              42.37,
              14.3,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#006CDC",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Lebanon",
          value: {
            label: "Lebanon",
            data: [
              32.77,
              35.22,
              37.72,
              38.3,
              19.86,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#E65F50",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Mexico",
          value: {
            label: "Mexico",
            data: [
              39.13,
              46.18,
              47.39,
              39.06,
              23.87,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#FF8D5C",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Morocco",
          value: {
            label: "Morocco",
            data: [
              33.58,
              48.47,
              38.75,
              31.92,
              15.18,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#FDC85E",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Nigeria",
          value: {
            label: "Nigeria",
            data: [
              22.82,
              29.98,
              24.82,
              29.29,
              7.18,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#FDEA8B",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Peru",
          value: {
            label: "Peru",
            data: [
              32.87,
              42.75,
              37.21,
              37.9,
              13.61,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#7EA157",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Qatar",
          value: {
            label: "Qatar",
            data: [
              48.71,
              62.21,
              62.43,
              53,
              17.2,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#37446C",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Russia",
          value: {
            label: "Russia",
            data: [
              42.42,
              45.12,
              50.39,
              49.52,
              24.67,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#F9D98E",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Saudi Arabia",
          value: {
            label: "Saudi Arabia",
            data: [
              45.1,
              47.82,
              59.69,
              46.89,
              26,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#F0876C",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Singapore",
          value: {
            label: "Singapore",
            data: [
              72.76,
              75.8,
              83.77,
              72.87,
              58.61,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#5E3D63",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "South Africa",
          value: {
            label: "South Africa",
            data: [
              39.49,
              48.11,
              40.93,
              37.33,
              31.6,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#5FC6BA",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Thailand",
          value: {
            label: "Thailand",
            data: [
              43.55,
              53.27,
              52.32,
              36.79,
              31.81,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#D5B96A",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Turkey",
          value: {
            label: "Turkey",
            data: [
              41.08,
              48.13,
              49.53,
              38.75,
              27.9,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#727670",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Ukraine",
          value: {
            label: "Ukraine",
            data: [
              36.19,
              38,
              43.23,
              45.54,
              17.99,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#9BA385",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "United Arab Emirates",
          value: {
            label: "United Arab Emirates",
            data: [
              59.24,
              67.92,
              64.18,
              63.98,
              40.88,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#D7C59F",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        },
        {
          text: "Viet Nam",
          value: {
            label: "Viet Nam",
            data: [
              38.56,
              46.66,
              48.04,
              34.02,
              25.51,
            ],
            fill: true,
            lineTension: 0.2,
            borderColor: "#34215E",
            // backgroundColor: "#DB443750",
            backgroundColor: '#FFFFFF00',
            borderWidth: 3
          }
        }
      ],
    };
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    chartData() {
      const labels = [["Future Readiness", "Score"], ["Institutions", "and Infrastructure"], "Technology", "Talent", "Innovation"]
      const chartDataSet = {
        labels: labels,
        datasets: this.selected,
      };
      return chartDataSet;
    },
  },
};
</script>

<style>

.radar-container {
  max-width: 600px;
  margin: auto;
}

.country-compare-buttons .btn-group .btn:first-child {
  border-bottom: solid 3px #88D8B0 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:first-child {
  background-color: #88D8B0 !important;
  color: #fff !important;
  border-color: #88D8B0 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(2) {
  border-bottom: solid 3px #ce5cc5 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(2) {
  background-color: #ce5cc5 !important;
  color: #fff !important;
  border-color: #ce5cc5 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(3) {
  border-bottom: solid 3px #FED06A !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(3) {
  background-color: #FED06A !important;
  color: #fff !important;
  border-color: #FED06A !important;
}

.country-compare-buttons .btn-group .btn:nth-child(4) {
  border-bottom: solid 3px #F96E5A !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(4) {
  background-color: #F96E5A !important;
  color: #fff !important;
  border-color: #F96E5A !important;
}

.country-compare-buttons .btn-group .btn:nth-child(5) {
  border-bottom: solid 3px #1583D1 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(5) {
  background-color: #1583D1 !important;
  color: #fff !important;
  border-color: #1583D1 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(6) {
  border-bottom: solid 3px #65CBDA !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(6) {
  background-color: #65CBDA !important;
  color: #fff !important;
  border-color: #65CBDA !important;
}

.country-compare-buttons .btn-group .btn:nth-child(7) {
  border-bottom: solid 3px #8635D5 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(7) {
  background-color: #8635D5 !important;
  color: #fff !important;
  border-color: #8635D5 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(8) {
  border-bottom: solid 3px #F24982 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(8) {
  background-color: #F24982 !important;
  color: #fff !important;
  border-color: #F24982 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(9) {
  border-bottom: solid 3px #F98617 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(9) {
  background-color: #F98617 !important;
  color: #fff !important;
  border-color: #F98617 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(10) {
  border-bottom: solid 3px #F9C823 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(10) {
  background-color: #F9C823 !important;
  color: #fff !important;
  border-color: #F9C823 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(11) {
  border-bottom: solid 3px #2DC574 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(11) {
  background-color: #2DC574 !important;
  color: #fff !important;
  border-color: #2DC574 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(12) {
  border-bottom: solid 3px #006CDC !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(12) {
  background-color: #006CDC !important;
  color: #fff !important;
  border-color: #006CDC !important;
}

.country-compare-buttons .btn-group .btn:nth-child(13) {
  border-bottom: solid 3px #E65F50 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(13) {
  background-color: #E65F50 !important;
  color: #fff !important;
  border-color: #E65F50 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(14) {
  border-bottom: solid 3px #FF8D5C !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(14) {
  background-color: #FF8D5C !important;
  color: #fff !important;
  border-color: #FF8D5C !important;
}

.country-compare-buttons .btn-group .btn:nth-child(15) {
  border-bottom: solid 3px #FDC85E !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(15) {
  background-color: #FDC85E !important;
  color: #fff !important;
  border-color: #FDC85E !important;
}

.country-compare-buttons .btn-group .btn:nth-child(16) {
  border-bottom: solid 3px #FDEA8B !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(16) {
  background-color: #FDEA8B !important;
  color: #fff !important;
  border-color: #FDEA8B !important;
}

.country-compare-buttons .btn-group .btn:nth-child(17) {
  border-bottom: solid 3px #7EA157 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(17) {
  background-color: #7EA157 !important;
  color: #fff !important;
  border-color: #7EA157 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(18) {
  border-bottom: solid 3px #37446C !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(18) {
  background-color: #37446C !important;
  color: #fff !important;
  border-color: #37446C !important;
}

.country-compare-buttons .btn-group .btn:nth-child(19) {
  border-bottom: solid 3px #F9D98E !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(19) {
  background-color: #F9D98E !important;
  color: #fff !important;
  border-color: #F9D98E !important;
}

.country-compare-buttons .btn-group .btn:nth-child(20) {
  border-bottom: solid 3px #F0876C !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(20) {
  background-color: #F0876C !important;
  color: #fff !important;
  border-color: #F0876C !important;
}

.country-compare-buttons .btn-group .btn:nth-child(21) {
  border-bottom: solid 3px #5E3D63 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(21) {
  background-color: #5E3D63 !important;
  color: #fff !important;
  border-color: #5E3D63 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(22) {
  border-bottom: solid 3px #5FC6BA !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(22) {
  background-color: #5FC6BA !important;
  color: #fff !important;
  border-color: #5FC6BA !important;
}

.country-compare-buttons .btn-group .btn:nth-child(23) {
  border-bottom: solid 3px #D5B96A !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(23) {
  background-color: #D5B96A !important;
  color: #fff !important;
  border-color: #D5B96A !important;
}

.country-compare-buttons .btn-group .btn:nth-child(24) {
  border-bottom: solid 3px #727670 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(24) {
  background-color: #727670 !important;
  color: #fff !important;
  border-color: #727670 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(25) {
  border-bottom: solid 3px #9BA385 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(25) {
  background-color: #9BA385 !important;
  color: #fff !important;
  border-color: #9BA385 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(26) {
  border-bottom: solid 3px #D7C59F !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(26) {
  background-color: #D7C59F !important;
  color: #fff !important;
  border-color: #D7C59F !important;
}

.country-compare-buttons .btn-group .btn:nth-child(27) {
  border-bottom: solid 3px #34215E !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(27) {
  background-color: #34215E !important;
  color: #fff !important;
  border-color: #34215E !important;
}
</style>
