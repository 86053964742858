<template>
  <div class="col-12">
    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Institutions_and_Infrastructure" :top-three="topThreePillar" scoreField="Institutions_and_Infrastructure"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Institutions_and_Infrastructure"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Technology"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Technology"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Talent" :top-three="topThreePillar" scoreField="Talent"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Talent"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Innovation" :top-three="topThreePillar" scoreField="Innovation"
               scoreRank="none" :pillar-rank="this.countryRankings[this.selectedCountry].pillars.Innovation"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Institutions_and_Infrastructure" :top-three="topThreePillar" scoreField="Regulatory_Environment"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Regulatory_Environment"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Digital_Transformation_Technologies" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Digital_Transformation_Technologies"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Talent" :top-three="topThreePillar" scoreField="Attract"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Attract"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Innovation" :top-three="topThreePillar" scoreField="Research_and_Development"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Research_and_Development"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Institutions_and_Infrastructure" :top-three="topThreePillar" scoreField="Market_Environment"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Market_Environment"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="People" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.People"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Talent" :top-three="topThreePillar" scoreField="Grow" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Grow"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Innovation" :top-three="topThreePillar" scoreField="Market_Sophistication"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Market_Sophistication"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Institutions_and_Infrastructure" :top-three="topThreePillar" scoreField="Infrastructure"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Infrastructure"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Governance"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Governance"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Talent" :top-three="topThreePillar" scoreField="Retain" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Retain"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Innovation" :top-three="topThreePillar" scoreField="Business_Sophistication"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Business_Sophistication"></Ranking>
    </div>

    <div class="row no-gutters">
      <Ranking class="" :pillar-average="this.countryRankings[this.selectedCountry].pillars.Institutions_and_Infrastructure" :top-three="topThreePillar" scoreField="Digital_Economy"
               scoreRank="none"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Technology" :top-three="topThreePillar" scoreField="Digital_Economy"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Digital_Economy"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Talent" :top-three="topThreePillar" scoreField="Skills" :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Skills"></Ranking>
      <Ranking :pillar-average="this.countryRankings[this.selectedCountry].pillars.Innovation" :top-three="topThreePillar" scoreField="Knowledge_Technology_and_Creative_Outputs"
               :scoreRank="this.countryRankings[this.selectedCountry].sub_pillars.Knowledge_Technology_and_Creative_Outputs"></Ranking>
    </div>

    <div class="container colour-legend-container">
      <div class="row">
        <div class="col text-center">
          <div class="m-a text-center">
            <div class="institutions colour-legend green"></div>
            <div class="technology colour-legend green"></div>
            <div class="talent colour-legend green"></div>
            <div class="innovation colour-legend green"></div>
          </div>
          <div class="row">
            <div class="col"><p>Top Performing Areas</p></div>
          </div>
        </div>
        <div class="col text-center">
          <div class="m-a text-center">
            <div class="institutions colour-legend yellow"></div>
            <div class="technology colour-legend yellow"></div>
            <div class="talent colour-legend yellow"></div>
            <div class="innovation colour-legend yellow"></div>
          </div>
          <div class="row">
            <div class="col"><p>Room for improvement</p></div>
          </div>
        </div>
        <div class="col text-center">
          <div class="m-a text-center">
            <div class="institutions colour-legend red"></div>
            <div class="technology colour-legend red"></div>
            <div class="talent colour-legend red"></div>
            <div class="innovation colour-legend red"></div>
          </div>
          <div class="row">
            <div class="col"><p>Requires Attention</p></div>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>

import Ranking from "@/views-2021/components-2021/Ranking";

export default {
  name: "CountryRankings",
  components: {Ranking},
  props: {
    countryRankings: {
      type: Object
    },
    selectedCountry: {
      type: String
    }
  },
  computed: {
    topThreePillar() {

      let rankings = this.countryRankings[this.selectedCountry].sub_pillars

      var sortable = [];
      for (var pillar in rankings) {
        sortable.push([pillar, rankings[pillar]]);
      }

      const sortedRankings = sortable.sort(function (a, b) {
        return a[1] - b[1];
      });

      var id = 'Talent';

      for (var i = 0; i < sortedRankings.length; i++) {
        if (sortedRankings[i].id == id) {
          sortedRankings.splice(i, 1);
          break;
        }
      }
      
      return sortedRankings.slice(0, 3);

      // Technology
      // Talent
      // Innovation
      // Future_Readiness

    }
  }
}
</script>

<style scoped>

.ranking-stat-box-header {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  background: #4a4a4a;
  margin: 0;
  min-height: 90px;
  color: white;
  border: 0.1rem solid #ffffff !important;
}

.ranking-stat-box-header p {
  font-size: 12px;
}

.ranking-stat-box-header .field-value {
  font-size: large;
}

.colour-legend {
  height: 25px;
  width: 25px;
  margin: 2px;
  border-radius: 100%;
  display: inline-block;
}

.institutions.colour-legend.red {
  background: #95b5e0 !important;
}

.institutions.colour-legend.yellow {
  background: #79a3da !important;
}

.institutions.colour-legend.green {
  background: #4e81c4 !important;
}

.technology.colour-legend.red {
  background: #e89b87 !important;
}

.technology.colour-legend.yellow {
  background: #d3684b !important;
}

.technology.colour-legend.green {
  background: #cc4c2a !important;
}

.talent.colour-legend.red {
  background: #f1c250 !important;
}

.talent.colour-legend.yellow {
  background: #e8b539 !important;
}

.talent.colour-legend.green {
  background: #e2aa23 !important;
}

.innovation.colour-legend.red {
  background: #89b982 !important;
}

.innovation.colour-legend.yellow {
  background: #74b46c !important;
}

.innovation.colour-legend.green {
  background: #66ad5d !important;
}

.colour-legend-container {
  background: #fafafa;
  margin: 2em 0 0 0;
  padding: 3em 0 1em 0;
}

</style>
