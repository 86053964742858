<template>
  <div>
    <highcharts
        v-if="this.mapData === 'FutureReadinessScore'"
        :constructorType="'mapChart'"
        class="hc"
        @country-clicked="goToProfile"
        :options="chartOptionsFutureReadinessScore"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'PhysicalCapital'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsPhysicalCapital"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'HumanCapital'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsHumanCapital"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'Technology'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsTechnology"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'Competitiveness'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsCompetitiveness"
        ref="chart"
    ></highcharts>
  </div>
</template>

<script>

import countryMapData from '../../data_2022/map/countryMap.js'
import worldMap from "../../data_2022/map/world-robinson-highres.geo.json";

export default {
  props: {
    mapData: {
      type: String,
    },
  },
  methods: {
    goToProfile(country) {
      console.log(country)
      // this.$router.push({ path: 'report', query: { page: 'map', country: country } })
    }
  },
  data() {
    return {
      chartOptionsFutureReadinessScore: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipLine"></span>' + '<div class="series-name py-2" style="background-color: #3ac7ff;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value">' + this.point.rank + '</span></br><span class="stat-field">Rank</span></div>' +
                  '<div class="col"><span class="stat-value">' + this.point.score + '</span></br><span class="stat-field">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#000",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#cbcbcb",
          borderRadius: 10,
          shadow: false,
        },
        title: {
          text: "",
        },
        chart: {
          height: 600,
          map: worldMap
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#ffffff",
          maxColor: "#3ac7ff",
          lineColor: "green",
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: ({point}) => {
                  let countryForUrl = point.name.replace(" ", "_").toLowerCase();
                  const query = { ...this.$route.query, page: 'profiles', country: countryForUrl };
                  this.$router.replace({ query });
                  window.location.reload();
                }
              }
            }
          }
        },
        series: countryMapData.futureReadiness
      },
      chartOptionsPhysicalCapital: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #4e81c4;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#000",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#cbcbcb",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#4e81c4",
          lineColor: "green",
        },
        series: countryMapData.physicalCapital
      },
      chartOptionsTechnology: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #cc4c2a;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#fff",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#cbcbcb",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#cc4c2a",
          lineColor: "green",
        },
        series: countryMapData.technology
      },
      chartOptionsHumanCapital: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #e2aa23;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#fff",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#FAFAFA",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#e2aa23",
          lineColor: "green",
        },
        series: countryMapData.humanCapital
      },
      chartOptionsCompetitiveness: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #66ad5d;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#fff",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#FAFAFA",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#66ad5d",
          lineColor: "green",
        },
        series: countryMapData.competitiveness
      },
    };
  },
};
</script>

<style>

.stat-name {
  font-size: large;
  font-weight: bold;
}

.stat-field {
  font-size: medium;
  font-weight: normal;
}

.stat-value {
  font-size: x-large;
  font-weight: bold;
}

.mapTooltip {
  font-family: 'Google Sans', sans-serif;
}

.mapTooltip .tooltipHeader {
  font-size: 1.2rem;
  font-weight: bold;
}

.mapTooltip .tooltipHeader::after {
  float: none;
}

.mapTooltip .tooltipPointWrapper {
  font-family: 'Google Sans', sans-serif !important;
  color: #4A4A4A !important;
  display: block;
  text-align: left;
  padding: 0;
  width: 400px;
  white-space: pre-wrap;
}

.mapTooltip .tooltipPoint {
  font-size: 1rem;
  padding-left: 5px;
}

.mapTooltip .tooltipValueSuffix {
  padding-left: 5px;
  color: #1bc9a8;
}

.mapTooltip .tooltipLine {
  display: block;
  opacity: 0.5;
  background-color: #fff;
  width: 100%;
  height: 1px;
  padding: 0;
  margin: 0;
}

.mapTooltip .country-name {
  border-bottom: solid 1px black;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.mapTooltip .series-name {
  font-size: larger;
}
</style>
