import data from '../data.json'

const countryRankingData = [
    {
        "Country": "Albania",
        "FREI": {
            "score": data.albania.future_readiness_score,
            "rank": data.albania.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.albania.physical_capital,
            "rank": data.albania.physical_capital_rank,
        },
        "Technology": {
            "score": data.albania.technology,
            "rank": data.albania.technology_rank,
        },
        "HumanCapital": {
            "score": data.albania.human_capital,
            "rank": data.albania.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.albania.competitiveness,
            "rank": data.albania.competitiveness_rank,
        }
    },
    {
        "Country": "Algeria",
        "FREI": {
            "score": data.algeria.future_readiness_score,
            "rank": data.algeria.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.algeria.physical_capital,
            "rank": data.algeria.physical_capital_rank,
        },
        "Technology": {
            "score": data.algeria.technology,
            "rank": data.algeria.technology_rank,
        },
        "HumanCapital": {
            "score": data.algeria.human_capital,
            "rank": data.algeria.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.algeria.competitiveness,
            "rank": data.algeria.competitiveness_rank,
        }
    },
    {
        "Country": "Angola",
        "FREI": {
            "score": data.angola.future_readiness_score,
            "rank": data.angola.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.angola.physical_capital,
            "rank": data.angola.physical_capital_rank,
        },
        "Technology": {
            "score": data.angola.technology,
            "rank": data.angola.technology_rank,
        },
        "HumanCapital": {
            "score": data.angola.human_capital,
            "rank": data.angola.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.angola.competitiveness,
            "rank": data.angola.competitiveness_rank,
        }
    },
    {
        "Country": "Argentina",
        "FREI": {
            "score": data.argentina.future_readiness_score,
            "rank": data.argentina.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.argentina.physical_capital,
            "rank": data.argentina.physical_capital_rank,
        },
        "Technology": {
            "score": data.argentina.technology,
            "rank": data.argentina.technology_rank,
        },
        "HumanCapital": {
            "score": data.argentina.human_capital,
            "rank": data.argentina.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.argentina.competitiveness,
            "rank": data.argentina.competitiveness_rank,
        }
    },
    {
        "Country": "Armenia",
        "FREI": {
            "score": data.armenia.future_readiness_score,
            "rank": data.armenia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.armenia.physical_capital,
            "rank": data.armenia.physical_capital_rank,
        },
        "Technology": {
            "score": data.armenia.technology,
            "rank": data.armenia.technology_rank,
        },
        "HumanCapital": {
            "score": data.armenia.human_capital,
            "rank": data.armenia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.armenia.competitiveness,
            "rank": data.armenia.competitiveness_rank,
        }
    },
    {
        "Country": "Australia",
        "FREI": {
            "score": data.australia.future_readiness_score,
            "rank": data.australia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.australia.physical_capital,
            "rank": data.australia.physical_capital_rank,
        },
        "Technology": {
            "score": data.australia.technology,
            "rank": data.australia.technology_rank,
        },
        "HumanCapital": {
            "score": data.australia.human_capital,
            "rank": data.australia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.australia.competitiveness,
            "rank": data.australia.competitiveness_rank,
        }
    },
    {
        "Country": "Austria",
        "FREI": {
            "score": data.austria.future_readiness_score,
            "rank": data.austria.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.austria.physical_capital,
            "rank": data.austria.physical_capital_rank,
        },
        "Technology": {
            "score": data.austria.technology,
            "rank": data.austria.technology_rank,
        },
        "HumanCapital": {
            "score": data.austria.human_capital,
            "rank": data.austria.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.austria.competitiveness,
            "rank": data.austria.competitiveness_rank,
        }
    },
    {
        "Country": "Azerbaijan",
        "FREI": {
            "score": data.azerbaijan.future_readiness_score,
            "rank": data.azerbaijan.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.azerbaijan.physical_capital,
            "rank": data.azerbaijan.physical_capital_rank,
        },
        "Technology": {
            "score": data.azerbaijan.technology,
            "rank": data.azerbaijan.technology_rank,
        },
        "HumanCapital": {
            "score": data.azerbaijan.human_capital,
            "rank": data.azerbaijan.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.azerbaijan.competitiveness,
            "rank": data.azerbaijan.competitiveness_rank,
        }
    },
    {
        "Country": "Bahrain",
        "FREI": {
            "score": data.bahrain.future_readiness_score,
            "rank": data.bahrain.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.bahrain.physical_capital,
            "rank": data.bahrain.physical_capital_rank,
        },
        "Technology": {
            "score": data.bahrain.technology,
            "rank": data.bahrain.technology_rank,
        },
        "HumanCapital": {
            "score": data.bahrain.human_capital,
            "rank": data.bahrain.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.bahrain.competitiveness,
            "rank": data.bahrain.competitiveness_rank,
        }
    },
    {
        "Country": "Bangladesh",
        "FREI": {
            "score": data.bangladesh.future_readiness_score,
            "rank": data.bangladesh.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.bangladesh.physical_capital,
            "rank": data.bangladesh.physical_capital_rank,
        },
        "Technology": {
            "score": data.bangladesh.technology,
            "rank": data.bangladesh.technology_rank,
        },
        "HumanCapital": {
            "score": data.bangladesh.human_capital,
            "rank": data.bangladesh.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.bangladesh.competitiveness,
            "rank": data.bangladesh.competitiveness_rank,
        }
    },
    {
        "Country": "Belarus",
        "FREI": {
            "score": data.belarus.future_readiness_score,
            "rank": data.belarus.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.belarus.physical_capital,
            "rank": data.belarus.physical_capital_rank,
        },
        "Technology": {
            "score": data.belarus.technology,
            "rank": data.belarus.technology_rank,
        },
        "HumanCapital": {
            "score": data.belarus.human_capital,
            "rank": data.belarus.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.belarus.competitiveness,
            "rank": data.belarus.competitiveness_rank,
        }
    },
    {
        "Country": "Belgium",
        "FREI": {
            "score": data.belgium.future_readiness_score,
            "rank": data.belgium.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.belgium.physical_capital,
            "rank": data.belgium.physical_capital_rank,
        },
        "Technology": {
            "score": data.belgium.technology,
            "rank": data.belgium.technology_rank,
        },
        "HumanCapital": {
            "score": data.belgium.human_capital,
            "rank": data.belgium.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.belgium.competitiveness,
            "rank": data.belgium.competitiveness_rank,
        }
    },
    {
        "Country": "Benin",
        "FREI": {
            "score": data.benin.future_readiness_score,
            "rank": data.benin.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.benin.physical_capital,
            "rank": data.benin.physical_capital_rank,
        },
        "Technology": {
            "score": data.benin.technology,
            "rank": data.benin.technology_rank,
        },
        "HumanCapital": {
            "score": data.benin.human_capital,
            "rank": data.benin.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.benin.competitiveness,
            "rank": data.benin.competitiveness_rank,
        }
    },
    {
        "Country": "Bolivia",
        "FREI": {
            "score": data.bolivia.future_readiness_score,
            "rank": data.bolivia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.bolivia.physical_capital,
            "rank": data.bolivia.physical_capital_rank,
        },
        "Technology": {
            "score": data.bolivia.technology,
            "rank": data.bolivia.technology_rank,
        },
        "HumanCapital": {
            "score": data.bolivia.human_capital,
            "rank": data.bolivia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.bolivia.competitiveness,
            "rank": data.bolivia.competitiveness_rank,
        }
    },
    {
        "Country": "Bosnia and Herzegovina",
        "FREI": {
            "score": data.bosnia_and_herzegovina.future_readiness_score,
            "rank": data.bosnia_and_herzegovina.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.bosnia_and_herzegovina.physical_capital,
            "rank": data.bosnia_and_herzegovina.physical_capital_rank,
        },
        "Technology": {
            "score": data.bosnia_and_herzegovina.technology,
            "rank": data.bosnia_and_herzegovina.technology_rank,
        },
        "HumanCapital": {
            "score": data.bosnia_and_herzegovina.human_capital,
            "rank": data.bosnia_and_herzegovina.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.bosnia_and_herzegovina.competitiveness,
            "rank": data.bosnia_and_herzegovina.competitiveness_rank,
        }
    },
    {
        "Country": "Botswana",
        "FREI": {
            "score": data.botswana.future_readiness_score,
            "rank": data.botswana.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.botswana.physical_capital,
            "rank": data.botswana.physical_capital_rank,
        },
        "Technology": {
            "score": data.botswana.technology,
            "rank": data.botswana.technology_rank,
        },
        "HumanCapital": {
            "score": data.botswana.human_capital,
            "rank": data.botswana.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.botswana.competitiveness,
            "rank": data.botswana.competitiveness_rank,
        }
    },
    {
        "Country": "Brazil",
        "FREI": {
            "score": data.brazil.future_readiness_score,
            "rank": data.brazil.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.brazil.physical_capital,
            "rank": data.brazil.physical_capital_rank,
        },
        "Technology": {
            "score": data.brazil.technology,
            "rank": data.brazil.technology_rank,
        },
        "HumanCapital": {
            "score": data.brazil.human_capital,
            "rank": data.brazil.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.brazil.competitiveness,
            "rank": data.brazil.competitiveness_rank,
        }
    },
    {
        "Country": "Bulgaria",
        "FREI": {
            "score": data.bulgaria.future_readiness_score,
            "rank": data.bulgaria.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.bulgaria.physical_capital,
            "rank": data.bulgaria.physical_capital_rank,
        },
        "Technology": {
            "score": data.bulgaria.technology,
            "rank": data.bulgaria.technology_rank,
        },
        "HumanCapital": {
            "score": data.bulgaria.human_capital,
            "rank": data.bulgaria.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.bulgaria.competitiveness,
            "rank": data.bulgaria.competitiveness_rank,
        }
    },
    {
        "Country": "Burkina Faso",
        "FREI": {
            "score": data.burkina_faso.future_readiness_score,
            "rank": data.burkina_faso.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.burkina_faso.physical_capital,
            "rank": data.burkina_faso.physical_capital_rank,
        },
        "Technology": {
            "score": data.burkina_faso.technology,
            "rank": data.burkina_faso.technology_rank,
        },
        "HumanCapital": {
            "score": data.burkina_faso.human_capital,
            "rank": data.burkina_faso.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.burkina_faso.competitiveness,
            "rank": data.burkina_faso.competitiveness_rank,
        }
    },
    {
        "Country": "Cambodia",
        "FREI": {
            "score": data.cambodia.future_readiness_score,
            "rank": data.cambodia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.cambodia.physical_capital,
            "rank": data.cambodia.physical_capital_rank,
        },
        "Technology": {
            "score": data.cambodia.technology,
            "rank": data.cambodia.technology_rank,
        },
        "HumanCapital": {
            "score": data.cambodia.human_capital,
            "rank": data.cambodia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.cambodia.competitiveness,
            "rank": data.cambodia.competitiveness_rank,
        }
    },
    {
        "Country": "Cameroon",
        "FREI": {
            "score": data.cameroon.future_readiness_score,
            "rank": data.cameroon.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.cameroon.physical_capital,
            "rank": data.cameroon.physical_capital_rank,
        },
        "Technology": {
            "score": data.cameroon.technology,
            "rank": data.cameroon.technology_rank,
        },
        "HumanCapital": {
            "score": data.cameroon.human_capital,
            "rank": data.cameroon.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.cameroon.competitiveness,
            "rank": data.cameroon.competitiveness_rank,
        }
    },
    {
        "Country": "Canada",
        "FREI": {
            "score": data.canada.future_readiness_score,
            "rank": data.canada.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.canada.physical_capital,
            "rank": data.canada.physical_capital_rank,
        },
        "Technology": {
            "score": data.canada.technology,
            "rank": data.canada.technology_rank,
        },
        "HumanCapital": {
            "score": data.canada.human_capital,
            "rank": data.canada.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.canada.competitiveness,
            "rank": data.canada.competitiveness_rank,
        }
    },
    {
        "Country": "Chile",
        "FREI": {
            "score": data.chile.future_readiness_score,
            "rank": data.chile.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.chile.physical_capital,
            "rank": data.chile.physical_capital_rank,
        },
        "Technology": {
            "score": data.chile.technology,
            "rank": data.chile.technology_rank,
        },
        "HumanCapital": {
            "score": data.chile.human_capital,
            "rank": data.chile.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.chile.competitiveness,
            "rank": data.chile.competitiveness_rank,
        }
    },
    {
        "Country": "China",
        "FREI": {
            "score": data.china.future_readiness_score,
            "rank": data.china.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.china.physical_capital,
            "rank": data.china.physical_capital_rank,
        },
        "Technology": {
            "score": data.china.technology,
            "rank": data.china.technology_rank,
        },
        "HumanCapital": {
            "score": data.china.human_capital,
            "rank": data.china.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.china.competitiveness,
            "rank": data.china.competitiveness_rank,
        }
    },
    {
        "Country": "Colombia",
        "FREI": {
            "score": data.colombia.future_readiness_score,
            "rank": data.colombia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.colombia.physical_capital,
            "rank": data.colombia.physical_capital_rank,
        },
        "Technology": {
            "score": data.colombia.technology,
            "rank": data.colombia.technology_rank,
        },
        "HumanCapital": {
            "score": data.colombia.human_capital,
            "rank": data.colombia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.colombia.competitiveness,
            "rank": data.colombia.competitiveness_rank,
        }
    },
    {
        "Country": "Costa Rica",
        "FREI": {
            "score": data.costa_rica.future_readiness_score,
            "rank": data.costa_rica.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.costa_rica.physical_capital,
            "rank": data.costa_rica.physical_capital_rank,
        },
        "Technology": {
            "score": data.costa_rica.technology,
            "rank": data.costa_rica.technology_rank,
        },
        "HumanCapital": {
            "score": data.costa_rica.human_capital,
            "rank": data.costa_rica.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.costa_rica.competitiveness,
            "rank": data.costa_rica.competitiveness_rank,
        }
    },
    {
        "Country": "Côte d'Ivoire",
        "FREI": {
            "score": data.cote_divoire.future_readiness_score,
            "rank": data.cote_divoire.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.cote_divoire.physical_capital,
            "rank": data.cote_divoire.physical_capital_rank,
        },
        "Technology": {
            "score": data.cote_divoire.technology,
            "rank": data.cote_divoire.technology_rank,
        },
        "HumanCapital": {
            "score": data.cote_divoire.human_capital,
            "rank": data.cote_divoire.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.cote_divoire.competitiveness,
            "rank": data.cote_divoire.competitiveness_rank,
        }
    },
    {
        "Country": "Croatia",
        "FREI": {
            "score": data.croatia.future_readiness_score,
            "rank": data.croatia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.croatia.physical_capital,
            "rank": data.croatia.physical_capital_rank,
        },
        "Technology": {
            "score": data.croatia.technology,
            "rank": data.croatia.technology_rank,
        },
        "HumanCapital": {
            "score": data.croatia.human_capital,
            "rank": data.croatia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.croatia.competitiveness,
            "rank": data.croatia.competitiveness_rank,
        }
    },
    {
        "Country": "Cyprus",
        "FREI": {
            "score": data.cyprus.future_readiness_score,
            "rank": data.cyprus.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.cyprus.physical_capital,
            "rank": data.cyprus.physical_capital_rank,
        },
        "Technology": {
            "score": data.cyprus.technology,
            "rank": data.cyprus.technology_rank,
        },
        "HumanCapital": {
            "score": data.cyprus.human_capital,
            "rank": data.cyprus.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.cyprus.competitiveness,
            "rank": data.cyprus.competitiveness_rank,
        }
    },
    {
        "Country": "Czech Republic",
        "FREI": {
            "score": data.czech_republic.future_readiness_score,
            "rank": data.czech_republic.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.czech_republic.physical_capital,
            "rank": data.czech_republic.physical_capital_rank,
        },
        "Technology": {
            "score": data.czech_republic.technology,
            "rank": data.czech_republic.technology_rank,
        },
        "HumanCapital": {
            "score": data.czech_republic.human_capital,
            "rank": data.czech_republic.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.czech_republic.competitiveness,
            "rank": data.czech_republic.competitiveness_rank,
        }
    },
    {
        "Country": "Denmark",
        "FREI": {
            "score": data.denmark.future_readiness_score,
            "rank": data.denmark.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.denmark.physical_capital,
            "rank": data.denmark.physical_capital_rank,
        },
        "Technology": {
            "score": data.denmark.technology,
            "rank": data.denmark.technology_rank,
        },
        "HumanCapital": {
            "score": data.denmark.human_capital,
            "rank": data.denmark.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.denmark.competitiveness,
            "rank": data.denmark.competitiveness_rank,
        }
    },
    {
        "Country": "Dominican Republic",
        "FREI": {
            "score": data.dominican_republic.future_readiness_score,
            "rank": data.dominican_republic.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.dominican_republic.physical_capital,
            "rank": data.dominican_republic.physical_capital_rank,
        },
        "Technology": {
            "score": data.dominican_republic.technology,
            "rank": data.dominican_republic.technology_rank,
        },
        "HumanCapital": {
            "score": data.dominican_republic.human_capital,
            "rank": data.dominican_republic.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.dominican_republic.competitiveness,
            "rank": data.dominican_republic.competitiveness_rank,
        }
    },
    {
        "Country": "Ecuador",
        "FREI": {
            "score": data.ecuador.future_readiness_score,
            "rank": data.ecuador.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.ecuador.physical_capital,
            "rank": data.ecuador.physical_capital_rank,
        },
        "Technology": {
            "score": data.ecuador.technology,
            "rank": data.ecuador.technology_rank,
        },
        "HumanCapital": {
            "score": data.ecuador.human_capital,
            "rank": data.ecuador.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.ecuador.competitiveness,
            "rank": data.ecuador.competitiveness_rank,
        }
    },
    {
        "Country": "Egypt",
        "FREI": {
            "score": data.egypt.future_readiness_score,
            "rank": data.egypt.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.egypt.physical_capital,
            "rank": data.egypt.physical_capital_rank,
        },
        "Technology": {
            "score": data.egypt.technology,
            "rank": data.egypt.technology_rank,
        },
        "HumanCapital": {
            "score": data.egypt.human_capital,
            "rank": data.egypt.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.egypt.competitiveness,
            "rank": data.egypt.competitiveness_rank,
        }
    },
    {
        "Country": "El Salvador",
        "FREI": {
            "score": data.el_salvador.future_readiness_score,
            "rank": data.el_salvador.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.el_salvador.physical_capital,
            "rank": data.el_salvador.physical_capital_rank,
        },
        "Technology": {
            "score": data.el_salvador.technology,
            "rank": data.el_salvador.technology_rank,
        },
        "HumanCapital": {
            "score": data.el_salvador.human_capital,
            "rank": data.el_salvador.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.el_salvador.competitiveness,
            "rank": data.el_salvador.competitiveness_rank,
        }
    },
    {
        "Country": "Estonia",
        "FREI": {
            "score": data.estonia.future_readiness_score,
            "rank": data.estonia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.estonia.physical_capital,
            "rank": data.estonia.physical_capital_rank,
        },
        "Technology": {
            "score": data.estonia.technology,
            "rank": data.estonia.technology_rank,
        },
        "HumanCapital": {
            "score": data.estonia.human_capital,
            "rank": data.estonia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.estonia.competitiveness,
            "rank": data.estonia.competitiveness_rank,
        }
    },
    {
        "Country": "Ethiopia",
        "FREI": {
            "score": data.ethiopia.future_readiness_score,
            "rank": data.ethiopia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.ethiopia.physical_capital,
            "rank": data.ethiopia.physical_capital_rank,
        },
        "Technology": {
            "score": data.ethiopia.technology,
            "rank": data.ethiopia.technology_rank,
        },
        "HumanCapital": {
            "score": data.ethiopia.human_capital,
            "rank": data.ethiopia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.ethiopia.competitiveness,
            "rank": data.ethiopia.competitiveness_rank,
        }
    },
    {
        "Country": "Finland",
        "FREI": {
            "score": data.finland.future_readiness_score,
            "rank": data.finland.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.finland.physical_capital,
            "rank": data.finland.physical_capital_rank,
        },
        "Technology": {
            "score": data.finland.technology,
            "rank": data.finland.technology_rank,
        },
        "HumanCapital": {
            "score": data.finland.human_capital,
            "rank": data.finland.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.finland.competitiveness,
            "rank": data.finland.competitiveness_rank,
        }
    },
    {
        "Country": "France",
        "FREI": {
            "score": data.france.future_readiness_score,
            "rank": data.france.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.france.physical_capital,
            "rank": data.france.physical_capital_rank,
        },
        "Technology": {
            "score": data.france.technology,
            "rank": data.france.technology_rank,
        },
        "HumanCapital": {
            "score": data.france.human_capital,
            "rank": data.france.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.france.competitiveness,
            "rank": data.france.competitiveness_rank,
        }
    },
    {
        "Country": "Georgia",
        "FREI": {
            "score": data.georgia.future_readiness_score,
            "rank": data.georgia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.georgia.physical_capital,
            "rank": data.georgia.physical_capital_rank,
        },
        "Technology": {
            "score": data.georgia.technology,
            "rank": data.georgia.technology_rank,
        },
        "HumanCapital": {
            "score": data.georgia.human_capital,
            "rank": data.georgia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.georgia.competitiveness,
            "rank": data.georgia.competitiveness_rank,
        }
    },
    {
        "Country": "Germany",
        "FREI": {
            "score": data.germany.future_readiness_score,
            "rank": data.germany.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.germany.physical_capital,
            "rank": data.germany.physical_capital_rank,
        },
        "Technology": {
            "score": data.germany.technology,
            "rank": data.germany.technology_rank,
        },
        "HumanCapital": {
            "score": data.germany.human_capital,
            "rank": data.germany.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.germany.competitiveness,
            "rank": data.germany.competitiveness_rank,
        }
    },
    {
        "Country": "Ghana",
        "FREI": {
            "score": data.ghana.future_readiness_score,
            "rank": data.ghana.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.ghana.physical_capital,
            "rank": data.ghana.physical_capital_rank,
        },
        "Technology": {
            "score": data.ghana.technology,
            "rank": data.ghana.technology_rank,
        },
        "HumanCapital": {
            "score": data.ghana.human_capital,
            "rank": data.ghana.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.ghana.competitiveness,
            "rank": data.ghana.competitiveness_rank,
        }
    },
    {
        "Country": "Greece",
        "FREI": {
            "score": data.greece.future_readiness_score,
            "rank": data.greece.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.greece.physical_capital,
            "rank": data.greece.physical_capital_rank,
        },
        "Technology": {
            "score": data.greece.technology,
            "rank": data.greece.technology_rank,
        },
        "HumanCapital": {
            "score": data.greece.human_capital,
            "rank": data.greece.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.greece.competitiveness,
            "rank": data.greece.competitiveness_rank,
        }
    },
    {
        "Country": "Guatemala",
        "FREI": {
            "score": data.guatemala.future_readiness_score,
            "rank": data.guatemala.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.guatemala.physical_capital,
            "rank": data.guatemala.physical_capital_rank,
        },
        "Technology": {
            "score": data.guatemala.technology,
            "rank": data.guatemala.technology_rank,
        },
        "HumanCapital": {
            "score": data.guatemala.human_capital,
            "rank": data.guatemala.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.guatemala.competitiveness,
            "rank": data.guatemala.competitiveness_rank,
        }
    },
    {
        "Country": "Guinea",
        "FREI": {
            "score": data.guinea.future_readiness_score,
            "rank": data.guinea.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.guinea.physical_capital,
            "rank": data.guinea.physical_capital_rank,
        },
        "Technology": {
            "score": data.guinea.technology,
            "rank": data.guinea.technology_rank,
        },
        "HumanCapital": {
            "score": data.guinea.human_capital,
            "rank": data.guinea.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.guinea.competitiveness,
            "rank": data.guinea.competitiveness_rank,
        }
    },
    {
        "Country": "Honduras",
        "FREI": {
            "score": data.honduras.future_readiness_score,
            "rank": data.honduras.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.honduras.physical_capital,
            "rank": data.honduras.physical_capital_rank,
        },
        "Technology": {
            "score": data.honduras.technology,
            "rank": data.honduras.technology_rank,
        },
        "HumanCapital": {
            "score": data.honduras.human_capital,
            "rank": data.honduras.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.honduras.competitiveness,
            "rank": data.honduras.competitiveness_rank,
        }
    },
    {
        "Country": "Hungary",
        "FREI": {
            "score": data.hungary.future_readiness_score,
            "rank": data.hungary.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.hungary.physical_capital,
            "rank": data.hungary.physical_capital_rank,
        },
        "Technology": {
            "score": data.hungary.technology,
            "rank": data.hungary.technology_rank,
        },
        "HumanCapital": {
            "score": data.hungary.human_capital,
            "rank": data.hungary.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.hungary.competitiveness,
            "rank": data.hungary.competitiveness_rank,
        }
    },
    {
        "Country": "Iceland",
        "FREI": {
            "score": data.iceland.future_readiness_score,
            "rank": data.iceland.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.iceland.physical_capital,
            "rank": data.iceland.physical_capital_rank,
        },
        "Technology": {
            "score": data.iceland.technology,
            "rank": data.iceland.technology_rank,
        },
        "HumanCapital": {
            "score": data.iceland.human_capital,
            "rank": data.iceland.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.iceland.competitiveness,
            "rank": data.iceland.competitiveness_rank,
        }
    },
    {
        "Country": "India",
        "FREI": {
            "score": data.india.future_readiness_score,
            "rank": data.india.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.india.physical_capital,
            "rank": data.india.physical_capital_rank,
        },
        "Technology": {
            "score": data.india.technology,
            "rank": data.india.technology_rank,
        },
        "HumanCapital": {
            "score": data.india.human_capital,
            "rank": data.india.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.india.competitiveness,
            "rank": data.india.competitiveness_rank,
        }
    },
    {
        "Country": "Indonesia",
        "FREI": {
            "score": data.indonesia.future_readiness_score,
            "rank": data.indonesia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.indonesia.physical_capital,
            "rank": data.indonesia.physical_capital_rank,
        },
        "Technology": {
            "score": data.indonesia.technology,
            "rank": data.indonesia.technology_rank,
        },
        "HumanCapital": {
            "score": data.indonesia.human_capital,
            "rank": data.indonesia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.indonesia.competitiveness,
            "rank": data.indonesia.competitiveness_rank,
        }
    },
    {
        "Country": "Iran",
        "FREI": {
            "score": data.iran.future_readiness_score,
            "rank": data.iran.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.iran.physical_capital,
            "rank": data.iran.physical_capital_rank,
        },
        "Technology": {
            "score": data.iran.technology,
            "rank": data.iran.technology_rank,
        },
        "HumanCapital": {
            "score": data.iran.human_capital,
            "rank": data.iran.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.iran.competitiveness,
            "rank": data.iran.competitiveness_rank,
        }
    },
    {
        "Country": "Ireland",
        "FREI": {
            "score": data.ireland.future_readiness_score,
            "rank": data.ireland.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.ireland.physical_capital,
            "rank": data.ireland.physical_capital_rank,
        },
        "Technology": {
            "score": data.ireland.technology,
            "rank": data.ireland.technology_rank,
        },
        "HumanCapital": {
            "score": data.ireland.human_capital,
            "rank": data.ireland.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.ireland.competitiveness,
            "rank": data.ireland.competitiveness_rank,
        }
    },
    {
        "Country": "Israel",
        "FREI": {
            "score": data.israel.future_readiness_score,
            "rank": data.israel.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.israel.physical_capital,
            "rank": data.israel.physical_capital_rank,
        },
        "Technology": {
            "score": data.israel.technology,
            "rank": data.israel.technology_rank,
        },
        "HumanCapital": {
            "score": data.israel.human_capital,
            "rank": data.israel.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.israel.competitiveness,
            "rank": data.israel.competitiveness_rank,
        }
    },
    {
        "Country": "Italy",
        "FREI": {
            "score": data.italy.future_readiness_score,
            "rank": data.italy.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.italy.physical_capital,
            "rank": data.italy.physical_capital_rank,
        },
        "Technology": {
            "score": data.italy.technology,
            "rank": data.italy.technology_rank,
        },
        "HumanCapital": {
            "score": data.italy.human_capital,
            "rank": data.italy.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.italy.competitiveness,
            "rank": data.italy.competitiveness_rank,
        }
    },
    {
        "Country": "Jamaica",
        "FREI": {
            "score": data.jamaica.future_readiness_score,
            "rank": data.jamaica.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.jamaica.physical_capital,
            "rank": data.jamaica.physical_capital_rank,
        },
        "Technology": {
            "score": data.jamaica.technology,
            "rank": data.jamaica.technology_rank,
        },
        "HumanCapital": {
            "score": data.jamaica.human_capital,
            "rank": data.jamaica.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.jamaica.competitiveness,
            "rank": data.jamaica.competitiveness_rank,
        }
    },
    {
        "Country": "Japan",
        "FREI": {
            "score": data.japan.future_readiness_score,
            "rank": data.japan.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.japan.physical_capital,
            "rank": data.japan.physical_capital_rank,
        },
        "Technology": {
            "score": data.japan.technology,
            "rank": data.japan.technology_rank,
        },
        "HumanCapital": {
            "score": data.japan.human_capital,
            "rank": data.japan.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.japan.competitiveness,
            "rank": data.japan.competitiveness_rank,
        }
    },
    {
        "Country": "Jordan",
        "FREI": {
            "score": data.jordan.future_readiness_score,
            "rank": data.jordan.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.jordan.physical_capital,
            "rank": data.jordan.physical_capital_rank,
        },
        "Technology": {
            "score": data.jordan.technology,
            "rank": data.jordan.technology_rank,
        },
        "HumanCapital": {
            "score": data.jordan.human_capital,
            "rank": data.jordan.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.jordan.competitiveness,
            "rank": data.jordan.competitiveness_rank,
        }
    },
    {
        "Country": "Kazakhstan",
        "FREI": {
            "score": data.kazakhstan.future_readiness_score,
            "rank": data.kazakhstan.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.kazakhstan.physical_capital,
            "rank": data.kazakhstan.physical_capital_rank,
        },
        "Technology": {
            "score": data.kazakhstan.technology,
            "rank": data.kazakhstan.technology_rank,
        },
        "HumanCapital": {
            "score": data.kazakhstan.human_capital,
            "rank": data.kazakhstan.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.kazakhstan.competitiveness,
            "rank": data.kazakhstan.competitiveness_rank,
        }
    },
    {
        "Country": "Kenya",
        "FREI": {
            "score": data.kenya.future_readiness_score,
            "rank": data.kenya.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.kenya.physical_capital,
            "rank": data.kenya.physical_capital_rank,
        },
        "Technology": {
            "score": data.kenya.technology,
            "rank": data.kenya.technology_rank,
        },
        "HumanCapital": {
            "score": data.kenya.human_capital,
            "rank": data.kenya.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.kenya.competitiveness,
            "rank": data.kenya.competitiveness_rank,
        }
    },
    {
        "Country": "Kuwait",
        "FREI": {
            "score": data.kuwait.future_readiness_score,
            "rank": data.kuwait.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.kuwait.physical_capital,
            "rank": data.kuwait.physical_capital_rank,
        },
        "Technology": {
            "score": data.kuwait.technology,
            "rank": data.kuwait.technology_rank,
        },
        "HumanCapital": {
            "score": data.kuwait.human_capital,
            "rank": data.kuwait.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.kuwait.competitiveness,
            "rank": data.kuwait.competitiveness_rank,
        }
    },
    {
        "Country": "Kyrgyzstan",
        "FREI": {
            "score": data.kyrgyzstan.future_readiness_score,
            "rank": data.kyrgyzstan.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.kyrgyzstan.physical_capital,
            "rank": data.kyrgyzstan.physical_capital_rank,
        },
        "Technology": {
            "score": data.kyrgyzstan.technology,
            "rank": data.kyrgyzstan.technology_rank,
        },
        "HumanCapital": {
            "score": data.kyrgyzstan.human_capital,
            "rank": data.kyrgyzstan.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.kyrgyzstan.competitiveness,
            "rank": data.kyrgyzstan.competitiveness_rank,
        }
    },
    {
        "Country": "Laos",
        "FREI": {
            "score": data.laos.future_readiness_score,
            "rank": data.laos.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.laos.physical_capital,
            "rank": data.laos.physical_capital_rank,
        },
        "Technology": {
            "score": data.laos.technology,
            "rank": data.laos.technology_rank,
        },
        "HumanCapital": {
            "score": data.laos.human_capital,
            "rank": data.laos.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.laos.competitiveness,
            "rank": data.laos.competitiveness_rank,
        }
    },
    {
        "Country": "Latvia",
        "FREI": {
            "score": data.latvia.future_readiness_score,
            "rank": data.latvia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.latvia.physical_capital,
            "rank": data.latvia.physical_capital_rank,
        },
        "Technology": {
            "score": data.latvia.technology,
            "rank": data.latvia.technology_rank,
        },
        "HumanCapital": {
            "score": data.latvia.human_capital,
            "rank": data.latvia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.latvia.competitiveness,
            "rank": data.latvia.competitiveness_rank,
        }
    },
    {
        "Country": "Lebanon",
        "FREI": {
            "score": data.lebanon.future_readiness_score,
            "rank": data.lebanon.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.lebanon.physical_capital,
            "rank": data.lebanon.physical_capital_rank,
        },
        "Technology": {
            "score": data.lebanon.technology,
            "rank": data.lebanon.technology_rank,
        },
        "HumanCapital": {
            "score": data.lebanon.human_capital,
            "rank": data.lebanon.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.lebanon.competitiveness,
            "rank": data.lebanon.competitiveness_rank,
        }
    },
    {
        "Country": "Lithuania",
        "FREI": {
            "score": data.lithuania.future_readiness_score,
            "rank": data.lithuania.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.lithuania.physical_capital,
            "rank": data.lithuania.physical_capital_rank,
        },
        "Technology": {
            "score": data.lithuania.technology,
            "rank": data.lithuania.technology_rank,
        },
        "HumanCapital": {
            "score": data.lithuania.human_capital,
            "rank": data.lithuania.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.lithuania.competitiveness,
            "rank": data.lithuania.competitiveness_rank,
        }
    },
    {
        "Country": "Luxembourg",
        "FREI": {
            "score": data.luxembourg.future_readiness_score,
            "rank": data.luxembourg.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.luxembourg.physical_capital,
            "rank": data.luxembourg.physical_capital_rank,
        },
        "Technology": {
            "score": data.luxembourg.technology,
            "rank": data.luxembourg.technology_rank,
        },
        "HumanCapital": {
            "score": data.luxembourg.human_capital,
            "rank": data.luxembourg.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.luxembourg.competitiveness,
            "rank": data.luxembourg.competitiveness_rank,
        }
    },
    {
        "Country": "Madagascar",
        "FREI": {
            "score": data.madagascar.future_readiness_score,
            "rank": data.madagascar.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.madagascar.physical_capital,
            "rank": data.madagascar.physical_capital_rank,
        },
        "Technology": {
            "score": data.madagascar.technology,
            "rank": data.madagascar.technology_rank,
        },
        "HumanCapital": {
            "score": data.madagascar.human_capital,
            "rank": data.madagascar.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.madagascar.competitiveness,
            "rank": data.madagascar.competitiveness_rank,
        }
    },
    {
        "Country": "Malawi",
        "FREI": {
            "score": data.malawi.future_readiness_score,
            "rank": data.malawi.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.malawi.physical_capital,
            "rank": data.malawi.physical_capital_rank,
        },
        "Technology": {
            "score": data.malawi.technology,
            "rank": data.malawi.technology_rank,
        },
        "HumanCapital": {
            "score": data.malawi.human_capital,
            "rank": data.malawi.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.malawi.competitiveness,
            "rank": data.malawi.competitiveness_rank,
        }
    },
    {
        "Country": "Malaysia",
        "FREI": {
            "score": data.malaysia.future_readiness_score,
            "rank": data.malaysia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.malaysia.physical_capital,
            "rank": data.malaysia.physical_capital_rank,
        },
        "Technology": {
            "score": data.malaysia.technology,
            "rank": data.malaysia.technology_rank,
        },
        "HumanCapital": {
            "score": data.malaysia.human_capital,
            "rank": data.malaysia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.malaysia.competitiveness,
            "rank": data.malaysia.competitiveness_rank,
        }
    },
    {
        "Country": "Mali",
        "FREI": {
            "score": data.mali.future_readiness_score,
            "rank": data.mali.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.mali.physical_capital,
            "rank": data.mali.physical_capital_rank,
        },
        "Technology": {
            "score": data.mali.technology,
            "rank": data.mali.technology_rank,
        },
        "HumanCapital": {
            "score": data.mali.human_capital,
            "rank": data.mali.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.mali.competitiveness,
            "rank": data.mali.competitiveness_rank,
        }
    },
    {
        "Country": "Malta",
        "FREI": {
            "score": data.malta.future_readiness_score,
            "rank": data.malta.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.malta.physical_capital,
            "rank": data.malta.physical_capital_rank,
        },
        "Technology": {
            "score": data.malta.technology,
            "rank": data.malta.technology_rank,
        },
        "HumanCapital": {
            "score": data.malta.human_capital,
            "rank": data.malta.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.malta.competitiveness,
            "rank": data.malta.competitiveness_rank,
        }
    },
    {
        "Country": "Mauritius",
        "FREI": {
            "score": data.mauritius.future_readiness_score,
            "rank": data.mauritius.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.mauritius.physical_capital,
            "rank": data.mauritius.physical_capital_rank,
        },
        "Technology": {
            "score": data.mauritius.technology,
            "rank": data.mauritius.technology_rank,
        },
        "HumanCapital": {
            "score": data.mauritius.human_capital,
            "rank": data.mauritius.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.mauritius.competitiveness,
            "rank": data.mauritius.competitiveness_rank,
        }
    },
    {
        "Country": "Mexico",
        "FREI": {
            "score": data.mexico.future_readiness_score,
            "rank": data.mexico.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.mexico.physical_capital,
            "rank": data.mexico.physical_capital_rank,
        },
        "Technology": {
            "score": data.mexico.technology,
            "rank": data.mexico.technology_rank,
        },
        "HumanCapital": {
            "score": data.mexico.human_capital,
            "rank": data.mexico.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.mexico.competitiveness,
            "rank": data.mexico.competitiveness_rank,
        }
    },
    {
        "Country": "Moldova",
        "FREI": {
            "score": data.moldova.future_readiness_score,
            "rank": data.moldova.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.moldova.physical_capital,
            "rank": data.moldova.physical_capital_rank,
        },
        "Technology": {
            "score": data.moldova.technology,
            "rank": data.moldova.technology_rank,
        },
        "HumanCapital": {
            "score": data.moldova.human_capital,
            "rank": data.moldova.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.moldova.competitiveness,
            "rank": data.moldova.competitiveness_rank,
        }
    },
    {
        "Country": "Mongolia",
        "FREI": {
            "score": data.mongolia.future_readiness_score,
            "rank": data.mongolia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.mongolia.physical_capital,
            "rank": data.mongolia.physical_capital_rank,
        },
        "Technology": {
            "score": data.mongolia.technology,
            "rank": data.mongolia.technology_rank,
        },
        "HumanCapital": {
            "score": data.mongolia.human_capital,
            "rank": data.mongolia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.mongolia.competitiveness,
            "rank": data.mongolia.competitiveness_rank,
        }
    },
    {
        "Country": "Morocco",
        "FREI": {
            "score": data.morocco.future_readiness_score,
            "rank": data.morocco.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.morocco.physical_capital,
            "rank": data.morocco.physical_capital_rank,
        },
        "Technology": {
            "score": data.morocco.technology,
            "rank": data.morocco.technology_rank,
        },
        "HumanCapital": {
            "score": data.morocco.human_capital,
            "rank": data.morocco.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.morocco.competitiveness,
            "rank": data.morocco.competitiveness_rank,
        }
    },
    {
        "Country": "Mozambique",
        "FREI": {
            "score": data.mozambique.future_readiness_score,
            "rank": data.mozambique.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.mozambique.physical_capital,
            "rank": data.mozambique.physical_capital_rank,
        },
        "Technology": {
            "score": data.mozambique.technology,
            "rank": data.mozambique.technology_rank,
        },
        "HumanCapital": {
            "score": data.mozambique.human_capital,
            "rank": data.mozambique.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.mozambique.competitiveness,
            "rank": data.mozambique.competitiveness_rank,
        }
    },
    {
        "Country": "Myanmar",
        "FREI": {
            "score": data.myanmar.future_readiness_score,
            "rank": data.myanmar.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.myanmar.physical_capital,
            "rank": data.myanmar.physical_capital_rank,
        },
        "Technology": {
            "score": data.myanmar.technology,
            "rank": data.myanmar.technology_rank,
        },
        "HumanCapital": {
            "score": data.myanmar.human_capital,
            "rank": data.myanmar.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.myanmar.competitiveness,
            "rank": data.myanmar.competitiveness_rank,
        }
    },
    {
        "Country": "Nepal",
        "FREI": {
            "score": data.nepal.future_readiness_score,
            "rank": data.nepal.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.nepal.physical_capital,
            "rank": data.nepal.physical_capital_rank,
        },
        "Technology": {
            "score": data.nepal.technology,
            "rank": data.nepal.technology_rank,
        },
        "HumanCapital": {
            "score": data.nepal.human_capital,
            "rank": data.nepal.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.nepal.competitiveness,
            "rank": data.nepal.competitiveness_rank,
        }
    },
    {
        "Country": "Netherlands",
        "FREI": {
            "score": data.netherlands.future_readiness_score,
            "rank": data.netherlands.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.netherlands.physical_capital,
            "rank": data.netherlands.physical_capital_rank,
        },
        "Technology": {
            "score": data.netherlands.technology,
            "rank": data.netherlands.technology_rank,
        },
        "HumanCapital": {
            "score": data.netherlands.human_capital,
            "rank": data.netherlands.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.netherlands.competitiveness,
            "rank": data.netherlands.competitiveness_rank,
        }
    },
    {
        "Country": "New Zealand",
        "FREI": {
            "score": data.new_zealand.future_readiness_score,
            "rank": data.new_zealand.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.new_zealand.physical_capital,
            "rank": data.new_zealand.physical_capital_rank,
        },
        "Technology": {
            "score": data.new_zealand.technology,
            "rank": data.new_zealand.technology_rank,
        },
        "HumanCapital": {
            "score": data.new_zealand.human_capital,
            "rank": data.new_zealand.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.new_zealand.competitiveness,
            "rank": data.new_zealand.competitiveness_rank,
        }
    },
    {
        "Country": "Niger",
        "FREI": {
            "score": data.niger.future_readiness_score,
            "rank": data.niger.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.niger.physical_capital,
            "rank": data.niger.physical_capital_rank,
        },
        "Technology": {
            "score": data.niger.technology,
            "rank": data.niger.technology_rank,
        },
        "HumanCapital": {
            "score": data.niger.human_capital,
            "rank": data.niger.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.niger.competitiveness,
            "rank": data.niger.competitiveness_rank,
        }
    },
    {
        "Country": "Nigeria",
        "FREI": {
            "score": data.nigeria.future_readiness_score,
            "rank": data.nigeria.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.nigeria.physical_capital,
            "rank": data.nigeria.physical_capital_rank,
        },
        "Technology": {
            "score": data.nigeria.technology,
            "rank": data.nigeria.technology_rank,
        },
        "HumanCapital": {
            "score": data.nigeria.human_capital,
            "rank": data.nigeria.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.nigeria.competitiveness,
            "rank": data.nigeria.competitiveness_rank,
        }
    },
    {
        "Country": "North Macedonia",
        "FREI": {
            "score": data.north_macedonia.future_readiness_score,
            "rank": data.north_macedonia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.north_macedonia.physical_capital,
            "rank": data.north_macedonia.physical_capital_rank,
        },
        "Technology": {
            "score": data.north_macedonia.technology,
            "rank": data.north_macedonia.technology_rank,
        },
        "HumanCapital": {
            "score": data.north_macedonia.human_capital,
            "rank": data.north_macedonia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.north_macedonia.competitiveness,
            "rank": data.north_macedonia.competitiveness_rank,
        }
    },
    {
        "Country": "Norway",
        "FREI": {
            "score": data.norway.future_readiness_score,
            "rank": data.norway.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.norway.physical_capital,
            "rank": data.norway.physical_capital_rank,
        },
        "Technology": {
            "score": data.norway.technology,
            "rank": data.norway.technology_rank,
        },
        "HumanCapital": {
            "score": data.norway.human_capital,
            "rank": data.norway.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.norway.competitiveness,
            "rank": data.norway.competitiveness_rank,
        }
    },
    {
        "Country": "Oman",
        "FREI": {
            "score": data.oman.future_readiness_score,
            "rank": data.oman.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.oman.physical_capital,
            "rank": data.oman.physical_capital_rank,
        },
        "Technology": {
            "score": data.oman.technology,
            "rank": data.oman.technology_rank,
        },
        "HumanCapital": {
            "score": data.oman.human_capital,
            "rank": data.oman.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.oman.competitiveness,
            "rank": data.oman.competitiveness_rank,
        }
    },
    {
        "Country": "Pakistan",
        "FREI": {
            "score": data.pakistan.future_readiness_score,
            "rank": data.pakistan.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.pakistan.physical_capital,
            "rank": data.pakistan.physical_capital_rank,
        },
        "Technology": {
            "score": data.pakistan.technology,
            "rank": data.pakistan.technology_rank,
        },
        "HumanCapital": {
            "score": data.pakistan.human_capital,
            "rank": data.pakistan.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.pakistan.competitiveness,
            "rank": data.pakistan.competitiveness_rank,
        }
    },
    {
        "Country": "Panama",
        "FREI": {
            "score": data.panama.future_readiness_score,
            "rank": data.panama.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.panama.physical_capital,
            "rank": data.panama.physical_capital_rank,
        },
        "Technology": {
            "score": data.panama.technology,
            "rank": data.panama.technology_rank,
        },
        "HumanCapital": {
            "score": data.panama.human_capital,
            "rank": data.panama.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.panama.competitiveness,
            "rank": data.panama.competitiveness_rank,
        }
    },
    {
        "Country": "Paraguay",
        "FREI": {
            "score": data.paraguay.future_readiness_score,
            "rank": data.paraguay.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.paraguay.physical_capital,
            "rank": data.paraguay.physical_capital_rank,
        },
        "Technology": {
            "score": data.paraguay.technology,
            "rank": data.paraguay.technology_rank,
        },
        "HumanCapital": {
            "score": data.paraguay.human_capital,
            "rank": data.paraguay.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.paraguay.competitiveness,
            "rank": data.paraguay.competitiveness_rank,
        }
    },
    {
        "Country": "Peru",
        "FREI": {
            "score": data.peru.future_readiness_score,
            "rank": data.peru.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.peru.physical_capital,
            "rank": data.peru.physical_capital_rank,
        },
        "Technology": {
            "score": data.peru.technology,
            "rank": data.peru.technology_rank,
        },
        "HumanCapital": {
            "score": data.peru.human_capital,
            "rank": data.peru.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.peru.competitiveness,
            "rank": data.peru.competitiveness_rank,
        }
    },
    {
        "Country": "Philippines",
        "FREI": {
            "score": data.philippines.future_readiness_score,
            "rank": data.philippines.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.philippines.physical_capital,
            "rank": data.philippines.physical_capital_rank,
        },
        "Technology": {
            "score": data.philippines.technology,
            "rank": data.philippines.technology_rank,
        },
        "HumanCapital": {
            "score": data.philippines.human_capital,
            "rank": data.philippines.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.philippines.competitiveness,
            "rank": data.philippines.competitiveness_rank,
        }
    },
    {
        "Country": "Poland",
        "FREI": {
            "score": data.poland.future_readiness_score,
            "rank": data.poland.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.poland.physical_capital,
            "rank": data.poland.physical_capital_rank,
        },
        "Technology": {
            "score": data.poland.technology,
            "rank": data.poland.technology_rank,
        },
        "HumanCapital": {
            "score": data.poland.human_capital,
            "rank": data.poland.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.poland.competitiveness,
            "rank": data.poland.competitiveness_rank,
        }
    },
    {
        "Country": "Portugal",
        "FREI": {
            "score": data.portugal.future_readiness_score,
            "rank": data.portugal.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.portugal.physical_capital,
            "rank": data.portugal.physical_capital_rank,
        },
        "Technology": {
            "score": data.portugal.technology,
            "rank": data.portugal.technology_rank,
        },
        "HumanCapital": {
            "score": data.portugal.human_capital,
            "rank": data.portugal.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.portugal.competitiveness,
            "rank": data.portugal.competitiveness_rank,
        }
    },
    {
        "Country": "Qatar",
        "FREI": {
            "score": data.qatar.future_readiness_score,
            "rank": data.qatar.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.qatar.physical_capital,
            "rank": data.qatar.physical_capital_rank,
        },
        "Technology": {
            "score": data.qatar.technology,
            "rank": data.qatar.technology_rank,
        },
        "HumanCapital": {
            "score": data.qatar.human_capital,
            "rank": data.qatar.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.qatar.competitiveness,
            "rank": data.qatar.competitiveness_rank,
        }
    },
    {
        "Country": "Romania",
        "FREI": {
            "score": data.romania.future_readiness_score,
            "rank": data.romania.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.romania.physical_capital,
            "rank": data.romania.physical_capital_rank,
        },
        "Technology": {
            "score": data.romania.technology,
            "rank": data.romania.technology_rank,
        },
        "HumanCapital": {
            "score": data.romania.human_capital,
            "rank": data.romania.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.romania.competitiveness,
            "rank": data.romania.competitiveness_rank,
        }
    },
    {
        "Country": "Russia",
        "FREI": {
            "score": data.russia.future_readiness_score,
            "rank": data.russia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.russia.physical_capital,
            "rank": data.russia.physical_capital_rank,
        },
        "Technology": {
            "score": data.russia.technology,
            "rank": data.russia.technology_rank,
        },
        "HumanCapital": {
            "score": data.russia.human_capital,
            "rank": data.russia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.russia.competitiveness,
            "rank": data.russia.competitiveness_rank,
        }
    },
    {
        "Country": "Rwanda",
        "FREI": {
            "score": data.rwanda.future_readiness_score,
            "rank": data.rwanda.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.rwanda.physical_capital,
            "rank": data.rwanda.physical_capital_rank,
        },
        "Technology": {
            "score": data.rwanda.technology,
            "rank": data.rwanda.technology_rank,
        },
        "HumanCapital": {
            "score": data.rwanda.human_capital,
            "rank": data.rwanda.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.rwanda.competitiveness,
            "rank": data.rwanda.competitiveness_rank,
        }
    },
    {
        "Country": "Saudi Arabia",
        "FREI": {
            "score": data.saudi_arabia.future_readiness_score,
            "rank": data.saudi_arabia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.saudi_arabia.physical_capital,
            "rank": data.saudi_arabia.physical_capital_rank,
        },
        "Technology": {
            "score": data.saudi_arabia.technology,
            "rank": data.saudi_arabia.technology_rank,
        },
        "HumanCapital": {
            "score": data.saudi_arabia.human_capital,
            "rank": data.saudi_arabia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.saudi_arabia.competitiveness,
            "rank": data.saudi_arabia.competitiveness_rank,
        }
    },
    {
        "Country": "Senegal",
        "FREI": {
            "score": data.senegal.future_readiness_score,
            "rank": data.senegal.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.senegal.physical_capital,
            "rank": data.senegal.physical_capital_rank,
        },
        "Technology": {
            "score": data.senegal.technology,
            "rank": data.senegal.technology_rank,
        },
        "HumanCapital": {
            "score": data.senegal.human_capital,
            "rank": data.senegal.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.senegal.competitiveness,
            "rank": data.senegal.competitiveness_rank,
        }
    },
    {
        "Country": "Serbia",
        "FREI": {
            "score": data.serbia.future_readiness_score,
            "rank": data.serbia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.serbia.physical_capital,
            "rank": data.serbia.physical_capital_rank,
        },
        "Technology": {
            "score": data.serbia.technology,
            "rank": data.serbia.technology_rank,
        },
        "HumanCapital": {
            "score": data.serbia.human_capital,
            "rank": data.serbia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.serbia.competitiveness,
            "rank": data.serbia.competitiveness_rank,
        }
    },
    {
        "Country": "Singapore",
        "FREI": {
            "score": data.singapore.future_readiness_score,
            "rank": data.singapore.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.singapore.physical_capital,
            "rank": data.singapore.physical_capital_rank,
        },
        "Technology": {
            "score": data.singapore.technology,
            "rank": data.singapore.technology_rank,
        },
        "HumanCapital": {
            "score": data.singapore.human_capital,
            "rank": data.singapore.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.singapore.competitiveness,
            "rank": data.singapore.competitiveness_rank,
        }
    },
    {
        "Country": "Slovakia",
        "FREI": {
            "score": data.slovakia.future_readiness_score,
            "rank": data.slovakia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.slovakia.physical_capital,
            "rank": data.slovakia.physical_capital_rank,
        },
        "Technology": {
            "score": data.slovakia.technology,
            "rank": data.slovakia.technology_rank,
        },
        "HumanCapital": {
            "score": data.slovakia.human_capital,
            "rank": data.slovakia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.slovakia.competitiveness,
            "rank": data.slovakia.competitiveness_rank,
        }
    },
    {
        "Country": "Slovenia",
        "FREI": {
            "score": data.slovenia.future_readiness_score,
            "rank": data.slovenia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.slovenia.physical_capital,
            "rank": data.slovenia.physical_capital_rank,
        },
        "Technology": {
            "score": data.slovenia.technology,
            "rank": data.slovenia.technology_rank,
        },
        "HumanCapital": {
            "score": data.slovenia.human_capital,
            "rank": data.slovenia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.slovenia.competitiveness,
            "rank": data.slovenia.competitiveness_rank,
        }
    },
    {
        "Country": "South Africa",
        "FREI": {
            "score": data.south_africa.future_readiness_score,
            "rank": data.south_africa.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.south_africa.physical_capital,
            "rank": data.south_africa.physical_capital_rank,
        },
        "Technology": {
            "score": data.south_africa.technology,
            "rank": data.south_africa.technology_rank,
        },
        "HumanCapital": {
            "score": data.south_africa.human_capital,
            "rank": data.south_africa.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.south_africa.competitiveness,
            "rank": data.south_africa.competitiveness_rank,
        }
    },
    {
        "Country": "South Korea",
        "FREI": {
            "score": data.south_korea.future_readiness_score,
            "rank": data.south_korea.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.south_korea.physical_capital,
            "rank": data.south_korea.physical_capital_rank,
        },
        "Technology": {
            "score": data.south_korea.technology,
            "rank": data.south_korea.technology_rank,
        },
        "HumanCapital": {
            "score": data.south_korea.human_capital,
            "rank": data.south_korea.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.south_korea.competitiveness,
            "rank": data.south_korea.competitiveness_rank,
        }
    },
    {
        "Country": "Spain",
        "FREI": {
            "score": data.spain.future_readiness_score,
            "rank": data.spain.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.spain.physical_capital,
            "rank": data.spain.physical_capital_rank,
        },
        "Technology": {
            "score": data.spain.technology,
            "rank": data.spain.technology_rank,
        },
        "HumanCapital": {
            "score": data.spain.human_capital,
            "rank": data.spain.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.spain.competitiveness,
            "rank": data.spain.competitiveness_rank,
        }
    },
    {
        "Country": "Sri Lanka",
        "FREI": {
            "score": data.sri_lanka.future_readiness_score,
            "rank": data.sri_lanka.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.sri_lanka.physical_capital,
            "rank": data.sri_lanka.physical_capital_rank,
        },
        "Technology": {
            "score": data.sri_lanka.technology,
            "rank": data.sri_lanka.technology_rank,
        },
        "HumanCapital": {
            "score": data.sri_lanka.human_capital,
            "rank": data.sri_lanka.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.sri_lanka.competitiveness,
            "rank": data.sri_lanka.competitiveness_rank,
        }
    },
    {
        "Country": "Sweden",
        "FREI": {
            "score": data.sweden.future_readiness_score,
            "rank": data.sweden.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.sweden.physical_capital,
            "rank": data.sweden.physical_capital_rank,
        },
        "Technology": {
            "score": data.sweden.technology,
            "rank": data.sweden.technology_rank,
        },
        "HumanCapital": {
            "score": data.sweden.human_capital,
            "rank": data.sweden.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.sweden.competitiveness,
            "rank": data.sweden.competitiveness_rank,
        }
    },
    {
        "Country": "Switzerland",
        "FREI": {
            "score": data.switzerland.future_readiness_score,
            "rank": data.switzerland.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.switzerland.physical_capital,
            "rank": data.switzerland.physical_capital_rank,
        },
        "Technology": {
            "score": data.switzerland.technology,
            "rank": data.switzerland.technology_rank,
        },
        "HumanCapital": {
            "score": data.switzerland.human_capital,
            "rank": data.switzerland.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.switzerland.competitiveness,
            "rank": data.switzerland.competitiveness_rank,
        }
    },
    {
        "Country": "Tanzania",
        "FREI": {
            "score": data.tanzania.future_readiness_score,
            "rank": data.tanzania.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.tanzania.physical_capital,
            "rank": data.tanzania.physical_capital_rank,
        },
        "Technology": {
            "score": data.tanzania.technology,
            "rank": data.tanzania.technology_rank,
        },
        "HumanCapital": {
            "score": data.tanzania.human_capital,
            "rank": data.tanzania.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.tanzania.competitiveness,
            "rank": data.tanzania.competitiveness_rank,
        }
    },
    {
        "Country": "Thailand",
        "FREI": {
            "score": data.thailand.future_readiness_score,
            "rank": data.thailand.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.thailand.physical_capital,
            "rank": data.thailand.physical_capital_rank,
        },
        "Technology": {
            "score": data.thailand.technology,
            "rank": data.thailand.technology_rank,
        },
        "HumanCapital": {
            "score": data.thailand.human_capital,
            "rank": data.thailand.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.thailand.competitiveness,
            "rank": data.thailand.competitiveness_rank,
        }
    },
    {
        "Country": "Togo",
        "FREI": {
            "score": data.togo.future_readiness_score,
            "rank": data.togo.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.togo.physical_capital,
            "rank": data.togo.physical_capital_rank,
        },
        "Technology": {
            "score": data.togo.technology,
            "rank": data.togo.technology_rank,
        },
        "HumanCapital": {
            "score": data.togo.human_capital,
            "rank": data.togo.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.togo.competitiveness,
            "rank": data.togo.competitiveness_rank,
        }
    },
    {
        "Country": "Trinidad and Tobago",
        "FREI": {
            "score": data.trinidad_and_tobago.future_readiness_score,
            "rank": data.trinidad_and_tobago.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.trinidad_and_tobago.physical_capital,
            "rank": data.trinidad_and_tobago.physical_capital_rank,
        },
        "Technology": {
            "score": data.trinidad_and_tobago.technology,
            "rank": data.trinidad_and_tobago.technology_rank,
        },
        "HumanCapital": {
            "score": data.trinidad_and_tobago.human_capital,
            "rank": data.trinidad_and_tobago.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.trinidad_and_tobago.competitiveness,
            "rank": data.trinidad_and_tobago.competitiveness_rank,
        }
    },
    {
        "Country": "Tunisia",
        "FREI": {
            "score": data.tunisia.future_readiness_score,
            "rank": data.tunisia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.tunisia.physical_capital,
            "rank": data.tunisia.physical_capital_rank,
        },
        "Technology": {
            "score": data.tunisia.technology,
            "rank": data.tunisia.technology_rank,
        },
        "HumanCapital": {
            "score": data.tunisia.human_capital,
            "rank": data.tunisia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.tunisia.competitiveness,
            "rank": data.tunisia.competitiveness_rank,
        }
    },
    {
        "Country": "Türkiye",
        "FREI": {
            "score": data.turkey.future_readiness_score,
            "rank": data.turkey.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.turkey.physical_capital,
            "rank": data.turkey.physical_capital_rank,
        },
        "Technology": {
            "score": data.turkey.technology,
            "rank": data.turkey.technology_rank,
        },
        "HumanCapital": {
            "score": data.turkey.human_capital,
            "rank": data.turkey.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.turkey.competitiveness,
            "rank": data.turkey.competitiveness_rank,
        }
    },
    {
        "Country": "Uganda",
        "FREI": {
            "score": data.uganda.future_readiness_score,
            "rank": data.uganda.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.uganda.physical_capital,
            "rank": data.uganda.physical_capital_rank,
        },
        "Technology": {
            "score": data.uganda.technology,
            "rank": data.uganda.technology_rank,
        },
        "HumanCapital": {
            "score": data.uganda.human_capital,
            "rank": data.uganda.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.uganda.competitiveness,
            "rank": data.uganda.competitiveness_rank,
        }
    },
    {
        "Country": "Ukraine",
        "FREI": {
            "score": data.ukraine.future_readiness_score,
            "rank": data.ukraine.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.ukraine.physical_capital,
            "rank": data.ukraine.physical_capital_rank,
        },
        "Technology": {
            "score": data.ukraine.technology,
            "rank": data.ukraine.technology_rank,
        },
        "HumanCapital": {
            "score": data.ukraine.human_capital,
            "rank": data.ukraine.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.ukraine.competitiveness,
            "rank": data.ukraine.competitiveness_rank,
        }
    },
    {
        "Country": "United Arab Emirates",
        "FREI": {
            "score": data.united_arab_emirates.future_readiness_score,
            "rank": data.united_arab_emirates.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.united_arab_emirates.physical_capital,
            "rank": data.united_arab_emirates.physical_capital_rank,
        },
        "Technology": {
            "score": data.united_arab_emirates.technology,
            "rank": data.united_arab_emirates.technology_rank,
        },
        "HumanCapital": {
            "score": data.united_arab_emirates.human_capital,
            "rank": data.united_arab_emirates.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.united_arab_emirates.competitiveness,
            "rank": data.united_arab_emirates.competitiveness_rank,
        }
    },
    {
        "Country": "United Kingdom",
        "FREI": {
            "score": data.united_kingdom.future_readiness_score,
            "rank": data.united_kingdom.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.united_kingdom.physical_capital,
            "rank": data.united_kingdom.physical_capital_rank,
        },
        "Technology": {
            "score": data.united_kingdom.technology,
            "rank": data.united_kingdom.technology_rank,
        },
        "HumanCapital": {
            "score": data.united_kingdom.human_capital,
            "rank": data.united_kingdom.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.united_kingdom.competitiveness,
            "rank": data.united_kingdom.competitiveness_rank,
        }
    },
    {
        "Country": "United States",
        "FREI": {
            "score": data.united_states.future_readiness_score,
            "rank": data.united_states.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.united_states.physical_capital,
            "rank": data.united_states.physical_capital_rank,
        },
        "Technology": {
            "score": data.united_states.technology,
            "rank": data.united_states.technology_rank,
        },
        "HumanCapital": {
            "score": data.united_states.human_capital,
            "rank": data.united_states.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.united_states.competitiveness,
            "rank": data.united_states.competitiveness_rank,
        }
    },
    {
        "Country": "Uruguay",
        "FREI": {
            "score": data.uruguay.future_readiness_score,
            "rank": data.uruguay.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.uruguay.physical_capital,
            "rank": data.uruguay.physical_capital_rank,
        },
        "Technology": {
            "score": data.uruguay.technology,
            "rank": data.uruguay.technology_rank,
        },
        "HumanCapital": {
            "score": data.uruguay.human_capital,
            "rank": data.uruguay.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.uruguay.competitiveness,
            "rank": data.uruguay.competitiveness_rank,
        }
    },
    {
        "Country": "Vietnam",
        "FREI": {
            "score": data.vietnam.future_readiness_score,
            "rank": data.vietnam.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.vietnam.physical_capital,
            "rank": data.vietnam.physical_capital_rank,
        },
        "Technology": {
            "score": data.vietnam.technology,
            "rank": data.vietnam.technology_rank,
        },
        "HumanCapital": {
            "score": data.vietnam.human_capital,
            "rank": data.vietnam.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.vietnam.competitiveness,
            "rank": data.vietnam.competitiveness_rank,
        }
    },
    {
        "Country": "Zambia",
        "FREI": {
            "score": data.zambia.future_readiness_score,
            "rank": data.zambia.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.zambia.physical_capital,
            "rank": data.zambia.physical_capital_rank,
        },
        "Technology": {
            "score": data.zambia.technology,
            "rank": data.zambia.technology_rank,
        },
        "HumanCapital": {
            "score": data.zambia.human_capital,
            "rank": data.zambia.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.zambia.competitiveness,
            "rank": data.zambia.competitiveness_rank,
        }
    },
    {
        "Country": "Zimbabwe",
        "FREI": {
            "score": data.zimbabwe.future_readiness_score,
            "rank": data.zimbabwe.future_readiness_rank,
        },
        "PhysicalCapital": {
            "score": data.zimbabwe.physical_capital,
            "rank": data.zimbabwe.physical_capital_rank,
        },
        "Technology": {
            "score": data.zimbabwe.technology,
            "rank": data.zimbabwe.technology_rank,
        },
        "HumanCapital": {
            "score": data.zimbabwe.human_capital,
            "rank": data.zimbabwe.human_capital_rank,
        },
        "Competitiveness": {
            "score": data.zimbabwe.competitiveness,
            "rank": data.zimbabwe.competitiveness_rank,
        }
    }
]

export default countryRankingData
