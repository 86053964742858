<template>
  <section class="section section-sm" style="padding-top: 3em">

    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-12">
          <h2>
              <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Argentina'"><img
                  src="/img/flags/ar.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Brazil'"><img
                src="/img/flags/br.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Chile'"><img
                src="/img/flags/ch.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'China'"><img
                src="/img/flags/cn.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Colombia'"><img
                src="/img/flags/co.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Egypt'"><img
                src="/img/flags/eg.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'India'"><img
                src="/img/flags/in.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Indonesia'"><img
                src="/img/flags/id.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Israel'"><img
                src="/img/flags/is.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Jordan'"><img
                src="/img/flags/jo.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Kenya'"><img
                src="/img/flags/ke.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Kuwait'"><img
                src="/img/flags/kw.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Lebanon'"><img
                src="/img/flags/le.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Mexico'"><img
                src="/img/flags/mx.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Morocco'"><img
                src="/img/flags/ma.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Nigeria'"><img
                src="/img/flags/ni.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Peru'"><img
                src="/img/flags/pe.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Qatar'"><img
                src="/img/flags/qa.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Russia'"><img
                src="/img/flags/ru.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Saudi Arabia'"><img
                src="/img/flags/sa.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Singapore'"><img
                src="/img/flags/sg.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'South Africa'"><img
                src="/img/flags/za.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Thailand'"><img
                src="/img/flags/th.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Turkey'"><img
                src="/img/flags/tu.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Ukraine'"><img
                src="/img/flags/ua.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'United Arab Emirates'"><img
                src="/img/flags/ae.png" width="50"/></span>
            <span v-if="this.countryProfiles.countries[this.selectedCountry].name === 'Viet Nam'"><img
                src="/img/flags/vn.png" width="50"/></span>
            {{ this.countryProfiles.countries[this.selectedCountry].name }}
          </h2>

          <hr style="margin-top: 1em;margin-bottom: 1em">

        </div>
      </div>

      <div class="row row-grid align-items-center mt-0">
        <div class="col-md-5 order-md-2 text-center">
          <img :src="this.countryProfiles.countries[this.selectedCountry].map_image" class=""
               style="padding: 3em; width:100%">
        </div>


        <div class="col-md-7 order-md-1">


          <div class="bg-lightgrey country-container">

            <ul class="list-unstyled mt-2">
              <li class="py-2">
                <div class="d-flex align-items-center country-stat">
                  <div>
                    <p class="stat-header font-weight-600 text-uppercase">future readiness ranking</p>
                    <h6 class="mb-0">
                      {{ this.countryProfiles.countries[this.selectedCountry].stats.future_readiness_ranking }}</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center country-stat">
                  <div>
                    <p class="stat-header font-weight-600 text-uppercase">population</p>
                    <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.population }}</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center country-stat">
                  <div>
                    <p class="stat-header font-weight-600 text-uppercase">GDP</p>
                    <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.GDP }}</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center country-stat">
                  <div>
                    <p class="stat-header font-weight-600 text-uppercase">GDP PPP</p>
                    <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.GDP_PPP }}</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center country-stat">
                  <div>
                    <p class="stat-header font-weight-600 text-uppercase">income group</p>
                    <h6 class="mb-0">{{ this.countryProfiles.countries[this.selectedCountry].stats.income_group }}</h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr>
      <div class="row row-grid align-items-center">

        <div class="col-md-12">
          <div class="row p-3">
            <div class="col-12 bg-lightgrey">
              <div class="row">
                <div class="col-12">
                  <div class="">
                    <h4 style="margin-bottom: 5px;margin-top: 30px" class="font-weight-600">How Future Ready is
                      {{ this.countryProfiles.countries[this.selectedCountry].name }}?</h4>
                    <p class="mb-3 lead">{{
                        this.countryProfiles.countries[this.selectedCountry].overall_readiness
                      }}</p>
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <RadarComparison :chartData="this.chartData" :showLegend="true"></RadarComparison>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">

          <div class="text-center mw-100 px-5 " v-if="isMobile">
            <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
            <br>
            <p>This section is best viewed in landscape mode</p>
          </div>

          <base-button class="p-0 mt-2 mb-2 font-weight-600" type="white"
                       v-if="!visible"
                       :aria-expanded="visible ? 'true' : 'false'"
                       aria-controls="collapse-2"
                       @click="visible = !visible">Read More  <i class="fa fa-chevron-down"></i></base-button>

          <base-button class="p-0 mt-2 mb-2 font-weight-600" type="white"
                       :aria-expanded="visible ? 'true' : 'false'"
                       v-else
                       aria-controls="collapse-2"
                       @click="visible = !visible">Read Less <i class="fa fa-chevron-up"></i></base-button>

          <b-collapse id="collapse-2" v-model="visible">
            <b-card class="border-0">
              <div class="col-12 mb-5">
                <h4 style="margin-bottom: 5px;margin-top: 30px" class="font-weight-600">Technology, Talent and Innovation</h4>
                <p class="mb-3">{{ this.countryProfiles.countries[this.selectedCountry].tti_performance }}</p>
                <h4 style="margin-bottom: 5px;margin-top: 30px" class="font-weight-600">Global Performance</h4>
                <p class="mb-3">{{ this.countryProfiles.countries[this.selectedCountry].global_performance }}</p>
              </div>
              <CountryRankings :country-rankings="this.countryRanks" :selected-country="this.selectedCountry"></CountryRankings>
            </b-card>
          </b-collapse>
        </div>

      </div>


    </div>
  </section>
</template>

<script>

import countryProfiles from '../../data_2022_old/countryProfiles.json'
import countryRanks from '../../data_2022_old/countryRankingDataForProfiles.json'
import RadarComparison from "@/components-2022/charts/RadarComparison";
import CountryRankings from "@/views-2022/components-2022/CountryRankings";

export default {
  name: "CountryProfile",
  components: {
    CountryRankings,
    RadarComparison
  },
  props: {
    countryData: {
      type: Object
    },
    selectedCountry: {
      type: String,
      default: "egypt"
    }
  },
  data() {
    return {
      countryProfiles: countryProfiles,
      countryRanks: countryRanks,
      visible: false
    }
  },
  computed: {
    chartData() {
      const labels = [["Future Readiness", "Score"], ["Institutions", "and Infrastructure"], "Technology", "Talent", "Innovation"]
      const chartDataSet = {
        labels: labels,
        datasets: this.countryProfiles.countries[this.selectedCountry].radar_data
      }
      return chartDataSet;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    rankClass(rank) {
      if (rank === 1) {
        return 'stat-rank-1'
      } else if (rank === 2) {
        return 'stat-rank-2'
      } else if (rank === 3) {
        return 'stat-rank-3'
      }
    }
  }
}
</script>

<style scoped>
.stat-header {
  margin-bottom: 0px;
  font-size: small;
  font-weight: 700 !important;
  color: #4a4a4a !important;
}

.country-container {
  padding: 1em;
  border-radius: 0.25rem;
}

.report-stat-box {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 15px 0;
  text-align: center;
  background: #fafafa;
  margin: 5px 0 5px 0;
  min-height: 90px;
  border-radius: 0.25em;
}

.report-stat-box .field-name {
  /*font-size: 10px;*/
}

.report-stat-box .field-value {
  font-size: larger;
}

.report-stat-box-header {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10px 0;
  text-align: center;
  background: #4a4a4a;
  margin: 5px 0 5px 0;
  min-height: 90px;
  border-radius: 0.25em;
  color: white;
}

.report-stat-box-header .field-value {
  font-size: large;
}

</style>
