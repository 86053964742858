<template>
  <div class="row">

    <div class="col-12">
      <p class="lead">
        The chart below visualizes the future readiness performance of all 124 countries - overall and by pillar. The list is in order of overall FREI score.
      </p>
    </div>

    <div class="col-12 text-center animate__animated animate__fadeIn mb-5 mt-3">
      <p class="font-weight-500 help-text"><i class="fa fa-lightbulb-o mr-2"/>Mouse over the bars to see each country's scores per pillar. Use the chart legend to toggle between readiness pillars</p>
    </div>

<!--    <div class="col-12 map-buttons text-center">-->
<!--      <b-form-group v-slot="{ ariaDescribedby }">-->
<!--        <b-form-radio-group-->
<!--            class="btn-group d-block"-->
<!--            id="checkbox-group-1"-->
<!--            v-model="selected"-->
<!--            :options="options"-->
<!--            :aria-describedby="ariaDescribedby"-->
<!--            buttons-->
<!--            button-variant="outline-secondary"-->
<!--            name="buttons2"-->
<!--        ></b-form-radio-group>-->
<!--      </b-form-group>-->
<!--    </div>-->

    <bar-chart :chartData="this.chartData" style="width: 100%" class="graph-view-bar-chart-2022"></bar-chart>

  </div>
</template>

<script>

import BarChart from "@/components-2022/charts/BarChart";

export default {
  name: "GraphView",
  components: {BarChart},
  data() {
    return {
      sortedLabels: '',
      sortedData: '',
      selected: 'FutureReadinessScore',
      options: [
        {text: 'FREI', value: 0},
        {text: 'Physical Capital', value: 1},
        {text: 'Human Capital', value: 2},
        {text: 'Technology', value: 3},
        {text: 'Competitiveness', value: 4}
      ],
      chartData: {
        chart: {
          height: (9 / 16 * 100) + '%' // 16:9 ratio
        },
        labels: [
          "Singapore",
          "Denmark",
          "Switzerland",
          "Sweden",
          "Norway",
          "United States",
          "Netherlands",
          "Finland",
          "United Kingdom",
          "Canada",
          "Australia",
          "Luxembourg",
          "Iceland",
          "Germany",
          "New Zealand",
          "Austria",
          "Ireland",
          "South Korea",
          "Belgium",
          "Israel",
          "France",
          "Japan",
          "Estonia",
          "Malta",
          "Spain",
          "Portugal",
          "United Arab Emirates",
          "Cyprus",
          "Italy",
          "Slovenia",
          "Czech Republic",
          "Lithuania",
          "Qatar",
          "Greece",
          "Poland",
          "Latvia",
          "Chile",
          "China",
          "Hungary",
          "Malaysia",
          "Slovakia",
          "Croatia",
          "Bahrain",
          "Saudi Arabia",
          "Uruguay",
          "Bulgaria",
          "Turkey",
          "Oman",
          "Mauritius",
          "Romania",
          "Serbia",
          "Costa Rica",
          "Kuwait",
          "Russia",
          "Armenia",
          "Thailand",
          "Georgia",
          "Argentina",
          "Brazil",
          "Belarus",
          "Ukraine",
          "Mexico",
          "North Macedonia",
          "Vietnam",
          "Kazakhstan",
          "South Africa",
          "Albania",
          "Panama",
          "Jordan",
          "Paraguay",
          "Lebanon",
          "Trinidad and Tobago",
          "Colombia",
          "Moldova",
          "Egypt",
          "Indonesia",
          "Azerbaijan",
          "Tunisia",
          "Ecuador",
          "Jamaica",
          "Philippines",
          "Iran",
          "Peru",
          "Morocco",
          "Bosnia and Herzegovina",
          "Botswana",
          "Mongolia",
          "Dominican Republic",
          "India",
          "Sri Lanka",
          "Kyrgyzstan",
          "Bolivia",
          "El Salvador",
          "Algeria",
          "Kenya",
          "Ghana",
          "Cambodia",
          "Guatemala",
          "Rwanda",
          "Honduras",
          "Senegal",
          "Bangladesh",
          "Nepal",
          "Pakistan",
          "Laos",
          "Nigeria",
          "Cameroon",
          "Côte d'Ivoire",
          "Tanzania",
          "Zambia",
          "Benin",
          "Myanmar",
          "Uganda",
          "Burkina Faso",
          "Angola",
          "Mali",
          "Togo",
          "Guinea",
          "Zimbabwe",
          "Malawi",
          "Madagascar",
          "Mozambique",
          "Ethiopia",
          "Niger"],
        datasets: [
          {
            label: "FREI",
            data: [
              '76.26',
              '75.89',
              '75.71',
              '74.72',
              '74.07',
              '73.55',
              '71.67',
              '71.49',
              '69.37',
              '69.24',
              '68.68',
              '68.63',
              '67.55',
              '66.28',
              '64.90',
              '64.86',
              '64.62',
              '64.14',
              '63.85',
              '61.98',
              '61.88',
              '61.81',
              '60.14',
              '58.56',
              '57.95',
              '57.72',
              '57.22',
              '55.30',
              '55.28',
              '54.78',
              '54.27',
              '53.46',
              '53.45',
              '51.57',
              '50.97',
              '50.76',
              '50.25',
              '50.01',
              '49.48',
              '49.46',
              '49.21',
              '48.46',
              '48.21',
              '47.25',
              '46.10',
              '45.45',
              '44.21',
              '44.18',
              '44.01',
              '44.00',
              '43.81',
              '43.77',
              '43.66',
              '42.98',
              '42.48',
              '42.37',
              '42.17',
              '41.70',
              '40.93',
              '40.91',
              '40.31',
              '40.18',
              '40.10',
              '40.07',
              '39.13',
              '39.01',
              '38.51',
              '38.15',
              '37.75',
              '37.57',
              '37.36',
              '37.08',
              '36.99',
              '36.46',
              '36.34',
              '36.12',
              '35.97',
              '35.93',
              '35.68',
              '35.50',
              '35.29',
              '35.05',
              '35.00',
              '34.88',
              '34.80',
              '34.34',
              '33.11',
              '32.68',
              '32.50',
              '32.17',
              '31.70',
              '31.55',
              '29.86',
              '29.28',
              '27.62',
              '26.47',
              '26.20',
              '26.12',
              '25.35',
              '24.93',
              '24.04',
              '24.02',
              '23.86',
              '23.77',
              '23.46',
              '22.58',
              '21.67',
              '21.29',
              '20.75',
              '20.33',
              '20.20',
              '20.07',
              '19.86',
              '19.67',
              '19.65',
              '19.54',
              '18.48',
              '18.24',
              '18.11',
              '17.75',
              '16.83',
              '15.73',
              '13.81',
              '10.06',
            ],
            backgroundColor: '#3ac7ffFF'
          }, {
            label: "Physical Capital",
            data: [
              '75.38',
              '73.51',
              '71.37',
              '72.73',
              '82.37',
              '69.18',
              '69.98',
              '70.71',
              '65.92',
              '66.19',
              '64.18',
              '77.43',
              '75.23',
              '65.96',
              '65.87',
              '70.36',
              '68.72',
              '65.00',
              '67.42',
              '61.84',
              '64.21',
              '63.78',
              '63.11',
              '70.67',
              '64.74',
              '67.76',
              '74.40',
              '63.48',
              '65.77',
              '61.82',
              '62.69',
              '62.19',
              '79.55',
              '65.54',
              '59.74',
              '62.05',
              '58.98',
              '61.61',
              '61.39',
              '61.69',
              '58.68',
              '62.06',
              '71.91',
              '54.43',
              '56.54',
              '56.30',
              '61.62',
              '64.02',
              '61.26',
              '58.55',
              '54.55',
              '53.54',
              '55.02',
              '52.96',
              '52.59',
              '59.59',
              '54.85',
              '51.87',
              '52.66',
              '53.12',
              '50.70',
              '51.76',
              '52.57',
              '57.70',
              '45.81',
              '46.83',
              '56.37',
              '53.13',
              '48.91',
              '47.03',
              '53.24',
              '42.97',
              '47.95',
              '53.63',
              '53.57',
              '56.82',
              '46.24',
              '47.01',
              '50.62',
              '48.05',
              '48.28',
              '43.49',
              '44.65',
              '51.36',
              '46.59',
              '42.92',
              '40.94',
              '43.83',
              '46.01',
              '53.01',
              '43.17',
              '39.85',
              '42.49',
              '43.68',
              '36.31',
              '35.29',
              '43.63',
              '38.07',
              '41.38',
              '39.40',
              '30.25',
              '37.82',
              '31.90',
              '34.19',
              '39.43',
              '26.04',
              '28.50',
              '31.28',
              '25.55',
              '23.29',
              '22.95',
              '37.02',
              '24.42',
              '23.56',
              '27.95',
              '32.19',
              '26.43',
              '30.50',
              '22.29',
              '25.86',
              '18.83',
              '21.53',
              '18.04',
              '10.51',
            ],
            backgroundColor: '#4e81c4'
          },
          {
            label: "Human Capital",
            data: [
              '75.02',
              '75.98',
              '74.94',
              '75.59',
              '75.25',
              '71.79',
              '73.92',
              '73.35',
              '71.56',
              '74.56',
              '76.14',
              '74.61',
              '76.06',
              '67.38',
              '71.70',
              '68.59',
              '71.04',
              '63.77',
              '69.39',
              '64.74',
              '65.81',
              '60.19',
              '59.11',
              '61.60',
              '62.35',
              '67.53',
              '59.60',
              '58.82',
              '57.25',
              '59.33',
              '56.79',
              '55.54',
              '45.14',
              '55.98',
              '53.10',
              '52.31',
              '58.22',
              '50.51',
              '52.13',
              '46.70',
              '53.36',
              '47.87',
              '44.91',
              '49.65',
              '54.12',
              '47.52',
              '40.73',
              '40.24',
              '46.59',
              '41.46',
              '45.74',
              '49.43',
              '45.64',
              '49.63',
              '45.06',
              '37.33',
              '44.83',
              '46.41',
              '40.77',
              '51.86',
              '47.12',
              '45.68',
              '40.30',
              '36.44',
              '47.78',
              '34.80',
              '44.23',
              '39.15',
              '42.55',
              '48.68',
              '43.45',
              '47.52',
              '40.40',
              '37.07',
              '33.45',
              '32.31',
              '41.33',
              '35.89',
              '37.92',
              '42.42',
              '34.38',
              '34.02',
              '37.56',
              '31.09',
              '35.94',
              '40.18',
              '38.59',
              '35.56',
              '26.34',
              '31.79',
              '40.73',
              '38.33',
              '28.88',
              '35.30',
              '31.86',
              '27.42',
              '24.89',
              '23.04',
              '22.71',
              '25.63',
              '30.21',
              '25.49',
              '30.09',
              '22.04',
              '26.84',
              '27.05',
              '28.76',
              '22.84',
              '28.32',
              '30.37',
              '25.72',
              '22.33',
              '22.57',
              '23.60',
              '19.33',
              '18.98',
              '22.88',
              '25.35',
              '21.30',
              '21.32',
              '24.96',
              '17.22',
              '19.43',
              '13.64',
            ],
            backgroundColor: '#e2aa23'
          },
          {
            label: "Technology",
            data: [
              '78.69',
              '78.90',
              '80.40',
              '80.45',
              '75.21',
              '79.15',
              '79.32',
              '75.48',
              '74.80',
              '71.34',
              '69.63',
              '61.18',
              '57.27',
              '70.84',
              '64.79',
              '64.06',
              '63.11',
              '67.73',
              '58.42',
              '59.09',
              '58.66',
              '62.40',
              '59.20',
              '52.22',
              '54.49',
              '47.64',
              '47.43',
              '49.30',
              '50.41',
              '52.91',
              '51.95',
              '47.99',
              '39.17',
              '42.55',
              '49.89',
              '42.90',
              '38.76',
              '40.28',
              '44.35',
              '38.81',
              '44.59',
              '42.31',
              '41.10',
              '36.91',
              '40.27',
              '40.12',
              '36.15',
              '37.01',
              '34.13',
              '37.61',
              '40.76',
              '33.37',
              '36.46',
              '38.61',
              '40.45',
              '32.90',
              '30.83',
              '37.07',
              '35.79',
              '37.34',
              '35.09',
              '30.65',
              '35.27',
              '30.83',
              '32.17',
              '30.36',
              '33.00',
              '31.49',
              '23.88',
              '28.57',
              '25.00',
              '29.45',
              '31.90',
              '28.48',
              '25.49',
              '25.26',
              '30.40',
              '26.65',
              '29.95',
              '24.24',
              '23.74',
              '27.72',
              '29.27',
              '25.52',
              '29.30',
              '21.45',
              '26.74',
              '28.03',
              '23.60',
              '20.82',
              '23.16',
              '24.79',
              '23.89',
              '14.13',
              '20.07',
              '17.99',
              '21.52',
              '20.18',
              '15.30',
              '16.76',
              '14.34',
              '16.34',
              '15.77',
              '15.90',
              '11.82',
              '13.33',
              '13.25',
              '12.84',
              '9.94',
              '13.10',
              '12.89',
              '9.62',
              '12.75',
              '10.40',
              '15.93',
              '13.58',
              '11.45',
              '5.23',
              '16.60',
              '9.80',
              '7.75',
              '11.18',
              '5.95',
              '6.21',
            ],
            backgroundColor: '#cc4c2a'
          },
          {
            label: "Competitiveness",
            data: [
              '75.96',
              '75.17',
              '76.12',
              '70.09',
              '63.46',
              '74.08',
              '63.47',
              '66.43',
              '65.20',
              '64.89',
              '64.79',
              '61.31',
              '61.62',
              '60.94',
              '57.25',
              '56.45',
              '55.62',
              '60.05',
              '60.14',
              '62.26',
              '58.85',
              '60.85',
              '59.13',
              '49.74',
              '50.23',
              '47.95',
              '47.46',
              '49.62',
              '47.69',
              '45.07',
              '45.66',
              '48.11',
              '49.96',
              '42.20',
              '41.15',
              '45.78',
              '45.05',
              '47.63',
              '40.05',
              '50.63',
              '40.21',
              '41.61',
              '34.93',
              '48.02',
              '33.47',
              '37.88',
              '38.34',
              '35.44',
              '34.05',
              '38.38',
              '34.21',
              '38.72',
              '37.54',
              '30.71',
              '31.81',
              '39.65',
              '38.17',
              '31.43',
              '34.47',
              '21.32',
              '28.32',
              '32.63',
              '32.28',
              '35.32',
              '30.75',
              '44.05',
              '20.44',
              '28.83',
              '35.67',
              '26.01',
              '27.74',
              '28.37',
              '27.73',
              '26.68',
              '32.85',
              '30.10',
              '25.93',
              '34.15',
              '24.24',
              '27.30',
              '34.74',
              '34.96',
              '28.51',
              '31.54',
              '27.35',
              '32.79',
              '26.18',
              '23.32',
              '34.07',
              '23.04',
              '19.73',
              '23.24',
              '24.17',
              '23.99',
              '22.25',
              '25.18',
              '14.79',
              '23.20',
              '22.02',
              '17.93',
              '21.36',
              '16.43',
              '17.67',
              '22.94',
              '15.77',
              '23.89',
              '16.19',
              '18.19',
              '19.18',
              '14.57',
              '19.22',
              '11.32',
              '19.69',
              '21.12',
              '15.40',
              '13.41',
              '13.17',
              '11.86',
              '12.27',
              '14.04',
              '15.77',
              '13.00',
              '11.82',
              '9.87',
            ],
            backgroundColor: '#66ad5d'
          }
        ]
      }
    }
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  // watch: {
  //   selected() {
  //     const allData = [];
  //     for (let i = 0; i < this.chartData.labels.length; ++i) {
  //       allData.push({
  //         label: this.chartData.labels[i],
  //         data: this.chartData.datasets[0].data[i]
  //       });
  //     }
  //     allData.sort((b, a) => b.data - a.data);
  //     this.sortedLabels = allData.map(e => e.label);
  //     this.sortedData = allData.map(e => e.data);
  //   }
  // }
}
</script>

<style>

.graph-view-bar-chart-2022 {
  width: 100% !important;
  height: 10000px !important
}

</style>
