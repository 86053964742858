<template>
  <div class="col-3" style="border-left: white solid 5px;border-right: white solid 5px;">
    <div class="ranking-stat-box" :class="this.getClass" v-if="this.scoreRank !== 'none'">
      <div class="rank-value container d-flex h-100">
        <div class="align-self-center px-2" style="width: 100%">
          <span class="field-value">{{ this.scoreRank }}</span><br>
          <span class="field-name">{{ this.scoreField.replace(/_/g, ' ').replace('Knowledge', 'Knowledge,') }}</span>
        </div>
      </div>
    </div>
    <div class="ranking-stat-box" :class="this.getPillarClass" v-else-if="this.pillarRank">
      <div class="rank-value container d-flex" style="border-radius: 20px 20px 0px 0px;margin-bottom: 4px;">
        <div class="align-self-center px-2" style="width: 100%">
          <span class="field-value">{{ this.pillarRank }}</span><br>
          <span class="field-name-pillar">{{ this.scoreField.replace(/_/g, ' ').replace('Knowledge', 'Knowledge,') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ranking",
  props: {
    scoreField: {
      type: String
    },
    scoreRank: {

    },
    topThree: {
      type: Array
    },
    pillarRank: {
      type: Number
    },
    pillarAverage: {
      type: Number
    }
  },
  computed: {
    pillarThreshold() {
      return this.pillarAverage + 5
    },

    pillar() {
      if (this.scoreField === 'Regulatory_Environment' | this.scoreField === 'Market_Environment' | this.scoreField === 'Infrastructure') {
        return 'institutions '
      } else if (this.scoreField === 'Digital_Transformation_Technologies' | this.scoreField === 'People' | this.scoreField === 'Governance' | this.scoreField === 'Digital_Economy') {
        return 'technology '
      } else if (this.scoreField === 'Attract' | this.scoreField === 'Grow' | this.scoreField === 'Retain' | this.scoreField === 'Skills') {
        return 'talent '
      } else {
        return 'innovation '
      }
    },

    getClass() {
      if (this.scoreRank <= 1) {
        return this.pillar +'rank-green'
      } else if (this.topThree[0][0] === this.scoreField) {
        return this.pillar +'rank-green'
      } else if (this.topThree[1][0] === this.scoreField) {
        return this.pillar +'rank-green'
      } else if (this.topThree[2][0] === this.scoreField) {
        return this.pillar +'rank-green'
      } else if (this.pillarThreshold <= this.scoreRank) {
        return this.pillar +'rank-red'
      } else {
        return this.pillar +'rank-yellow'
      }
    },
    getPillarClass() {
      if (this.scoreField === 'Institutions_and_Infrastructure') {
        return 'rank-header institutions'
      } else if (this.scoreField === 'Technology') {
        return 'rank-header technology'
      }else if (this.scoreField === 'Talent') {
        return 'rank-header talent'
      } else {
        return 'rank-header innovation'
      }
    }
  },
  methods: {}
}
</script>

<style scoped>

/*.rank-red .field-name {*/
/*  background: #DB4437 !important;*/
/*  font-weight: 600;*/
/*}*/

/*.rank-yellow .field-name {*/
/*  background: #F4B400 !important;*/
/*  font-weight: 600;*/
/*}*/

/*.rank-green .field-name {*/
/*  background: #0F9D58 !important;*/
/*  font-weight: 600;*/
/*}*/

.rank-value {
  min-height: 115px;
}

.rank-header > div {
  background: #fafafa;
}


.rank-header.institutions {
  color: #4e81c4;
}

.rank-header.technology {
  color: #cc4c2a;
}

.rank-header.talent {
  color: #e2aa23;
}

.rank-header.innovation {
  color: #66ad5d;
}


/*Institutions*/
.institutions.rank-red  .rank-value {
  background: #95b5e0 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

.institutions.rank-yellow  .rank-value {
  background: #79a3da !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;

}

.institutions.rank-green  .rank-value {
  background: #4e81c4 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

/*Technology*/
.technology.rank-red  .rank-value {
  background: #e89b87 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

.technology.rank-yellow  .rank-value {
  background: #d3684b !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;

}

.technology.rank-green  .rank-value {
  background: #cc4c2a !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

/*Talent*/
.talent.rank-red  .rank-value {
  background: #f1c250 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

.talent.rank-yellow  .rank-value {
  background: #e8b539 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;

}

.talent.rank-green  .rank-value {
  background: #e2aa23 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

/*Innovation*/


.innovation.rank-red  .rank-value {
  background: #89b982 !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

.innovation.rank-yellow  .rank-value {
  background: #74b46c !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;

}

.innovation.rank-green  .rank-value {
  background: #66ad5d !important;
  font-weight: 600;
  color: white;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
}

.ranking-stat-box {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
  margin: 0;
  border-left: 0.1rem solid #ffffff !important;
  border-right: 0.1rem solid #ffffff !important;
  background: #fafafa;
}

.ranking-stat-box .field-name {
  white-space: normal;
  font-size: 14px;
  max-width: 200px;
  margin: 0 auto 0 auto;
}

.ranking-stat-box .field-name-pillar {
  white-space: normal;
  font-size: 18px;
  max-width: 200px;
  font-weight: 600;
  margin: 0 auto 0 auto;
}

.ranking-stat-box.rank-header {
  background: white;
}

.ranking-stat-box .field-value {
  font-size: 18px;
  font-weight: 300 !important;
}

</style>
