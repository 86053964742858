<script>
import {HorizontalBar} from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Score'
            }
          }]
        },
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
