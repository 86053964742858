import data from '../data.json'

const countryRanksData = {
    "albania": {
        "pillars": {
            "Future_Readiness": data.albania.future_readiness_rank,
            "Physical_Capital": data.albania.physical_capital_rank,
            "Technology": data.albania.technology_rank,
            "Human_Capital": data.albania.human_capital_rank,
            "Competitiveness": data.albania.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.albania.digital_infrastructure_rank,
            "Transport_Infrastructure": data.albania.transport_infrastructure_rank,
            "Energy_Infrastructure": data.albania.energy_infrastructure_rank,
            "Attract": data.albania.attract_rank,
            "Grow": data.albania.grow_rank,
            "Retain": data.albania.retain_rank,
            "Skills": data.albania.skills_rank,
            "Digital_Usage": data.albania.digital_usage_rank,
            "Digital_Content_Creation": data.albania.digital_content_creation_rank,
            "Industry_4": data.albania.industry_4_rank,
            "Digital_Policies": data.albania.digital_policies_rank,
            "Market_Environment": data.albania.market_environment_rank,
            "Research_and_Development": data.albania.research_and_development_rank,
            "Innovation": data.albania.innovation_rank
        }
    },
    "algeria": {
        "pillars": {
            "Future_Readiness": data.algeria.future_readiness_rank,
            "Physical_Capital": data.algeria.physical_capital_rank,
            "Technology": data.algeria.technology_rank,
            "Human_Capital": data.algeria.human_capital_rank,
            "Competitiveness": data.algeria.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.algeria.digital_infrastructure_rank,
            "Transport_Infrastructure": data.algeria.transport_infrastructure_rank,
            "Energy_Infrastructure": data.algeria.energy_infrastructure_rank,
            "Attract": data.algeria.attract_rank,
            "Grow": data.algeria.grow_rank,
            "Retain": data.algeria.retain_rank,
            "Skills": data.algeria.skills_rank,
            "Digital_Usage": data.algeria.digital_usage_rank,
            "Digital_Content_Creation": data.algeria.digital_content_creation_rank,
            "Industry_4": data.algeria.industry_4_rank,
            "Digital_Policies": data.algeria.digital_policies_rank,
            "Market_Environment": data.algeria.market_environment_rank,
            "Research_and_Development": data.algeria.research_and_development_rank,
            "Innovation": data.algeria.innovation_rank
        }
    },
    "angola": {
        "pillars": {
            "Future_Readiness": data.angola.future_readiness_rank,
            "Physical_Capital": data.angola.physical_capital_rank,
            "Technology": data.angola.technology_rank,
            "Human_Capital": data.angola.human_capital_rank,
            "Competitiveness": data.angola.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.angola.digital_infrastructure_rank,
            "Transport_Infrastructure": data.angola.transport_infrastructure_rank,
            "Energy_Infrastructure": data.angola.energy_infrastructure_rank,
            "Attract": data.angola.attract_rank,
            "Grow": data.angola.grow_rank,
            "Retain": data.angola.retain_rank,
            "Skills": data.angola.skills_rank,
            "Digital_Usage": data.angola.digital_usage_rank,
            "Digital_Content_Creation": data.angola.digital_content_creation_rank,
            "Industry_4": data.angola.industry_4_rank,
            "Digital_Policies": data.angola.digital_policies_rank,
            "Market_Environment": data.angola.market_environment_rank,
            "Research_and_Development": data.angola.research_and_development_rank,
            "Innovation": data.angola.innovation_rank
        }
    },
    "argentina": {
        "pillars": {
            "Future_Readiness": data.argentina.future_readiness_rank,
            "Physical_Capital": data.argentina.physical_capital_rank,
            "Technology": data.argentina.technology_rank,
            "Human_Capital": data.argentina.human_capital_rank,
            "Competitiveness": data.argentina.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.argentina.digital_infrastructure_rank,
            "Transport_Infrastructure": data.argentina.transport_infrastructure_rank,
            "Energy_Infrastructure": data.argentina.energy_infrastructure_rank,
            "Attract": data.argentina.attract_rank,
            "Grow": data.argentina.grow_rank,
            "Retain": data.argentina.retain_rank,
            "Skills": data.argentina.skills_rank,
            "Digital_Usage": data.argentina.digital_usage_rank,
            "Digital_Content_Creation": data.argentina.digital_content_creation_rank,
            "Industry_4": data.argentina.industry_4_rank,
            "Digital_Policies": data.argentina.digital_policies_rank,
            "Market_Environment": data.argentina.market_environment_rank,
            "Research_and_Development": data.argentina.research_and_development_rank,
            "Innovation": data.argentina.innovation_rank
        }
    },
    "armenia": {
        "pillars": {
            "Future_Readiness": data.armenia.future_readiness_rank,
            "Physical_Capital": data.armenia.physical_capital_rank,
            "Technology": data.armenia.technology_rank,
            "Human_Capital": data.armenia.human_capital_rank,
            "Competitiveness": data.armenia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.armenia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.armenia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.armenia.energy_infrastructure_rank,
            "Attract": data.armenia.attract_rank,
            "Grow": data.armenia.grow_rank,
            "Retain": data.armenia.retain_rank,
            "Skills": data.armenia.skills_rank,
            "Digital_Usage": data.armenia.digital_usage_rank,
            "Digital_Content_Creation": data.armenia.digital_content_creation_rank,
            "Industry_4": data.armenia.industry_4_rank,
            "Digital_Policies": data.armenia.digital_policies_rank,
            "Market_Environment": data.armenia.market_environment_rank,
            "Research_and_Development": data.armenia.research_and_development_rank,
            "Innovation": data.armenia.innovation_rank
        }
    },
    "australia": {
        "pillars": {
            "Future_Readiness": data.australia.future_readiness_rank,
            "Physical_Capital": data.australia.physical_capital_rank,
            "Technology": data.australia.technology_rank,
            "Human_Capital": data.australia.human_capital_rank,
            "Competitiveness": data.australia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.australia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.australia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.australia.energy_infrastructure_rank,
            "Attract": data.australia.attract_rank,
            "Grow": data.australia.grow_rank,
            "Retain": data.australia.retain_rank,
            "Skills": data.australia.skills_rank,
            "Digital_Usage": data.australia.digital_usage_rank,
            "Digital_Content_Creation": data.australia.digital_content_creation_rank,
            "Industry_4": data.australia.industry_4_rank,
            "Digital_Policies": data.australia.digital_policies_rank,
            "Market_Environment": data.australia.market_environment_rank,
            "Research_and_Development": data.australia.research_and_development_rank,
            "Innovation": data.australia.innovation_rank
        }
    },
    "austria": {
        "pillars": {
            "Future_Readiness": data.austria.future_readiness_rank,
            "Physical_Capital": data.austria.physical_capital_rank,
            "Technology": data.austria.technology_rank,
            "Human_Capital": data.austria.human_capital_rank,
            "Competitiveness": data.austria.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.austria.digital_infrastructure_rank,
            "Transport_Infrastructure": data.austria.transport_infrastructure_rank,
            "Energy_Infrastructure": data.austria.energy_infrastructure_rank,
            "Attract": data.austria.attract_rank,
            "Grow": data.austria.grow_rank,
            "Retain": data.austria.retain_rank,
            "Skills": data.austria.skills_rank,
            "Digital_Usage": data.austria.digital_usage_rank,
            "Digital_Content_Creation": data.austria.digital_content_creation_rank,
            "Industry_4": data.austria.industry_4_rank,
            "Digital_Policies": data.austria.digital_policies_rank,
            "Market_Environment": data.austria.market_environment_rank,
            "Research_and_Development": data.austria.research_and_development_rank,
            "Innovation": data.austria.innovation_rank
        }
    },
    "azerbaijan": {
        "pillars": {
            "Future_Readiness": data.azerbaijan.future_readiness_rank,
            "Physical_Capital": data.azerbaijan.physical_capital_rank,
            "Technology": data.azerbaijan.technology_rank,
            "Human_Capital": data.azerbaijan.human_capital_rank,
            "Competitiveness": data.azerbaijan.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.azerbaijan.digital_infrastructure_rank,
            "Transport_Infrastructure": data.azerbaijan.transport_infrastructure_rank,
            "Energy_Infrastructure": data.azerbaijan.energy_infrastructure_rank,
            "Attract": data.azerbaijan.attract_rank,
            "Grow": data.azerbaijan.grow_rank,
            "Retain": data.azerbaijan.retain_rank,
            "Skills": data.azerbaijan.skills_rank,
            "Digital_Usage": data.azerbaijan.digital_usage_rank,
            "Digital_Content_Creation": data.azerbaijan.digital_content_creation_rank,
            "Industry_4": data.azerbaijan.industry_4_rank,
            "Digital_Policies": data.azerbaijan.digital_policies_rank,
            "Market_Environment": data.azerbaijan.market_environment_rank,
            "Research_and_Development": data.azerbaijan.research_and_development_rank,
            "Innovation": data.azerbaijan.innovation_rank
        }
    },
    "bahrain": {
        "pillars": {
            "Future_Readiness": data.bahrain.future_readiness_rank,
            "Physical_Capital": data.bahrain.physical_capital_rank,
            "Technology": data.bahrain.technology_rank,
            "Human_Capital": data.bahrain.human_capital_rank,
            "Competitiveness": data.bahrain.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.bahrain.digital_infrastructure_rank,
            "Transport_Infrastructure": data.bahrain.transport_infrastructure_rank,
            "Energy_Infrastructure": data.bahrain.energy_infrastructure_rank,
            "Attract": data.bahrain.attract_rank,
            "Grow": data.bahrain.grow_rank,
            "Retain": data.bahrain.retain_rank,
            "Skills": data.bahrain.skills_rank,
            "Digital_Usage": data.bahrain.digital_usage_rank,
            "Digital_Content_Creation": data.bahrain.digital_content_creation_rank,
            "Industry_4": data.bahrain.industry_4_rank,
            "Digital_Policies": data.bahrain.digital_policies_rank,
            "Market_Environment": data.bahrain.market_environment_rank,
            "Research_and_Development": data.bahrain.research_and_development_rank,
            "Innovation": data.bahrain.innovation_rank
        }
    },
    "bangladesh": {
        "pillars": {
            "Future_Readiness": data.bangladesh.future_readiness_rank,
            "Physical_Capital": data.bangladesh.physical_capital_rank,
            "Technology": data.bangladesh.technology_rank,
            "Human_Capital": data.bangladesh.human_capital_rank,
            "Competitiveness": data.bangladesh.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.bangladesh.digital_infrastructure_rank,
            "Transport_Infrastructure": data.bangladesh.transport_infrastructure_rank,
            "Energy_Infrastructure": data.bangladesh.energy_infrastructure_rank,
            "Attract": data.bangladesh.attract_rank,
            "Grow": data.bangladesh.grow_rank,
            "Retain": data.bangladesh.retain_rank,
            "Skills": data.bangladesh.skills_rank,
            "Digital_Usage": data.bangladesh.digital_usage_rank,
            "Digital_Content_Creation": data.bangladesh.digital_content_creation_rank,
            "Industry_4": data.bangladesh.industry_4_rank,
            "Digital_Policies": data.bangladesh.digital_policies_rank,
            "Market_Environment": data.bangladesh.market_environment_rank,
            "Research_and_Development": data.bangladesh.research_and_development_rank,
            "Innovation": data.bangladesh.innovation_rank
        }
    },
    "belarus": {
        "pillars": {
            "Future_Readiness": data.belarus.future_readiness_rank,
            "Physical_Capital": data.belarus.physical_capital_rank,
            "Technology": data.belarus.technology_rank,
            "Human_Capital": data.belarus.human_capital_rank,
            "Competitiveness": data.belarus.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.belarus.digital_infrastructure_rank,
            "Transport_Infrastructure": data.belarus.transport_infrastructure_rank,
            "Energy_Infrastructure": data.belarus.energy_infrastructure_rank,
            "Attract": data.belarus.attract_rank,
            "Grow": data.belarus.grow_rank,
            "Retain": data.belarus.retain_rank,
            "Skills": data.belarus.skills_rank,
            "Digital_Usage": data.belarus.digital_usage_rank,
            "Digital_Content_Creation": data.belarus.digital_content_creation_rank,
            "Industry_4": data.belarus.industry_4_rank,
            "Digital_Policies": data.belarus.digital_policies_rank,
            "Market_Environment": data.belarus.market_environment_rank,
            "Research_and_Development": data.belarus.research_and_development_rank,
            "Innovation": data.belarus.innovation_rank
        }
    },
    "belgium": {
        "pillars": {
            "Future_Readiness": data.belgium.future_readiness_rank,
            "Physical_Capital": data.belgium.physical_capital_rank,
            "Technology": data.belgium.technology_rank,
            "Human_Capital": data.belgium.human_capital_rank,
            "Competitiveness": data.belgium.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.belgium.digital_infrastructure_rank,
            "Transport_Infrastructure": data.belgium.transport_infrastructure_rank,
            "Energy_Infrastructure": data.belgium.energy_infrastructure_rank,
            "Attract": data.belgium.attract_rank,
            "Grow": data.belgium.grow_rank,
            "Retain": data.belgium.retain_rank,
            "Skills": data.belgium.skills_rank,
            "Digital_Usage": data.belgium.digital_usage_rank,
            "Digital_Content_Creation": data.belgium.digital_content_creation_rank,
            "Industry_4": data.belgium.industry_4_rank,
            "Digital_Policies": data.belgium.digital_policies_rank,
            "Market_Environment": data.belgium.market_environment_rank,
            "Research_and_Development": data.belgium.research_and_development_rank,
            "Innovation": data.belgium.innovation_rank
        }
    },
    "benin": {
        "pillars": {
            "Future_Readiness": data.benin.future_readiness_rank,
            "Physical_Capital": data.benin.physical_capital_rank,
            "Technology": data.benin.technology_rank,
            "Human_Capital": data.benin.human_capital_rank,
            "Competitiveness": data.benin.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.benin.digital_infrastructure_rank,
            "Transport_Infrastructure": data.benin.transport_infrastructure_rank,
            "Energy_Infrastructure": data.benin.energy_infrastructure_rank,
            "Attract": data.benin.attract_rank,
            "Grow": data.benin.grow_rank,
            "Retain": data.benin.retain_rank,
            "Skills": data.benin.skills_rank,
            "Digital_Usage": data.benin.digital_usage_rank,
            "Digital_Content_Creation": data.benin.digital_content_creation_rank,
            "Industry_4": data.benin.industry_4_rank,
            "Digital_Policies": data.benin.digital_policies_rank,
            "Market_Environment": data.benin.market_environment_rank,
            "Research_and_Development": data.benin.research_and_development_rank,
            "Innovation": data.benin.innovation_rank
        }
    },
    "bolivia": {
        "pillars": {
            "Future_Readiness": data.bolivia.future_readiness_rank,
            "Physical_Capital": data.bolivia.physical_capital_rank,
            "Technology": data.bolivia.technology_rank,
            "Human_Capital": data.bolivia.human_capital_rank,
            "Competitiveness": data.bolivia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.bolivia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.bolivia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.bolivia.energy_infrastructure_rank,
            "Attract": data.bolivia.attract_rank,
            "Grow": data.bolivia.grow_rank,
            "Retain": data.bolivia.retain_rank,
            "Skills": data.bolivia.skills_rank,
            "Digital_Usage": data.bolivia.digital_usage_rank,
            "Digital_Content_Creation": data.bolivia.digital_content_creation_rank,
            "Industry_4": data.bolivia.industry_4_rank,
            "Digital_Policies": data.bolivia.digital_policies_rank,
            "Market_Environment": data.bolivia.market_environment_rank,
            "Research_and_Development": data.bolivia.research_and_development_rank,
            "Innovation": data.bolivia.innovation_rank
        }
    },
    "bosnia_and_herzegovina": {
        "pillars": {
            "Future_Readiness": data.bosnia_and_herzegovina.future_readiness_rank,
            "Physical_Capital": data.bosnia_and_herzegovina.physical_capital_rank,
            "Technology": data.bosnia_and_herzegovina.technology_rank,
            "Human_Capital": data.bosnia_and_herzegovina.human_capital_rank,
            "Competitiveness": data.bosnia_and_herzegovina.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.bosnia_and_herzegovina.digital_infrastructure_rank,
            "Transport_Infrastructure": data.bosnia_and_herzegovina.transport_infrastructure_rank,
            "Energy_Infrastructure": data.bosnia_and_herzegovina.energy_infrastructure_rank,
            "Attract": data.bosnia_and_herzegovina.attract_rank,
            "Grow": data.bosnia_and_herzegovina.grow_rank,
            "Retain": data.bosnia_and_herzegovina.retain_rank,
            "Skills": data.bosnia_and_herzegovina.skills_rank,
            "Digital_Usage": data.bosnia_and_herzegovina.digital_usage_rank,
            "Digital_Content_Creation": data.bosnia_and_herzegovina.digital_content_creation_rank,
            "Industry_4": data.bosnia_and_herzegovina.industry_4_rank,
            "Digital_Policies": data.bosnia_and_herzegovina.digital_policies_rank,
            "Market_Environment": data.bosnia_and_herzegovina.market_environment_rank,
            "Research_and_Development": data.bosnia_and_herzegovina.research_and_development_rank,
            "Innovation": data.bosnia_and_herzegovina.innovation_rank
        }
    },
    "botswana": {
        "pillars": {
            "Future_Readiness": data.botswana.future_readiness_rank,
            "Physical_Capital": data.botswana.physical_capital_rank,
            "Technology": data.botswana.technology_rank,
            "Human_Capital": data.botswana.human_capital_rank,
            "Competitiveness": data.botswana.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.botswana.digital_infrastructure_rank,
            "Transport_Infrastructure": data.botswana.transport_infrastructure_rank,
            "Energy_Infrastructure": data.botswana.energy_infrastructure_rank,
            "Attract": data.botswana.attract_rank,
            "Grow": data.botswana.grow_rank,
            "Retain": data.botswana.retain_rank,
            "Skills": data.botswana.skills_rank,
            "Digital_Usage": data.botswana.digital_usage_rank,
            "Digital_Content_Creation": data.botswana.digital_content_creation_rank,
            "Industry_4": data.botswana.industry_4_rank,
            "Digital_Policies": data.botswana.digital_policies_rank,
            "Market_Environment": data.botswana.market_environment_rank,
            "Research_and_Development": data.botswana.research_and_development_rank,
            "Innovation": data.botswana.innovation_rank
        }
    },
    "brazil": {
        "pillars": {
            "Future_Readiness": data.brazil.future_readiness_rank,
            "Physical_Capital": data.brazil.physical_capital_rank,
            "Technology": data.brazil.technology_rank,
            "Human_Capital": data.brazil.human_capital_rank,
            "Competitiveness": data.brazil.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.brazil.digital_infrastructure_rank,
            "Transport_Infrastructure": data.brazil.transport_infrastructure_rank,
            "Energy_Infrastructure": data.brazil.energy_infrastructure_rank,
            "Attract": data.brazil.attract_rank,
            "Grow": data.brazil.grow_rank,
            "Retain": data.brazil.retain_rank,
            "Skills": data.brazil.skills_rank,
            "Digital_Usage": data.brazil.digital_usage_rank,
            "Digital_Content_Creation": data.brazil.digital_content_creation_rank,
            "Industry_4": data.brazil.industry_4_rank,
            "Digital_Policies": data.brazil.digital_policies_rank,
            "Market_Environment": data.brazil.market_environment_rank,
            "Research_and_Development": data.brazil.research_and_development_rank,
            "Innovation": data.brazil.innovation_rank
        }
    },
    "bulgaria": {
        "pillars": {
            "Future_Readiness": data.bulgaria.future_readiness_rank,
            "Physical_Capital": data.bulgaria.physical_capital_rank,
            "Technology": data.bulgaria.technology_rank,
            "Human_Capital": data.bulgaria.human_capital_rank,
            "Competitiveness": data.bulgaria.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.bulgaria.digital_infrastructure_rank,
            "Transport_Infrastructure": data.bulgaria.transport_infrastructure_rank,
            "Energy_Infrastructure": data.bulgaria.energy_infrastructure_rank,
            "Attract": data.bulgaria.attract_rank,
            "Grow": data.bulgaria.grow_rank,
            "Retain": data.bulgaria.retain_rank,
            "Skills": data.bulgaria.skills_rank,
            "Digital_Usage": data.bulgaria.digital_usage_rank,
            "Digital_Content_Creation": data.bulgaria.digital_content_creation_rank,
            "Industry_4": data.bulgaria.industry_4_rank,
            "Digital_Policies": data.bulgaria.digital_policies_rank,
            "Market_Environment": data.bulgaria.market_environment_rank,
            "Research_and_Development": data.bulgaria.research_and_development_rank,
            "Innovation": data.bulgaria.innovation_rank
        }
    },
    "burkina_faso": {
        "pillars": {
            "Future_Readiness": data.burkina_faso.future_readiness_rank,
            "Physical_Capital": data.burkina_faso.physical_capital_rank,
            "Technology": data.burkina_faso.technology_rank,
            "Human_Capital": data.burkina_faso.human_capital_rank,
            "Competitiveness": data.burkina_faso.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.burkina_faso.digital_infrastructure_rank,
            "Transport_Infrastructure": data.burkina_faso.transport_infrastructure_rank,
            "Energy_Infrastructure": data.burkina_faso.energy_infrastructure_rank,
            "Attract": data.burkina_faso.attract_rank,
            "Grow": data.burkina_faso.grow_rank,
            "Retain": data.burkina_faso.retain_rank,
            "Skills": data.burkina_faso.skills_rank,
            "Digital_Usage": data.burkina_faso.digital_usage_rank,
            "Digital_Content_Creation": data.burkina_faso.digital_content_creation_rank,
            "Industry_4": data.burkina_faso.industry_4_rank,
            "Digital_Policies": data.burkina_faso.digital_policies_rank,
            "Market_Environment": data.burkina_faso.market_environment_rank,
            "Research_and_Development": data.burkina_faso.research_and_development_rank,
            "Innovation": data.burkina_faso.innovation_rank
        }
    },
    "cambodia": {
        "pillars": {
            "Future_Readiness": data.cambodia.future_readiness_rank,
            "Physical_Capital": data.cambodia.physical_capital_rank,
            "Technology": data.cambodia.technology_rank,
            "Human_Capital": data.cambodia.human_capital_rank,
            "Competitiveness": data.cambodia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.cambodia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.cambodia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.cambodia.energy_infrastructure_rank,
            "Attract": data.cambodia.attract_rank,
            "Grow": data.cambodia.grow_rank,
            "Retain": data.cambodia.retain_rank,
            "Skills": data.cambodia.skills_rank,
            "Digital_Usage": data.cambodia.digital_usage_rank,
            "Digital_Content_Creation": data.cambodia.digital_content_creation_rank,
            "Industry_4": data.cambodia.industry_4_rank,
            "Digital_Policies": data.cambodia.digital_policies_rank,
            "Market_Environment": data.cambodia.market_environment_rank,
            "Research_and_Development": data.cambodia.research_and_development_rank,
            "Innovation": data.cambodia.innovation_rank
        }
    },
    "cameroon": {
        "pillars": {
            "Future_Readiness": data.cameroon.future_readiness_rank,
            "Physical_Capital": data.cameroon.physical_capital_rank,
            "Technology": data.cameroon.technology_rank,
            "Human_Capital": data.cameroon.human_capital_rank,
            "Competitiveness": data.cameroon.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.cameroon.digital_infrastructure_rank,
            "Transport_Infrastructure": data.cameroon.transport_infrastructure_rank,
            "Energy_Infrastructure": data.cameroon.energy_infrastructure_rank,
            "Attract": data.cameroon.attract_rank,
            "Grow": data.cameroon.grow_rank,
            "Retain": data.cameroon.retain_rank,
            "Skills": data.cameroon.skills_rank,
            "Digital_Usage": data.cameroon.digital_usage_rank,
            "Digital_Content_Creation": data.cameroon.digital_content_creation_rank,
            "Industry_4": data.cameroon.industry_4_rank,
            "Digital_Policies": data.cameroon.digital_policies_rank,
            "Market_Environment": data.cameroon.market_environment_rank,
            "Research_and_Development": data.cameroon.research_and_development_rank,
            "Innovation": data.cameroon.innovation_rank
        }
    },
    "canada": {
        "pillars": {
            "Future_Readiness": data.canada.future_readiness_rank,
            "Physical_Capital": data.canada.physical_capital_rank,
            "Technology": data.canada.technology_rank,
            "Human_Capital": data.canada.human_capital_rank,
            "Competitiveness": data.canada.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.canada.digital_infrastructure_rank,
            "Transport_Infrastructure": data.canada.transport_infrastructure_rank,
            "Energy_Infrastructure": data.canada.energy_infrastructure_rank,
            "Attract": data.canada.attract_rank,
            "Grow": data.canada.grow_rank,
            "Retain": data.canada.retain_rank,
            "Skills": data.canada.skills_rank,
            "Digital_Usage": data.canada.digital_usage_rank,
            "Digital_Content_Creation": data.canada.digital_content_creation_rank,
            "Industry_4": data.canada.industry_4_rank,
            "Digital_Policies": data.canada.digital_policies_rank,
            "Market_Environment": data.canada.market_environment_rank,
            "Research_and_Development": data.canada.research_and_development_rank,
            "Innovation": data.canada.innovation_rank
        }
    },
    "chile": {
        "pillars": {
            "Future_Readiness": data.chile.future_readiness_rank,
            "Physical_Capital": data.chile.physical_capital_rank,
            "Technology": data.chile.technology_rank,
            "Human_Capital": data.chile.human_capital_rank,
            "Competitiveness": data.chile.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.chile.digital_infrastructure_rank,
            "Transport_Infrastructure": data.chile.transport_infrastructure_rank,
            "Energy_Infrastructure": data.chile.energy_infrastructure_rank,
            "Attract": data.chile.attract_rank,
            "Grow": data.chile.grow_rank,
            "Retain": data.chile.retain_rank,
            "Skills": data.chile.skills_rank,
            "Digital_Usage": data.chile.digital_usage_rank,
            "Digital_Content_Creation": data.chile.digital_content_creation_rank,
            "Industry_4": data.chile.industry_4_rank,
            "Digital_Policies": data.chile.digital_policies_rank,
            "Market_Environment": data.chile.market_environment_rank,
            "Research_and_Development": data.chile.research_and_development_rank,
            "Innovation": data.chile.innovation_rank
        }
    },
    "china": {
        "pillars": {
            "Future_Readiness": data.china.future_readiness_rank,
            "Physical_Capital": data.china.physical_capital_rank,
            "Technology": data.china.technology_rank,
            "Human_Capital": data.china.human_capital_rank,
            "Competitiveness": data.china.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.china.digital_infrastructure_rank,
            "Transport_Infrastructure": data.china.transport_infrastructure_rank,
            "Energy_Infrastructure": data.china.energy_infrastructure_rank,
            "Attract": data.china.attract_rank,
            "Grow": data.china.grow_rank,
            "Retain": data.china.retain_rank,
            "Skills": data.china.skills_rank,
            "Digital_Usage": data.china.digital_usage_rank,
            "Digital_Content_Creation": data.china.digital_content_creation_rank,
            "Industry_4": data.china.industry_4_rank,
            "Digital_Policies": data.china.digital_policies_rank,
            "Market_Environment": data.china.market_environment_rank,
            "Research_and_Development": data.china.research_and_development_rank,
            "Innovation": data.china.innovation_rank
        }
    },
    "colombia": {
        "pillars": {
            "Future_Readiness": data.colombia.future_readiness_rank,
            "Physical_Capital": data.colombia.physical_capital_rank,
            "Technology": data.colombia.technology_rank,
            "Human_Capital": data.colombia.human_capital_rank,
            "Competitiveness": data.colombia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.colombia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.colombia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.colombia.energy_infrastructure_rank,
            "Attract": data.colombia.attract_rank,
            "Grow": data.colombia.grow_rank,
            "Retain": data.colombia.retain_rank,
            "Skills": data.colombia.skills_rank,
            "Digital_Usage": data.colombia.digital_usage_rank,
            "Digital_Content_Creation": data.colombia.digital_content_creation_rank,
            "Industry_4": data.colombia.industry_4_rank,
            "Digital_Policies": data.colombia.digital_policies_rank,
            "Market_Environment": data.colombia.market_environment_rank,
            "Research_and_Development": data.colombia.research_and_development_rank,
            "Innovation": data.colombia.innovation_rank
        }
    },
    "costa_rica": {
        "pillars": {
            "Future_Readiness": data.costa_rica.future_readiness_rank,
            "Physical_Capital": data.costa_rica.physical_capital_rank,
            "Technology": data.costa_rica.technology_rank,
            "Human_Capital": data.costa_rica.human_capital_rank,
            "Competitiveness": data.costa_rica.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.costa_rica.digital_infrastructure_rank,
            "Transport_Infrastructure": data.costa_rica.transport_infrastructure_rank,
            "Energy_Infrastructure": data.costa_rica.energy_infrastructure_rank,
            "Attract": data.costa_rica.attract_rank,
            "Grow": data.costa_rica.grow_rank,
            "Retain": data.costa_rica.retain_rank,
            "Skills": data.costa_rica.skills_rank,
            "Digital_Usage": data.costa_rica.digital_usage_rank,
            "Digital_Content_Creation": data.costa_rica.digital_content_creation_rank,
            "Industry_4": data.costa_rica.industry_4_rank,
            "Digital_Policies": data.costa_rica.digital_policies_rank,
            "Market_Environment": data.costa_rica.market_environment_rank,
            "Research_and_Development": data.costa_rica.research_and_development_rank,
            "Innovation": data.costa_rica.innovation_rank
        }
    },
    "cote_divoire": {
        "pillars": {
            "Future_Readiness": data.cote_divoire.future_readiness_rank,
            "Physical_Capital": data.cote_divoire.physical_capital_rank,
            "Technology": data.cote_divoire.technology_rank,
            "Human_Capital": data.cote_divoire.human_capital_rank,
            "Competitiveness": data.cote_divoire.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.cote_divoire.digital_infrastructure_rank,
            "Transport_Infrastructure": data.cote_divoire.transport_infrastructure_rank,
            "Energy_Infrastructure": data.cote_divoire.energy_infrastructure_rank,
            "Attract": data.cote_divoire.attract_rank,
            "Grow": data.cote_divoire.grow_rank,
            "Retain": data.cote_divoire.retain_rank,
            "Skills": data.cote_divoire.skills_rank,
            "Digital_Usage": data.cote_divoire.digital_usage_rank,
            "Digital_Content_Creation": data.cote_divoire.digital_content_creation_rank,
            "Industry_4": data.cote_divoire.industry_4_rank,
            "Digital_Policies": data.cote_divoire.digital_policies_rank,
            "Market_Environment": data.cote_divoire.market_environment_rank,
            "Research_and_Development": data.cote_divoire.research_and_development_rank,
            "Innovation": data.cote_divoire.innovation_rank
        }
    },
    "croatia": {
        "pillars": {
            "Future_Readiness": data.croatia.future_readiness_rank,
            "Physical_Capital": data.croatia.physical_capital_rank,
            "Technology": data.croatia.technology_rank,
            "Human_Capital": data.croatia.human_capital_rank,
            "Competitiveness": data.croatia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.croatia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.croatia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.croatia.energy_infrastructure_rank,
            "Attract": data.croatia.attract_rank,
            "Grow": data.croatia.grow_rank,
            "Retain": data.croatia.retain_rank,
            "Skills": data.croatia.skills_rank,
            "Digital_Usage": data.croatia.digital_usage_rank,
            "Digital_Content_Creation": data.croatia.digital_content_creation_rank,
            "Industry_4": data.croatia.industry_4_rank,
            "Digital_Policies": data.croatia.digital_policies_rank,
            "Market_Environment": data.croatia.market_environment_rank,
            "Research_and_Development": data.croatia.research_and_development_rank,
            "Innovation": data.croatia.innovation_rank
        }
    },
    "cyprus": {
        "pillars": {
            "Future_Readiness": data.cyprus.future_readiness_rank,
            "Physical_Capital": data.cyprus.physical_capital_rank,
            "Technology": data.cyprus.technology_rank,
            "Human_Capital": data.cyprus.human_capital_rank,
            "Competitiveness": data.cyprus.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.cyprus.digital_infrastructure_rank,
            "Transport_Infrastructure": data.cyprus.transport_infrastructure_rank,
            "Energy_Infrastructure": data.cyprus.energy_infrastructure_rank,
            "Attract": data.cyprus.attract_rank,
            "Grow": data.cyprus.grow_rank,
            "Retain": data.cyprus.retain_rank,
            "Skills": data.cyprus.skills_rank,
            "Digital_Usage": data.cyprus.digital_usage_rank,
            "Digital_Content_Creation": data.cyprus.digital_content_creation_rank,
            "Industry_4": data.cyprus.industry_4_rank,
            "Digital_Policies": data.cyprus.digital_policies_rank,
            "Market_Environment": data.cyprus.market_environment_rank,
            "Research_and_Development": data.cyprus.research_and_development_rank,
            "Innovation": data.cyprus.innovation_rank
        }
    },
    "czech_republic": {
        "pillars": {
            "Future_Readiness": data.czech_republic.future_readiness_rank,
            "Physical_Capital": data.czech_republic.physical_capital_rank,
            "Technology": data.czech_republic.technology_rank,
            "Human_Capital": data.czech_republic.human_capital_rank,
            "Competitiveness": data.czech_republic.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.czech_republic.digital_infrastructure_rank,
            "Transport_Infrastructure": data.czech_republic.transport_infrastructure_rank,
            "Energy_Infrastructure": data.czech_republic.energy_infrastructure_rank,
            "Attract": data.czech_republic.attract_rank,
            "Grow": data.czech_republic.grow_rank,
            "Retain": data.czech_republic.retain_rank,
            "Skills": data.czech_republic.skills_rank,
            "Digital_Usage": data.czech_republic.digital_usage_rank,
            "Digital_Content_Creation": data.czech_republic.digital_content_creation_rank,
            "Industry_4": data.czech_republic.industry_4_rank,
            "Digital_Policies": data.czech_republic.digital_policies_rank,
            "Market_Environment": data.czech_republic.market_environment_rank,
            "Research_and_Development": data.czech_republic.research_and_development_rank,
            "Innovation": data.czech_republic.innovation_rank
        }
    },
    "denmark": {
        "pillars": {
            "Future_Readiness": data.denmark.future_readiness_rank,
            "Physical_Capital": data.denmark.physical_capital_rank,
            "Technology": data.denmark.technology_rank,
            "Human_Capital": data.denmark.human_capital_rank,
            "Competitiveness": data.denmark.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.denmark.digital_infrastructure_rank,
            "Transport_Infrastructure": data.denmark.transport_infrastructure_rank,
            "Energy_Infrastructure": data.denmark.energy_infrastructure_rank,
            "Attract": data.denmark.attract_rank,
            "Grow": data.denmark.grow_rank,
            "Retain": data.denmark.retain_rank,
            "Skills": data.denmark.skills_rank,
            "Digital_Usage": data.denmark.digital_usage_rank,
            "Digital_Content_Creation": data.denmark.digital_content_creation_rank,
            "Industry_4": data.denmark.industry_4_rank,
            "Digital_Policies": data.denmark.digital_policies_rank,
            "Market_Environment": data.denmark.market_environment_rank,
            "Research_and_Development": data.denmark.research_and_development_rank,
            "Innovation": data.denmark.innovation_rank
        }
    },
    "dominican_republic": {
        "pillars": {
            "Future_Readiness": data.dominican_republic.future_readiness_rank,
            "Physical_Capital": data.dominican_republic.physical_capital_rank,
            "Technology": data.dominican_republic.technology_rank,
            "Human_Capital": data.dominican_republic.human_capital_rank,
            "Competitiveness": data.dominican_republic.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.dominican_republic.digital_infrastructure_rank,
            "Transport_Infrastructure": data.dominican_republic.transport_infrastructure_rank,
            "Energy_Infrastructure": data.dominican_republic.energy_infrastructure_rank,
            "Attract": data.dominican_republic.attract_rank,
            "Grow": data.dominican_republic.grow_rank,
            "Retain": data.dominican_republic.retain_rank,
            "Skills": data.dominican_republic.skills_rank,
            "Digital_Usage": data.dominican_republic.digital_usage_rank,
            "Digital_Content_Creation": data.dominican_republic.digital_content_creation_rank,
            "Industry_4": data.dominican_republic.industry_4_rank,
            "Digital_Policies": data.dominican_republic.digital_policies_rank,
            "Market_Environment": data.dominican_republic.market_environment_rank,
            "Research_and_Development": data.dominican_republic.research_and_development_rank,
            "Innovation": data.dominican_republic.innovation_rank
        }
    },
    "ecuador": {
        "pillars": {
            "Future_Readiness": data.ecuador.future_readiness_rank,
            "Physical_Capital": data.ecuador.physical_capital_rank,
            "Technology": data.ecuador.technology_rank,
            "Human_Capital": data.ecuador.human_capital_rank,
            "Competitiveness": data.ecuador.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.ecuador.digital_infrastructure_rank,
            "Transport_Infrastructure": data.ecuador.transport_infrastructure_rank,
            "Energy_Infrastructure": data.ecuador.energy_infrastructure_rank,
            "Attract": data.ecuador.attract_rank,
            "Grow": data.ecuador.grow_rank,
            "Retain": data.ecuador.retain_rank,
            "Skills": data.ecuador.skills_rank,
            "Digital_Usage": data.ecuador.digital_usage_rank,
            "Digital_Content_Creation": data.ecuador.digital_content_creation_rank,
            "Industry_4": data.ecuador.industry_4_rank,
            "Digital_Policies": data.ecuador.digital_policies_rank,
            "Market_Environment": data.ecuador.market_environment_rank,
            "Research_and_Development": data.ecuador.research_and_development_rank,
            "Innovation": data.ecuador.innovation_rank
        }
    },
    "egypt": {
        "pillars": {
            "Future_Readiness": data.egypt.future_readiness_rank,
            "Physical_Capital": data.egypt.physical_capital_rank,
            "Technology": data.egypt.technology_rank,
            "Human_Capital": data.egypt.human_capital_rank,
            "Competitiveness": data.egypt.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.egypt.digital_infrastructure_rank,
            "Transport_Infrastructure": data.egypt.transport_infrastructure_rank,
            "Energy_Infrastructure": data.egypt.energy_infrastructure_rank,
            "Attract": data.egypt.attract_rank,
            "Grow": data.egypt.grow_rank,
            "Retain": data.egypt.retain_rank,
            "Skills": data.egypt.skills_rank,
            "Digital_Usage": data.egypt.digital_usage_rank,
            "Digital_Content_Creation": data.egypt.digital_content_creation_rank,
            "Industry_4": data.egypt.industry_4_rank,
            "Digital_Policies": data.egypt.digital_policies_rank,
            "Market_Environment": data.egypt.market_environment_rank,
            "Research_and_Development": data.egypt.research_and_development_rank,
            "Innovation": data.egypt.innovation_rank
        }
    },
    "el_salvador": {
        "pillars": {
            "Future_Readiness": data.el_salvador.future_readiness_rank,
            "Physical_Capital": data.el_salvador.physical_capital_rank,
            "Technology": data.el_salvador.technology_rank,
            "Human_Capital": data.el_salvador.human_capital_rank,
            "Competitiveness": data.el_salvador.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.el_salvador.digital_infrastructure_rank,
            "Transport_Infrastructure": data.el_salvador.transport_infrastructure_rank,
            "Energy_Infrastructure": data.el_salvador.energy_infrastructure_rank,
            "Attract": data.el_salvador.attract_rank,
            "Grow": data.el_salvador.grow_rank,
            "Retain": data.el_salvador.retain_rank,
            "Skills": data.el_salvador.skills_rank,
            "Digital_Usage": data.el_salvador.digital_usage_rank,
            "Digital_Content_Creation": data.el_salvador.digital_content_creation_rank,
            "Industry_4": data.el_salvador.industry_4_rank,
            "Digital_Policies": data.el_salvador.digital_policies_rank,
            "Market_Environment": data.el_salvador.market_environment_rank,
            "Research_and_Development": data.el_salvador.research_and_development_rank,
            "Innovation": data.el_salvador.innovation_rank
        }
    },
    "estonia": {
        "pillars": {
            "Future_Readiness": data.estonia.future_readiness_rank,
            "Physical_Capital": data.estonia.physical_capital_rank,
            "Technology": data.estonia.technology_rank,
            "Human_Capital": data.estonia.human_capital_rank,
            "Competitiveness": data.estonia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.estonia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.estonia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.estonia.energy_infrastructure_rank,
            "Attract": data.estonia.attract_rank,
            "Grow": data.estonia.grow_rank,
            "Retain": data.estonia.retain_rank,
            "Skills": data.estonia.skills_rank,
            "Digital_Usage": data.estonia.digital_usage_rank,
            "Digital_Content_Creation": data.estonia.digital_content_creation_rank,
            "Industry_4": data.estonia.industry_4_rank,
            "Digital_Policies": data.estonia.digital_policies_rank,
            "Market_Environment": data.estonia.market_environment_rank,
            "Research_and_Development": data.estonia.research_and_development_rank,
            "Innovation": data.estonia.innovation_rank
        }
    },
    "ethiopia": {
        "pillars": {
            "Future_Readiness": data.ethiopia.future_readiness_rank,
            "Physical_Capital": data.ethiopia.physical_capital_rank,
            "Technology": data.ethiopia.technology_rank,
            "Human_Capital": data.ethiopia.human_capital_rank,
            "Competitiveness": data.ethiopia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.ethiopia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.ethiopia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.ethiopia.energy_infrastructure_rank,
            "Attract": data.ethiopia.attract_rank,
            "Grow": data.ethiopia.grow_rank,
            "Retain": data.ethiopia.retain_rank,
            "Skills": data.ethiopia.skills_rank,
            "Digital_Usage": data.ethiopia.digital_usage_rank,
            "Digital_Content_Creation": data.ethiopia.digital_content_creation_rank,
            "Industry_4": data.ethiopia.industry_4_rank,
            "Digital_Policies": data.ethiopia.digital_policies_rank,
            "Market_Environment": data.ethiopia.market_environment_rank,
            "Research_and_Development": data.ethiopia.research_and_development_rank,
            "Innovation": data.ethiopia.innovation_rank
        }
    },
    "finland": {
        "pillars": {
            "Future_Readiness": data.finland.future_readiness_rank,
            "Physical_Capital": data.finland.physical_capital_rank,
            "Technology": data.finland.technology_rank,
            "Human_Capital": data.finland.human_capital_rank,
            "Competitiveness": data.finland.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.finland.digital_infrastructure_rank,
            "Transport_Infrastructure": data.finland.transport_infrastructure_rank,
            "Energy_Infrastructure": data.finland.energy_infrastructure_rank,
            "Attract": data.finland.attract_rank,
            "Grow": data.finland.grow_rank,
            "Retain": data.finland.retain_rank,
            "Skills": data.finland.skills_rank,
            "Digital_Usage": data.finland.digital_usage_rank,
            "Digital_Content_Creation": data.finland.digital_content_creation_rank,
            "Industry_4": data.finland.industry_4_rank,
            "Digital_Policies": data.finland.digital_policies_rank,
            "Market_Environment": data.finland.market_environment_rank,
            "Research_and_Development": data.finland.research_and_development_rank,
            "Innovation": data.finland.innovation_rank
        }
    },
    "france": {
        "pillars": {
            "Future_Readiness": data.france.future_readiness_rank,
            "Physical_Capital": data.france.physical_capital_rank,
            "Technology": data.france.technology_rank,
            "Human_Capital": data.france.human_capital_rank,
            "Competitiveness": data.france.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.france.digital_infrastructure_rank,
            "Transport_Infrastructure": data.france.transport_infrastructure_rank,
            "Energy_Infrastructure": data.france.energy_infrastructure_rank,
            "Attract": data.france.attract_rank,
            "Grow": data.france.grow_rank,
            "Retain": data.france.retain_rank,
            "Skills": data.france.skills_rank,
            "Digital_Usage": data.france.digital_usage_rank,
            "Digital_Content_Creation": data.france.digital_content_creation_rank,
            "Industry_4": data.france.industry_4_rank,
            "Digital_Policies": data.france.digital_policies_rank,
            "Market_Environment": data.france.market_environment_rank,
            "Research_and_Development": data.france.research_and_development_rank,
            "Innovation": data.france.innovation_rank
        }
    },
    "georgia": {
        "pillars": {
            "Future_Readiness": data.georgia.future_readiness_rank,
            "Physical_Capital": data.georgia.physical_capital_rank,
            "Technology": data.georgia.technology_rank,
            "Human_Capital": data.georgia.human_capital_rank,
            "Competitiveness": data.georgia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.georgia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.georgia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.georgia.energy_infrastructure_rank,
            "Attract": data.georgia.attract_rank,
            "Grow": data.georgia.grow_rank,
            "Retain": data.georgia.retain_rank,
            "Skills": data.georgia.skills_rank,
            "Digital_Usage": data.georgia.digital_usage_rank,
            "Digital_Content_Creation": data.georgia.digital_content_creation_rank,
            "Industry_4": data.georgia.industry_4_rank,
            "Digital_Policies": data.georgia.digital_policies_rank,
            "Market_Environment": data.georgia.market_environment_rank,
            "Research_and_Development": data.georgia.research_and_development_rank,
            "Innovation": data.georgia.innovation_rank
        }
    },
    "germany": {
        "pillars": {
            "Future_Readiness": data.germany.future_readiness_rank,
            "Physical_Capital": data.germany.physical_capital_rank,
            "Technology": data.germany.technology_rank,
            "Human_Capital": data.germany.human_capital_rank,
            "Competitiveness": data.germany.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.germany.digital_infrastructure_rank,
            "Transport_Infrastructure": data.germany.transport_infrastructure_rank,
            "Energy_Infrastructure": data.germany.energy_infrastructure_rank,
            "Attract": data.germany.attract_rank,
            "Grow": data.germany.grow_rank,
            "Retain": data.germany.retain_rank,
            "Skills": data.germany.skills_rank,
            "Digital_Usage": data.germany.digital_usage_rank,
            "Digital_Content_Creation": data.germany.digital_content_creation_rank,
            "Industry_4": data.germany.industry_4_rank,
            "Digital_Policies": data.germany.digital_policies_rank,
            "Market_Environment": data.germany.market_environment_rank,
            "Research_and_Development": data.germany.research_and_development_rank,
            "Innovation": data.germany.innovation_rank
        }
    },
    "ghana": {
        "pillars": {
            "Future_Readiness": data.ghana.future_readiness_rank,
            "Physical_Capital": data.ghana.physical_capital_rank,
            "Technology": data.ghana.technology_rank,
            "Human_Capital": data.ghana.human_capital_rank,
            "Competitiveness": data.ghana.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.ghana.digital_infrastructure_rank,
            "Transport_Infrastructure": data.ghana.transport_infrastructure_rank,
            "Energy_Infrastructure": data.ghana.energy_infrastructure_rank,
            "Attract": data.ghana.attract_rank,
            "Grow": data.ghana.grow_rank,
            "Retain": data.ghana.retain_rank,
            "Skills": data.ghana.skills_rank,
            "Digital_Usage": data.ghana.digital_usage_rank,
            "Digital_Content_Creation": data.ghana.digital_content_creation_rank,
            "Industry_4": data.ghana.industry_4_rank,
            "Digital_Policies": data.ghana.digital_policies_rank,
            "Market_Environment": data.ghana.market_environment_rank,
            "Research_and_Development": data.ghana.research_and_development_rank,
            "Innovation": data.ghana.innovation_rank
        }
    },
    "greece": {
        "pillars": {
            "Future_Readiness": data.greece.future_readiness_rank,
            "Physical_Capital": data.greece.physical_capital_rank,
            "Technology": data.greece.technology_rank,
            "Human_Capital": data.greece.human_capital_rank,
            "Competitiveness": data.greece.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.greece.digital_infrastructure_rank,
            "Transport_Infrastructure": data.greece.transport_infrastructure_rank,
            "Energy_Infrastructure": data.greece.energy_infrastructure_rank,
            "Attract": data.greece.attract_rank,
            "Grow": data.greece.grow_rank,
            "Retain": data.greece.retain_rank,
            "Skills": data.greece.skills_rank,
            "Digital_Usage": data.greece.digital_usage_rank,
            "Digital_Content_Creation": data.greece.digital_content_creation_rank,
            "Industry_4": data.greece.industry_4_rank,
            "Digital_Policies": data.greece.digital_policies_rank,
            "Market_Environment": data.greece.market_environment_rank,
            "Research_and_Development": data.greece.research_and_development_rank,
            "Innovation": data.greece.innovation_rank
        }
    },
    "guatemala": {
        "pillars": {
            "Future_Readiness": data.guatemala.future_readiness_rank,
            "Physical_Capital": data.guatemala.physical_capital_rank,
            "Technology": data.guatemala.technology_rank,
            "Human_Capital": data.guatemala.human_capital_rank,
            "Competitiveness": data.guatemala.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.guatemala.digital_infrastructure_rank,
            "Transport_Infrastructure": data.guatemala.transport_infrastructure_rank,
            "Energy_Infrastructure": data.guatemala.energy_infrastructure_rank,
            "Attract": data.guatemala.attract_rank,
            "Grow": data.guatemala.grow_rank,
            "Retain": data.guatemala.retain_rank,
            "Skills": data.guatemala.skills_rank,
            "Digital_Usage": data.guatemala.digital_usage_rank,
            "Digital_Content_Creation": data.guatemala.digital_content_creation_rank,
            "Industry_4": data.guatemala.industry_4_rank,
            "Digital_Policies": data.guatemala.digital_policies_rank,
            "Market_Environment": data.guatemala.market_environment_rank,
            "Research_and_Development": data.guatemala.research_and_development_rank,
            "Innovation": data.guatemala.innovation_rank
        }
    },
    "guinea": {
        "pillars": {
            "Future_Readiness": data.guinea.future_readiness_rank,
            "Physical_Capital": data.guinea.physical_capital_rank,
            "Technology": data.guinea.technology_rank,
            "Human_Capital": data.guinea.human_capital_rank,
            "Competitiveness": data.guinea.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.guinea.digital_infrastructure_rank,
            "Transport_Infrastructure": data.guinea.transport_infrastructure_rank,
            "Energy_Infrastructure": data.guinea.energy_infrastructure_rank,
            "Attract": data.guinea.attract_rank,
            "Grow": data.guinea.grow_rank,
            "Retain": data.guinea.retain_rank,
            "Skills": data.guinea.skills_rank,
            "Digital_Usage": data.guinea.digital_usage_rank,
            "Digital_Content_Creation": data.guinea.digital_content_creation_rank,
            "Industry_4": data.guinea.industry_4_rank,
            "Digital_Policies": data.guinea.digital_policies_rank,
            "Market_Environment": data.guinea.market_environment_rank,
            "Research_and_Development": data.guinea.research_and_development_rank,
            "Innovation": data.guinea.innovation_rank
        }
    },
    "honduras": {
        "pillars": {
            "Future_Readiness": data.honduras.future_readiness_rank,
            "Physical_Capital": data.honduras.physical_capital_rank,
            "Technology": data.honduras.technology_rank,
            "Human_Capital": data.honduras.human_capital_rank,
            "Competitiveness": data.honduras.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.honduras.digital_infrastructure_rank,
            "Transport_Infrastructure": data.honduras.transport_infrastructure_rank,
            "Energy_Infrastructure": data.honduras.energy_infrastructure_rank,
            "Attract": data.honduras.attract_rank,
            "Grow": data.honduras.grow_rank,
            "Retain": data.honduras.retain_rank,
            "Skills": data.honduras.skills_rank,
            "Digital_Usage": data.honduras.digital_usage_rank,
            "Digital_Content_Creation": data.honduras.digital_content_creation_rank,
            "Industry_4": data.honduras.industry_4_rank,
            "Digital_Policies": data.honduras.digital_policies_rank,
            "Market_Environment": data.honduras.market_environment_rank,
            "Research_and_Development": data.honduras.research_and_development_rank,
            "Innovation": data.honduras.innovation_rank
        }
    },
    "hungary": {
        "pillars": {
            "Future_Readiness": data.hungary.future_readiness_rank,
            "Physical_Capital": data.hungary.physical_capital_rank,
            "Technology": data.hungary.technology_rank,
            "Human_Capital": data.hungary.human_capital_rank,
            "Competitiveness": data.hungary.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.hungary.digital_infrastructure_rank,
            "Transport_Infrastructure": data.hungary.transport_infrastructure_rank,
            "Energy_Infrastructure": data.hungary.energy_infrastructure_rank,
            "Attract": data.hungary.attract_rank,
            "Grow": data.hungary.grow_rank,
            "Retain": data.hungary.retain_rank,
            "Skills": data.hungary.skills_rank,
            "Digital_Usage": data.hungary.digital_usage_rank,
            "Digital_Content_Creation": data.hungary.digital_content_creation_rank,
            "Industry_4": data.hungary.industry_4_rank,
            "Digital_Policies": data.hungary.digital_policies_rank,
            "Market_Environment": data.hungary.market_environment_rank,
            "Research_and_Development": data.hungary.research_and_development_rank,
            "Innovation": data.hungary.innovation_rank
        }
    },
    "iceland": {
        "pillars": {
            "Future_Readiness": data.iceland.future_readiness_rank,
            "Physical_Capital": data.iceland.physical_capital_rank,
            "Technology": data.iceland.technology_rank,
            "Human_Capital": data.iceland.human_capital_rank,
            "Competitiveness": data.iceland.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.iceland.digital_infrastructure_rank,
            "Transport_Infrastructure": data.iceland.transport_infrastructure_rank,
            "Energy_Infrastructure": data.iceland.energy_infrastructure_rank,
            "Attract": data.iceland.attract_rank,
            "Grow": data.iceland.grow_rank,
            "Retain": data.iceland.retain_rank,
            "Skills": data.iceland.skills_rank,
            "Digital_Usage": data.iceland.digital_usage_rank,
            "Digital_Content_Creation": data.iceland.digital_content_creation_rank,
            "Industry_4": data.iceland.industry_4_rank,
            "Digital_Policies": data.iceland.digital_policies_rank,
            "Market_Environment": data.iceland.market_environment_rank,
            "Research_and_Development": data.iceland.research_and_development_rank,
            "Innovation": data.iceland.innovation_rank
        }
    },
    "india": {
        "pillars": {
            "Future_Readiness": data.india.future_readiness_rank,
            "Physical_Capital": data.india.physical_capital_rank,
            "Technology": data.india.technology_rank,
            "Human_Capital": data.india.human_capital_rank,
            "Competitiveness": data.india.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.india.digital_infrastructure_rank,
            "Transport_Infrastructure": data.india.transport_infrastructure_rank,
            "Energy_Infrastructure": data.india.energy_infrastructure_rank,
            "Attract": data.india.attract_rank,
            "Grow": data.india.grow_rank,
            "Retain": data.india.retain_rank,
            "Skills": data.india.skills_rank,
            "Digital_Usage": data.india.digital_usage_rank,
            "Digital_Content_Creation": data.india.digital_content_creation_rank,
            "Industry_4": data.india.industry_4_rank,
            "Digital_Policies": data.india.digital_policies_rank,
            "Market_Environment": data.india.market_environment_rank,
            "Research_and_Development": data.india.research_and_development_rank,
            "Innovation": data.india.innovation_rank
        }
    },
    "indonesia": {
        "pillars": {
            "Future_Readiness": data.indonesia.future_readiness_rank,
            "Physical_Capital": data.indonesia.physical_capital_rank,
            "Technology": data.indonesia.technology_rank,
            "Human_Capital": data.indonesia.human_capital_rank,
            "Competitiveness": data.indonesia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.indonesia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.indonesia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.indonesia.energy_infrastructure_rank,
            "Attract": data.indonesia.attract_rank,
            "Grow": data.indonesia.grow_rank,
            "Retain": data.indonesia.retain_rank,
            "Skills": data.indonesia.skills_rank,
            "Digital_Usage": data.indonesia.digital_usage_rank,
            "Digital_Content_Creation": data.indonesia.digital_content_creation_rank,
            "Industry_4": data.indonesia.industry_4_rank,
            "Digital_Policies": data.indonesia.digital_policies_rank,
            "Market_Environment": data.indonesia.market_environment_rank,
            "Research_and_Development": data.indonesia.research_and_development_rank,
            "Innovation": data.indonesia.innovation_rank
        }
    },
    "iran": {
        "pillars": {
            "Future_Readiness": data.iran.future_readiness_rank,
            "Physical_Capital": data.iran.physical_capital_rank,
            "Technology": data.iran.technology_rank,
            "Human_Capital": data.iran.human_capital_rank,
            "Competitiveness": data.iran.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.iran.digital_infrastructure_rank,
            "Transport_Infrastructure": data.iran.transport_infrastructure_rank,
            "Energy_Infrastructure": data.iran.energy_infrastructure_rank,
            "Attract": data.iran.attract_rank,
            "Grow": data.iran.grow_rank,
            "Retain": data.iran.retain_rank,
            "Skills": data.iran.skills_rank,
            "Digital_Usage": data.iran.digital_usage_rank,
            "Digital_Content_Creation": data.iran.digital_content_creation_rank,
            "Industry_4": data.iran.industry_4_rank,
            "Digital_Policies": data.iran.digital_policies_rank,
            "Market_Environment": data.iran.market_environment_rank,
            "Research_and_Development": data.iran.research_and_development_rank,
            "Innovation": data.iran.innovation_rank
        }
    },
    "ireland": {
        "pillars": {
            "Future_Readiness": data.ireland.future_readiness_rank,
            "Physical_Capital": data.ireland.physical_capital_rank,
            "Technology": data.ireland.technology_rank,
            "Human_Capital": data.ireland.human_capital_rank,
            "Competitiveness": data.ireland.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.ireland.digital_infrastructure_rank,
            "Transport_Infrastructure": data.ireland.transport_infrastructure_rank,
            "Energy_Infrastructure": data.ireland.energy_infrastructure_rank,
            "Attract": data.ireland.attract_rank,
            "Grow": data.ireland.grow_rank,
            "Retain": data.ireland.retain_rank,
            "Skills": data.ireland.skills_rank,
            "Digital_Usage": data.ireland.digital_usage_rank,
            "Digital_Content_Creation": data.ireland.digital_content_creation_rank,
            "Industry_4": data.ireland.industry_4_rank,
            "Digital_Policies": data.ireland.digital_policies_rank,
            "Market_Environment": data.ireland.market_environment_rank,
            "Research_and_Development": data.ireland.research_and_development_rank,
            "Innovation": data.ireland.innovation_rank
        }
    },
    "israel": {
        "pillars": {
            "Future_Readiness": data.israel.future_readiness_rank,
            "Physical_Capital": data.israel.physical_capital_rank,
            "Technology": data.israel.technology_rank,
            "Human_Capital": data.israel.human_capital_rank,
            "Competitiveness": data.israel.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.israel.digital_infrastructure_rank,
            "Transport_Infrastructure": data.israel.transport_infrastructure_rank,
            "Energy_Infrastructure": data.israel.energy_infrastructure_rank,
            "Attract": data.israel.attract_rank,
            "Grow": data.israel.grow_rank,
            "Retain": data.israel.retain_rank,
            "Skills": data.israel.skills_rank,
            "Digital_Usage": data.israel.digital_usage_rank,
            "Digital_Content_Creation": data.israel.digital_content_creation_rank,
            "Industry_4": data.israel.industry_4_rank,
            "Digital_Policies": data.israel.digital_policies_rank,
            "Market_Environment": data.israel.market_environment_rank,
            "Research_and_Development": data.israel.research_and_development_rank,
            "Innovation": data.israel.innovation_rank
        }
    },
    "italy": {
        "pillars": {
            "Future_Readiness": data.italy.future_readiness_rank,
            "Physical_Capital": data.italy.physical_capital_rank,
            "Technology": data.italy.technology_rank,
            "Human_Capital": data.italy.human_capital_rank,
            "Competitiveness": data.italy.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.italy.digital_infrastructure_rank,
            "Transport_Infrastructure": data.italy.transport_infrastructure_rank,
            "Energy_Infrastructure": data.italy.energy_infrastructure_rank,
            "Attract": data.italy.attract_rank,
            "Grow": data.italy.grow_rank,
            "Retain": data.italy.retain_rank,
            "Skills": data.italy.skills_rank,
            "Digital_Usage": data.italy.digital_usage_rank,
            "Digital_Content_Creation": data.italy.digital_content_creation_rank,
            "Industry_4": data.italy.industry_4_rank,
            "Digital_Policies": data.italy.digital_policies_rank,
            "Market_Environment": data.italy.market_environment_rank,
            "Research_and_Development": data.italy.research_and_development_rank,
            "Innovation": data.italy.innovation_rank
        }
    },
    "jamaica": {
        "pillars": {
            "Future_Readiness": data.jamaica.future_readiness_rank,
            "Physical_Capital": data.jamaica.physical_capital_rank,
            "Technology": data.jamaica.technology_rank,
            "Human_Capital": data.jamaica.human_capital_rank,
            "Competitiveness": data.jamaica.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.jamaica.digital_infrastructure_rank,
            "Transport_Infrastructure": data.jamaica.transport_infrastructure_rank,
            "Energy_Infrastructure": data.jamaica.energy_infrastructure_rank,
            "Attract": data.jamaica.attract_rank,
            "Grow": data.jamaica.grow_rank,
            "Retain": data.jamaica.retain_rank,
            "Skills": data.jamaica.skills_rank,
            "Digital_Usage": data.jamaica.digital_usage_rank,
            "Digital_Content_Creation": data.jamaica.digital_content_creation_rank,
            "Industry_4": data.jamaica.industry_4_rank,
            "Digital_Policies": data.jamaica.digital_policies_rank,
            "Market_Environment": data.jamaica.market_environment_rank,
            "Research_and_Development": data.jamaica.research_and_development_rank,
            "Innovation": data.jamaica.innovation_rank
        }
    },
    "japan": {
        "pillars": {
            "Future_Readiness": data.japan.future_readiness_rank,
            "Physical_Capital": data.japan.physical_capital_rank,
            "Technology": data.japan.technology_rank,
            "Human_Capital": data.japan.human_capital_rank,
            "Competitiveness": data.japan.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.japan.digital_infrastructure_rank,
            "Transport_Infrastructure": data.japan.transport_infrastructure_rank,
            "Energy_Infrastructure": data.japan.energy_infrastructure_rank,
            "Attract": data.japan.attract_rank,
            "Grow": data.japan.grow_rank,
            "Retain": data.japan.retain_rank,
            "Skills": data.japan.skills_rank,
            "Digital_Usage": data.japan.digital_usage_rank,
            "Digital_Content_Creation": data.japan.digital_content_creation_rank,
            "Industry_4": data.japan.industry_4_rank,
            "Digital_Policies": data.japan.digital_policies_rank,
            "Market_Environment": data.japan.market_environment_rank,
            "Research_and_Development": data.japan.research_and_development_rank,
            "Innovation": data.japan.innovation_rank
        }
    },
    "jordan": {
        "pillars": {
            "Future_Readiness": data.jordan.future_readiness_rank,
            "Physical_Capital": data.jordan.physical_capital_rank,
            "Technology": data.jordan.technology_rank,
            "Human_Capital": data.jordan.human_capital_rank,
            "Competitiveness": data.jordan.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.jordan.digital_infrastructure_rank,
            "Transport_Infrastructure": data.jordan.transport_infrastructure_rank,
            "Energy_Infrastructure": data.jordan.energy_infrastructure_rank,
            "Attract": data.jordan.attract_rank,
            "Grow": data.jordan.grow_rank,
            "Retain": data.jordan.retain_rank,
            "Skills": data.jordan.skills_rank,
            "Digital_Usage": data.jordan.digital_usage_rank,
            "Digital_Content_Creation": data.jordan.digital_content_creation_rank,
            "Industry_4": data.jordan.industry_4_rank,
            "Digital_Policies": data.jordan.digital_policies_rank,
            "Market_Environment": data.jordan.market_environment_rank,
            "Research_and_Development": data.jordan.research_and_development_rank,
            "Innovation": data.jordan.innovation_rank
        }
    },
    "kazakhstan": {
        "pillars": {
            "Future_Readiness": data.kazakhstan.future_readiness_rank,
            "Physical_Capital": data.kazakhstan.physical_capital_rank,
            "Technology": data.kazakhstan.technology_rank,
            "Human_Capital": data.kazakhstan.human_capital_rank,
            "Competitiveness": data.kazakhstan.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.kazakhstan.digital_infrastructure_rank,
            "Transport_Infrastructure": data.kazakhstan.transport_infrastructure_rank,
            "Energy_Infrastructure": data.kazakhstan.energy_infrastructure_rank,
            "Attract": data.kazakhstan.attract_rank,
            "Grow": data.kazakhstan.grow_rank,
            "Retain": data.kazakhstan.retain_rank,
            "Skills": data.kazakhstan.skills_rank,
            "Digital_Usage": data.kazakhstan.digital_usage_rank,
            "Digital_Content_Creation": data.kazakhstan.digital_content_creation_rank,
            "Industry_4": data.kazakhstan.industry_4_rank,
            "Digital_Policies": data.kazakhstan.digital_policies_rank,
            "Market_Environment": data.kazakhstan.market_environment_rank,
            "Research_and_Development": data.kazakhstan.research_and_development_rank,
            "Innovation": data.kazakhstan.innovation_rank
        }
    },
    "kenya": {
        "pillars": {
            "Future_Readiness": data.kenya.future_readiness_rank,
            "Physical_Capital": data.kenya.physical_capital_rank,
            "Technology": data.kenya.technology_rank,
            "Human_Capital": data.kenya.human_capital_rank,
            "Competitiveness": data.kenya.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.kenya.digital_infrastructure_rank,
            "Transport_Infrastructure": data.kenya.transport_infrastructure_rank,
            "Energy_Infrastructure": data.kenya.energy_infrastructure_rank,
            "Attract": data.kenya.attract_rank,
            "Grow": data.kenya.grow_rank,
            "Retain": data.kenya.retain_rank,
            "Skills": data.kenya.skills_rank,
            "Digital_Usage": data.kenya.digital_usage_rank,
            "Digital_Content_Creation": data.kenya.digital_content_creation_rank,
            "Industry_4": data.kenya.industry_4_rank,
            "Digital_Policies": data.kenya.digital_policies_rank,
            "Market_Environment": data.kenya.market_environment_rank,
            "Research_and_Development": data.kenya.research_and_development_rank,
            "Innovation": data.kenya.innovation_rank
        }
    },
    "kuwait": {
        "pillars": {
            "Future_Readiness": data.kuwait.future_readiness_rank,
            "Physical_Capital": data.kuwait.physical_capital_rank,
            "Technology": data.kuwait.technology_rank,
            "Human_Capital": data.kuwait.human_capital_rank,
            "Competitiveness": data.kuwait.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.kuwait.digital_infrastructure_rank,
            "Transport_Infrastructure": data.kuwait.transport_infrastructure_rank,
            "Energy_Infrastructure": data.kuwait.energy_infrastructure_rank,
            "Attract": data.kuwait.attract_rank,
            "Grow": data.kuwait.grow_rank,
            "Retain": data.kuwait.retain_rank,
            "Skills": data.kuwait.skills_rank,
            "Digital_Usage": data.kuwait.digital_usage_rank,
            "Digital_Content_Creation": data.kuwait.digital_content_creation_rank,
            "Industry_4": data.kuwait.industry_4_rank,
            "Digital_Policies": data.kuwait.digital_policies_rank,
            "Market_Environment": data.kuwait.market_environment_rank,
            "Research_and_Development": data.kuwait.research_and_development_rank,
            "Innovation": data.kuwait.innovation_rank
        }
    },
    "kyrgyzstan": {
        "pillars": {
            "Future_Readiness": data.kyrgyzstan.future_readiness_rank,
            "Physical_Capital": data.kyrgyzstan.physical_capital_rank,
            "Technology": data.kyrgyzstan.technology_rank,
            "Human_Capital": data.kyrgyzstan.human_capital_rank,
            "Competitiveness": data.kyrgyzstan.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.kyrgyzstan.digital_infrastructure_rank,
            "Transport_Infrastructure": data.kyrgyzstan.transport_infrastructure_rank,
            "Energy_Infrastructure": data.kyrgyzstan.energy_infrastructure_rank,
            "Attract": data.kyrgyzstan.attract_rank,
            "Grow": data.kyrgyzstan.grow_rank,
            "Retain": data.kyrgyzstan.retain_rank,
            "Skills": data.kyrgyzstan.skills_rank,
            "Digital_Usage": data.kyrgyzstan.digital_usage_rank,
            "Digital_Content_Creation": data.kyrgyzstan.digital_content_creation_rank,
            "Industry_4": data.kyrgyzstan.industry_4_rank,
            "Digital_Policies": data.kyrgyzstan.digital_policies_rank,
            "Market_Environment": data.kyrgyzstan.market_environment_rank,
            "Research_and_Development": data.kyrgyzstan.research_and_development_rank,
            "Innovation": data.kyrgyzstan.innovation_rank
        }
    },
    "laos": {
        "pillars": {
            "Future_Readiness": data.laos.future_readiness_rank,
            "Physical_Capital": data.laos.physical_capital_rank,
            "Technology": data.laos.technology_rank,
            "Human_Capital": data.laos.human_capital_rank,
            "Competitiveness": data.laos.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.laos.digital_infrastructure_rank,
            "Transport_Infrastructure": data.laos.transport_infrastructure_rank,
            "Energy_Infrastructure": data.laos.energy_infrastructure_rank,
            "Attract": data.laos.attract_rank,
            "Grow": data.laos.grow_rank,
            "Retain": data.laos.retain_rank,
            "Skills": data.laos.skills_rank,
            "Digital_Usage": data.laos.digital_usage_rank,
            "Digital_Content_Creation": data.laos.digital_content_creation_rank,
            "Industry_4": data.laos.industry_4_rank,
            "Digital_Policies": data.laos.digital_policies_rank,
            "Market_Environment": data.laos.market_environment_rank,
            "Research_and_Development": data.laos.research_and_development_rank,
            "Innovation": data.laos.innovation_rank
        }
    },
    "latvia": {
        "pillars": {
            "Future_Readiness": data.latvia.future_readiness_rank,
            "Physical_Capital": data.latvia.physical_capital_rank,
            "Technology": data.latvia.technology_rank,
            "Human_Capital": data.latvia.human_capital_rank,
            "Competitiveness": data.latvia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.latvia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.latvia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.latvia.energy_infrastructure_rank,
            "Attract": data.latvia.attract_rank,
            "Grow": data.latvia.grow_rank,
            "Retain": data.latvia.retain_rank,
            "Skills": data.latvia.skills_rank,
            "Digital_Usage": data.latvia.digital_usage_rank,
            "Digital_Content_Creation": data.latvia.digital_content_creation_rank,
            "Industry_4": data.latvia.industry_4_rank,
            "Digital_Policies": data.latvia.digital_policies_rank,
            "Market_Environment": data.latvia.market_environment_rank,
            "Research_and_Development": data.latvia.research_and_development_rank,
            "Innovation": data.latvia.innovation_rank
        }
    },
    "lebanon": {
        "pillars": {
            "Future_Readiness": data.lebanon.future_readiness_rank,
            "Physical_Capital": data.lebanon.physical_capital_rank,
            "Technology": data.lebanon.technology_rank,
            "Human_Capital": data.lebanon.human_capital_rank,
            "Competitiveness": data.lebanon.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.lebanon.digital_infrastructure_rank,
            "Transport_Infrastructure": data.lebanon.transport_infrastructure_rank,
            "Energy_Infrastructure": data.lebanon.energy_infrastructure_rank,
            "Attract": data.lebanon.attract_rank,
            "Grow": data.lebanon.grow_rank,
            "Retain": data.lebanon.retain_rank,
            "Skills": data.lebanon.skills_rank,
            "Digital_Usage": data.lebanon.digital_usage_rank,
            "Digital_Content_Creation": data.lebanon.digital_content_creation_rank,
            "Industry_4": data.lebanon.industry_4_rank,
            "Digital_Policies": data.lebanon.digital_policies_rank,
            "Market_Environment": data.lebanon.market_environment_rank,
            "Research_and_Development": data.lebanon.research_and_development_rank,
            "Innovation": data.lebanon.innovation_rank
        }
    },
    "lithuania": {
        "pillars": {
            "Future_Readiness": data.lithuania.future_readiness_rank,
            "Physical_Capital": data.lithuania.physical_capital_rank,
            "Technology": data.lithuania.technology_rank,
            "Human_Capital": data.lithuania.human_capital_rank,
            "Competitiveness": data.lithuania.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.lithuania.digital_infrastructure_rank,
            "Transport_Infrastructure": data.lithuania.transport_infrastructure_rank,
            "Energy_Infrastructure": data.lithuania.energy_infrastructure_rank,
            "Attract": data.lithuania.attract_rank,
            "Grow": data.lithuania.grow_rank,
            "Retain": data.lithuania.retain_rank,
            "Skills": data.lithuania.skills_rank,
            "Digital_Usage": data.lithuania.digital_usage_rank,
            "Digital_Content_Creation": data.lithuania.digital_content_creation_rank,
            "Industry_4": data.lithuania.industry_4_rank,
            "Digital_Policies": data.lithuania.digital_policies_rank,
            "Market_Environment": data.lithuania.market_environment_rank,
            "Research_and_Development": data.lithuania.research_and_development_rank,
            "Innovation": data.lithuania.innovation_rank
        }
    },
    "luxembourg": {
        "pillars": {
            "Future_Readiness": data.luxembourg.future_readiness_rank,
            "Physical_Capital": data.luxembourg.physical_capital_rank,
            "Technology": data.luxembourg.technology_rank,
            "Human_Capital": data.luxembourg.human_capital_rank,
            "Competitiveness": data.luxembourg.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.luxembourg.digital_infrastructure_rank,
            "Transport_Infrastructure": data.luxembourg.transport_infrastructure_rank,
            "Energy_Infrastructure": data.luxembourg.energy_infrastructure_rank,
            "Attract": data.luxembourg.attract_rank,
            "Grow": data.luxembourg.grow_rank,
            "Retain": data.luxembourg.retain_rank,
            "Skills": data.luxembourg.skills_rank,
            "Digital_Usage": data.luxembourg.digital_usage_rank,
            "Digital_Content_Creation": data.luxembourg.digital_content_creation_rank,
            "Industry_4": data.luxembourg.industry_4_rank,
            "Digital_Policies": data.luxembourg.digital_policies_rank,
            "Market_Environment": data.luxembourg.market_environment_rank,
            "Research_and_Development": data.luxembourg.research_and_development_rank,
            "Innovation": data.luxembourg.innovation_rank
        }
    },
    "madagascar": {
        "pillars": {
            "Future_Readiness": data.madagascar.future_readiness_rank,
            "Physical_Capital": data.madagascar.physical_capital_rank,
            "Technology": data.madagascar.technology_rank,
            "Human_Capital": data.madagascar.human_capital_rank,
            "Competitiveness": data.madagascar.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.madagascar.digital_infrastructure_rank,
            "Transport_Infrastructure": data.madagascar.transport_infrastructure_rank,
            "Energy_Infrastructure": data.madagascar.energy_infrastructure_rank,
            "Attract": data.madagascar.attract_rank,
            "Grow": data.madagascar.grow_rank,
            "Retain": data.madagascar.retain_rank,
            "Skills": data.madagascar.skills_rank,
            "Digital_Usage": data.madagascar.digital_usage_rank,
            "Digital_Content_Creation": data.madagascar.digital_content_creation_rank,
            "Industry_4": data.madagascar.industry_4_rank,
            "Digital_Policies": data.madagascar.digital_policies_rank,
            "Market_Environment": data.madagascar.market_environment_rank,
            "Research_and_Development": data.madagascar.research_and_development_rank,
            "Innovation": data.madagascar.innovation_rank
        }
    },
    "malawi": {
        "pillars": {
            "Future_Readiness": data.malawi.future_readiness_rank,
            "Physical_Capital": data.malawi.physical_capital_rank,
            "Technology": data.malawi.technology_rank,
            "Human_Capital": data.malawi.human_capital_rank,
            "Competitiveness": data.malawi.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.malawi.digital_infrastructure_rank,
            "Transport_Infrastructure": data.malawi.transport_infrastructure_rank,
            "Energy_Infrastructure": data.malawi.energy_infrastructure_rank,
            "Attract": data.malawi.attract_rank,
            "Grow": data.malawi.grow_rank,
            "Retain": data.malawi.retain_rank,
            "Skills": data.malawi.skills_rank,
            "Digital_Usage": data.malawi.digital_usage_rank,
            "Digital_Content_Creation": data.malawi.digital_content_creation_rank,
            "Industry_4": data.malawi.industry_4_rank,
            "Digital_Policies": data.malawi.digital_policies_rank,
            "Market_Environment": data.malawi.market_environment_rank,
            "Research_and_Development": data.malawi.research_and_development_rank,
            "Innovation": data.malawi.innovation_rank
        }
    },
    "malaysia": {
        "pillars": {
            "Future_Readiness": data.malaysia.future_readiness_rank,
            "Physical_Capital": data.malaysia.physical_capital_rank,
            "Technology": data.malaysia.technology_rank,
            "Human_Capital": data.malaysia.human_capital_rank,
            "Competitiveness": data.malaysia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.malaysia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.malaysia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.malaysia.energy_infrastructure_rank,
            "Attract": data.malaysia.attract_rank,
            "Grow": data.malaysia.grow_rank,
            "Retain": data.malaysia.retain_rank,
            "Skills": data.malaysia.skills_rank,
            "Digital_Usage": data.malaysia.digital_usage_rank,
            "Digital_Content_Creation": data.malaysia.digital_content_creation_rank,
            "Industry_4": data.malaysia.industry_4_rank,
            "Digital_Policies": data.malaysia.digital_policies_rank,
            "Market_Environment": data.malaysia.market_environment_rank,
            "Research_and_Development": data.malaysia.research_and_development_rank,
            "Innovation": data.malaysia.innovation_rank
        }
    },
    "mali": {
        "pillars": {
            "Future_Readiness": data.mali.future_readiness_rank,
            "Physical_Capital": data.mali.physical_capital_rank,
            "Technology": data.mali.technology_rank,
            "Human_Capital": data.mali.human_capital_rank,
            "Competitiveness": data.mali.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.mali.digital_infrastructure_rank,
            "Transport_Infrastructure": data.mali.transport_infrastructure_rank,
            "Energy_Infrastructure": data.mali.energy_infrastructure_rank,
            "Attract": data.mali.attract_rank,
            "Grow": data.mali.grow_rank,
            "Retain": data.mali.retain_rank,
            "Skills": data.mali.skills_rank,
            "Digital_Usage": data.mali.digital_usage_rank,
            "Digital_Content_Creation": data.mali.digital_content_creation_rank,
            "Industry_4": data.mali.industry_4_rank,
            "Digital_Policies": data.mali.digital_policies_rank,
            "Market_Environment": data.mali.market_environment_rank,
            "Research_and_Development": data.mali.research_and_development_rank,
            "Innovation": data.mali.innovation_rank
        }
    },
    "malta": {
        "pillars": {
            "Future_Readiness": data.malta.future_readiness_rank,
            "Physical_Capital": data.malta.physical_capital_rank,
            "Technology": data.malta.technology_rank,
            "Human_Capital": data.malta.human_capital_rank,
            "Competitiveness": data.malta.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.malta.digital_infrastructure_rank,
            "Transport_Infrastructure": data.malta.transport_infrastructure_rank,
            "Energy_Infrastructure": data.malta.energy_infrastructure_rank,
            "Attract": data.malta.attract_rank,
            "Grow": data.malta.grow_rank,
            "Retain": data.malta.retain_rank,
            "Skills": data.malta.skills_rank,
            "Digital_Usage": data.malta.digital_usage_rank,
            "Digital_Content_Creation": data.malta.digital_content_creation_rank,
            "Industry_4": data.malta.industry_4_rank,
            "Digital_Policies": data.malta.digital_policies_rank,
            "Market_Environment": data.malta.market_environment_rank,
            "Research_and_Development": data.malta.research_and_development_rank,
            "Innovation": data.malta.innovation_rank
        }
    },
    "mauritius": {
        "pillars": {
            "Future_Readiness": data.mauritius.future_readiness_rank,
            "Physical_Capital": data.mauritius.physical_capital_rank,
            "Technology": data.mauritius.technology_rank,
            "Human_Capital": data.mauritius.human_capital_rank,
            "Competitiveness": data.mauritius.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.mauritius.digital_infrastructure_rank,
            "Transport_Infrastructure": data.mauritius.transport_infrastructure_rank,
            "Energy_Infrastructure": data.mauritius.energy_infrastructure_rank,
            "Attract": data.mauritius.attract_rank,
            "Grow": data.mauritius.grow_rank,
            "Retain": data.mauritius.retain_rank,
            "Skills": data.mauritius.skills_rank,
            "Digital_Usage": data.mauritius.digital_usage_rank,
            "Digital_Content_Creation": data.mauritius.digital_content_creation_rank,
            "Industry_4": data.mauritius.industry_4_rank,
            "Digital_Policies": data.mauritius.digital_policies_rank,
            "Market_Environment": data.mauritius.market_environment_rank,
            "Research_and_Development": data.mauritius.research_and_development_rank,
            "Innovation": data.mauritius.innovation_rank
        }
    },
    "mexico": {
        "pillars": {
            "Future_Readiness": data.mexico.future_readiness_rank,
            "Physical_Capital": data.mexico.physical_capital_rank,
            "Technology": data.mexico.technology_rank,
            "Human_Capital": data.mexico.human_capital_rank,
            "Competitiveness": data.mexico.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.mexico.digital_infrastructure_rank,
            "Transport_Infrastructure": data.mexico.transport_infrastructure_rank,
            "Energy_Infrastructure": data.mexico.energy_infrastructure_rank,
            "Attract": data.mexico.attract_rank,
            "Grow": data.mexico.grow_rank,
            "Retain": data.mexico.retain_rank,
            "Skills": data.mexico.skills_rank,
            "Digital_Usage": data.mexico.digital_usage_rank,
            "Digital_Content_Creation": data.mexico.digital_content_creation_rank,
            "Industry_4": data.mexico.industry_4_rank,
            "Digital_Policies": data.mexico.digital_policies_rank,
            "Market_Environment": data.mexico.market_environment_rank,
            "Research_and_Development": data.mexico.research_and_development_rank,
            "Innovation": data.mexico.innovation_rank
        }
    },
    "moldova": {
        "pillars": {
            "Future_Readiness": data.moldova.future_readiness_rank,
            "Physical_Capital": data.moldova.physical_capital_rank,
            "Technology": data.moldova.technology_rank,
            "Human_Capital": data.moldova.human_capital_rank,
            "Competitiveness": data.moldova.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.moldova.digital_infrastructure_rank,
            "Transport_Infrastructure": data.moldova.transport_infrastructure_rank,
            "Energy_Infrastructure": data.moldova.energy_infrastructure_rank,
            "Attract": data.moldova.attract_rank,
            "Grow": data.moldova.grow_rank,
            "Retain": data.moldova.retain_rank,
            "Skills": data.moldova.skills_rank,
            "Digital_Usage": data.moldova.digital_usage_rank,
            "Digital_Content_Creation": data.moldova.digital_content_creation_rank,
            "Industry_4": data.moldova.industry_4_rank,
            "Digital_Policies": data.moldova.digital_policies_rank,
            "Market_Environment": data.moldova.market_environment_rank,
            "Research_and_Development": data.moldova.research_and_development_rank,
            "Innovation": data.moldova.innovation_rank
        }
    },
    "mongolia": {
        "pillars": {
            "Future_Readiness": data.mongolia.future_readiness_rank,
            "Physical_Capital": data.mongolia.physical_capital_rank,
            "Technology": data.mongolia.technology_rank,
            "Human_Capital": data.mongolia.human_capital_rank,
            "Competitiveness": data.mongolia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.mongolia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.mongolia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.mongolia.energy_infrastructure_rank,
            "Attract": data.mongolia.attract_rank,
            "Grow": data.mongolia.grow_rank,
            "Retain": data.mongolia.retain_rank,
            "Skills": data.mongolia.skills_rank,
            "Digital_Usage": data.mongolia.digital_usage_rank,
            "Digital_Content_Creation": data.mongolia.digital_content_creation_rank,
            "Industry_4": data.mongolia.industry_4_rank,
            "Digital_Policies": data.mongolia.digital_policies_rank,
            "Market_Environment": data.mongolia.market_environment_rank,
            "Research_and_Development": data.mongolia.research_and_development_rank,
            "Innovation": data.mongolia.innovation_rank
        }
    },
    "morocco": {
        "pillars": {
            "Future_Readiness": data.morocco.future_readiness_rank,
            "Physical_Capital": data.morocco.physical_capital_rank,
            "Technology": data.morocco.technology_rank,
            "Human_Capital": data.morocco.human_capital_rank,
            "Competitiveness": data.morocco.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.morocco.digital_infrastructure_rank,
            "Transport_Infrastructure": data.morocco.transport_infrastructure_rank,
            "Energy_Infrastructure": data.morocco.energy_infrastructure_rank,
            "Attract": data.morocco.attract_rank,
            "Grow": data.morocco.grow_rank,
            "Retain": data.morocco.retain_rank,
            "Skills": data.morocco.skills_rank,
            "Digital_Usage": data.morocco.digital_usage_rank,
            "Digital_Content_Creation": data.morocco.digital_content_creation_rank,
            "Industry_4": data.morocco.industry_4_rank,
            "Digital_Policies": data.morocco.digital_policies_rank,
            "Market_Environment": data.morocco.market_environment_rank,
            "Research_and_Development": data.morocco.research_and_development_rank,
            "Innovation": data.morocco.innovation_rank
        }
    },
    "mozambique": {
        "pillars": {
            "Future_Readiness": data.mozambique.future_readiness_rank,
            "Physical_Capital": data.mozambique.physical_capital_rank,
            "Technology": data.mozambique.technology_rank,
            "Human_Capital": data.mozambique.human_capital_rank,
            "Competitiveness": data.mozambique.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.mozambique.digital_infrastructure_rank,
            "Transport_Infrastructure": data.mozambique.transport_infrastructure_rank,
            "Energy_Infrastructure": data.mozambique.energy_infrastructure_rank,
            "Attract": data.mozambique.attract_rank,
            "Grow": data.mozambique.grow_rank,
            "Retain": data.mozambique.retain_rank,
            "Skills": data.mozambique.skills_rank,
            "Digital_Usage": data.mozambique.digital_usage_rank,
            "Digital_Content_Creation": data.mozambique.digital_content_creation_rank,
            "Industry_4": data.mozambique.industry_4_rank,
            "Digital_Policies": data.mozambique.digital_policies_rank,
            "Market_Environment": data.mozambique.market_environment_rank,
            "Research_and_Development": data.mozambique.research_and_development_rank,
            "Innovation": data.mozambique.innovation_rank
        }
    },
    "myanmar": {
        "pillars": {
            "Future_Readiness": data.myanmar.future_readiness_rank,
            "Physical_Capital": data.myanmar.physical_capital_rank,
            "Technology": data.myanmar.technology_rank,
            "Human_Capital": data.myanmar.human_capital_rank,
            "Competitiveness": data.myanmar.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.myanmar.digital_infrastructure_rank,
            "Transport_Infrastructure": data.myanmar.transport_infrastructure_rank,
            "Energy_Infrastructure": data.myanmar.energy_infrastructure_rank,
            "Attract": data.myanmar.attract_rank,
            "Grow": data.myanmar.grow_rank,
            "Retain": data.myanmar.retain_rank,
            "Skills": data.myanmar.skills_rank,
            "Digital_Usage": data.myanmar.digital_usage_rank,
            "Digital_Content_Creation": data.myanmar.digital_content_creation_rank,
            "Industry_4": data.myanmar.industry_4_rank,
            "Digital_Policies": data.myanmar.digital_policies_rank,
            "Market_Environment": data.myanmar.market_environment_rank,
            "Research_and_Development": data.myanmar.research_and_development_rank,
            "Innovation": data.myanmar.innovation_rank
        }
    },
    "nepal": {
        "pillars": {
            "Future_Readiness": data.nepal.future_readiness_rank,
            "Physical_Capital": data.nepal.physical_capital_rank,
            "Technology": data.nepal.technology_rank,
            "Human_Capital": data.nepal.human_capital_rank,
            "Competitiveness": data.nepal.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.nepal.digital_infrastructure_rank,
            "Transport_Infrastructure": data.nepal.transport_infrastructure_rank,
            "Energy_Infrastructure": data.nepal.energy_infrastructure_rank,
            "Attract": data.nepal.attract_rank,
            "Grow": data.nepal.grow_rank,
            "Retain": data.nepal.retain_rank,
            "Skills": data.nepal.skills_rank,
            "Digital_Usage": data.nepal.digital_usage_rank,
            "Digital_Content_Creation": data.nepal.digital_content_creation_rank,
            "Industry_4": data.nepal.industry_4_rank,
            "Digital_Policies": data.nepal.digital_policies_rank,
            "Market_Environment": data.nepal.market_environment_rank,
            "Research_and_Development": data.nepal.research_and_development_rank,
            "Innovation": data.nepal.innovation_rank
        }
    },
    "netherlands": {
        "pillars": {
            "Future_Readiness": data.netherlands.future_readiness_rank,
            "Physical_Capital": data.netherlands.physical_capital_rank,
            "Technology": data.netherlands.technology_rank,
            "Human_Capital": data.netherlands.human_capital_rank,
            "Competitiveness": data.netherlands.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.netherlands.digital_infrastructure_rank,
            "Transport_Infrastructure": data.netherlands.transport_infrastructure_rank,
            "Energy_Infrastructure": data.netherlands.energy_infrastructure_rank,
            "Attract": data.netherlands.attract_rank,
            "Grow": data.netherlands.grow_rank,
            "Retain": data.netherlands.retain_rank,
            "Skills": data.netherlands.skills_rank,
            "Digital_Usage": data.netherlands.digital_usage_rank,
            "Digital_Content_Creation": data.netherlands.digital_content_creation_rank,
            "Industry_4": data.netherlands.industry_4_rank,
            "Digital_Policies": data.netherlands.digital_policies_rank,
            "Market_Environment": data.netherlands.market_environment_rank,
            "Research_and_Development": data.netherlands.research_and_development_rank,
            "Innovation": data.netherlands.innovation_rank
        }
    },
    "new_zealand": {
        "pillars": {
            "Future_Readiness": data.new_zealand.future_readiness_rank,
            "Physical_Capital": data.new_zealand.physical_capital_rank,
            "Technology": data.new_zealand.technology_rank,
            "Human_Capital": data.new_zealand.human_capital_rank,
            "Competitiveness": data.new_zealand.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.new_zealand.digital_infrastructure_rank,
            "Transport_Infrastructure": data.new_zealand.transport_infrastructure_rank,
            "Energy_Infrastructure": data.new_zealand.energy_infrastructure_rank,
            "Attract": data.new_zealand.attract_rank,
            "Grow": data.new_zealand.grow_rank,
            "Retain": data.new_zealand.retain_rank,
            "Skills": data.new_zealand.skills_rank,
            "Digital_Usage": data.new_zealand.digital_usage_rank,
            "Digital_Content_Creation": data.new_zealand.digital_content_creation_rank,
            "Industry_4": data.new_zealand.industry_4_rank,
            "Digital_Policies": data.new_zealand.digital_policies_rank,
            "Market_Environment": data.new_zealand.market_environment_rank,
            "Research_and_Development": data.new_zealand.research_and_development_rank,
            "Innovation": data.new_zealand.innovation_rank
        }
    },
    "niger": {
        "pillars": {
            "Future_Readiness": data.niger.future_readiness_rank,
            "Physical_Capital": data.niger.physical_capital_rank,
            "Technology": data.niger.technology_rank,
            "Human_Capital": data.niger.human_capital_rank,
            "Competitiveness": data.niger.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.niger.digital_infrastructure_rank,
            "Transport_Infrastructure": data.niger.transport_infrastructure_rank,
            "Energy_Infrastructure": data.niger.energy_infrastructure_rank,
            "Attract": data.niger.attract_rank,
            "Grow": data.niger.grow_rank,
            "Retain": data.niger.retain_rank,
            "Skills": data.niger.skills_rank,
            "Digital_Usage": data.niger.digital_usage_rank,
            "Digital_Content_Creation": data.niger.digital_content_creation_rank,
            "Industry_4": data.niger.industry_4_rank,
            "Digital_Policies": data.niger.digital_policies_rank,
            "Market_Environment": data.niger.market_environment_rank,
            "Research_and_Development": data.niger.research_and_development_rank,
            "Innovation": data.niger.innovation_rank
        }
    },
    "nigeria": {
        "pillars": {
            "Future_Readiness": data.nigeria.future_readiness_rank,
            "Physical_Capital": data.nigeria.physical_capital_rank,
            "Technology": data.nigeria.technology_rank,
            "Human_Capital": data.nigeria.human_capital_rank,
            "Competitiveness": data.nigeria.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.nigeria.digital_infrastructure_rank,
            "Transport_Infrastructure": data.nigeria.transport_infrastructure_rank,
            "Energy_Infrastructure": data.nigeria.energy_infrastructure_rank,
            "Attract": data.nigeria.attract_rank,
            "Grow": data.nigeria.grow_rank,
            "Retain": data.nigeria.retain_rank,
            "Skills": data.nigeria.skills_rank,
            "Digital_Usage": data.nigeria.digital_usage_rank,
            "Digital_Content_Creation": data.nigeria.digital_content_creation_rank,
            "Industry_4": data.nigeria.industry_4_rank,
            "Digital_Policies": data.nigeria.digital_policies_rank,
            "Market_Environment": data.nigeria.market_environment_rank,
            "Research_and_Development": data.nigeria.research_and_development_rank,
            "Innovation": data.nigeria.innovation_rank
        }
    },
    "north_macedonia": {
        "pillars": {
            "Future_Readiness": data.north_macedonia.future_readiness_rank,
            "Physical_Capital": data.north_macedonia.physical_capital_rank,
            "Technology": data.north_macedonia.technology_rank,
            "Human_Capital": data.north_macedonia.human_capital_rank,
            "Competitiveness": data.north_macedonia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.north_macedonia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.north_macedonia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.north_macedonia.energy_infrastructure_rank,
            "Attract": data.north_macedonia.attract_rank,
            "Grow": data.north_macedonia.grow_rank,
            "Retain": data.north_macedonia.retain_rank,
            "Skills": data.north_macedonia.skills_rank,
            "Digital_Usage": data.north_macedonia.digital_usage_rank,
            "Digital_Content_Creation": data.north_macedonia.digital_content_creation_rank,
            "Industry_4": data.north_macedonia.industry_4_rank,
            "Digital_Policies": data.north_macedonia.digital_policies_rank,
            "Market_Environment": data.north_macedonia.market_environment_rank,
            "Research_and_Development": data.north_macedonia.research_and_development_rank,
            "Innovation": data.north_macedonia.innovation_rank
        }
    },
    "norway": {
        "pillars": {
            "Future_Readiness": data.norway.future_readiness_rank,
            "Physical_Capital": data.norway.physical_capital_rank,
            "Technology": data.norway.technology_rank,
            "Human_Capital": data.norway.human_capital_rank,
            "Competitiveness": data.norway.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.norway.digital_infrastructure_rank,
            "Transport_Infrastructure": data.norway.transport_infrastructure_rank,
            "Energy_Infrastructure": data.norway.energy_infrastructure_rank,
            "Attract": data.norway.attract_rank,
            "Grow": data.norway.grow_rank,
            "Retain": data.norway.retain_rank,
            "Skills": data.norway.skills_rank,
            "Digital_Usage": data.norway.digital_usage_rank,
            "Digital_Content_Creation": data.norway.digital_content_creation_rank,
            "Industry_4": data.norway.industry_4_rank,
            "Digital_Policies": data.norway.digital_policies_rank,
            "Market_Environment": data.norway.market_environment_rank,
            "Research_and_Development": data.norway.research_and_development_rank,
            "Innovation": data.norway.innovation_rank
        }
    },
    "oman": {
        "pillars": {
            "Future_Readiness": data.oman.future_readiness_rank,
            "Physical_Capital": data.oman.physical_capital_rank,
            "Technology": data.oman.technology_rank,
            "Human_Capital": data.oman.human_capital_rank,
            "Competitiveness": data.oman.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.oman.digital_infrastructure_rank,
            "Transport_Infrastructure": data.oman.transport_infrastructure_rank,
            "Energy_Infrastructure": data.oman.energy_infrastructure_rank,
            "Attract": data.oman.attract_rank,
            "Grow": data.oman.grow_rank,
            "Retain": data.oman.retain_rank,
            "Skills": data.oman.skills_rank,
            "Digital_Usage": data.oman.digital_usage_rank,
            "Digital_Content_Creation": data.oman.digital_content_creation_rank,
            "Industry_4": data.oman.industry_4_rank,
            "Digital_Policies": data.oman.digital_policies_rank,
            "Market_Environment": data.oman.market_environment_rank,
            "Research_and_Development": data.oman.research_and_development_rank,
            "Innovation": data.oman.innovation_rank
        }
    },
    "pakistan": {
        "pillars": {
            "Future_Readiness": data.pakistan.future_readiness_rank,
            "Physical_Capital": data.pakistan.physical_capital_rank,
            "Technology": data.pakistan.technology_rank,
            "Human_Capital": data.pakistan.human_capital_rank,
            "Competitiveness": data.pakistan.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.pakistan.digital_infrastructure_rank,
            "Transport_Infrastructure": data.pakistan.transport_infrastructure_rank,
            "Energy_Infrastructure": data.pakistan.energy_infrastructure_rank,
            "Attract": data.pakistan.attract_rank,
            "Grow": data.pakistan.grow_rank,
            "Retain": data.pakistan.retain_rank,
            "Skills": data.pakistan.skills_rank,
            "Digital_Usage": data.pakistan.digital_usage_rank,
            "Digital_Content_Creation": data.pakistan.digital_content_creation_rank,
            "Industry_4": data.pakistan.industry_4_rank,
            "Digital_Policies": data.pakistan.digital_policies_rank,
            "Market_Environment": data.pakistan.market_environment_rank,
            "Research_and_Development": data.pakistan.research_and_development_rank,
            "Innovation": data.pakistan.innovation_rank
        }
    },
    "panama": {
        "pillars": {
            "Future_Readiness": data.panama.future_readiness_rank,
            "Physical_Capital": data.panama.physical_capital_rank,
            "Technology": data.panama.technology_rank,
            "Human_Capital": data.panama.human_capital_rank,
            "Competitiveness": data.panama.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.panama.digital_infrastructure_rank,
            "Transport_Infrastructure": data.panama.transport_infrastructure_rank,
            "Energy_Infrastructure": data.panama.energy_infrastructure_rank,
            "Attract": data.panama.attract_rank,
            "Grow": data.panama.grow_rank,
            "Retain": data.panama.retain_rank,
            "Skills": data.panama.skills_rank,
            "Digital_Usage": data.panama.digital_usage_rank,
            "Digital_Content_Creation": data.panama.digital_content_creation_rank,
            "Industry_4": data.panama.industry_4_rank,
            "Digital_Policies": data.panama.digital_policies_rank,
            "Market_Environment": data.panama.market_environment_rank,
            "Research_and_Development": data.panama.research_and_development_rank,
            "Innovation": data.panama.innovation_rank
        }
    },
    "paraguay": {
        "pillars": {
            "Future_Readiness": data.paraguay.future_readiness_rank,
            "Physical_Capital": data.paraguay.physical_capital_rank,
            "Technology": data.paraguay.technology_rank,
            "Human_Capital": data.paraguay.human_capital_rank,
            "Competitiveness": data.paraguay.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.paraguay.digital_infrastructure_rank,
            "Transport_Infrastructure": data.paraguay.transport_infrastructure_rank,
            "Energy_Infrastructure": data.paraguay.energy_infrastructure_rank,
            "Attract": data.paraguay.attract_rank,
            "Grow": data.paraguay.grow_rank,
            "Retain": data.paraguay.retain_rank,
            "Skills": data.paraguay.skills_rank,
            "Digital_Usage": data.paraguay.digital_usage_rank,
            "Digital_Content_Creation": data.paraguay.digital_content_creation_rank,
            "Industry_4": data.paraguay.industry_4_rank,
            "Digital_Policies": data.paraguay.digital_policies_rank,
            "Market_Environment": data.paraguay.market_environment_rank,
            "Research_and_Development": data.paraguay.research_and_development_rank,
            "Innovation": data.paraguay.innovation_rank
        }
    },
    "peru": {
        "pillars": {
            "Future_Readiness": data.peru.future_readiness_rank,
            "Physical_Capital": data.peru.physical_capital_rank,
            "Technology": data.peru.technology_rank,
            "Human_Capital": data.peru.human_capital_rank,
            "Competitiveness": data.peru.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.peru.digital_infrastructure_rank,
            "Transport_Infrastructure": data.peru.transport_infrastructure_rank,
            "Energy_Infrastructure": data.peru.energy_infrastructure_rank,
            "Attract": data.peru.attract_rank,
            "Grow": data.peru.grow_rank,
            "Retain": data.peru.retain_rank,
            "Skills": data.peru.skills_rank,
            "Digital_Usage": data.peru.digital_usage_rank,
            "Digital_Content_Creation": data.peru.digital_content_creation_rank,
            "Industry_4": data.peru.industry_4_rank,
            "Digital_Policies": data.peru.digital_policies_rank,
            "Market_Environment": data.peru.market_environment_rank,
            "Research_and_Development": data.peru.research_and_development_rank,
            "Innovation": data.peru.innovation_rank
        }
    },
    "philippines": {
        "pillars": {
            "Future_Readiness": data.philippines.future_readiness_rank,
            "Physical_Capital": data.philippines.physical_capital_rank,
            "Technology": data.philippines.technology_rank,
            "Human_Capital": data.philippines.human_capital_rank,
            "Competitiveness": data.philippines.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.philippines.digital_infrastructure_rank,
            "Transport_Infrastructure": data.philippines.transport_infrastructure_rank,
            "Energy_Infrastructure": data.philippines.energy_infrastructure_rank,
            "Attract": data.philippines.attract_rank,
            "Grow": data.philippines.grow_rank,
            "Retain": data.philippines.retain_rank,
            "Skills": data.philippines.skills_rank,
            "Digital_Usage": data.philippines.digital_usage_rank,
            "Digital_Content_Creation": data.philippines.digital_content_creation_rank,
            "Industry_4": data.philippines.industry_4_rank,
            "Digital_Policies": data.philippines.digital_policies_rank,
            "Market_Environment": data.philippines.market_environment_rank,
            "Research_and_Development": data.philippines.research_and_development_rank,
            "Innovation": data.philippines.innovation_rank
        }
    },
    "poland": {
        "pillars": {
            "Future_Readiness": data.poland.future_readiness_rank,
            "Physical_Capital": data.poland.physical_capital_rank,
            "Technology": data.poland.technology_rank,
            "Human_Capital": data.poland.human_capital_rank,
            "Competitiveness": data.poland.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.poland.digital_infrastructure_rank,
            "Transport_Infrastructure": data.poland.transport_infrastructure_rank,
            "Energy_Infrastructure": data.poland.energy_infrastructure_rank,
            "Attract": data.poland.attract_rank,
            "Grow": data.poland.grow_rank,
            "Retain": data.poland.retain_rank,
            "Skills": data.poland.skills_rank,
            "Digital_Usage": data.poland.digital_usage_rank,
            "Digital_Content_Creation": data.poland.digital_content_creation_rank,
            "Industry_4": data.poland.industry_4_rank,
            "Digital_Policies": data.poland.digital_policies_rank,
            "Market_Environment": data.poland.market_environment_rank,
            "Research_and_Development": data.poland.research_and_development_rank,
            "Innovation": data.poland.innovation_rank
        }
    },
    "portugal": {
        "pillars": {
            "Future_Readiness": data.portugal.future_readiness_rank,
            "Physical_Capital": data.portugal.physical_capital_rank,
            "Technology": data.portugal.technology_rank,
            "Human_Capital": data.portugal.human_capital_rank,
            "Competitiveness": data.portugal.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.portugal.digital_infrastructure_rank,
            "Transport_Infrastructure": data.portugal.transport_infrastructure_rank,
            "Energy_Infrastructure": data.portugal.energy_infrastructure_rank,
            "Attract": data.portugal.attract_rank,
            "Grow": data.portugal.grow_rank,
            "Retain": data.portugal.retain_rank,
            "Skills": data.portugal.skills_rank,
            "Digital_Usage": data.portugal.digital_usage_rank,
            "Digital_Content_Creation": data.portugal.digital_content_creation_rank,
            "Industry_4": data.portugal.industry_4_rank,
            "Digital_Policies": data.portugal.digital_policies_rank,
            "Market_Environment": data.portugal.market_environment_rank,
            "Research_and_Development": data.portugal.research_and_development_rank,
            "Innovation": data.portugal.innovation_rank
        }
    },
    "qatar": {
        "pillars": {
            "Future_Readiness": data.qatar.future_readiness_rank,
            "Physical_Capital": data.qatar.physical_capital_rank,
            "Technology": data.qatar.technology_rank,
            "Human_Capital": data.qatar.human_capital_rank,
            "Competitiveness": data.qatar.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.qatar.digital_infrastructure_rank,
            "Transport_Infrastructure": data.qatar.transport_infrastructure_rank,
            "Energy_Infrastructure": data.qatar.energy_infrastructure_rank,
            "Attract": data.qatar.attract_rank,
            "Grow": data.qatar.grow_rank,
            "Retain": data.qatar.retain_rank,
            "Skills": data.qatar.skills_rank,
            "Digital_Usage": data.qatar.digital_usage_rank,
            "Digital_Content_Creation": data.qatar.digital_content_creation_rank,
            "Industry_4": data.qatar.industry_4_rank,
            "Digital_Policies": data.qatar.digital_policies_rank,
            "Market_Environment": data.qatar.market_environment_rank,
            "Research_and_Development": data.qatar.research_and_development_rank,
            "Innovation": data.qatar.innovation_rank
        }
    },
    "romania": {
        "pillars": {
            "Future_Readiness": data.romania.future_readiness_rank,
            "Physical_Capital": data.romania.physical_capital_rank,
            "Technology": data.romania.technology_rank,
            "Human_Capital": data.romania.human_capital_rank,
            "Competitiveness": data.romania.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.romania.digital_infrastructure_rank,
            "Transport_Infrastructure": data.romania.transport_infrastructure_rank,
            "Energy_Infrastructure": data.romania.energy_infrastructure_rank,
            "Attract": data.romania.attract_rank,
            "Grow": data.romania.grow_rank,
            "Retain": data.romania.retain_rank,
            "Skills": data.romania.skills_rank,
            "Digital_Usage": data.romania.digital_usage_rank,
            "Digital_Content_Creation": data.romania.digital_content_creation_rank,
            "Industry_4": data.romania.industry_4_rank,
            "Digital_Policies": data.romania.digital_policies_rank,
            "Market_Environment": data.romania.market_environment_rank,
            "Research_and_Development": data.romania.research_and_development_rank,
            "Innovation": data.romania.innovation_rank
        }
    },
    "russia": {
        "pillars": {
            "Future_Readiness": data.russia.future_readiness_rank,
            "Physical_Capital": data.russia.physical_capital_rank,
            "Technology": data.russia.technology_rank,
            "Human_Capital": data.russia.human_capital_rank,
            "Competitiveness": data.russia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.russia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.russia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.russia.energy_infrastructure_rank,
            "Attract": data.russia.attract_rank,
            "Grow": data.russia.grow_rank,
            "Retain": data.russia.retain_rank,
            "Skills": data.russia.skills_rank,
            "Digital_Usage": data.russia.digital_usage_rank,
            "Digital_Content_Creation": data.russia.digital_content_creation_rank,
            "Industry_4": data.russia.industry_4_rank,
            "Digital_Policies": data.russia.digital_policies_rank,
            "Market_Environment": data.russia.market_environment_rank,
            "Research_and_Development": data.russia.research_and_development_rank,
            "Innovation": data.russia.innovation_rank
        }
    },
    "rwanda": {
        "pillars": {
            "Future_Readiness": data.rwanda.future_readiness_rank,
            "Physical_Capital": data.rwanda.physical_capital_rank,
            "Technology": data.rwanda.technology_rank,
            "Human_Capital": data.rwanda.human_capital_rank,
            "Competitiveness": data.rwanda.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.rwanda.digital_infrastructure_rank,
            "Transport_Infrastructure": data.rwanda.transport_infrastructure_rank,
            "Energy_Infrastructure": data.rwanda.energy_infrastructure_rank,
            "Attract": data.rwanda.attract_rank,
            "Grow": data.rwanda.grow_rank,
            "Retain": data.rwanda.retain_rank,
            "Skills": data.rwanda.skills_rank,
            "Digital_Usage": data.rwanda.digital_usage_rank,
            "Digital_Content_Creation": data.rwanda.digital_content_creation_rank,
            "Industry_4": data.rwanda.industry_4_rank,
            "Digital_Policies": data.rwanda.digital_policies_rank,
            "Market_Environment": data.rwanda.market_environment_rank,
            "Research_and_Development": data.rwanda.research_and_development_rank,
            "Innovation": data.rwanda.innovation_rank
        }
    },
    "saudi_arabia": {
        "pillars": {
            "Future_Readiness": data.saudi_arabia.future_readiness_rank,
            "Physical_Capital": data.saudi_arabia.physical_capital_rank,
            "Technology": data.saudi_arabia.technology_rank,
            "Human_Capital": data.saudi_arabia.human_capital_rank,
            "Competitiveness": data.saudi_arabia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.saudi_arabia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.saudi_arabia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.saudi_arabia.energy_infrastructure_rank,
            "Attract": data.saudi_arabia.attract_rank,
            "Grow": data.saudi_arabia.grow_rank,
            "Retain": data.saudi_arabia.retain_rank,
            "Skills": data.saudi_arabia.skills_rank,
            "Digital_Usage": data.saudi_arabia.digital_usage_rank,
            "Digital_Content_Creation": data.saudi_arabia.digital_content_creation_rank,
            "Industry_4": data.saudi_arabia.industry_4_rank,
            "Digital_Policies": data.saudi_arabia.digital_policies_rank,
            "Market_Environment": data.saudi_arabia.market_environment_rank,
            "Research_and_Development": data.saudi_arabia.research_and_development_rank,
            "Innovation": data.saudi_arabia.innovation_rank
        }
    },
    "senegal": {
        "pillars": {
            "Future_Readiness": data.senegal.future_readiness_rank,
            "Physical_Capital": data.senegal.physical_capital_rank,
            "Technology": data.senegal.technology_rank,
            "Human_Capital": data.senegal.human_capital_rank,
            "Competitiveness": data.senegal.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.senegal.digital_infrastructure_rank,
            "Transport_Infrastructure": data.senegal.transport_infrastructure_rank,
            "Energy_Infrastructure": data.senegal.energy_infrastructure_rank,
            "Attract": data.senegal.attract_rank,
            "Grow": data.senegal.grow_rank,
            "Retain": data.senegal.retain_rank,
            "Skills": data.senegal.skills_rank,
            "Digital_Usage": data.senegal.digital_usage_rank,
            "Digital_Content_Creation": data.senegal.digital_content_creation_rank,
            "Industry_4": data.senegal.industry_4_rank,
            "Digital_Policies": data.senegal.digital_policies_rank,
            "Market_Environment": data.senegal.market_environment_rank,
            "Research_and_Development": data.senegal.research_and_development_rank,
            "Innovation": data.senegal.innovation_rank
        }
    },
    "serbia": {
        "pillars": {
            "Future_Readiness": data.serbia.future_readiness_rank,
            "Physical_Capital": data.serbia.physical_capital_rank,
            "Technology": data.serbia.technology_rank,
            "Human_Capital": data.serbia.human_capital_rank,
            "Competitiveness": data.serbia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.serbia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.serbia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.serbia.energy_infrastructure_rank,
            "Attract": data.serbia.attract_rank,
            "Grow": data.serbia.grow_rank,
            "Retain": data.serbia.retain_rank,
            "Skills": data.serbia.skills_rank,
            "Digital_Usage": data.serbia.digital_usage_rank,
            "Digital_Content_Creation": data.serbia.digital_content_creation_rank,
            "Industry_4": data.serbia.industry_4_rank,
            "Digital_Policies": data.serbia.digital_policies_rank,
            "Market_Environment": data.serbia.market_environment_rank,
            "Research_and_Development": data.serbia.research_and_development_rank,
            "Innovation": data.serbia.innovation_rank
        }
    },
    "singapore": {
        "pillars": {
            "Future_Readiness": data.singapore.future_readiness_rank,
            "Physical_Capital": data.singapore.physical_capital_rank,
            "Technology": data.singapore.technology_rank,
            "Human_Capital": data.singapore.human_capital_rank,
            "Competitiveness": data.singapore.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.singapore.digital_infrastructure_rank,
            "Transport_Infrastructure": data.singapore.transport_infrastructure_rank,
            "Energy_Infrastructure": data.singapore.energy_infrastructure_rank,
            "Attract": data.singapore.attract_rank,
            "Grow": data.singapore.grow_rank,
            "Retain": data.singapore.retain_rank,
            "Skills": data.singapore.skills_rank,
            "Digital_Usage": data.singapore.digital_usage_rank,
            "Digital_Content_Creation": data.singapore.digital_content_creation_rank,
            "Industry_4": data.singapore.industry_4_rank,
            "Digital_Policies": data.singapore.digital_policies_rank,
            "Market_Environment": data.singapore.market_environment_rank,
            "Research_and_Development": data.singapore.research_and_development_rank,
            "Innovation": data.singapore.innovation_rank
        }
    },
    "slovakia": {
        "pillars": {
            "Future_Readiness": data.slovakia.future_readiness_rank,
            "Physical_Capital": data.slovakia.physical_capital_rank,
            "Technology": data.slovakia.technology_rank,
            "Human_Capital": data.slovakia.human_capital_rank,
            "Competitiveness": data.slovakia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.slovakia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.slovakia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.slovakia.energy_infrastructure_rank,
            "Attract": data.slovakia.attract_rank,
            "Grow": data.slovakia.grow_rank,
            "Retain": data.slovakia.retain_rank,
            "Skills": data.slovakia.skills_rank,
            "Digital_Usage": data.slovakia.digital_usage_rank,
            "Digital_Content_Creation": data.slovakia.digital_content_creation_rank,
            "Industry_4": data.slovakia.industry_4_rank,
            "Digital_Policies": data.slovakia.digital_policies_rank,
            "Market_Environment": data.slovakia.market_environment_rank,
            "Research_and_Development": data.slovakia.research_and_development_rank,
            "Innovation": data.slovakia.innovation_rank
        }
    },
    "slovenia": {
        "pillars": {
            "Future_Readiness": data.slovenia.future_readiness_rank,
            "Physical_Capital": data.slovenia.physical_capital_rank,
            "Technology": data.slovenia.technology_rank,
            "Human_Capital": data.slovenia.human_capital_rank,
            "Competitiveness": data.slovenia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.slovenia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.slovenia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.slovenia.energy_infrastructure_rank,
            "Attract": data.slovenia.attract_rank,
            "Grow": data.slovenia.grow_rank,
            "Retain": data.slovenia.retain_rank,
            "Skills": data.slovenia.skills_rank,
            "Digital_Usage": data.slovenia.digital_usage_rank,
            "Digital_Content_Creation": data.slovenia.digital_content_creation_rank,
            "Industry_4": data.slovenia.industry_4_rank,
            "Digital_Policies": data.slovenia.digital_policies_rank,
            "Market_Environment": data.slovenia.market_environment_rank,
            "Research_and_Development": data.slovenia.research_and_development_rank,
            "Innovation": data.slovenia.innovation_rank
        }
    },
    "south_africa": {
        "pillars": {
            "Future_Readiness": data.south_africa.future_readiness_rank,
            "Physical_Capital": data.south_africa.physical_capital_rank,
            "Technology": data.south_africa.technology_rank,
            "Human_Capital": data.south_africa.human_capital_rank,
            "Competitiveness": data.south_africa.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.south_africa.digital_infrastructure_rank,
            "Transport_Infrastructure": data.south_africa.transport_infrastructure_rank,
            "Energy_Infrastructure": data.south_africa.energy_infrastructure_rank,
            "Attract": data.south_africa.attract_rank,
            "Grow": data.south_africa.grow_rank,
            "Retain": data.south_africa.retain_rank,
            "Skills": data.south_africa.skills_rank,
            "Digital_Usage": data.south_africa.digital_usage_rank,
            "Digital_Content_Creation": data.south_africa.digital_content_creation_rank,
            "Industry_4": data.south_africa.industry_4_rank,
            "Digital_Policies": data.south_africa.digital_policies_rank,
            "Market_Environment": data.south_africa.market_environment_rank,
            "Research_and_Development": data.south_africa.research_and_development_rank,
            "Innovation": data.south_africa.innovation_rank
        }
    },
    "south_korea": {
        "pillars": {
            "Future_Readiness": data.south_korea.future_readiness_rank,
            "Physical_Capital": data.south_korea.physical_capital_rank,
            "Technology": data.south_korea.technology_rank,
            "Human_Capital": data.south_korea.human_capital_rank,
            "Competitiveness": data.south_korea.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.south_korea.digital_infrastructure_rank,
            "Transport_Infrastructure": data.south_korea.transport_infrastructure_rank,
            "Energy_Infrastructure": data.south_korea.energy_infrastructure_rank,
            "Attract": data.south_korea.attract_rank,
            "Grow": data.south_korea.grow_rank,
            "Retain": data.south_korea.retain_rank,
            "Skills": data.south_korea.skills_rank,
            "Digital_Usage": data.south_korea.digital_usage_rank,
            "Digital_Content_Creation": data.south_korea.digital_content_creation_rank,
            "Industry_4": data.south_korea.industry_4_rank,
            "Digital_Policies": data.south_korea.digital_policies_rank,
            "Market_Environment": data.south_korea.market_environment_rank,
            "Research_and_Development": data.south_korea.research_and_development_rank,
            "Innovation": data.south_korea.innovation_rank
        }
    },
    "spain": {
        "pillars": {
            "Future_Readiness": data.spain.future_readiness_rank,
            "Physical_Capital": data.spain.physical_capital_rank,
            "Technology": data.spain.technology_rank,
            "Human_Capital": data.spain.human_capital_rank,
            "Competitiveness": data.spain.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.spain.digital_infrastructure_rank,
            "Transport_Infrastructure": data.spain.transport_infrastructure_rank,
            "Energy_Infrastructure": data.spain.energy_infrastructure_rank,
            "Attract": data.spain.attract_rank,
            "Grow": data.spain.grow_rank,
            "Retain": data.spain.retain_rank,
            "Skills": data.spain.skills_rank,
            "Digital_Usage": data.spain.digital_usage_rank,
            "Digital_Content_Creation": data.spain.digital_content_creation_rank,
            "Industry_4": data.spain.industry_4_rank,
            "Digital_Policies": data.spain.digital_policies_rank,
            "Market_Environment": data.spain.market_environment_rank,
            "Research_and_Development": data.spain.research_and_development_rank,
            "Innovation": data.spain.innovation_rank
        }
    },
    "sri_lanka": {
        "pillars": {
            "Future_Readiness": data.sri_lanka.future_readiness_rank,
            "Physical_Capital": data.sri_lanka.physical_capital_rank,
            "Technology": data.sri_lanka.technology_rank,
            "Human_Capital": data.sri_lanka.human_capital_rank,
            "Competitiveness": data.sri_lanka.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.sri_lanka.digital_infrastructure_rank,
            "Transport_Infrastructure": data.sri_lanka.transport_infrastructure_rank,
            "Energy_Infrastructure": data.sri_lanka.energy_infrastructure_rank,
            "Attract": data.sri_lanka.attract_rank,
            "Grow": data.sri_lanka.grow_rank,
            "Retain": data.sri_lanka.retain_rank,
            "Skills": data.sri_lanka.skills_rank,
            "Digital_Usage": data.sri_lanka.digital_usage_rank,
            "Digital_Content_Creation": data.sri_lanka.digital_content_creation_rank,
            "Industry_4": data.sri_lanka.industry_4_rank,
            "Digital_Policies": data.sri_lanka.digital_policies_rank,
            "Market_Environment": data.sri_lanka.market_environment_rank,
            "Research_and_Development": data.sri_lanka.research_and_development_rank,
            "Innovation": data.sri_lanka.innovation_rank
        }
    },
    "sweden": {
        "pillars": {
            "Future_Readiness": data.sweden.future_readiness_rank,
            "Physical_Capital": data.sweden.physical_capital_rank,
            "Technology": data.sweden.technology_rank,
            "Human_Capital": data.sweden.human_capital_rank,
            "Competitiveness": data.sweden.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.sweden.digital_infrastructure_rank,
            "Transport_Infrastructure": data.sweden.transport_infrastructure_rank,
            "Energy_Infrastructure": data.sweden.energy_infrastructure_rank,
            "Attract": data.sweden.attract_rank,
            "Grow": data.sweden.grow_rank,
            "Retain": data.sweden.retain_rank,
            "Skills": data.sweden.skills_rank,
            "Digital_Usage": data.sweden.digital_usage_rank,
            "Digital_Content_Creation": data.sweden.digital_content_creation_rank,
            "Industry_4": data.sweden.industry_4_rank,
            "Digital_Policies": data.sweden.digital_policies_rank,
            "Market_Environment": data.sweden.market_environment_rank,
            "Research_and_Development": data.sweden.research_and_development_rank,
            "Innovation": data.sweden.innovation_rank
        }
    },
    "switzerland": {
        "pillars": {
            "Future_Readiness": data.switzerland.future_readiness_rank,
            "Physical_Capital": data.switzerland.physical_capital_rank,
            "Technology": data.switzerland.technology_rank,
            "Human_Capital": data.switzerland.human_capital_rank,
            "Competitiveness": data.switzerland.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.switzerland.digital_infrastructure_rank,
            "Transport_Infrastructure": data.switzerland.transport_infrastructure_rank,
            "Energy_Infrastructure": data.switzerland.energy_infrastructure_rank,
            "Attract": data.switzerland.attract_rank,
            "Grow": data.switzerland.grow_rank,
            "Retain": data.switzerland.retain_rank,
            "Skills": data.switzerland.skills_rank,
            "Digital_Usage": data.switzerland.digital_usage_rank,
            "Digital_Content_Creation": data.switzerland.digital_content_creation_rank,
            "Industry_4": data.switzerland.industry_4_rank,
            "Digital_Policies": data.switzerland.digital_policies_rank,
            "Market_Environment": data.switzerland.market_environment_rank,
            "Research_and_Development": data.switzerland.research_and_development_rank,
            "Innovation": data.switzerland.innovation_rank
        }
    },
    "tanzania": {
        "pillars": {
            "Future_Readiness": data.tanzania.future_readiness_rank,
            "Physical_Capital": data.tanzania.physical_capital_rank,
            "Technology": data.tanzania.technology_rank,
            "Human_Capital": data.tanzania.human_capital_rank,
            "Competitiveness": data.tanzania.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.tanzania.digital_infrastructure_rank,
            "Transport_Infrastructure": data.tanzania.transport_infrastructure_rank,
            "Energy_Infrastructure": data.tanzania.energy_infrastructure_rank,
            "Attract": data.tanzania.attract_rank,
            "Grow": data.tanzania.grow_rank,
            "Retain": data.tanzania.retain_rank,
            "Skills": data.tanzania.skills_rank,
            "Digital_Usage": data.tanzania.digital_usage_rank,
            "Digital_Content_Creation": data.tanzania.digital_content_creation_rank,
            "Industry_4": data.tanzania.industry_4_rank,
            "Digital_Policies": data.tanzania.digital_policies_rank,
            "Market_Environment": data.tanzania.market_environment_rank,
            "Research_and_Development": data.tanzania.research_and_development_rank,
            "Innovation": data.tanzania.innovation_rank
        }
    },
    "thailand": {
        "pillars": {
            "Future_Readiness": data.thailand.future_readiness_rank,
            "Physical_Capital": data.thailand.physical_capital_rank,
            "Technology": data.thailand.technology_rank,
            "Human_Capital": data.thailand.human_capital_rank,
            "Competitiveness": data.thailand.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.thailand.digital_infrastructure_rank,
            "Transport_Infrastructure": data.thailand.transport_infrastructure_rank,
            "Energy_Infrastructure": data.thailand.energy_infrastructure_rank,
            "Attract": data.thailand.attract_rank,
            "Grow": data.thailand.grow_rank,
            "Retain": data.thailand.retain_rank,
            "Skills": data.thailand.skills_rank,
            "Digital_Usage": data.thailand.digital_usage_rank,
            "Digital_Content_Creation": data.thailand.digital_content_creation_rank,
            "Industry_4": data.thailand.industry_4_rank,
            "Digital_Policies": data.thailand.digital_policies_rank,
            "Market_Environment": data.thailand.market_environment_rank,
            "Research_and_Development": data.thailand.research_and_development_rank,
            "Innovation": data.thailand.innovation_rank
        }
    },
    "togo": {
        "pillars": {
            "Future_Readiness": data.togo.future_readiness_rank,
            "Physical_Capital": data.togo.physical_capital_rank,
            "Technology": data.togo.technology_rank,
            "Human_Capital": data.togo.human_capital_rank,
            "Competitiveness": data.togo.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.togo.digital_infrastructure_rank,
            "Transport_Infrastructure": data.togo.transport_infrastructure_rank,
            "Energy_Infrastructure": data.togo.energy_infrastructure_rank,
            "Attract": data.togo.attract_rank,
            "Grow": data.togo.grow_rank,
            "Retain": data.togo.retain_rank,
            "Skills": data.togo.skills_rank,
            "Digital_Usage": data.togo.digital_usage_rank,
            "Digital_Content_Creation": data.togo.digital_content_creation_rank,
            "Industry_4": data.togo.industry_4_rank,
            "Digital_Policies": data.togo.digital_policies_rank,
            "Market_Environment": data.togo.market_environment_rank,
            "Research_and_Development": data.togo.research_and_development_rank,
            "Innovation": data.togo.innovation_rank
        }
    },
    "trinidad_and_tobago": {
        "pillars": {
            "Future_Readiness": data.trinidad_and_tobago.future_readiness_rank,
            "Physical_Capital": data.trinidad_and_tobago.physical_capital_rank,
            "Technology": data.trinidad_and_tobago.technology_rank,
            "Human_Capital": data.trinidad_and_tobago.human_capital_rank,
            "Competitiveness": data.trinidad_and_tobago.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.trinidad_and_tobago.digital_infrastructure_rank,
            "Transport_Infrastructure": data.trinidad_and_tobago.transport_infrastructure_rank,
            "Energy_Infrastructure": data.trinidad_and_tobago.energy_infrastructure_rank,
            "Attract": data.trinidad_and_tobago.attract_rank,
            "Grow": data.trinidad_and_tobago.grow_rank,
            "Retain": data.trinidad_and_tobago.retain_rank,
            "Skills": data.trinidad_and_tobago.skills_rank,
            "Digital_Usage": data.trinidad_and_tobago.digital_usage_rank,
            "Digital_Content_Creation": data.trinidad_and_tobago.digital_content_creation_rank,
            "Industry_4": data.trinidad_and_tobago.industry_4_rank,
            "Digital_Policies": data.trinidad_and_tobago.digital_policies_rank,
            "Market_Environment": data.trinidad_and_tobago.market_environment_rank,
            "Research_and_Development": data.trinidad_and_tobago.research_and_development_rank,
            "Innovation": data.trinidad_and_tobago.innovation_rank
        }
    },
    "tunisia": {
        "pillars": {
            "Future_Readiness": data.tunisia.future_readiness_rank,
            "Physical_Capital": data.tunisia.physical_capital_rank,
            "Technology": data.tunisia.technology_rank,
            "Human_Capital": data.tunisia.human_capital_rank,
            "Competitiveness": data.tunisia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.tunisia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.tunisia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.tunisia.energy_infrastructure_rank,
            "Attract": data.tunisia.attract_rank,
            "Grow": data.tunisia.grow_rank,
            "Retain": data.tunisia.retain_rank,
            "Skills": data.tunisia.skills_rank,
            "Digital_Usage": data.tunisia.digital_usage_rank,
            "Digital_Content_Creation": data.tunisia.digital_content_creation_rank,
            "Industry_4": data.tunisia.industry_4_rank,
            "Digital_Policies": data.tunisia.digital_policies_rank,
            "Market_Environment": data.tunisia.market_environment_rank,
            "Research_and_Development": data.tunisia.research_and_development_rank,
            "Innovation": data.tunisia.innovation_rank
        }
    },
    "turkey": {
        "pillars": {
            "Future_Readiness": data.turkey.future_readiness_rank,
            "Physical_Capital": data.turkey.physical_capital_rank,
            "Technology": data.turkey.technology_rank,
            "Human_Capital": data.turkey.human_capital_rank,
            "Competitiveness": data.turkey.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.turkey.digital_infrastructure_rank,
            "Transport_Infrastructure": data.turkey.transport_infrastructure_rank,
            "Energy_Infrastructure": data.turkey.energy_infrastructure_rank,
            "Attract": data.turkey.attract_rank,
            "Grow": data.turkey.grow_rank,
            "Retain": data.turkey.retain_rank,
            "Skills": data.turkey.skills_rank,
            "Digital_Usage": data.turkey.digital_usage_rank,
            "Digital_Content_Creation": data.turkey.digital_content_creation_rank,
            "Industry_4": data.turkey.industry_4_rank,
            "Digital_Policies": data.turkey.digital_policies_rank,
            "Market_Environment": data.turkey.market_environment_rank,
            "Research_and_Development": data.turkey.research_and_development_rank,
            "Innovation": data.turkey.innovation_rank
        }
    },
    "uganda": {
        "pillars": {
            "Future_Readiness": data.uganda.future_readiness_rank,
            "Physical_Capital": data.uganda.physical_capital_rank,
            "Technology": data.uganda.technology_rank,
            "Human_Capital": data.uganda.human_capital_rank,
            "Competitiveness": data.uganda.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.uganda.digital_infrastructure_rank,
            "Transport_Infrastructure": data.uganda.transport_infrastructure_rank,
            "Energy_Infrastructure": data.uganda.energy_infrastructure_rank,
            "Attract": data.uganda.attract_rank,
            "Grow": data.uganda.grow_rank,
            "Retain": data.uganda.retain_rank,
            "Skills": data.uganda.skills_rank,
            "Digital_Usage": data.uganda.digital_usage_rank,
            "Digital_Content_Creation": data.uganda.digital_content_creation_rank,
            "Industry_4": data.uganda.industry_4_rank,
            "Digital_Policies": data.uganda.digital_policies_rank,
            "Market_Environment": data.uganda.market_environment_rank,
            "Research_and_Development": data.uganda.research_and_development_rank,
            "Innovation": data.uganda.innovation_rank
        }
    },
    "ukraine": {
        "pillars": {
            "Future_Readiness": data.ukraine.future_readiness_rank,
            "Physical_Capital": data.ukraine.physical_capital_rank,
            "Technology": data.ukraine.technology_rank,
            "Human_Capital": data.ukraine.human_capital_rank,
            "Competitiveness": data.ukraine.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.ukraine.digital_infrastructure_rank,
            "Transport_Infrastructure": data.ukraine.transport_infrastructure_rank,
            "Energy_Infrastructure": data.ukraine.energy_infrastructure_rank,
            "Attract": data.ukraine.attract_rank,
            "Grow": data.ukraine.grow_rank,
            "Retain": data.ukraine.retain_rank,
            "Skills": data.ukraine.skills_rank,
            "Digital_Usage": data.ukraine.digital_usage_rank,
            "Digital_Content_Creation": data.ukraine.digital_content_creation_rank,
            "Industry_4": data.ukraine.industry_4_rank,
            "Digital_Policies": data.ukraine.digital_policies_rank,
            "Market_Environment": data.ukraine.market_environment_rank,
            "Research_and_Development": data.ukraine.research_and_development_rank,
            "Innovation": data.ukraine.innovation_rank
        }
    },
    "united_arab_emirates": {
        "pillars": {
            "Future_Readiness": data.united_arab_emirates.future_readiness_rank,
            "Physical_Capital": data.united_arab_emirates.physical_capital_rank,
            "Technology": data.united_arab_emirates.technology_rank,
            "Human_Capital": data.united_arab_emirates.human_capital_rank,
            "Competitiveness": data.united_arab_emirates.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.united_arab_emirates.digital_infrastructure_rank,
            "Transport_Infrastructure": data.united_arab_emirates.transport_infrastructure_rank,
            "Energy_Infrastructure": data.united_arab_emirates.energy_infrastructure_rank,
            "Attract": data.united_arab_emirates.attract_rank,
            "Grow": data.united_arab_emirates.grow_rank,
            "Retain": data.united_arab_emirates.retain_rank,
            "Skills": data.united_arab_emirates.skills_rank,
            "Digital_Usage": data.united_arab_emirates.digital_usage_rank,
            "Digital_Content_Creation": data.united_arab_emirates.digital_content_creation_rank,
            "Industry_4": data.united_arab_emirates.industry_4_rank,
            "Digital_Policies": data.united_arab_emirates.digital_policies_rank,
            "Market_Environment": data.united_arab_emirates.market_environment_rank,
            "Research_and_Development": data.united_arab_emirates.research_and_development_rank,
            "Innovation": data.united_arab_emirates.innovation_rank
        }
    },
    "united_kingdom": {
        "pillars": {
            "Future_Readiness": data.united_kingdom.future_readiness_rank,
            "Physical_Capital": data.united_kingdom.physical_capital_rank,
            "Technology": data.united_kingdom.technology_rank,
            "Human_Capital": data.united_kingdom.human_capital_rank,
            "Competitiveness": data.united_kingdom.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.united_kingdom.digital_infrastructure_rank,
            "Transport_Infrastructure": data.united_kingdom.transport_infrastructure_rank,
            "Energy_Infrastructure": data.united_kingdom.energy_infrastructure_rank,
            "Attract": data.united_kingdom.attract_rank,
            "Grow": data.united_kingdom.grow_rank,
            "Retain": data.united_kingdom.retain_rank,
            "Skills": data.united_kingdom.skills_rank,
            "Digital_Usage": data.united_kingdom.digital_usage_rank,
            "Digital_Content_Creation": data.united_kingdom.digital_content_creation_rank,
            "Industry_4": data.united_kingdom.industry_4_rank,
            "Digital_Policies": data.united_kingdom.digital_policies_rank,
            "Market_Environment": data.united_kingdom.market_environment_rank,
            "Research_and_Development": data.united_kingdom.research_and_development_rank,
            "Innovation": data.united_kingdom.innovation_rank
        }
    },
    "united_states": {
        "pillars": {
            "Future_Readiness": data.united_states.future_readiness_rank,
            "Physical_Capital": data.united_states.physical_capital_rank,
            "Technology": data.united_states.technology_rank,
            "Human_Capital": data.united_states.human_capital_rank,
            "Competitiveness": data.united_states.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.united_states.digital_infrastructure_rank,
            "Transport_Infrastructure": data.united_states.transport_infrastructure_rank,
            "Energy_Infrastructure": data.united_states.energy_infrastructure_rank,
            "Attract": data.united_states.attract_rank,
            "Grow": data.united_states.grow_rank,
            "Retain": data.united_states.retain_rank,
            "Skills": data.united_states.skills_rank,
            "Digital_Usage": data.united_states.digital_usage_rank,
            "Digital_Content_Creation": data.united_states.digital_content_creation_rank,
            "Industry_4": data.united_states.industry_4_rank,
            "Digital_Policies": data.united_states.digital_policies_rank,
            "Market_Environment": data.united_states.market_environment_rank,
            "Research_and_Development": data.united_states.research_and_development_rank,
            "Innovation": data.united_states.innovation_rank
        }
    },
    "uruguay": {
        "pillars": {
            "Future_Readiness": data.uruguay.future_readiness_rank,
            "Physical_Capital": data.uruguay.physical_capital_rank,
            "Technology": data.uruguay.technology_rank,
            "Human_Capital": data.uruguay.human_capital_rank,
            "Competitiveness": data.uruguay.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.uruguay.digital_infrastructure_rank,
            "Transport_Infrastructure": data.uruguay.transport_infrastructure_rank,
            "Energy_Infrastructure": data.uruguay.energy_infrastructure_rank,
            "Attract": data.uruguay.attract_rank,
            "Grow": data.uruguay.grow_rank,
            "Retain": data.uruguay.retain_rank,
            "Skills": data.uruguay.skills_rank,
            "Digital_Usage": data.uruguay.digital_usage_rank,
            "Digital_Content_Creation": data.uruguay.digital_content_creation_rank,
            "Industry_4": data.uruguay.industry_4_rank,
            "Digital_Policies": data.uruguay.digital_policies_rank,
            "Market_Environment": data.uruguay.market_environment_rank,
            "Research_and_Development": data.uruguay.research_and_development_rank,
            "Innovation": data.uruguay.innovation_rank
        }
    },
    "vietnam": {
        "pillars": {
            "Future_Readiness": data.vietnam.future_readiness_rank,
            "Physical_Capital": data.vietnam.physical_capital_rank,
            "Technology": data.vietnam.technology_rank,
            "Human_Capital": data.vietnam.human_capital_rank,
            "Competitiveness": data.vietnam.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.vietnam.digital_infrastructure_rank,
            "Transport_Infrastructure": data.vietnam.transport_infrastructure_rank,
            "Energy_Infrastructure": data.vietnam.energy_infrastructure_rank,
            "Attract": data.vietnam.attract_rank,
            "Grow": data.vietnam.grow_rank,
            "Retain": data.vietnam.retain_rank,
            "Skills": data.vietnam.skills_rank,
            "Digital_Usage": data.vietnam.digital_usage_rank,
            "Digital_Content_Creation": data.vietnam.digital_content_creation_rank,
            "Industry_4": data.vietnam.industry_4_rank,
            "Digital_Policies": data.vietnam.digital_policies_rank,
            "Market_Environment": data.vietnam.market_environment_rank,
            "Research_and_Development": data.vietnam.research_and_development_rank,
            "Innovation": data.vietnam.innovation_rank
        }
    },
    "zambia": {
        "pillars": {
            "Future_Readiness": data.zambia.future_readiness_rank,
            "Physical_Capital": data.zambia.physical_capital_rank,
            "Technology": data.zambia.technology_rank,
            "Human_Capital": data.zambia.human_capital_rank,
            "Competitiveness": data.zambia.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.zambia.digital_infrastructure_rank,
            "Transport_Infrastructure": data.zambia.transport_infrastructure_rank,
            "Energy_Infrastructure": data.zambia.energy_infrastructure_rank,
            "Attract": data.zambia.attract_rank,
            "Grow": data.zambia.grow_rank,
            "Retain": data.zambia.retain_rank,
            "Skills": data.zambia.skills_rank,
            "Digital_Usage": data.zambia.digital_usage_rank,
            "Digital_Content_Creation": data.zambia.digital_content_creation_rank,
            "Industry_4": data.zambia.industry_4_rank,
            "Digital_Policies": data.zambia.digital_policies_rank,
            "Market_Environment": data.zambia.market_environment_rank,
            "Research_and_Development": data.zambia.research_and_development_rank,
            "Innovation": data.zambia.innovation_rank
        }
    },
    "zimbabwe": {
        "pillars": {
            "Future_Readiness": data.zimbabwe.future_readiness_rank,
            "Physical_Capital": data.zimbabwe.physical_capital_rank,
            "Technology": data.zimbabwe.technology_rank,
            "Human_Capital": data.zimbabwe.human_capital_rank,
            "Competitiveness": data.zimbabwe.competitiveness_rank,
        },
        "sub_pillars": {
            "Digital_Infrastructure": data.zimbabwe.digital_infrastructure_rank,
            "Transport_Infrastructure": data.zimbabwe.transport_infrastructure_rank,
            "Energy_Infrastructure": data.zimbabwe.energy_infrastructure_rank,
            "Attract": data.zimbabwe.attract_rank,
            "Grow": data.zimbabwe.grow_rank,
            "Retain": data.zimbabwe.retain_rank,
            "Skills": data.zimbabwe.skills_rank,
            "Digital_Usage": data.zimbabwe.digital_usage_rank,
            "Digital_Content_Creation": data.zimbabwe.digital_content_creation_rank,
            "Industry_4": data.zimbabwe.industry_4_rank,
            "Digital_Policies": data.zimbabwe.digital_policies_rank,
            "Market_Environment": data.zimbabwe.market_environment_rank,
            "Research_and_Development": data.zimbabwe.research_and_development_rank,
            "Innovation": data.zimbabwe.innovation_rank
        }
    }
}

export default countryRanksData
