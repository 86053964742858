<template>
  <div>

    <section class="bg-lightgrey mt-5">
      <div class="container shape-container d-flex">
        <div class="col px-0">
          <div class="row pb-100 pt-100 mt-5">
            <div class="col-lg-12">
              <h1 class="display-3">
                Countries
                <span>Future Readiness Economic Index</span>
              </h1>
              <p class="lead">For information on our methodology and how scoring is calculated, please visit our <router-link to="/2021/about" class="font-weight-500">About FREI page</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="">
        <tabs-square fill class="flex-column flex-md-row data-tabs report-rabs">
          <card shadow>

            <tab-pane-square>
             <span slot="title">
                <i class="ni ni-single-copy-04"></i>
                Profiles
              </span>

              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <CountryProfilesMobile v-if="isMobile"></CountryProfilesMobile>
                      <CountryProfiles v-else></CountryProfiles>
                    </div>
                  </div>
                </div>
              </section>

            </tab-pane-square>

            <tab-pane-square>
            <span slot="title">
                <i class="ni ni-bullet-list-67"></i>
                Rankings
            </span>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <RankingsTable></RankingsTable>
                    </div>
                  </div>
                </div>
              </section>
            </tab-pane-square>

            <tab-pane-square title="Profile">
            <span slot="title">
                <i class="ni ni-chart-bar-32 mr-2"></i>
                Graph View
            </span>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <graph-view></graph-view>
                    </div>
                  </div>
                </div>
              </section>
            </tab-pane-square>

            <tab-pane-square>
             <span slot="title">
                <i class="ni ni-world"></i>
                Map View
              </span>
              <section class="mt-0">
                <div class="container-fluid">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <MapView></MapView>
                    </div>
                  </div>
                </div>
              </section>
            </tab-pane-square>

            <tab-pane-square>
             <span slot="title">
                <i class="ni ni-curved-next"></i>
                Compare
              </span>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <CountryComparison></CountryComparison>
                    </div>
                  </div>
                </div>
              </section>
            </tab-pane-square>

          </card>
        </tabs-square>
      </div>
    </section>

  </div>
</template>
<script>

import TabsSquare from "../components-2021/Tabs/TabsSquare";
import TabPaneSquare from "../components-2021/Tabs/TabPaneSquare";
import RankingsTable from "./components-2021/RankingsTable";
import CountryComparison from "@/views-2021/components-2021/CountryComparison";
import GraphView from "@/components-2021/GraphView";
import MapView from "@/components-2021/MapView";
import CountryProfiles from "@/components-2021/CountryProfiles";
import CountryProfilesMobile from "@/components-2021/CountryProfilesMobile";

export default {
  name: "components",
  components: {
    CountryProfiles,
    CountryProfilesMobile,
    MapView,
    GraphView,
    CountryComparison,
    TabsSquare,
    TabPaneSquare,
    RankingsTable
  },
  data() {
    return {
      selected: null
    }
  },
  metaInfo: {
    title: 'Portulans Institute FREI 2021 | Report',
  },
  ready() {
    this.$nextTick(function () {
      this.drawChart();
    })
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  }
};
</script>
