<template>
  <div class="row">

    <div class="col-12">
      <p class="lead">
        The chart below visualizes the future readiness performance of all 124 countries - overall and by pillar. The list is in order of overall FREI score.
      </p>
    </div>

    <div class="col-12 text-center animate__animated animate__fadeIn mb-5 mt-3">
      <p class="font-weight-500 help-text-green"><i class="fa fa-lightbulb-o mr-2"/>Mouse over the bars to see each country's scores per pillar. Use the chart legend to toggle between readiness pillars</p>
    </div>

<!--    <div class="col-12 map-buttons text-center">-->
<!--      <b-form-group v-slot="{ ariaDescribedby }">-->
<!--        <b-form-radio-group-->
<!--            class="btn-group d-block"-->
<!--            id="checkbox-group-1"-->
<!--            v-model="selected"-->
<!--            :options="options"-->
<!--            :aria-describedby="ariaDescribedby"-->
<!--            buttons-->
<!--            button-variant="outline-secondary"-->
<!--            name="buttons2"-->
<!--        ></b-form-radio-group>-->
<!--      </b-form-group>-->
<!--    </div>-->

    <bar-chart :chartData="this.chartData" style="width: 100%" class="graph-view-bar-chart-2023"></bar-chart>

  </div>
</template>

<script>

import BarChart from "@/components-2023/charts/BarChart";

export default {
  name: "GraphView",
  components: {BarChart},
  data() {
    return {
      sortedLabels: '',
      sortedData: '',
      selected: 'FutureReadinessScore',
      options: [
        {text: 'FREI', value: 0},
        {text: 'Physical Capital', value: 1},
        {text: 'Human Capital', value: 2},
        {text: 'Technology', value: 3},
        {text: 'Competitiveness', value: 4}
      ],
      chartData: {
        chart: {
          height: (9 / 16 * 100) + '%' // 16:9 ratio
        },
        labels: [
          "Singapore",
          "Denmark",
          "Switzerland",
          "United States",
          "Sweden",
          "Finland",
          "Norway",
          "Netherlands",
          "United Kingdom",
          "Australia",
          "Canada",
          "Luxembourg",
          "Iceland",
          "South Korea",
          "Ireland",
          "Germany",
          "Belgium",
          "Austria",
          "Estonia",
          "New Zealand",
          "France",
          "Japan",
          "Malta",
          "Israel",
          "Spain",
          "Portugal",
          "United Arab Emirates",
          "Cyprus",
          "Italy",
          "Slovenia",
          "Czech Republic",
          "Lithuania",
          "Greece",
          "Qatar",
          "Croatia",
          "Latvia",
          "Poland",
          "China",
          "Hungary",
          "Malaysia",
          "Slovakia",
          "Saudi Arabia",
          "Chile",
          "Serbia",
          "Bahrain",
          "Bulgaria",
          "Uruguay",
          "Russia",
          "Armenia",
          "Romania",
          "Ukraine",
          "Argentina",
          "Türkiye",
          "Brazil",
          "Thailand",
          "Georgia",
          "Costa Rica",
          "Kuwait",
          "Mauritius",
          "Belarus",
          "Vietnam",
          "Albania",
          "Oman",
          "North Macedonia",
          "South Africa",
          "Kazakhstan",
          "Colombia",
          "Mexico",
          "Ecuador",
          "Jordan",
          "Moldova",
          "Bosnia and Herzegovina",
          "Indonesia",
          "Azerbaijan",
          "Mongolia",
          "Egypt",
          "Lebanon",
          "Panama",
          "Peru",
          "Tunisia",
          "Kyrgyzstan",
          "Philippines",
          "Jamaica",
          "Trinidad and Tobago",
          "Paraguay",
          "Bolivia",
          "Iran",
          "India",
          "Botswana",
          "Dominican Republic",
          "Sri Lanka",
          "Morocco",
          "Algeria",
          "El Salvador",
          "Honduras",
          "Kenya",
          "Cambodia",
          "Guatemala",
          "Ghana",
          "Rwanda",
          "Bangladesh",
          "Senegal",
          "Laos",
          "Pakistan",
          "Nepal",
          "Côte d'Ivoire",
          "Nigeria",
          "Benin",
          "Togo",
          "Myanmar",
          "Zambia",
          "Zimbabwe",
          "Cameroon",
          "Uganda",
          "Angola",
          "Tanzania",
          "Mali",
          "Burkina Faso",
          "Malawi",
          "Guinea",
          "Mozambique",
          "Madagascar",
          "Ethiopia",
          "Niger"],
        datasets: [
          {
            label: "FREI",
            data: [
              '78.01',
              '75.08',
              '74.33',
              '73.5',
              '72.51',
              '72.33',
              '71.55',
              '70.58',
              '70.23',
              '69.94',
              '68.93',
              '67.01',
              '66.57',
              '66.27',
              '66.17',
              '65.52',
              '64.08',
              '63.59',
              '63.49',
              '63.05',
              '62.18',
              '61.56',
              '61.32',
              '61.26',
              '59.13',
              '59.1',
              '59.04',
              '57.93',
              '56.47',
              '56.22',
              '55.75',
              '55.41',
              '55.11',
              '54.58',
              '52.94',
              '52.81',
              '52.74',
              '52.5',
              '51.39',
              '51.29',
              '50.91',
              '50.44',
              '49.6',
              '49.59',
              '49.5',
              '49.47',
              '48.94',
              '47.96',
              '46.5',
              '46.49',
              '46.18',
              '45.42',
              '45.18',
              '45.11',
              '44.89',
              '44.81',
              '44.49',
              '44.39',
              '44.12',
              '43.73',
              '42.97',
              '42.65',
              '42.61',
              '42.11',
              '42.06',
              '42.06',
              '41.73',
              '41.54',
              '41.51',
              '40.9',
              '40.68',
              '39.67',
              '39.6',
              '39.36',
              '39.23',
              '39.05',
              '38.83',
              '38.43',
              '38.37',
              '38.27',
              '38.23',
              '38.15',
              '38.05',
              '37.93',
              '37.55',
              '36.27',
              '36.19',
              '36.14',
              '36.12',
              '34.9',
              '34.59',
              '33.94',
              '33.38',
              '31.89',
              '30.89',
              '29.67',
              '29.15',
              '28.34',
              '28.15',
              '27.83',
              '27.71',
              '27.53',
              '26.68',
              '26.54',
              '26.5',
              '25.93',
              '25.2',
              '23.51',
              '23.49',
              '23.33',
              '22.94',
              '22.06',
              '21.99',
              '21.91',
              '21.38',
              '21.28',
              '20.4',
              '19.37',
              '19.02',
              '18.31',
              '17.46',
              '16.82',
              '16.54',
              '11.49',
            ],
            backgroundColor: '#3ac7ffFF'
          }, {
            label: "Physical Capital",
            data: [
              '84.1',
              '75.9',
              '76.18',
              '74.02',
              '73.43',
              '74.13',
              '84.32',
              '74.67',
              '69.61',
              '70.46',
              '72.68',
              '81.55',
              '76.14',
              '71.89',
              '73.36',
              '72.13',
              '72.32',
              '71.96',
              '67.41',
              '67.61',
              '69.83',
              '69.02',
              '77.15',
              '67.58',
              '69.98',
              '71.88',
              '80.56',
              '67.65',
              '69.75',
              '66.55',
              '65.4',
              '67.28',
              '70.55',
              '79.59',
              '65.51',
              '64.59',
              '64.45',
              '67.03',
              '64.84',
              '67.75',
              '64.33',
              '63.5',
              '59.7',
              '57.02',
              '75.91',
              '60.99',
              '58.13',
              '58.85',
              '56.93',
              '62.45',
              '57.45',
              '55.72',
              '66.59',
              '54.79',
              '64',
              '56.86',
              '56.39',
              '62.23',
              '64.21',
              '59.58',
              '62.39',
              '59.87',
              '61.3',
              '55.02',
              '52',
              '51.47',
              '51.91',
              '55.24',
              '54.48',
              '53.29',
              '55.77',
              '54.35',
              '60.9',
              '51.39',
              '50.02',
              '58.87',
              '59.44',
              '57.11',
              '52.09',
              '52.01',
              '47.9',
              '51.71',
              '52.81',
              '43.36',
              '49.77',
              '46.25',
              '48.01',
              '54.29',
              '47.33',
              '47.39',
              '54.13',
              '54.41',
              '50.25',
              '45.94',
              '43',
              '42.19',
              '44.26',
              '42.9',
              '35.74',
              '40.71',
              '43.58',
              '33.46',
              '44.45',
              '37.46',
              '39.06',
              '35.86',
              '28.27',
              '23.62',
              '30.83',
              '34.94',
              '20.95',
              '25.37',
              '28.03',
              '25.92',
              '29.66',
              '26.08',
              '30.19',
              '23.8',
              '27.61',
              '34.19',
              '21.86',
              '15.44',
              '21.59',
              '9.72',
            ],
            backgroundColor: '#4e81c4'
          },
          {
            label: "Human Capital",
            data: [
              '75.04',
              '75.98',
              '74.67',
              '71.23',
              '75.5',
              '73.93',
              '73.63',
              '73.54',
              '70.82',
              '77.21',
              '73.42',
              '75.62',
              '73.26',
              '63.68',
              '73.7',
              '65.77',
              '67.81',
              '67.6',
              '60.62',
              '68.82',
              '62.97',
              '59.03',
              '67.39',
              '58.73',
              '61.51',
              '68.7',
              '64.84',
              '62.56',
              '56.6',
              '59.8',
              '58.6',
              '57.96',
              '58.5',
              '47.62',
              '50.33',
              '54.04',
              '53.4',
              '50.73',
              '52.45',
              '44.29',
              '51.87',
              '50.27',
              '54.42',
              '47.37',
              '44.92',
              '49.44',
              '52.88',
              '53.71',
              '46.99',
              '44.48',
              '49.54',
              '49.87',
              '41.3',
              '42.23',
              '38.08',
              '48.42',
              '48.11',
              '45.99',
              '49.42',
              '49.12',
              '39.17',
              '47.02',
              '37.86',
              '43.66',
              '36.92',
              '49.23',
              '40.23',
              '42.98',
              '41.55',
              '41.72',
              '42.27',
              '38.96',
              '34.72',
              '41.6',
              '42.66',
              '34.27',
              '43.82',
              '35.51',
              '38.01',
              '37.69',
              '44.51',
              '34.36',
              '46.03',
              '51.49',
              '43.66',
              '42.93',
              '31.89',
              '26.48',
              '42.37',
              '35.48',
              '33.9',
              '27.93',
              '33.56',
              '31.93',
              '28.5',
              '28.99',
              '27.51',
              '23.58',
              '29.13',
              '23.73',
              '26.48',
              '27.85',
              '27.28',
              '22.38',
              '33.47',
              '24.33',
              '30.42',
              '26.74',
              '24.64',
              '25.89',
              '30.06',
              '24.01',
              '25.91',
              '22.56',
              '20.61',
              '25.33',
              '17.69',
              '21.97',
              '25.21',
              '19.71',
              '16.74',
              '24.95',
              '21.84',
              '14.47',
            ],
            backgroundColor: '#e2aa23'
          },
          {
            label: "Technology",
            data: [
              '77.15',
              '76.75',
              '74.47',
              '73.12',
              '75.29',
              '76.11',
              '68.53',
              '75.45',
              '69.76',
              '65.01',
              '65.32',
              '59.86',
              '57.99',
              '64.9',
              '61.46',
              '65.58',
              '55.26',
              '60.59',
              '65.24',
              '61.98',
              '54.47',
              '58.66',
              '51.91',
              '59.94',
              '51.12',
              '48.78',
              '45.23',
              '50.74',
              '47.64',
              '50.26',
              '49.57',
              '47.49',
              '45.17',
              '37.84',
              '44.46',
              '46.18',
              '47.7',
              '41.65',
              '45.9',
              '39.56',
              '42.84',
              '37.34',
              '40.2',
              '47.72',
              '37.21',
              '40.92',
              '39.31',
              '39.06',
              '42.53',
              '38.53',
              '39.04',
              '34.45',
              '35.92',
              '37.88',
              '34.91',
              '32.88',
              '34.29',
              '33.2',
              '34.03',
              '36.14',
              '31.84',
              '35.66',
              '35.51',
              '36.09',
              '35',
              '34.33',
              '33.95',
              '32.13',
              '31.54',
              '31.09',
              '32.1',
              '32.62',
              '27.26',
              '29.94',
              '33.27',
              '25.8',
              '25.79',
              '31.48',
              '33.06',
              '29.92',
              '28.78',
              '24.25',
              '25.76',
              '23.07',
              '28.84',
              '26.14',
              '28.9',
              '26.86',
              '21.96',
              '25.68',
              '22.73',
              '27.37',
              '19.31',
              '23.98',
              '18.2',
              '23.85',
              '21.8',
              '21.54',
              '22.2',
              '19.62',
              '19.09',
              '17.75',
              '17.7',
              '16.45',
              '18.92',
              '16.53',
              '13.83',
              '15.27',
              '14.87',
              '17.21',
              '16.62',
              '16.92',
              '16.95',
              '13.91',
              '14.98',
              '10.43',
              '14.62',
              '10.91',
              '11.26',
              '7.78',
              '14.22',
              '12.08',
              '8.51',
              '7.52',
            ],
            backgroundColor: '#cc4c2a'
          },
          {
            label: "Competitiveness",
            data: [
              '75.77',
              '71.69',
              '72.01',
              '75.63',
              '65.83',
              '65.16',
              '59.73',
              '58.68',
              '70.72',
              '67.09',
              '64.3',
              '51.01',
              '58.91',
              '64.61',
              '56.17',
              '58.6',
              '60.91',
              '54.19',
              '60.68',
              '53.81',
              '61.45',
              '59.53',
              '48.84',
              '58.8',
              '53.91',
              '47.04',
              '45.54',
              '50.76',
              '51.88',
              '48.26',
              '49.41',
              '48.9',
              '46.24',
              '53.28',
              '51.45',
              '46.44',
              '45.39',
              '50.57',
              '42.34',
              '53.55',
              '44.6',
              '50.64',
              '44.09',
              '46.24',
              '39.97',
              '46.54',
              '45.45',
              '40.23',
              '39.57',
              '40.49',
              '38.7',
              '41.62',
              '36.91',
              '45.55',
              '42.56',
              '41.08',
              '39.15',
              '36.16',
              '28.82',
              '30.07',
              '38.47',
              '28.04',
              '35.78',
              '33.67',
              '44.31',
              '33.19',
              '40.81',
              '35.79',
              '38.47',
              '37.48',
              '32.56',
              '32.76',
              '35.5',
              '34.53',
              '30.99',
              '37.24',
              '26.27',
              '29.63',
              '30.31',
              '33.48',
              '31.71',
              '42.28',
              '27.62',
              '33.81',
              '27.92',
              '29.77',
              '35.96',
              '36.93',
              '32.81',
              '31.07',
              '27.59',
              '26.06',
              '30.4',
              '25.7',
              '33.88',
              '23.64',
              '23.03',
              '25.33',
              '25.52',
              '27.24',
              '21.68',
              '31.05',
              '17.3',
              '29.87',
              '14.54',
              '27.02',
              '28.29',
              '28.4',
              '23.63',
              '15.29',
              '24.15',
              '21.95',
              '17.07',
              '25.25',
              '20.26',
              '23.27',
              '19.12',
              '20.8',
              '11.98',
              '11.54',
              '17.02',
              '14.79',
              '14.24',
              '14.26',
            ],
            backgroundColor: '#66ad5d'
          }
        ]
      }
    }
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  // watch: {
  //   selected() {
  //     const allData = [];
  //     for (let i = 0; i < this.chartData.labels.length; ++i) {
  //       allData.push({
  //         label: this.chartData.labels[i],
  //         data: this.chartData.datasets[0].data[i]
  //       });
  //     }
  //     allData.sort((b, a) => b.data - a.data);
  //     this.sortedLabels = allData.map(e => e.label);
  //     this.sortedData = allData.map(e => e.data);
  //   }
  // }
}
</script>

<style>

.graph-view-bar-chart-2023 {
  width: 100% !important;
  height: 10000px !important
}

</style>
