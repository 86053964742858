import data from '../data.json'

const countryProfilesData = {
    "countries": {
        "albania": {
            "radar_data": [
                {
                    "label": "Albania",
                    "data": [
                        data.albania.future_readiness_score,
                        data.albania.physical_capital,
                        data.albania.human_capital,
                        data.albania.technology,
                        data.albania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Albania",
            "code": "al",
            "stats": {
                "country": "Albania",
                "future_readiness_rank": "67",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "2.84",
                "gdp_per_capita": "14,224.07",
                "gdp": "14.80",
                "future_readiness_score": "38.51",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.albania.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "algeria": {
            "radar_data": [
                {
                    "label": "Algeria",
                    "data": [
                        data.algeria.future_readiness_score,
                        data.algeria.physical_capital,
                        data.algeria.human_capital,
                        data.algeria.technology,
                        data.algeria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Algeria",
            "code": "dz",
            "stats": {
                "country": "Algeria",
                "future_readiness_rank": "94",
                "income_group": "Lower-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "43.85",
                "gdp_per_capita": "11,997.34",
                "gdp": "145.16",
                "future_readiness_score": "29.28",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.algeria.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "angola": {
            "radar_data": [
                {
                    "label": "Angola",
                    "data": [
                        data.angola.future_readiness_score,
                        data.angola.physical_capital,
                        data.angola.human_capital,
                        data.angola.technology,
                        data.angola.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Angola",
            "code": "ao",
            "stats": {
                "country": "Angola",
                "future_readiness_rank": "115",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "32.87",
                "gdp_per_capita": "6,905.65",
                "gdp": "62.31",
                "future_readiness_score": "19.65",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.angola.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "argentina": {
            "radar_data": [
                {
                    "label": "Argentina",
                    "data": [
                        data.argentina.future_readiness_score,
                        data.argentina.physical_capital,
                        data.argentina.human_capital,
                        data.argentina.technology,
                        data.argentina.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Argentina",
            "code": "ar",
            "stats": {
                "country": "Argentina",
                "future_readiness_rank": "58",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "45.38",
                "gdp_per_capita": "22,996.99",
                "gdp": "383.07",
                "future_readiness_score": "41.70",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.argentina.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "armenia": {
            "radar_data": [
                {
                    "label": "Armenia",
                    "data": [
                        data.armenia.future_readiness_score,
                        data.armenia.physical_capital,
                        data.armenia.human_capital,
                        data.armenia.technology,
                        data.armenia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Armenia",
            "code": "am",
            "stats": {
                "country": "Armenia",
                "future_readiness_rank": "55",
                "income_group": "Upper-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "2.96",
                "gdp_per_capita": "14,231.18",
                "gdp": "12.65",
                "future_readiness_score": "42.48",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.armenia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "australia": {
            "radar_data": [
                {
                    "label": "Australia",
                    "data": [
                        data.australia.future_readiness_score,
                        data.australia.physical_capital,
                        data.australia.human_capital,
                        data.australia.technology,
                        data.australia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Australia",
            "code": "au",
            "stats": {
                "country": "Australia",
                "future_readiness_rank": "11",
                "income_group": "High income",
                "regional_group": "Asia and Pacific",
                "population": "25.69",
                "gdp_per_capita": "52,203.13",
                "gdp": "1,330.90",
                "future_readiness_score": "68.68",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.australia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "austria": {
            "radar_data": [
                {
                    "label": "Austria",
                    "data": [
                        data.austria.future_readiness_score,
                        data.austria.physical_capital,
                        data.austria.human_capital,
                        data.austria.technology,
                        data.austria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Austria",
            "code": "at",
            "stats": {
                "country": "Austria",
                "future_readiness_rank": "16",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "8.92",
                "gdp_per_capita": "58,649.67",
                "gdp": "430.95",
                "future_readiness_score": "64.86",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.austria.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "azerbaijan": {
            "radar_data": [
                {
                    "label": "Azerbaijan",
                    "data": [
                        data.azerbaijan.future_readiness_score,
                        data.azerbaijan.physical_capital,
                        data.azerbaijan.human_capital,
                        data.azerbaijan.technology,
                        data.azerbaijan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Azerbaijan",
            "code": "az",
            "stats": {
                "country": "Azerbaijan",
                "future_readiness_rank": "77",
                "income_group": "Upper-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "10.11",
                "gdp_per_capita": "15,010.39",
                "gdp": "42.61",
                "future_readiness_score": "35.97",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.azerbaijan.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bahrain": {
            "radar_data": [
                {
                    "label": "Bahrain",
                    "data": [
                        data.bahrain.future_readiness_score,
                        data.bahrain.physical_capital,
                        data.bahrain.human_capital,
                        data.bahrain.technology,
                        data.bahrain.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bahrain",
            "code": "bh",
            "stats": {
                "country": "Bahrain",
                "future_readiness_rank": "43",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "1.70",
                "gdp_per_capita": "47,087.54",
                "gdp": "38.47",
                "future_readiness_score": "48.21",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.bahrain.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bangladesh": {
            "radar_data": [
                {
                    "label": "Bangladesh",
                    "data": [
                        data.bangladesh.future_readiness_score,
                        data.bangladesh.physical_capital,
                        data.bangladesh.human_capital,
                        data.bangladesh.technology,
                        data.bangladesh.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bangladesh",
            "code": "bd",
            "stats": {
                "country": "Bangladesh",
                "future_readiness_rank": "102",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "164.69",
                "gdp_per_capita": "4,954.76",
                "gdp": "324.24",
                "future_readiness_score": "24.02",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.bangladesh.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "belarus": {
            "radar_data": [
                {
                    "label": "Belarus",
                    "data": [
                        data.belarus.future_readiness_score,
                        data.belarus.physical_capital,
                        data.belarus.human_capital,
                        data.belarus.technology,
                        data.belarus.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Belarus",
            "code": "by",
            "stats": {
                "country": "Belarus",
                "future_readiness_rank": "60",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "9.40",
                "gdp_per_capita": "19,683.83",
                "gdp": "60.26",
                "future_readiness_score": "40.91",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.belarus.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "belgium": {
            "radar_data": [
                {
                    "label": "Belgium",
                    "data": [
                        data.belgium.future_readiness_score,
                        data.belgium.physical_capital,
                        data.belgium.human_capital,
                        data.belgium.technology,
                        data.belgium.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Belgium",
            "code": "be",
            "stats": {
                "country": "Belgium",
                "future_readiness_rank": "19",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "11.56",
                "gdp_per_capita": "54,693.35",
                "gdp": "515.33",
                "future_readiness_score": "63.85",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.belgium.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "benin": {
            "radar_data": [
                {
                    "label": "Benin",
                    "data": [
                        data.benin.future_readiness_score,
                        data.benin.physical_capital,
                        data.benin.human_capital,
                        data.benin.technology,
                        data.benin.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Benin",
            "code": "bj",
            "stats": {
                "country": "Benin",
                "future_readiness_rank": "111",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "12.12",
                "gdp_per_capita": "3,426.33",
                "gdp": "15.65",
                "future_readiness_score": "20.20",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.benin.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bolivia": {
            "radar_data": [
                {
                    "label": "Bolivia",
                    "data": [
                        data.bolivia.future_readiness_score,
                        data.bolivia.physical_capital,
                        data.bolivia.human_capital,
                        data.bolivia.technology,
                        data.bolivia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bolivia",
            "code": "bo",
            "stats": {
                "country": "Bolivia, Plurinational St.",
                "future_readiness_rank": "92",
                "income_group": "Lower-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "11.67",
                "gdp_per_capita": "9,093.43",
                "gdp": "36.69",
                "future_readiness_score": "31.55",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.bolivia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bosnia_and_herzegovina": {
            "radar_data": [
                {
                    "label": "Bosnia_and_herzegovina",
                    "data": [
                        data.bosnia_and_herzegovina.future_readiness_score,
                        data.bosnia_and_herzegovina.physical_capital,
                        data.bosnia_and_herzegovina.human_capital,
                        data.bosnia_and_herzegovina.technology,
                        data.bosnia_and_herzegovina.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bosnia_and_herzegovina",
            "code": "ba",
            "stats": {
                "country": "Bosnia and Herzegovina",
                "future_readiness_rank": "85",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "3.28",
                "gdp_per_capita": "15,817.10",
                "gdp": "19.79",
                "future_readiness_score": "34.80",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.bosnia_and_herzegovina.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "botswana": {
            "radar_data": [
                {
                    "label": "Botswana",
                    "data": [
                        data.botswana.future_readiness_score,
                        data.botswana.physical_capital,
                        data.botswana.human_capital,
                        data.botswana.technology,
                        data.botswana.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Botswana",
            "code": "bw",
            "stats": {
                "country": "Botswana",
                "future_readiness_rank": "86",
                "income_group": "Upper-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "2.35",
                "gdp_per_capita": "18,506.52",
                "gdp": "15.78",
                "future_readiness_score": "34.34",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.botswana.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "brazil": {
            "radar_data": [
                {
                    "label": "Brazil",
                    "data": [
                        data.brazil.future_readiness_score,
                        data.brazil.physical_capital,
                        data.brazil.human_capital,
                        data.brazil.technology,
                        data.brazil.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Brazil",
            "code": "br",
            "stats": {
                "country": "Brazil",
                "future_readiness_rank": "59",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "212.56",
                "gdp_per_capita": "15,076.40",
                "gdp": "1,444.73",
                "future_readiness_score": "40.93",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.brazil.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bulgaria": {
            "radar_data": [
                {
                    "label": "Bulgaria",
                    "data": [
                        data.bulgaria.future_readiness_score,
                        data.bulgaria.physical_capital,
                        data.bulgaria.human_capital,
                        data.bulgaria.technology,
                        data.bulgaria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bulgaria",
            "code": "bg",
            "stats": {
                "country": "Bulgaria",
                "future_readiness_rank": "46",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "6.93",
                "gdp_per_capita": "24,579.22",
                "gdp": "69.11",
                "future_readiness_score": "45.45",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.bulgaria.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "burkina_faso": {
            "radar_data": [
                {
                    "label": "Burkina Faso",
                    "data": [
                        data.burkina_faso.future_readiness_score,
                        data.burkina_faso.physical_capital,
                        data.burkina_faso.human_capital,
                        data.burkina_faso.technology,
                        data.burkina_faso.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Burkina Faso",
            "code": "bf",
            "stats": {
                "country": "Burkina Faso",
                "future_readiness_rank": "114",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "20.90",
                "gdp_per_capita": "2,270.44",
                "gdp": "17.37",
                "future_readiness_score": "19.67",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.burkina_faso.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cambodia": {
            "radar_data": [
                {
                    "label": "Cambodia",
                    "data": [
                        data.cambodia.future_readiness_score,
                        data.cambodia.physical_capital,
                        data.cambodia.human_capital,
                        data.cambodia.technology,
                        data.cambodia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Cambodia",
            "code": "kh",
            "stats": {
                "country": "Cambodia",
                "future_readiness_rank": "97",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "16.72",
                "gdp_per_capita": "4,574.40",
                "gdp": "25.29",
                "future_readiness_score": "26.20",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.cambodia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cameroon": {
            "radar_data": [
                {
                    "label": "Cameroon",
                    "data": [
                        data.cameroon.future_readiness_score,
                        data.cameroon.physical_capital,
                        data.cameroon.human_capital,
                        data.cameroon.technology,
                        data.cameroon.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Cameroon",
            "code": "cm",
            "stats": {
                "country": "Cameroon",
                "future_readiness_rank": "107",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "26.55",
                "gdp_per_capita": "3,796.31",
                "gdp": "39.80",
                "future_readiness_score": "21.67",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.cameroon.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "canada": {
            "radar_data": [
                {
                    "label": "Canada",
                    "data": [
                        data.canada.future_readiness_score,
                        data.canada.physical_capital,
                        data.canada.human_capital,
                        data.canada.technology,
                        data.canada.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Canada",
            "code": "ca",
            "stats": {
                "country": "Canada",
                "future_readiness_rank": "10",
                "income_group": "High income",
                "regional_group": "Northern America",
                "population": "38.01",
                "gdp_per_capita": "50,510.75",
                "gdp": "1,644.04",
                "future_readiness_score": "69.24",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.canada.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "chile": {
            "radar_data": [
                {
                    "label": "Chile",
                    "data": [
                        data.chile.future_readiness_score,
                        data.chile.physical_capital,
                        data.chile.human_capital,
                        data.chile.technology,
                        data.chile.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Chile",
            "code": "cl",
            "stats": {
                "country": "Chile",
                "future_readiness_rank": "37",
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "19.12",
                "gdp_per_capita": "26,247.39",
                "gdp": "252.94",
                "future_readiness_score": "50.25",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.chile.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "china": {
            "radar_data": [
                {
                    "label": "China",
                    "data": [
                        data.china.future_readiness_score,
                        data.china.physical_capital,
                        data.china.human_capital,
                        data.china.technology,
                        data.china.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "China",
            "code": "cn",
            "stats": {
                "country": "China",
                "future_readiness_rank": "38",
                "income_group": "Upper-middle income",
                "regional_group": "Asia and Pacific",
                "population": "1,402.11",
                "gdp_per_capita": "16,846.80",
                "gdp": "14,722.73",
                "future_readiness_score": "50.01",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.china.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "colombia": {
            "radar_data": [
                {
                    "label": "Colombia",
                    "data": [
                        data.colombia.future_readiness_score,
                        data.colombia.physical_capital,
                        data.colombia.human_capital,
                        data.colombia.technology,
                        data.colombia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Colombia",
            "code": "co",
            "stats": {
                "country": "Colombia",
                "future_readiness_rank": "73",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "50.88",
                "gdp_per_capita": "15,630.08",
                "gdp": "271.35",
                "future_readiness_score": "36.99",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.colombia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "costa_rica": {
            "radar_data": [
                {
                    "label": "Costa Rica",
                    "data": [
                        data.costa_rica.future_readiness_score,
                        data.costa_rica.physical_capital,
                        data.costa_rica.human_capital,
                        data.costa_rica.technology,
                        data.costa_rica.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Costa Rica",
            "code": "cr",
            "stats": {
                "country": "Costa Rica",
                "future_readiness_rank": "52",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "5.09",
                "gdp_per_capita": "21,059.92",
                "gdp": "61.52",
                "future_readiness_score": "43.77",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.costa_rica.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cote_divoire": {
            "radar_data": [
                {
                    "label": "Côte d'Ivoire",
                    "data": [
                        data.cote_divoire.future_readiness_score,
                        data.cote_divoire.physical_capital,
                        data.cote_divoire.human_capital,
                        data.cote_divoire.technology,
                        data.cote_divoire.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Côte d'Ivoire",
            "code": "ci",
            "stats": {
                "country": "Côte d’Ivoire",
                "future_readiness_rank": "108",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "26.38",
                "gdp_per_capita": "5,432.99",
                "gdp": "61.35",
                "future_readiness_score": "21.29",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.cote_divoire.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "croatia": {
            "radar_data": [
                {
                    "label": "Croatia",
                    "data": [
                        data.croatia.future_readiness_score,
                        data.croatia.physical_capital,
                        data.croatia.human_capital,
                        data.croatia.technology,
                        data.croatia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Croatia",
            "code": "hr",
            "stats": {
                "country": "Croatia",
                "future_readiness_rank": "42",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "4.05",
                "gdp_per_capita": "30,245.98",
                "gdp": "55.97",
                "future_readiness_score": "48.46",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.croatia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cyprus": {
            "radar_data": [
                {
                    "label": "Cyprus",
                    "data": [
                        data.cyprus.future_readiness_score,
                        data.cyprus.physical_capital,
                        data.cyprus.human_capital,
                        data.cyprus.technology,
                        data.cyprus.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Cyprus",
            "code": "cy",
            "stats": {
                "country": "Cyprus",
                "future_readiness_rank": "28",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "1.21",
                "gdp_per_capita": "38,458.19",
                "gdp": "23.80",
                "future_readiness_score": "55.30",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.cyprus.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "czech_republic": {
            "radar_data": [
                {
                    "label": "Czech Republic",
                    "data": [
                        data.czech_republic.future_readiness_score,
                        data.czech_republic.physical_capital,
                        data.czech_republic.human_capital,
                        data.czech_republic.technology,
                        data.czech_republic.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Czech Republic",
            "code": "cz",
            "stats": {
                "country": "Czech Republic",
                "future_readiness_rank": "31",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.70",
                "gdp_per_capita": "43,004.53",
                "gdp": "245.35",
                "future_readiness_score": "54.27",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.czech_republic.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "denmark": {
            "radar_data": [
                {
                    "label": "Denmark",
                    "data": [
                        data.denmark.future_readiness_score,
                        data.denmark.physical_capital,
                        data.denmark.human_capital,
                        data.denmark.technology,
                        data.denmark.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Denmark",
            "code": "dk",
            "stats": {
                "country": "Denmark",
                "future_readiness_rank": "2",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.83",
                "gdp_per_capita": "60,334.81",
                "gdp": "356.08",
                "future_readiness_score": "75.89",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.denmark.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "dominican_republic": {
            "radar_data": [
                {
                    "label": "Dominican Republic",
                    "data": [
                        data.dominican_republic.future_readiness_score,
                        data.dominican_republic.physical_capital,
                        data.dominican_republic.human_capital,
                        data.dominican_republic.technology,
                        data.dominican_republic.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Dominican Republic",
            "code": "do",
            "stats": {
                "country": "Dominican Republic",
                "future_readiness_rank": "88",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "10.85",
                "gdp_per_capita": "19,191.58",
                "gdp": "78.84",
                "future_readiness_score": "32.68",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.dominican_republic.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ecuador": {
            "radar_data": [
                {
                    "label": "Ecuador",
                    "data": [
                        data.ecuador.future_readiness_score,
                        data.ecuador.physical_capital,
                        data.ecuador.human_capital,
                        data.ecuador.technology,
                        data.ecuador.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ecuador",
            "code": "ec",
            "stats": {
                "country": "Ecuador",
                "future_readiness_rank": "79",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "17.64",
                "gdp_per_capita": "11,777.76",
                "gdp": "98.81",
                "future_readiness_score": "35.68",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.ecuador.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "egypt": {
            "radar_data": [
                {
                    "label": "Egypt",
                    "data": [
                        data.egypt.future_readiness_score,
                        data.egypt.physical_capital,
                        data.egypt.human_capital,
                        data.egypt.technology,
                        data.egypt.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Egypt",
            "code": "eg",
            "stats": {
                "country": "Egypt",
                "future_readiness_rank": "75",
                "income_group": "Lower-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "102.33",
                "gdp_per_capita": "12,261.18",
                "gdp": "363.07",
                "future_readiness_score": "36.34",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.egypt.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "el_salvador": {
            "radar_data": [
                {
                    "label": "El Salvador",
                    "data": [
                        data.el_salvador.future_readiness_score,
                        data.el_salvador.physical_capital,
                        data.el_salvador.human_capital,
                        data.el_salvador.technology,
                        data.el_salvador.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "El Salvador",
            "code": "sv",
            "stats": {
                "country": "El Salvador",
                "future_readiness_rank": "93",
                "income_group": "Lower-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "6.49",
                "gdp_per_capita": "9,210.99",
                "gdp": "24.64",
                "future_readiness_score": "29.86",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.el_salvador.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "estonia": {
            "radar_data": [
                {
                    "label": "Estonia",
                    "data": [
                        data.estonia.future_readiness_score,
                        data.estonia.physical_capital,
                        data.estonia.human_capital,
                        data.estonia.technology,
                        data.estonia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Estonia",
            "code": "ee",
            "stats": {
                "country": "Estonia",
                "future_readiness_rank": "23",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "1.33",
                "gdp_per_capita": "38,819.34",
                "gdp": "30.65",
                "future_readiness_score": "60.14",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.estonia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ethiopia": {
            "radar_data": [
                {
                    "label": "Ethiopia",
                    "data": [
                        data.ethiopia.future_readiness_score,
                        data.ethiopia.physical_capital,
                        data.ethiopia.human_capital,
                        data.ethiopia.technology,
                        data.ethiopia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ethiopia",
            "code": "et",
            "stats": {
                "country": "Ethiopia",
                "future_readiness_rank": "123",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "114.96",
                "gdp_per_capita": "2,315.35",
                "gdp": "107.65",
                "future_readiness_score": "13.81",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.ethiopia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "finland": {
            "radar_data": [
                {
                    "label": "Finland",
                    "data": [
                        data.finland.future_readiness_score,
                        data.finland.physical_capital,
                        data.finland.human_capital,
                        data.finland.technology,
                        data.finland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Finland",
            "code": "fi",
            "stats": {
                "country": "Finland",
                "future_readiness_rank": "8",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.53",
                "gdp_per_capita": "51,619.83",
                "gdp": "269.75",
                "future_readiness_score": "71.49",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.finland.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "france": {
            "radar_data": [
                {
                    "label": "France",
                    "data": [
                        data.france.future_readiness_score,
                        data.france.physical_capital,
                        data.france.human_capital,
                        data.france.technology,
                        data.france.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "France",
            "code": "fr",
            "stats": {
                "country": "France",
                "future_readiness_rank": "21",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "67.39",
                "gdp_per_capita": "49,377.13",
                "gdp": "2,630.32",
                "future_readiness_score": "61.88",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.france.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "georgia": {
            "radar_data": [
                {
                    "label": "Georgia",
                    "data": [
                        data.georgia.future_readiness_score,
                        data.georgia.physical_capital,
                        data.georgia.human_capital,
                        data.georgia.technology,
                        data.georgia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Georgia",
            "code": "ge",
            "stats": {
                "country": "Georgia",
                "future_readiness_rank": "57",
                "income_group": "Upper-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "3.71",
                "gdp_per_capita": "15,623.15",
                "gdp": "15.89",
                "future_readiness_score": "42.17",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.georgia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "germany": {
            "radar_data": [
                {
                    "label": "Germany",
                    "data": [
                        data.germany.future_readiness_score,
                        data.germany.physical_capital,
                        data.germany.human_capital,
                        data.germany.technology,
                        data.germany.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Germany",
            "code": "de",
            "stats": {
                "country": "Germany",
                "future_readiness_rank": "14",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "83.24",
                "gdp_per_capita": "55,891.20",
                "gdp": "3,846.41",
                "future_readiness_score": "66.28",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.germany.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ghana": {
            "radar_data": [
                {
                    "label": "Ghana",
                    "data": [
                        data.ghana.future_readiness_score,
                        data.ghana.physical_capital,
                        data.ghana.human_capital,
                        data.ghana.technology,
                        data.ghana.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ghana",
            "code": "gh",
            "stats": {
                "country": "Ghana",
                "future_readiness_rank": "96",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "31.07",
                "gdp_per_capita": "5,604.13",
                "gdp": "72.35",
                "future_readiness_score": "26.47",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.ghana.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "greece": {
            "radar_data": [
                {
                    "label": "Greece",
                    "data": [
                        data.greece.future_readiness_score,
                        data.greece.physical_capital,
                        data.greece.human_capital,
                        data.greece.technology,
                        data.greece.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Greece",
            "code": "gr",
            "stats": {
                "country": "Greece",
                "future_readiness_rank": "34",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.72",
                "gdp_per_capita": "30,869.15",
                "gdp": "189.41",
                "future_readiness_score": "51.57",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.greece.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "guatemala": {
            "radar_data": [
                {
                    "label": "Guatemala",
                    "data": [
                        data.guatemala.future_readiness_score,
                        data.guatemala.physical_capital,
                        data.guatemala.human_capital,
                        data.guatemala.technology,
                        data.guatemala.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Guatemala",
            "code": "gl",
            "stats": {
                "country": "Guatemala",
                "future_readiness_rank": "98",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "16.86",
                "gdp_per_capita": "8,982.99",
                "gdp": "77.60",
                "future_readiness_score": "26.12",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.guatemala.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "guinea": {
            "radar_data": [
                {
                    "label": "Guinea",
                    "data": [
                        data.guinea.future_readiness_score,
                        data.guinea.physical_capital,
                        data.guinea.human_capital,
                        data.guinea.technology,
                        data.guinea.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Guinea",
            "code": "gn",
            "stats": {
                "country": "Guinea",
                "future_readiness_rank": "118",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "13.13",
                "gdp_per_capita": "2,434.61",
                "gdp": "15.68",
                "future_readiness_score": "18.24",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.guinea.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "honduras": {
            "radar_data": [
                {
                    "label": "Honduras",
                    "data": [
                        data.honduras.future_readiness_score,
                        data.honduras.physical_capital,
                        data.honduras.human_capital,
                        data.honduras.technology,
                        data.honduras.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Honduras",
            "code": "hn",
            "stats": {
                "country": "Honduras",
                "future_readiness_rank": "100",
                "income_group": "Lower-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "9.90",
                "gdp_per_capita": "5,980.06",
                "gdp": "23.83",
                "future_readiness_score": "24.93",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.honduras.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "hungary": {
            "radar_data": [
                {
                    "label": "Hungary",
                    "data": [
                        data.hungary.future_readiness_score,
                        data.hungary.physical_capital,
                        data.hungary.human_capital,
                        data.hungary.technology,
                        data.hungary.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Hungary",
            "code": "hu",
            "stats": {
                "country": "Hungary",
                "future_readiness_rank": "39",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "9.75",
                "gdp_per_capita": "33,949.63",
                "gdp": "155.01",
                "future_readiness_score": "49.48",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.hungary.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "iceland": {
            "radar_data": [
                {
                    "label": "Iceland",
                    "data": [
                        data.iceland.future_readiness_score,
                        data.iceland.physical_capital,
                        data.iceland.human_capital,
                        data.iceland.technology,
                        data.iceland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Iceland",
            "code": "is",
            "stats": {
                "country": "Iceland",
                "future_readiness_rank": "13",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "0.37",
                "gdp_per_capita": "58,512.65",
                "gdp": "21.72",
                "future_readiness_score": "67.55",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.iceland.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "india": {
            "radar_data": [
                {
                    "label": "India",
                    "data": [
                        data.india.future_readiness_score,
                        data.india.physical_capital,
                        data.india.human_capital,
                        data.india.technology,
                        data.india.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "India",
            "code": "in",
            "stats": {
                "country": "India",
                "future_readiness_rank": "89",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "1,380.00",
                "gdp_per_capita": "6,994.03",
                "gdp": "2,622.98",
                "future_readiness_score": "32.50",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.india.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "indonesia": {
            "radar_data": [
                {
                    "label": "Indonesia",
                    "data": [
                        data.indonesia.future_readiness_score,
                        data.indonesia.physical_capital,
                        data.indonesia.human_capital,
                        data.indonesia.technology,
                        data.indonesia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Indonesia",
            "code": "id",
            "stats": {
                "country": "Indonesia",
                "future_readiness_rank": "76",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "273.52",
                "gdp_per_capita": "12,312.60",
                "gdp": "1,058.42",
                "future_readiness_score": "36.12",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.indonesia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "iran": {
            "radar_data": [
                {
                    "label": "Iran",
                    "data": [
                        data.iran.future_readiness_score,
                        data.iran.physical_capital,
                        data.iran.human_capital,
                        data.iran.technology,
                        data.iran.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Iran",
            "code": "ir",
            "stats": {
                "country": "Iran, Islamic Rep.",
                "future_readiness_rank": "82",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "83.99",
                "gdp_per_capita": "12,913.16",
                "gdp": "191.72",
                "future_readiness_score": "35.05",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.iran.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ireland": {
            "radar_data": [
                {
                    "label": "Ireland",
                    "data": [
                        data.ireland.future_readiness_score,
                        data.ireland.physical_capital,
                        data.ireland.human_capital,
                        data.ireland.technology,
                        data.ireland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ireland",
            "code": "ie",
            "stats": {
                "country": "Ireland",
                "future_readiness_rank": "17",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "4.99",
                "gdp_per_capita": "87,212.05",
                "gdp": "425.89",
                "future_readiness_score": "64.62",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.ireland.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "israel": {
            "radar_data": [
                {
                    "label": "Israel",
                    "data": [
                        data.israel.future_readiness_score,
                        data.israel.physical_capital,
                        data.israel.human_capital,
                        data.israel.technology,
                        data.israel.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Israel",
            "code": "il",
            "stats": {
                "country": "Israel",
                "future_readiness_rank": "20",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "9.22",
                "gdp_per_capita": "41,947.59",
                "gdp": "401.95",
                "future_readiness_score": "61.98",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.israel.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "italy": {
            "radar_data": [
                {
                    "label": "Italy",
                    "data": [
                        data.italy.future_readiness_score,
                        data.italy.physical_capital,
                        data.italy.human_capital,
                        data.italy.technology,
                        data.italy.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Italy",
            "code": "it",
            "stats": {
                "country": "Italy",
                "future_readiness_rank": "29",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "59.55",
                "gdp_per_capita": "44,821.01",
                "gdp": "1,886.45",
                "future_readiness_score": "55.28",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.italy.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "jamaica": {
            "radar_data": [
                {
                    "label": "Jamaica",
                    "data": [
                        data.jamaica.future_readiness_score,
                        data.jamaica.physical_capital,
                        data.jamaica.human_capital,
                        data.jamaica.technology,
                        data.jamaica.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Jamaica",
            "code": "jm",
            "stats": {
                "country": "Jamaica",
                "future_readiness_rank": "80",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "2.96",
                "gdp_per_capita": "10,594.28",
                "gdp": "13.81",
                "future_readiness_score": "35.50",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.jamaica.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "japan": {
            "radar_data": [
                {
                    "label": "Japan",
                    "data": [
                        data.japan.future_readiness_score,
                        data.japan.physical_capital,
                        data.japan.human_capital,
                        data.japan.technology,
                        data.japan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Japan",
            "code": "jp",
            "stats": {
                "country": "Japan",
                "future_readiness_rank": "22",
                "income_group": "High income",
                "regional_group": "Asia and Pacific",
                "population": "125.84",
                "gdp_per_capita": "42,338.03",
                "gdp": "4,975.42",
                "future_readiness_score": "61.81",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.japan.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "jordan": {
            "radar_data": [
                {
                    "label": "Jordan",
                    "data": [
                        data.jordan.future_readiness_score,
                        data.jordan.physical_capital,
                        data.jordan.human_capital,
                        data.jordan.technology,
                        data.jordan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Jordan",
            "code": "jo",
            "stats": {
                "country": "Jordan",
                "future_readiness_rank": "69",
                "income_group": "Upper-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "10.20",
                "gdp_per_capita": "10,497.30",
                "gdp": "43.70",
                "future_readiness_score": "37.75",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.jordan.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kazakhstan": {
            "radar_data": [
                {
                    "label": "Kazakhstan",
                    "data": [
                        data.kazakhstan.future_readiness_score,
                        data.kazakhstan.physical_capital,
                        data.kazakhstan.human_capital,
                        data.kazakhstan.technology,
                        data.kazakhstan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kazakhstan",
            "code": "kz",
            "stats": {
                "country": "Kazakhstan",
                "future_readiness_rank": "65",
                "income_group": "Upper-middle income",
                "regional_group": "Asia and Pacific",
                "population": "18.75",
                "gdp_per_capita": "27,466.22",
                "gdp": "169.84",
                "future_readiness_score": "39.13",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.kazakhstan.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kenya": {
            "radar_data": [
                {
                    "label": "Kenya",
                    "data": [
                        data.kenya.future_readiness_score,
                        data.kenya.physical_capital,
                        data.kenya.human_capital,
                        data.kenya.technology,
                        data.kenya.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kenya",
            "code": "ke",
            "stats": {
                "country": "Kenya",
                "future_readiness_rank": "95",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "53.77",
                "gdp_per_capita": "4,512.98",
                "gdp": "98.84",
                "future_readiness_score": "27.62",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.kenya.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kuwait": {
            "radar_data": [
                {
                    "label": "Kuwait",
                    "data": [
                        data.kuwait.future_readiness_score,
                        data.kuwait.physical_capital,
                        data.kuwait.human_capital,
                        data.kuwait.technology,
                        data.kuwait.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kuwait",
            "code": "kw",
            "stats": {
                "country": "Kuwait",
                "future_readiness_rank": "53",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "4.27",
                "gdp_per_capita": "51,363.74",
                "gdp": "136.20",
                "future_readiness_score": "43.66",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.kuwait.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kyrgyzstan": {
            "radar_data": [
                {
                    "label": "Kyrgyzstan",
                    "data": [
                        data.kyrgyzstan.future_readiness_score,
                        data.kyrgyzstan.physical_capital,
                        data.kyrgyzstan.human_capital,
                        data.kyrgyzstan.technology,
                        data.kyrgyzstan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kyrgyzstan",
            "code": "kg",
            "stats": {
                "country": "Kyrgyzstan",
                "future_readiness_rank": "91",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "6.59",
                "gdp_per_capita": "5,223.48",
                "gdp": "7.74",
                "future_readiness_score": "31.70",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.kyrgyzstan.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "laos": {
            "radar_data": [
                {
                    "label": "Laos",
                    "data": [
                        data.laos.future_readiness_score,
                        data.laos.physical_capital,
                        data.laos.human_capital,
                        data.laos.technology,
                        data.laos.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Laos",
            "code": "la",
            "stats": {
                "country": "Lao PDR",
                "future_readiness_rank": "105",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "7.28",
                "gdp_per_capita": "8,187.70",
                "gdp": "19.14",
                "future_readiness_score": "23.46",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.laos.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "latvia": {
            "radar_data": [
                {
                    "label": "Latvia",
                    "data": [
                        data.latvia.future_readiness_score,
                        data.latvia.physical_capital,
                        data.latvia.human_capital,
                        data.latvia.technology,
                        data.latvia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Latvia",
            "code": "lv",
            "stats": {
                "country": "Latvia",
                "future_readiness_rank": "36",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "1.90",
                "gdp_per_capita": "32,047.35",
                "gdp": "33.51",
                "future_readiness_score": "50.76",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.latvia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "lebanon": {
            "radar_data": [
                {
                    "label": "Lebanon",
                    "data": [
                        data.lebanon.future_readiness_score,
                        data.lebanon.physical_capital,
                        data.lebanon.human_capital,
                        data.lebanon.technology,
                        data.lebanon.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Lebanon",
            "code": "lb",
            "stats": {
                "country": "Lebanon",
                "future_readiness_rank": "71",
                "income_group": "Upper-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "6.83",
                "gdp_per_capita": "15,166.98",
                "gdp": "33.38",
                "future_readiness_score": "37.36",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.lebanon.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "lithuania": {
            "radar_data": [
                {
                    "label": "Lithuania",
                    "data": [
                        data.lithuania.future_readiness_score,
                        data.lithuania.physical_capital,
                        data.lithuania.human_capital,
                        data.lithuania.technology,
                        data.lithuania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Lithuania",
            "code": "lt",
            "stats": {
                "country": "Lithuania",
                "future_readiness_rank": "32",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "2.79",
                "gdp_per_capita": "38,756.11",
                "gdp": "55.89",
                "future_readiness_score": "53.46",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.lithuania.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "luxembourg": {
            "radar_data": [
                {
                    "label": "Luxembourg",
                    "data": [
                        data.luxembourg.future_readiness_score,
                        data.luxembourg.physical_capital,
                        data.luxembourg.human_capital,
                        data.luxembourg.technology,
                        data.luxembourg.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Luxembourg",
            "code": "lu",
            "stats": {
                "country": "Luxembourg",
                "future_readiness_rank": "12",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "0.63",
                "gdp_per_capita": "120,962.19",
                "gdp": "73.26",
                "future_readiness_score": "68.63",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.luxembourg.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "madagascar": {
            "radar_data": [
                {
                    "label": "Madagascar",
                    "data": [
                        data.madagascar.future_readiness_score,
                        data.madagascar.physical_capital,
                        data.madagascar.human_capital,
                        data.madagascar.technology,
                        data.madagascar.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Madagascar",
            "code": "mg",
            "stats": {
                "country": "Madagascar",
                "future_readiness_rank": "121",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "27.69",
                "gdp_per_capita": "1,677.79",
                "gdp": "13.72",
                "future_readiness_score": "16.83",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.madagascar.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "malawi": {
            "radar_data": [
                {
                    "label": "Malawi",
                    "data": [
                        data.malawi.future_readiness_score,
                        data.malawi.physical_capital,
                        data.malawi.human_capital,
                        data.malawi.technology,
                        data.malawi.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Malawi",
            "code": "mw",
            "stats": {
                "country": "Malawi",
                "future_readiness_rank": "120",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "19.13",
                "gdp_per_capita": "1,114.83",
                "gdp": "11.96",
                "future_readiness_score": "17.75",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.malawi.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "malaysia": {
            "radar_data": [
                {
                    "label": "Malaysia",
                    "data": [
                        data.malaysia.future_readiness_score,
                        data.malaysia.physical_capital,
                        data.malaysia.human_capital,
                        data.malaysia.technology,
                        data.malaysia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Malaysia",
            "code": "my",
            "stats": {
                "country": "Malaysia",
                "future_readiness_rank": "40",
                "income_group": "Upper-middle income",
                "regional_group": "Asia and Pacific",
                "population": "32.37",
                "gdp_per_capita": "29,564.01",
                "gdp": "336.66",
                "future_readiness_score": "49.46",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.malaysia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mali": {
            "radar_data": [
                {
                    "label": "Mali",
                    "data": [
                        data.mali.future_readiness_score,
                        data.mali.physical_capital,
                        data.mali.human_capital,
                        data.mali.technology,
                        data.mali.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mali",
            "code": "ml",
            "stats": {
                "country": "Mali",
                "future_readiness_rank": "116",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "20.25",
                "gdp_per_capita": "2,419.79",
                "gdp": "17.39",
                "future_readiness_score": "19.54",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.mali.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "malta": {
            "radar_data": [
                {
                    "label": "Malta",
                    "data": [
                        data.malta.future_readiness_score,
                        data.malta.physical_capital,
                        data.malta.human_capital,
                        data.malta.technology,
                        data.malta.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Malta",
            "code": "mt",
            "stats": {
                "country": "Malta",
                "future_readiness_rank": "24",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "0.53",
                "gdp_per_capita": "46,071.22",
                "gdp": "14.65",
                "future_readiness_score": "58.56",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.malta.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mauritius": {
            "radar_data": [
                {
                    "label": "Mauritius",
                    "data": [
                        data.mauritius.future_readiness_score,
                        data.mauritius.physical_capital,
                        data.mauritius.human_capital,
                        data.mauritius.technology,
                        data.mauritius.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mauritius",
            "code": "mu",
            "stats": {
                "country": "Mauritius",
                "future_readiness_rank": "49",
                "income_group": "Upper-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "1.27",
                "gdp_per_capita": "23,841.01",
                "gdp": "10.91",
                "future_readiness_score": "44.01",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.mauritius.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mexico": {
            "radar_data": [
                {
                    "label": "Mexico",
                    "data": [
                        data.mexico.future_readiness_score,
                        data.mexico.physical_capital,
                        data.mexico.human_capital,
                        data.mexico.technology,
                        data.mexico.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mexico",
            "code": "mx",
            "stats": {
                "country": "Mexico",
                "future_readiness_rank": "62",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "128.93",
                "gdp_per_capita": "20,447.89",
                "gdp": "1,076.16",
                "future_readiness_score": "40.18",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.mexico.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "moldova": {
            "radar_data": [
                {
                    "label": "Moldova",
                    "data": [
                        data.moldova.future_readiness_score,
                        data.moldova.physical_capital,
                        data.moldova.human_capital,
                        data.moldova.technology,
                        data.moldova.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Moldova",
            "code": "md",
            "stats": {
                "country": "Moldova, Rep.",
                "future_readiness_rank": "74",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "2.62",
                "gdp_per_capita": "13,572.70",
                "gdp": "11.91",
                "future_readiness_score": "36.46",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.moldova.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mongolia": {
            "radar_data": [
                {
                    "label": "Mongolia",
                    "data": [
                        data.mongolia.future_readiness_score,
                        data.mongolia.physical_capital,
                        data.mongolia.human_capital,
                        data.mongolia.technology,
                        data.mongolia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mongolia",
            "code": "mn",
            "stats": {
                "country": "Mongolia",
                "future_readiness_rank": "87",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "3.28",
                "gdp_per_capita": "12,837.67",
                "gdp": "13.14",
                "future_readiness_score": "33.11",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.mongolia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "morocco": {
            "radar_data": [
                {
                    "label": "Morocco",
                    "data": [
                        data.morocco.future_readiness_score,
                        data.morocco.physical_capital,
                        data.morocco.human_capital,
                        data.morocco.technology,
                        data.morocco.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Morocco",
            "code": "ma",
            "stats": {
                "country": "Morocco",
                "future_readiness_rank": "84",
                "income_group": "Lower-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "36.91",
                "gdp_per_capita": "7,296.16",
                "gdp": "112.87",
                "future_readiness_score": "34.88",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.morocco.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mozambique": {
            "radar_data": [
                {
                    "label": "Mozambique",
                    "data": [
                        data.mozambique.future_readiness_score,
                        data.mozambique.physical_capital,
                        data.mozambique.human_capital,
                        data.mozambique.technology,
                        data.mozambique.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mozambique",
            "code": "mz",
            "stats": {
                "country": "Mozambique",
                "future_readiness_rank": "122",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "31.26",
                "gdp_per_capita": "1,335.70",
                "gdp": "14.02",
                "future_readiness_score": "15.73",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.mozambique.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "myanmar": {
            "radar_data": [
                {
                    "label": "Myanmar",
                    "data": [
                        data.myanmar.future_readiness_score,
                        data.myanmar.physical_capital,
                        data.myanmar.human_capital,
                        data.myanmar.technology,
                        data.myanmar.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Myanmar",
            "code": "mm",
            "stats": {
                "country": "Myanmar",
                "future_readiness_rank": "112",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "54.41",
                "gdp_per_capita": "5,412.94",
                "gdp": "76.19",
                "future_readiness_score": "20.07",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.myanmar.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "nepal": {
            "radar_data": [
                {
                    "label": "Nepal",
                    "data": [
                        data.nepal.future_readiness_score,
                        data.nepal.physical_capital,
                        data.nepal.human_capital,
                        data.nepal.technology,
                        data.nepal.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Nepal",
            "code": "np",
            "stats": {
                "country": "Nepal",
                "future_readiness_rank": "103",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "29.14",
                "gdp_per_capita": "3,692.72",
                "gdp": "33.66",
                "future_readiness_score": "23.86",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.nepal.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "netherlands": {
            "radar_data": [
                {
                    "label": "Netherlands",
                    "data": [
                        data.netherlands.future_readiness_score,
                        data.netherlands.physical_capital,
                        data.netherlands.human_capital,
                        data.netherlands.technology,
                        data.netherlands.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Netherlands",
            "code": "nl",
            "stats": {
                "country": "Netherlands",
                "future_readiness_rank": "7",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "17.44",
                "gdp_per_capita": "59,469.08",
                "gdp": "913.87",
                "future_readiness_score": "71.67",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.netherlands.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "new_zealand": {
            "radar_data": [
                {
                    "label": "New Zealand",
                    "data": [
                        data.new_zealand.future_readiness_score,
                        data.new_zealand.physical_capital,
                        data.new_zealand.human_capital,
                        data.new_zealand.technology,
                        data.new_zealand.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "New Zealand",
            "code": "nz",
            "stats": {
                "country": "New Zealand",
                "future_readiness_rank": "15",
                "income_group": "High income",
                "regional_group": "Asia and Pacific",
                "population": "5.08",
                "gdp_per_capita": "44,251.79",
                "gdp": "210.89",
                "future_readiness_score": "64.90",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.new_zealand.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "niger": {
            "radar_data": [
                {
                    "label": "Niger",
                    "data": [
                        data.niger.future_readiness_score,
                        data.niger.physical_capital,
                        data.niger.human_capital,
                        data.niger.technology,
                        data.niger.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Niger",
            "code": "ne",
            "stats": {
                "country": "Niger",
                "future_readiness_rank": "124",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "24.21",
                "gdp_per_capita": "1,276.29",
                "gdp": "13.68",
                "future_readiness_score": "10.06",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.niger.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "nigeria": {
            "radar_data": [
                {
                    "label": "Nigeria",
                    "data": [
                        data.nigeria.future_readiness_score,
                        data.nigeria.physical_capital,
                        data.nigeria.human_capital,
                        data.nigeria.technology,
                        data.nigeria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Nigeria",
            "code": "ng",
            "stats": {
                "country": "Nigeria",
                "future_readiness_rank": "106",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "206.14",
                "gdp_per_capita": "5,352.68",
                "gdp": "432.29",
                "future_readiness_score": "22.58",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.nigeria.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "north_macedonia": {
            "radar_data": [
                {
                    "label": "North Macedonia",
                    "data": [
                        data.north_macedonia.future_readiness_score,
                        data.north_macedonia.physical_capital,
                        data.north_macedonia.human_capital,
                        data.north_macedonia.technology,
                        data.north_macedonia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "North Macedonia",
            "code": "mk",
            "stats": {
                "country": "North Macedonia",
                "future_readiness_rank": "63",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "2.08",
                "gdp_per_capita": "17,583.38",
                "gdp": "12.27",
                "future_readiness_score": "40.10",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.north_macedonia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "norway": {
            "radar_data": [
                {
                    "label": "Norway",
                    "data": [
                        data.norway.future_readiness_score,
                        data.norway.physical_capital,
                        data.norway.human_capital,
                        data.norway.technology,
                        data.norway.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Norway",
            "code": "no",
            "stats": {
                "country": "Norway",
                "future_readiness_rank": "5",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.38",
                "gdp_per_capita": "67,978.72",
                "gdp": "362.52",
                "future_readiness_score": "74.07",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.norway.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "oman": {
            "radar_data": [
                {
                    "label": "Oman",
                    "data": [
                        data.oman.future_readiness_score,
                        data.oman.physical_capital,
                        data.oman.human_capital,
                        data.oman.technology,
                        data.oman.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Oman",
            "code": "om",
            "stats": {
                "country": "Oman",
                "future_readiness_rank": "48",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "5.11",
                "gdp_per_capita": "28,448.86",
                "gdp": "76.33",
                "future_readiness_score": "44.18",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.oman.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "pakistan": {
            "radar_data": [
                {
                    "label": "Pakistan",
                    "data": [
                        data.pakistan.future_readiness_score,
                        data.pakistan.physical_capital,
                        data.pakistan.human_capital,
                        data.pakistan.technology,
                        data.pakistan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Pakistan",
            "code": "pk",
            "stats": {
                "country": "Pakistan",
                "future_readiness_rank": "104",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "220.89",
                "gdp_per_capita": "4,888.85",
                "gdp": "263.69",
                "future_readiness_score": "23.77",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.pakistan.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "panama": {
            "radar_data": [
                {
                    "label": "Panama",
                    "data": [
                        data.panama.future_readiness_score,
                        data.panama.physical_capital,
                        data.panama.human_capital,
                        data.panama.technology,
                        data.panama.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Panama",
            "code": "pa",
            "stats": {
                "country": "Panama",
                "future_readiness_rank": "68",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "4.31",
                "gdp_per_capita": "32,767.70",
                "gdp": "52.94",
                "future_readiness_score": "38.15",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.panama.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "paraguay": {
            "radar_data": [
                {
                    "label": "Paraguay",
                    "data": [
                        data.paraguay.future_readiness_score,
                        data.paraguay.physical_capital,
                        data.paraguay.human_capital,
                        data.paraguay.technology,
                        data.paraguay.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Paraguay",
            "code": "py",
            "stats": {
                "country": "Paraguay",
                "future_readiness_rank": "70",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "7.13",
                "gdp_per_capita": "13,231.71",
                "gdp": "35.30",
                "future_readiness_score": "37.57",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.paraguay.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "peru": {
            "radar_data": [
                {
                    "label": "Peru",
                    "data": [
                        data.peru.future_readiness_score,
                        data.peru.physical_capital,
                        data.peru.human_capital,
                        data.peru.technology,
                        data.peru.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Peru",
            "code": "pe",
            "stats": {
                "country": "Peru",
                "future_readiness_rank": "83",
                "income_group": "Upper-middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "32.97",
                "gdp_per_capita": "13,302.11",
                "gdp": "202.01",
                "future_readiness_score": "35.00",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.peru.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "philippines": {
            "radar_data": [
                {
                    "label": "Philippines",
                    "data": [
                        data.philippines.future_readiness_score,
                        data.philippines.physical_capital,
                        data.philippines.human_capital,
                        data.philippines.technology,
                        data.philippines.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Philippines",
            "code": "ph",
            "stats": {
                "country": "Philippines",
                "future_readiness_rank": "81",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "109.58",
                "gdp_per_capita": "9,291.04",
                "gdp": "361.49",
                "future_readiness_score": "35.29",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.philippines.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "poland": {
            "radar_data": [
                {
                    "label": "Poland",
                    "data": [
                        data.poland.future_readiness_score,
                        data.poland.physical_capital,
                        data.poland.human_capital,
                        data.poland.technology,
                        data.poland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Poland",
            "code": "pl",
            "stats": {
                "country": "Poland",
                "future_readiness_rank": "35",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "37.95",
                "gdp_per_capita": "34,151.79",
                "gdp": "594.16",
                "future_readiness_score": "50.97",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.poland.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "portugal": {
            "radar_data": [
                {
                    "label": "Portugal",
                    "data": [
                        data.portugal.future_readiness_score,
                        data.portugal.physical_capital,
                        data.portugal.human_capital,
                        data.portugal.technology,
                        data.portugal.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Portugal",
            "code": "pt",
            "stats": {
                "country": "Portugal",
                "future_readiness_rank": "26",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.31",
                "gdp_per_capita": "36,760.12",
                "gdp": "231.23",
                "future_readiness_score": "57.72",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.portugal.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "qatar": {
            "radar_data": [
                {
                    "label": "Qatar",
                    "data": [
                        data.qatar.future_readiness_score,
                        data.qatar.physical_capital,
                        data.qatar.human_capital,
                        data.qatar.technology,
                        data.qatar.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Qatar",
            "code": "qa",
            "stats": {
                "country": "Qatar",
                "future_readiness_rank": "33",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "2.88",
                "gdp_per_capita": "93,851.75",
                "gdp": "146.37",
                "future_readiness_score": "53.45",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.qatar.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "romania": {
            "radar_data": [
                {
                    "label": "Romania",
                    "data": [
                        data.romania.future_readiness_score,
                        data.romania.physical_capital,
                        data.romania.human_capital,
                        data.romania.technology,
                        data.romania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Romania",
            "code": "ro",
            "stats": {
                "country": "Romania",
                "future_readiness_rank": "50",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "19.29",
                "gdp_per_capita": "32,349.20",
                "gdp": "248.72",
                "future_readiness_score": "44.00",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.romania.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "russia": {
            "radar_data": [
                {
                    "label": "Russia",
                    "data": [
                        data.russia.future_readiness_score,
                        data.russia.physical_capital,
                        data.russia.human_capital,
                        data.russia.technology,
                        data.russia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Russia",
            "code": "ru",
            "stats": {
                "country": "Russian Federation",
                "future_readiness_rank": "54",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "144.10",
                "gdp_per_capita": "28,213.45",
                "gdp": "1,483.50",
                "future_readiness_score": "42.98",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.russia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "rwanda": {
            "radar_data": [
                {
                    "label": "Rwanda",
                    "data": [
                        data.rwanda.future_readiness_score,
                        data.rwanda.physical_capital,
                        data.rwanda.human_capital,
                        data.rwanda.technology,
                        data.rwanda.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Rwanda",
            "code": "rw",
            "stats": {
                "country": "Rwanda",
                "future_readiness_rank": "99",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "12.95",
                "gdp_per_capita": "2,321.37",
                "gdp": "10.33",
                "future_readiness_score": "25.35",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.rwanda.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "saudi_arabia": {
            "radar_data": [
                {
                    "label": "Saudi Arabia",
                    "data": [
                        data.saudi_arabia.future_readiness_score,
                        data.saudi_arabia.physical_capital,
                        data.saudi_arabia.human_capital,
                        data.saudi_arabia.technology,
                        data.saudi_arabia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Saudi Arabia",
            "code": "sa",
            "stats": {
                "country": "Saudi Arabia",
                "future_readiness_rank": "44",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "34.81",
                "gdp_per_capita": "48,948.17",
                "gdp": "700.12",
                "future_readiness_score": "47.25",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.saudi_arabia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "senegal": {
            "radar_data": [
                {
                    "label": "Senegal",
                    "data": [
                        data.senegal.future_readiness_score,
                        data.senegal.physical_capital,
                        data.senegal.human_capital,
                        data.senegal.technology,
                        data.senegal.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Senegal",
            "code": "sn",
            "stats": {
                "country": "Senegal",
                "future_readiness_rank": "101",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "16.74",
                "gdp_per_capita": "3,544.49",
                "gdp": "24.91",
                "future_readiness_score": "24.04",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.senegal.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "serbia": {
            "radar_data": [
                {
                    "label": "Serbia",
                    "data": [
                        data.serbia.future_readiness_score,
                        data.serbia.physical_capital,
                        data.serbia.human_capital,
                        data.serbia.technology,
                        data.serbia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Serbia",
            "code": "rs",
            "stats": {
                "country": "Serbia",
                "future_readiness_rank": "51",
                "income_group": "Upper-middle income",
                "regional_group": "Europe",
                "population": "6.91",
                "gdp_per_capita": "18,929.88",
                "gdp": "52.96",
                "future_readiness_score": "43.81",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.serbia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "singapore": {
            "radar_data": [
                {
                    "label": "Singapore",
                    "data": [
                        data.singapore.future_readiness_score,
                        data.singapore.physical_capital,
                        data.singapore.human_capital,
                        data.singapore.technology,
                        data.singapore.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Singapore",
            "code": "sg",
            "stats": {
                "country": "Singapore",
                "future_readiness_rank": "1",
                "income_group": "High income",
                "regional_group": "Asia and Pacific",
                "population": "5.69",
                "gdp_per_capita": "101,936.74",
                "gdp": "340.00",
                "future_readiness_score": "76.26",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.singapore.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "slovakia": {
            "radar_data": [
                {
                    "label": "Slovakia",
                    "data": [
                        data.slovakia.future_readiness_score,
                        data.slovakia.physical_capital,
                        data.slovakia.human_capital,
                        data.slovakia.technology,
                        data.slovakia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Slovakia",
            "code": "sk",
            "stats": {
                "country": "Slovakia",
                "future_readiness_rank": "41",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.46",
                "gdp_per_capita": "32,544.96",
                "gdp": "104.57",
                "future_readiness_score": "49.21",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.slovakia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "slovenia": {
            "radar_data": [
                {
                    "label": "Slovenia",
                    "data": [
                        data.slovenia.future_readiness_score,
                        data.slovenia.physical_capital,
                        data.slovenia.human_capital,
                        data.slovenia.technology,
                        data.slovenia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Slovenia",
            "code": "si",
            "stats": {
                "country": "Slovenia",
                "future_readiness_rank": "30",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "2.10",
                "gdp_per_capita": "41,193.84",
                "gdp": "53.59",
                "future_readiness_score": "54.78",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.slovenia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "south_africa": {
            "radar_data": [
                {
                    "label": "South Africa",
                    "data": [
                        data.south_africa.future_readiness_score,
                        data.south_africa.physical_capital,
                        data.south_africa.human_capital,
                        data.south_africa.technology,
                        data.south_africa.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "South Africa",
            "code": "za",
            "stats": {
                "country": "South Africa",
                "future_readiness_rank": "66",
                "income_group": "Upper-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "59.31",
                "gdp_per_capita": "13,009.67",
                "gdp": "301.92",
                "future_readiness_score": "39.01",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.south_africa.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "south_korea": {
            "radar_data": [
                {
                    "label": "South Korea",
                    "data": [
                        data.south_korea.future_readiness_score,
                        data.south_korea.physical_capital,
                        data.south_korea.human_capital,
                        data.south_korea.technology,
                        data.south_korea.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "South Korea",
            "code": "kr",
            "stats": {
                "country": "South Korea",
                "future_readiness_rank": "18",
                "income_group": "High income",
                "regional_group": "Asia and Pacific",
                "population": "51.78",
                "gdp_per_capita": "42,727.95",
                "gdp": "1,630.53",
                "future_readiness_score": "64.14",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.south_korea.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "spain": {
            "radar_data": [
                {
                    "label": "Spain",
                    "data": [
                        data.spain.future_readiness_score,
                        data.spain.physical_capital,
                        data.spain.human_capital,
                        data.spain.technology,
                        data.spain.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Spain",
            "code": "es",
            "stats": {
                "country": "Spain",
                "future_readiness_rank": "25",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "47.35",
                "gdp_per_capita": "42,185.59",
                "gdp": "1,281.48",
                "future_readiness_score": "57.95",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.spain.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "sri_lanka": {
            "radar_data": [
                {
                    "label": "Sri Lanka",
                    "data": [
                        data.sri_lanka.future_readiness_score,
                        data.sri_lanka.physical_capital,
                        data.sri_lanka.human_capital,
                        data.sri_lanka.technology,
                        data.sri_lanka.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Sri Lanka",
            "code": "lk",
            "stats": {
                "country": "Sri Lanka",
                "future_readiness_rank": "90",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "21.92",
                "gdp_per_capita": "13,625.75",
                "gdp": "80.71",
                "future_readiness_score": "32.17",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.sri_lanka.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "sweden": {
            "radar_data": [
                {
                    "label": "Sweden",
                    "data": [
                        data.sweden.future_readiness_score,
                        data.sweden.physical_capital,
                        data.sweden.human_capital,
                        data.sweden.technology,
                        data.sweden.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Sweden",
            "code": "se",
            "stats": {
                "country": "Sweden",
                "future_readiness_rank": "4",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.35",
                "gdp_per_capita": "55,027.37",
                "gdp": "541.06",
                "future_readiness_score": "74.72",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.sweden.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "switzerland": {
            "radar_data": [
                {
                    "label": "Switzerland",
                    "data": [
                        data.switzerland.future_readiness_score,
                        data.switzerland.physical_capital,
                        data.switzerland.human_capital,
                        data.switzerland.technology,
                        data.switzerland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Switzerland",
            "code": "ch",
            "stats": {
                "country": "Switzerland",
                "future_readiness_rank": "3",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "8.64",
                "gdp_per_capita": "70,276.55",
                "gdp": "752.25",
                "future_readiness_score": "75.71",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.switzerland.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "tanzania": {
            "radar_data": [
                {
                    "label": "Tanzania",
                    "data": [
                        data.tanzania.future_readiness_score,
                        data.tanzania.physical_capital,
                        data.tanzania.human_capital,
                        data.tanzania.technology,
                        data.tanzania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Tanzania",
            "code": "tz",
            "stats": {
                "country": "Tanzania, United Rep.",
                "future_readiness_rank": "109",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "59.73",
                "gdp_per_capita": "2,780.06",
                "gdp": "62.41",
                "future_readiness_score": "20.75",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.tanzania.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "thailand": {
            "radar_data": [
                {
                    "label": "Thailand",
                    "data": [
                        data.thailand.future_readiness_score,
                        data.thailand.physical_capital,
                        data.thailand.human_capital,
                        data.thailand.technology,
                        data.thailand.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Thailand",
            "code": "th",
            "stats": {
                "country": "Thailand",
                "future_readiness_rank": "56",
                "income_group": "Upper-middle income",
                "regional_group": "Asia and Pacific",
                "population": "69.80",
                "gdp_per_capita": "19,208.60",
                "gdp": "501.79",
                "future_readiness_score": "42.37",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.thailand.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "togo": {
            "radar_data": [
                {
                    "label": "Togo",
                    "data": [
                        data.togo.future_readiness_score,
                        data.togo.physical_capital,
                        data.togo.human_capital,
                        data.togo.technology,
                        data.togo.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Togo",
            "code": "tg",
            "stats": {
                "country": "Togo",
                "future_readiness_rank": "117",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "8.28",
                "gdp_per_capita": "1,681.69",
                "gdp": "7.57",
                "future_readiness_score": "18.48",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.togo.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "trinidad_and_tobago": {
            "radar_data": [
                {
                    "label": "Trinidad and Tobago",
                    "data": [
                        data.trinidad_and_tobago.future_readiness_score,
                        data.trinidad_and_tobago.physical_capital,
                        data.trinidad_and_tobago.human_capital,
                        data.trinidad_and_tobago.technology,
                        data.trinidad_and_tobago.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Trinidad and Tobago",
            "code": "tt",
            "stats": {
                "country": "Trinidad and Tobago",
                "future_readiness_rank": "72",
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "1.40",
                "gdp_per_capita": "28,151.29",
                "gdp": "21.53",
                "future_readiness_score": "37.08",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.trinidad_and_tobago.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "tunisia": {
            "radar_data": [
                {
                    "label": "Tunisia",
                    "data": [
                        data.tunisia.future_readiness_score,
                        data.tunisia.physical_capital,
                        data.tunisia.human_capital,
                        data.tunisia.technology,
                        data.tunisia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Tunisia",
            "code": "tn",
            "stats": {
                "country": "Tunisia",
                "future_readiness_rank": "78",
                "income_group": "Lower-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "11.82",
                "gdp_per_capita": "11,096.30",
                "gdp": "39.24",
                "future_readiness_score": "35.93",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.tunisia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "turkey": {
            "radar_data": [
                {
                    "label": "Turkey",
                    "data": [
                        data.turkey.future_readiness_score,
                        data.turkey.physical_capital,
                        data.turkey.human_capital,
                        data.turkey.technology,
                        data.turkey.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Turkey",
            "code": "tr",
            "stats": {
                "country": "Turkey",
                "future_readiness_rank": "47",
                "income_group": "Upper-middle income",
                "regional_group": "Middle East and North Africa",
                "population": "84.34",
                "gdp_per_capita": "27,318.43",
                "gdp": "720.10",
                "future_readiness_score": "44.21",
                "future_readiness_score_income_group_average": "39.69",
                "future_readiness_ranking": data.turkey.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "uganda": {
            "radar_data": [
                {
                    "label": "Uganda",
                    "data": [
                        data.uganda.future_readiness_score,
                        data.uganda.physical_capital,
                        data.uganda.human_capital,
                        data.uganda.technology,
                        data.uganda.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Uganda",
            "code": "ug",
            "stats": {
                "country": "Uganda",
                "future_readiness_rank": "113",
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "45.74",
                "gdp_per_capita": "2,279.97",
                "gdp": "37.37",
                "future_readiness_score": "19.86",
                "future_readiness_score_income_group_average": "17.76",
                "future_readiness_ranking": data.uganda.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ukraine": {
            "radar_data": [
                {
                    "label": "Ukraine",
                    "data": [
                        data.ukraine.future_readiness_score,
                        data.ukraine.physical_capital,
                        data.ukraine.human_capital,
                        data.ukraine.technology,
                        data.ukraine.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ukraine",
            "code": "ua",
            "stats": {
                "country": "Ukraine",
                "future_readiness_rank": "61",
                "income_group": "Lower-middle income",
                "regional_group": "Europe",
                "population": "44.13",
                "gdp_per_capita": "13,056.70",
                "gdp": "155.58",
                "future_readiness_score": "40.31",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.ukraine.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "united_arab_emirates": {
            "radar_data": [
                {
                    "label": "United Arab Emirates",
                    "data": [
                        data.united_arab_emirates.future_readiness_score,
                        data.united_arab_emirates.physical_capital,
                        data.united_arab_emirates.human_capital,
                        data.united_arab_emirates.technology,
                        data.united_arab_emirates.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "United Arab Emirates",
            "code": "ae",
            "stats": {
                "country": "United Arab Emirates",
                "future_readiness_rank": "27",
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "9.89",
                "gdp_per_capita": "69,957.62",
                "gdp": "421.14",
                "future_readiness_score": "57.22",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.united_arab_emirates.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "united_kingdom": {
            "radar_data": [
                {
                    "label": "United Kingdom",
                    "data": [
                        data.united_kingdom.future_readiness_score,
                        data.united_kingdom.physical_capital,
                        data.united_kingdom.human_capital,
                        data.united_kingdom.technology,
                        data.united_kingdom.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "United Kingdom",
            "code": "gb",
            "stats": {
                "country": "United Kingdom",
                "future_readiness_rank": "9",
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "67.22",
                "gdp_per_capita": "48,438.58",
                "gdp": "2,707.74",
                "future_readiness_score": "69.37",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.united_kingdom.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "united_states": {
            "radar_data": [
                {
                    "label": "United States",
                    "data": [
                        data.united_states.future_readiness_score,
                        data.united_states.physical_capital,
                        data.united_states.human_capital,
                        data.united_states.technology,
                        data.united_states.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "United States",
            "code": "us",
            "stats": {
                "country": "United States of America",
                "future_readiness_rank": "6",
                "income_group": "High income",
                "regional_group": "Northern America",
                "population": "329.48",
                "gdp_per_capita": "65,279.53",
                "gdp": "20,936.60",
                "future_readiness_score": "73.55",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.united_states.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "uruguay": {
            "radar_data": [
                {
                    "label": "Uruguay",
                    "data": [
                        data.uruguay.future_readiness_score,
                        data.uruguay.physical_capital,
                        data.uruguay.human_capital,
                        data.uruguay.technology,
                        data.uruguay.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Uruguay",
            "code": "uy",
            "stats": {
                "country": "Uruguay",
                "future_readiness_rank": "45",
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "3.47",
                "gdp_per_capita": "21,973.82",
                "gdp": "53.63",
                "future_readiness_score": "46.10",
                "future_readiness_score_income_group_average": "59.71",
                "future_readiness_ranking": data.uruguay.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "vietnam": {
            "radar_data": [
                {
                    "label": "Vietnam",
                    "data": [
                        data.vietnam.future_readiness_score,
                        data.vietnam.physical_capital,
                        data.vietnam.human_capital,
                        data.vietnam.technology,
                        data.vietnam.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Vietnam",
            "code": "vn",
            "stats": {
                "country": "Viet Nam",
                "future_readiness_rank": "64",
                "income_group": "Lower-middle income",
                "regional_group": "Asia and Pacific",
                "population": "97.34",
                "gdp_per_capita": "8,381.24",
                "gdp": "271.16",
                "future_readiness_score": "40.07",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.vietnam.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "zambia": {
            "radar_data": [
                {
                    "label": "Zambia",
                    "data": [
                        data.zambia.future_readiness_score,
                        data.zambia.physical_capital,
                        data.zambia.human_capital,
                        data.zambia.technology,
                        data.zambia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Zambia",
            "code": "zm",
            "stats": {
                "country": "Zambia",
                "future_readiness_rank": "110",
                "income_group": "Lower-middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "18.38",
                "gdp_per_capita": "3,617.21",
                "gdp": "19.32",
                "future_readiness_score": "20.33",
                "future_readiness_score_income_group_average": "27.97",
                "future_readiness_ranking": data.zambia.future_readiness_rank,
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "zimbabwe": {
            "radar_data": [
                {
                    "label": "Zimbabwe",
                    "data": [
                        data.zimbabwe.future_readiness_score,
                        data.zimbabwe.physical_capital,
                        data.zimbabwe.human_capital,
                        data.zimbabwe.technology,
                        data.zimbabwe.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Zimbabwe",
            "code": "zw",
            "stats": {
                "stats": {
                    "country": "Zimbabwe",
                    "future_readiness_rank": "119",
                    "income_group": "Lower-middle income",
                    "regional_group": "Sub-Saharan Africa",
                    "population": "14.86",
                    "gdp_per_capita": "411.17",
                    "gdp": "16.77",
                    "future_readiness_score": "18.11",
                    "future_readiness_score_income_group_average": "27.97",
                    "future_readiness_ranking": data.zimbabwe.future_readiness_rank,
                },
                "overall_readiness": "Placeholder text",
                "tti_performance": "Placeholder text",
                "global_performance": "Placeholder text"
            }
        }
    }
}

export default countryProfilesData
