<template>
  <div>
    <highcharts
        v-if="this.mapData === 'FutureReadinessScore'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsFutureReadinessScore"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'InstitutionsAndInfrastructure'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsInstitutionsAndInfrastructure"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'Technology'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsTechnology"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'Talent'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsTalent"
        ref="chart"
    ></highcharts>
    <highcharts
        v-else-if="this.mapData === 'Innovation'"
        :constructorType="'mapChart'"
        class="hc"
        :options="chartOptionsInnovation"
        ref="chart"
    ></highcharts>
  </div>
</template>

<script>
import worldMap from "../../data-2021/world-robinson-highres.geo.json";

export default {
  props: {
    mapData: {
      type: String,
    },
  },
  data() {
    return {
      chartOptionsFutureReadinessScore: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #3ac7ff;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value">' + this.point.rank + '</span></br><span class="stat-field">Rank</span></div>' +
                  '<div class="col"><span class="stat-value">' + this.point.score + '</span></br><span class="stat-field">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#000",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#cbcbcb",
          borderRadius: 10,
          shadow: false,
        },
        title: {
          text: "",
        },
        chart: {
          height: 600,
          map: worldMap
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#ffffff",
          maxColor: "#3ac7ff",
          lineColor: "green",
        },
        series: [
          {
            name: "Future Readiness",
            states: {
              hover: {
                color: "#3ac7ff",
                borderColor: "#3ac7ff",
              },
            },
            borderWidth: 0.2,
            allAreas: true,
            joinBy: ["hc-key", "key"],
            data: [
              {
                score: 38.2,
                rank: 68,
                value: 38.2,
                key: "ar",
                name: "ar",
                customText: "Argentina is ranked 68th in terms of future readiness out of a sample of 123 economies. It has a clear strength in the TTI components, ranking 51st in Talent, 58th in Technology, and 63rd in Innovation. The country’s main weakness relates to Institutions and Infrastructure (101st), where a challenging Market Environment (110th) and General Infrastructure (99th) is partially offset by Argentina’s Regulatory Environment (75th)."
              },
              {
                score: 38.39,
                rank: 67,
                value: 38.39,
                key: "br",
                name: "br",
                customText: "Brazil is ranked 67th in terms of future readiness out of a sample of 123 economies. The country’s best performance relates to Innovation (42nd), the only pillar where it is positioned in the third quartile. It finds itself in the second quartile with respect to the other two TTI components—Technology (62nd) and Talent (70th). Brazil’s weakest dimension is Institutions and Infrastructure (99th), where improving the General Infrastructure presents a particular challenge."
              },
              {
                score: 44.71,
                rank: 42,
                value: 44.71,
                key: "cl",
                name: "cl",
                customText: "Chile is ranked 42nd in terms of future readiness out of a sample of 123 economies. It ranks in the third quartile in all four pillars, with the best showing relating to Institutions and Infrastructure (35th), which is driven by a solid Regulatory Environment (24th). The country’s ability to Grow (26th) human skills contribute to Talent (43rd) being its best TTI component. Innovation (55th) is Chile’s lowest-ranked pillar—slightly below that of Technology (51st)—where there is primarily scope to boost innovative outputs (71st)."
              },
              {
                score: 54.71,
                rank: 26,
                value: 54.71,
                key: "cn",
                name: "cn",
                customText: "China is ranked 26th in terms of future readiness out of a sample of 123 economies. It ranks in the top quartile in the pillars related to Innovation (17th) and to Institutions and Infrastructure (27th), although the latter dimension would benefit from an improved Regulatory Environment (60th). As for the other two TTI components—Technology and Talent—China is positioned 36th and 42nd, respectively."
              },
              {
                score: 37.17,
                rank: 74,
                value: 37.17,
                key: "co",
                name: "co",
                customText: "Colombia is ranked 74th in terms of future readiness out of a sample of 123 economies. The country performs consistently across all four pillars, with rankings ranging from 61 to 74. Above all, Colombia does well with respect to Innovation (61st); the only pillar where it is positioned in the upper half of the global rankings. In the other two TTI components—Technology and Talent—it is ranked 66th and 74th, respectively. As for Institutions and Infrastructure (70th), a conducive Market Environment (39th) is offset by weaker Regulatory Environment (80th) and General Infrastructure (87th)."
              },
              {
                score: 32.19,
                rank: 89,
                value: 32.19,
                key: "eg",
                name: "eg",
                customText: "Egypt is ranked 89th in terms of future readiness out of a sample of 123 economies and, as such, is the laggard in the MENA region. Its strongest performances are in the Talent (78th) and Technology (80th) pillars. However, the country finds itself in the bottom quartile with respect to the third TTI component—Innovation (97th)—and when it comes to Institutions and Infrastructure (106th), where a weak Regulatory Environment and poor General Infrastructure present particular challenges."
              },
              {
                score: 39.81,
                rank: 59,
                value: 39.81,
                key: "in",
                name: "in",
                customText: "India is ranked 59th in terms of future readiness out of a sample of 123 economies. The country finds itself in the upper half of the global rankings in two pillars—Innovation (38th) and Institutions and Infrastructure (50th)—where the latter primarily benefits from the General Infrastructure (41st) and the Market Environment (46th). India ranks in the lower half of the rankings with respect to Technology (78th) and Talent (86th)."
              },
              {
                score: 38.05,
                rank: 69,
                value: 38.05,
                key: "id",
                name: "id",
                customText: "Indonesia is ranked 69th in terms of future readiness out of a sample of 123 economies. The country’s best performance relates to Institutions and Infrastructure (46th), which is the only pillar where Indonesia is positioned in the upper half of the global ranking. This can be attributed to the country’s Market Environment and General Infrastructure (both 34th), whereas there is a clear need to strengthen the Regulatory Environment (71st). As for the three TTI components, Indonesia ranks in the second quartile in each of them."
              },
              {
                score: 61.22,
                rank: 20,
                value: 61.22,
                key: "il",
                name: "il",
                customText: "Israel is ranked 20th in terms of future readiness out of a sample of 123 economies. It is a top-10 country when it comes to Innovation (5th), where it is the global leader in terms of Business Sophistication and also has an impressive R&D environment (3rd). Israels ranks 25th with respect to the two other TTI components (Technology and Talent). As for Institutions and Infrastructure, the country finds itself just outside the top quartile, where the General Infrastructure (52nd) presents most scope for improvement."
              },
              {
                score: 37.23,
                rank: 73,
                value: 37.23,
                key: "jo",
                name: "jo",
                customText: "Jordan is ranked 73rd in terms of future readiness out of a sample of 123 economies. The country’s future readiness is driven by its human skills; indeed, the only dimension where it finds itself positioned in the upper half of the global rankings is Talent (58th). Its greatest challenge, meanwhile, is to improve its Institutions and Infrastructure (80th), especially its state of general infrastructure."
              },
              {
                score: 30.66,
                rank: 94,
                value: 30.66,
                key: "ke",
                name: "ke",
                customText: "Kenya is ranked 94th in terms of future readiness out of a sample of 123 economies. There is a low degree of variability among the four pillars, where Innovation (83rd) is the highest-ranked dimension. Institutions and Infrastructure (97th) is the lowest-ranked pillar, where a conducive Market Environment (40th) is offset by weak Regulatory Environment (97th) and General Infrastructure (114th). As for the other two TTI components—Technology (93rd) and Talent (91st)—Kenya finds itself placed towards the bottom of the second quartile."
              },
              {
                score: 39.12,
                rank: 65,
                value: 39.12,
                key: "kw",
                name: "kw",
                customText: "Kuwait is ranked 65th in terms of future readiness out of a sample of 123 economies. Its key strength is the state of its Technology (45th), whereas the country is ranked just below the median when it comes to Talent (66th) and Institutions and Infrastructure (67th). Its weakest dimension is Innovation (82nd).Kuwait is ranked 65th in terms of future readiness out of a sample of 123 economies. Its key strength is the state of its Technology (45th), whereas the country is ranked just below the median when it comes to Talent (66th) and Institutions and Infrastructure (67th). Its weakest dimension is Innovation (82nd)."
              },
              {
                score: 32.77,
                rank: 88,
                value: 32.77,
                key: "lb",
                name: "lb",
                customText: "Lebanon is ranked 88th in terms of future readiness out of a sample of 123 economies. The country’s performances in the four main dimensions are strikingly diverse. Its main strength relates to Innovation (57th), where it makes it into the upper half in the global ranking. This is in stark contrast to the country’s position with respect to Institutions and Infrastructure (113th), where there is room for improvement in all three sub-pillars (Regulatory Environment, 111th; Market Environment, 92nd; General Infrastructure, 113th)."
              },
              {
                score: 39.13,
                rank: 64,
                value: 39.13,
                key: "mx",
                name: "mx",
                customText: "Mexico is ranked 64th in terms of future readiness out of a sample of 123 economies, which means that it is positioned just outside the upper half of the global rankings. The country’s main strength is in Innovation (48th) and it also finds itself in the upper half of the rankings with respect to Technology (61st). The third TTI component—Talent (75th)—is ranked slightly below the pillar related to Institutions and Infrastructure (73rd), where the latter enjoys a conducive Market Environment 36th) that is offset by weak Regulatory Environment (89th) and General Infrastructure (80th)."
              },
              {
                score: 33.58,
                rank: 84,
                value: 33.58,
                key: "ma",
                name: "ma",
                customText: "Morocco is ranked 84th in terms of future readiness out of a sample of 123 economies. It is an inconsistent performer in how it ranks vary across the four main dimensions. Morocco’s strength resides in its Institutions and Infrastructure (55th), especially in the state of its General Infrastructure (49th). The relatively solid institutions and infrastructure do not, however, translate into strong performances in the three TTI components. Morocco’s best showing relates to Innovation (74th) and Technology (83rd), while it finds itself in the bottom quartile when it comes to Talent (103rd)."
              },
              {
                score: 22.82,
                rank: 116,
                value: 22.82,
                key: "ng",
                name: "ng",
                customText: "Nigeria is ranked 116th in terms of future readiness out of a sample of 123 economies. It is positioned in the bottom quartile in all four key dimensions. The lowest-ranked pillar—Institutions and Infrastructure (121st)—is primarily dragged down by a dismal Regulatory Environment and General Infrastructure (both 120th), even though the Market Environment (109th) is also challenging. The weakest TTI component is Innovation (119th), with the Technology (109th) and Talent (108th) ranked a few places higher."
              },
              {
                score: 32.87,
                rank: 87,
                value: 32.87,
                key: "pe",
                name: "pe",
                customText: "Peru is ranked 87th in terms of future readiness out of a sample of 123 economies. The country is one of the more consistent performers in that its rankings in the four dimensions range from 80 to 90. The highest rank relates to Talent (80th), which is followed by the other two TTI components: Technology (86th) and Innovation (87th). The lowest rank concerns its Institutions and Infrastructure (90th), as a result of weak performances in all three sub-pillars (Regulatory Environment, 83rd; Market Environment, 73rd; General Infrastructure, 93rd)."
              },
              {
                score: 48.71,
                rank: 35,
                value: 48.71,
                key: "qa",
                name: "qa",
                customText: "Qatar is ranked 35th in terms of future readiness out of a sample of 123 economies. The country’s highest rank relates to its Institutions and Infrastructure (26th), which can be primarily attributed to its world-class General Infrastructure. Qatar is in the top quartile with respect to Technology (29th) and also performs well in the Talent (35th) component. Its weakest dimension, meanwhile, relates to Innovation (64th), where it is positioned just below the global median."
              },
              {
                score: 42.42,
                rank: 48,
                value: 42.42,
                key: "ru",
                name: "ru",
                customText: "Russia is ranked 48th in terms of future readiness out of a sample of 123 economies. The country has fairly similar performances in the three TTI components (Technology, 53rd; Talent, 44th; Innovation (45th). Strengthening its Institutions and Infrastructure (79th), meanwhile, presents Russia’s greatest challenge, especially when it comes to fostering a more conducive Regulatory Environment (95th)."
              },
              {
                score: 45.1,
                rank: 41,
                value: 45.1,
                key: "sa",
                name: "sa",
                customText: "Saudi Arabia is ranked 41st in terms of future readiness out of a sample of 123 economies. The country’s highest global rank relates to its state of Technology (33rd), whereas it is also positioned in the upper half of the global rankings when it comes to Innovation (41st) and Talent (50th). Saudi Arabia’s greatest challenge for raising its future readiness is to improve its Institutions and Infrastructure (64th), particularly with respect to fostering a more conducive Market Environment (105th)."
              },
              {
                score: 72.76,
                rank: 1,
                value: 72.76,
                key: "sg",
                name: "sg",
                customText: "Singapore is the world’s most future-ready country and is ranked in the top 10 in all four core pillars. The city-state is the global leader in both Technology and Talent. It is also one of the world’s best-performing countries when it comes to Institutions and Infrastructure (2nd), not least because of its conducive Regulatory Environment. As for Innovation (10th), Singapore benefits primarily from its high Market Sophistication (2nd)."
              },
              {
                score: 39.49,
                rank: 62,
                value: 39.49,
                key: "za",
                name: "za",
                customText: "South Africa is ranked 62nd in terms of future readiness out of a sample of 123 economies. The country’s best dimension is, by far, Innovation (34th), where it is just a shy of a position in the top quartile. Institutions and Infrastructure (60th) is the other pillar where South Africa finds itself in the upper half of the global rankings. This can mainly be attributed to the Regulatory Environment (59th) and the Market Environment (51st). As for the other two TTI components, Technology and Talent are ranked 77th and 84th, respectively."
              },
              {
                score: 43.55,
                rank: 45,
                value: 43.55,
                key: "th",
                name: "th",
                customText: "Thailand is ranked 45th in terms of future readiness out of a sample of 123 economies, which means that it is positioned in the third quartile in the ranking. At the pillar-level, the country finds itself in the same quartile in two of the three TTI components: Innovation (33rd) and Technology (47th). Thailand also ranks in the third quartile with respect to Institutions and Infrastructure (49th), which is primarily due to a conducive Market Environment (29th). Its weakest pillar relates to Talent, with relatively poor performances in all four sub-pillars."
              },
              {
                score: 41.08,
                rank: 53,
                value: 41.08,
                key: "tr",
                name: "tr",
                customText: "Turkey is ranked 53rd in terms of future readiness out of a sample of 123 economies. The country does particularly well with respect to Innovation (39th). It also finds itself in the third quartile in Technology (55th) and Institutions and Infrastructure (59th), where the latter has a particular asset in General Infrastructure (54th) while more could be done to improve the Regulatory Environment (74th). Turkey’s weakest dimension is Talent (77th), especially as it relates to attracting (114th) human skills."
              },
              {
                score: 36.19,
                rank: 75,
                value: 36.19,
                key: "ua",
                name: "ua",
                customText: "Ukraine is ranked 75th in terms of future readiness out of a sample of 123 economies, which means that it is positioned in the second quartile in the ranking. The country finds itself in the upper half of the rankings in two of the three TTI components: Talent (56th) and Innovation (59th). As for the third TTI component—Technology—it ranks 73rd. Ukraine’s weakest dimension, however, is Institutions and Infrastructure (108th), where there is scope for considerable improvement in all three sub-pillars (Regulatory Environment, 99th; Market Environment, 102nd; General Infrastructure, 94th)."
              },
              {
                score: 59.24,
                rank: 23,
                value: 59.24,
                key: "ae",
                name: "ae",
                customText: "United Arab Emirates is ranked 23rd in terms of future readiness out of a sample of 123 economies. In fact, the UAE features in the top quartile in all four dimensions, with the highest rank achieved in Talent (16th). The country’s second-best dimension in terms of global rank is Institutions and Infrastructure (19th), where it primarily benefits from a world-class state of General Infrastructure (6th)."
              },
              {
                score: 38.56,
                rank: 66,
                value: 38.56,
                key: "vn",
                name: "vn",
                customText: "Viet Nam is ranked 66th in terms of future readiness out of a sample of 123 economies. The country’s main strength relates to Innovation (44th) and it also finds itself in the upper half in the rankings with respect to Technology (59th). The most disappointing TTI component is Talent (96th), where Viet Nam is close to the bottom quartile. As for Institutions and Infrastructure (68th), the country enjoys a solid General Infrastructure (51st), but is hampered by weak Regulatory Environment (72nd) and Market Environment (88th)."
              }
            ],
          },
        ],
      },
      chartOptionsInstitutionsAndInfrastructure: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #4e81c4;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#000",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#cbcbcb",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#4e81c4",
          lineColor: "green",
        },
        series: [
          {
            name: "Institutions and Infrastructure",
            states: {
              hover: {
                color: "#4e81c4",
                borderColor: "#4e81c4",
              },
            },
            borderWidth: 0.2,
            allAreas: true,
            joinBy: ["hc-key", "key"],
            data: [
              {
                score: 40.14,
                rank: 101,
                value: 40.14,
                key: "ar",
                name: "ar",
                customText: "Argentina’s main weakness relates to Institutions and Infrastructure (101st), where a challenging Market Environment (110th) and General Infrastructure (99th) is partially offset by Argentina’s Regulatory Environment (75th)."
              },
              {
                score: 40.48,
                rank: 99,
                value: 40.48,
                key: "br",
                name: "br",
                customText: "Brazil’s weakest dimension is Institutions and Infrastructure (99th), where improving the General Infrastructure presents a particular challenge."
              },
              {
                score: 57.97,
                rank: 35,
                value: 57.97,
                key: "cl",
                name: "cl",
                customText: "Chile ranks in the third quartile in all four pillars, with the best showing relating to Institutions and Infrastructure (35th), which is driven by a solid Regulatory Environment (24th)."
              },
              {
                score: 62.06,
                rank: 27,
                value: 62.06,
                key: "cn",
                name: "cn",
                customText: "China ranks in the top quartile in the pillars related to Innovation (17th) and to Institutions and Infrastructure (27th), although the latter dimension would benefit from an improved Regulatory Environment (60th)."
              },
              {
                score: 46.61,
                rank: 70,
                value: 46.61,
                key: "co",
                name: "co",
                customText: "Colombia ranks 70th for Institutions and Infrastructure, with a conducive Market Environment (39th) that is offset by weaker Regulatory Environment (80th) and General Infrastructure (87th)."
              },
              {
                score: 38.28,
                rank: 106,
                value: 38.28,
                key: "eg",
                name: "eg",
                customText: "Egypt finds itself in the bottom quartile when it comes to Institutions and Infrastructure (106th), where a weak Regulatory Environment and poor General Infrastructure present particular challenges."
              },
              {
                score: 52.48,
                rank: 50,
                value: 52.48,
                key: "in",
                name: "in",
                customText: "India finds itself in the upper half of the global rankings in Institutions and Infrastructure (50th)—where it benefits from the General Infrastructure (41st) and the Market Environment (46th)."
              },
              {
                score: 54.03,
                rank: 46,
                value: 54.03,
                key: "id",
                name: "id",
                customText: "Indonesia’s best performance relates to Institutions and Infrastructure (46th), which is the only pillar where Indonesia is positioned in the upper half of the global ranking. This can be attributed to the country’s Market Environment and General Infrastructure (both 34th), whereas there is a clear need to strengthen the Regulatory Environment (71st)."
              },
              {
                score: 60.02,
                rank: 31,
                value: 60.02,
                key: "il",
                name: "il",
                customText: "Israel finds itself just outside the top quartile for Institutions and Infrastructure (30th), where the General Infrastructure (52nd) presents most scope for improvement."
              },
              {
                score: 44.78,
                rank: 80,
                value: 44.78,
                key: "jo",
                name: "jo",
                customText: "Jordan greatest challenge is to improve its Institutions and Infrastructure (80th), especially its state of general infrastructure."
              },
              {
                score: 41.05,
                rank: 97,
                value: 41.05,
                key: "ke",
                name: "ke",
                customText: "Kenya's Institutions and Infrastructure (97th) is the lowest-ranked pillar, where a conducive Market Environment (40th) is offset by weak Regulatory Environment (97th) and General Infrastructure (114th)."
              },
              {
                score: 46.84,
                rank: 67,
                value: 46.84,
                key: "kw",
                name: "kw",
                customText: "Kuwait is ranked just below the median when it comes to Institutions and Infrastructure (67th)."
              },
              {
                score: 35.22,
                rank: 113,
                value: 35.22,
                key: "lb",
                name: "lb",
                customText: "Lebanon is ranked 113th in terms of Institutions and Infrastructure, where there is room for improvement in all three sub-pillars (Regulatory Environment, 111th; Market Environment, 92nd; General Infrastructure, 113th)."
              },
              {
                score: 46.18,
                rank: 73,
                value: 46.18,
                key: "mx",
                name: "mx",
                customText: "Mexico's Institutions and Infrastructure (73rd) enjoys a conducive Market Environment 36th) that is offset by weak Regulatory Environment (89th) and General Infrastructure (80th)."
              },
              {
                score: 48.47,
                rank: 55,
                value: 48.47,
                key: "ma",
                name: "ma",
                customText: "Morocco’s strength resides in its Institutions and Infrastructure (55th), especially in the state of its General Infrastructure (49th)."
              },
              {
                score: 29.98,
                rank: 121,
                value: 29.98,
                key: "ng",
                name: "ng",
                customText: "Nigeria's lowest-ranked pillar—Institutions and Infrastructure (121st)—is primarily dragged down by a dismal Regulatory Environment and General Infrastructure (both 120th), even though the Market Environment (109th) is also challenging."
              },
              {
                score: 42.75,
                rank: 90,
                value: 42.75,
                key: "pe",
                name: "pe",
                customText: "Peru's lowest rank concerns its Institutions and Infrastructure (90th), as a result of weak performances in all three sub-pillars (Regulatory Environment, 83rd; Market Environment, 73rd; General Infrastructure, 93rd)."
              },
              {
                score: 62.21,
                rank: 26,
                value: 62.21,
                key: "qa",
                name: "qa",
                customText: "Qatar’s highest rank relates to its Institutions and Infrastructure (26th), which can be primarily attributed to its world-class General Infrastructure."
              },
              {
                score: 45.12,
                rank: 79,
                value: 45.12,
                key: "ru",
                name: "ru",
                customText: "Strengthening its Institutions and Infrastructure (79th), presents Russia’s greatest challenge, especially when it comes to fostering a more conducive Regulatory Environment (95th)."
              },
              {
                score: 47.82,
                rank: 64,
                value: 47.82,
                key: "sa",
                name: "sa",
                customText: "Saudi Arabia’s greatest challenge for raising its future readiness is to improve its Institutions and Infrastructure (64th), particularly with respect to fostering a more conducive Market Environment (105th)."
              },
              {
                score: 75.8,
                rank: 2,
                value: 75.8,
                key: "sg",
                name: "sg",
                customText: "Singapore is also one of the world’s best-performing countries when it comes to Institutions and Infrastructure (2nd), not least because of its conducive Regulatory Environment. As for Innovation (10th), Singapore benefits primarily from its high Market Sophistication (2nd)."
              },
              {
                score: 48.11,
                rank: 60,
                value: 48.11,
                key: "za",
                name: "za",
                customText: "South Africa's Institutions and Infrastructure (60th) ranking puts the country in the upper half of the global rankings. This can mainly be attributed to the Regulatory Environment (59th) and the Market Environment (51st)."
              },
              {
                score: 53.27,
                rank: 49,
                value: 53.27,
                key: "th",
                name: "th",
                customText: "Thailand ranks in the third quartile with respect to Institutions and Infrastructure (49th), which is primarily due to a conducive Market Environment (29th). Its weakest pillar relates to Talent, with relatively poor performances in all four sub-pillars."
              },
              {
                score: 48.13,
                rank: 59,
                value: 48.13,
                key: "tr",
                name: "tr",
                customText: "Turkey finds itself in the third quartile in Technology (55th) and Institutions and Infrastructure (59th), where the latter has a particular asset in General Infrastructure (54th) while more could be done to improve the Regulatory Environment (74th)."
              },
              {
                score: 38,
                rank: 108,
                value: 38,
                key: "ua",
                name: "ua",
                customText: "Ukraine’s weakest dimension is Institutions and Infrastructure (108th), where there is scope for considerable improvement in all three sub-pillars (Regulatory Environment, 99th; Market Environment, 102nd; General Infrastructure, 94th)."
              },
              {
                score: 67.92,
                rank: 19,
                value: 67.92,
                key: "ae",
                name: "ae",
                customText: "United Arab Emirates’ second-best dimension in terms of global rank is Institutions and Infrastructure (19th), where it primarily benefits from a world-class state of General Infrastructure (6th)."
              },
              {
                score: 46.66,
                rank: 68,
                value: 46.66,
                key: "vn",
                name: "vn",
                customText: "Viet Nam's Institutions and Infrastructure (68th) enjoys a solid General Infrastructure (51st), but is hampered by weak Regulatory Environment (72nd) and Market Environment (88th)."
              }
            ],
          },
        ],
      },
      chartOptionsTechnology: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #cc4c2a;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#fff",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#cbcbcb",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#cc4c2a",
          lineColor: "green",
        },
        series: [
          {
            name: "Technology",
            states: {
              hover: {
                color: "#cc4c2a",
                borderColor: "#cc4c2a",
              },
            },
            borderWidth: 0.2,
            allAreas: true,
            joinBy: ["hc-key", "key"],
            data: [
              {
                score: 48.25,
                rank: 58,
                value: 48.25,
                key: "ar",
                name: "ar",
                customText: "Argentina is ranked 58th in Technology. Argentina benefits from a solid use of digital technologies among its People (43rd), while there is particular scope for improvement with respect to its technology-related Governance (70th)."
              },
              {
                score: 46.88,
                rank: 62,
                value: 46.88,
                key: "br",
                name: "br",
                customText: "Brazil finds itself in the second quartile with respect to Technology (62nd). The third top-3 sub-pillar concerns technology-related Governance (51st), but the Technology component is hampered by the state of Digital Transformation Technologies (69th) and the relatively low use of digital technologies among its People (71st)."
              },
              {
                score: 50.88,
                rank: 51,
                value: 50.88,
                key: "cl",
                name: "cl",
                customText: "Chile’s lowest-ranked pillar—slightly below that of Technology (51st)—where there is primarily scope to boost innovative outputs (71st)."
              },
              {
                score: 58.49,
                rank: 36,
                value: 58.49,
                key: "cn",
                name: "cn",
                customText: "China is ranked 26th in terms of future readiness out of a sample of 123 economies. It ranks in the top quartile in the pillars related to Innovation (17th) and to Institutions and Infrastructure (27th), although the latter dimension would benefit from an improved Regulatory Environment (60th). As for the other two TTI components—Technology and Talent—China is positioned 36th and 42nd, respectively."
              },
              {
                score: 45.06,
                rank: 66,
                value: 45.06,
                key: "co",
                name: "co",
                customText: "Colombia's Technology is ranked 66th. Their Technology component includes a top-3 sub-pillar: People (52nd). Colombia’s weakest Technology sub-pillars relate to Digital Transformation Technologies and Digital Economy (both 72nd)."
              },
              {
                score: 39.59,
                rank: 80,
                value: 39.59,
                key: "eg",
                name: "eg",
                customText: "One of Egypt's strongest performances pillars is Technology (80th)."
              },
              {
                score: 40.89,
                rank: 78,
                value: 40.89,
                key: "in",
                name: "in",
                customText: "India ranks in the lower half of the rankings with respect to Technology (78th). The Technology component is boosted by the country’s Digital Economy (63rd), while there is primarily a need to increase the level of Digital Transformation Technologies (87th)."
              },
              {
                score: 43.7,
                rank: 71,
                value: 43.7,
                key: "id",
                name: "id",
                customText: "Indonesia ranks in the second quartile for Technology (71st). Their third-best sub-pillar, is Digital Economy (64th), is offset by the relatively low use of digital technologies among Indonesia’s People (80th)."
              },
              {
                score: 64.39,
                rank: 25,
                value: 64.39,
                key: "il",
                name: "il",
                customText: "Israels ranks 25th with respect to Technology. The country finds itself in the top quartile in all but two sub-pillars, one of which is technology-related Governance (33rd)"
              },
              {
                score: 44.14,
                rank: 68,
                value: 44.14,
                key: "jo",
                name: "jo",
                customText: "Jordan is ranked 68th for Technology."
              },
              {
                score: 32.53,
                rank: 93,
                value: 32.53,
                key: "ke",
                name: "ke",
                customText: "Kenya finds itself placed towards the bottom of the second quartile for Technology (93rd). Governance (69th) related to digital technologies is one of Kenya’s positive assets, but there is considerable scope for improvement with respect to Digital Transformation Technologies and the Digital Economy (102nd)."
              },
              {
                score: 52.96,
                rank: 45,
                value: 52.96,
                key: "kw",
                name: "kw",
                customText: "Kuwait's key strength is the state of its Technology (45th). The country features in the top quartile for People (28th) in the case of Technology."
              },
              {
                score: 37.72,
                rank: 85,
                value: 37.72,
                key: "lb",
                name: "lb",
                customText: "Lebanon is ranked 85th in terms of Technology."
              },
              {
                score: 47.39,
                rank: 61,
                value: 47.39,
                key: "mx",
                name: "mx",
                customText: "Mexico finds itself in the upper half of the rankings with respect to Technology (61st). A favourable Digital Economy (35th) stands in contrast to a relatively low use of digital technologies among the People (70th) and a weak level of Digital Transformation Technologies (78th)."
              },
              {
                score: 38.75,
                rank: 83,
                value: 38.75,
                key: "ma",
                name: "ma",
                customText: "One of Morocco’s top performing areas is Technology (83rd). Digital Transformation Technologies is one of the top-3 sub-pillars in the TTI triangle, but the Technology component is weighed down by poor Governance (93rd) in digital technologies."
              },
              {
                score: 24.82,
                rank: 109,
                value: 24.82,
                key: "ng",
                name: "ng",
                customText: "Nigeria is ranked 109th for Technology. Nigeria features in the bottom quartile in all but two of the sub-pillars in the TTI triangle. The exceptions are technology-related Governance (96th) and Digital Economy (79th), where the country is ranked in the second quartile. Within the same pillar, Nigeria primarily needs to address its level of Digital Transformation Technologies (116th) and the low use of digital technologies among its People (118th)."
              },
              {
                score: 37.21,
                rank: 86,
                value: 37.21,
                key: "pe",
                name: "pe",
                customText: "Peru is ranked 86th for Technology. The country trails the global average in all four key dimensions, especially in Technology."
              },
              {
                score: 62.43,
                rank: 29,
                value: 62.43,
                key: "qa",
                name: "qa",
                customText: "Qatar is in the top quartile with respect to Technology (29th). Technology is their most impressive component, where Qatar features in the top quartile in three sub-pillars: People (12th), Digital Economy (18th), and Governance (28th)."
              },
              {
                score: 50.39,
                rank: 53,
                value: 50.39,
                key: "ru",
                name: "ru",
                customText: "Russia's Technology ranking is 53. The performance of Russia with respect to Technology is quite even across all four sub-pillars. "
              },
              {
                score: 59.69,
                rank: 33,
                value: 59.69,
                key: "sa",
                name: "sa",
                customText: "Saudi Arabia’s highest global rank relates to its state of Technology (33rd). Saudi Arabia’s Technology component includes two sub-pillars where the country features in the top quartile: People (26th) and Digital Economy (30th)."
              },
              {
                score: 83.77,
                rank: 1,
                value: 83.77,
                key: "sg",
                name: "sg",
                customText: "Singapore is the world’s most future-ready country and is ranked in the top 10 in all four core pillars. The city-state is the global leader in Technology."
              },
              {
                score: 40.93,
                rank: 77,
                value: 40.93,
                key: "za",
                name: "za",
                customText: "South Africa is ranked 77th for Technology. The main challenge is to increase the use of digital technologies among individuals, firms, and governmental authorities (People, 96th)."
              },
              {
                score: 52.32,
                rank: 47,
                value: 52.32,
                key: "th",
                name: "th",
                customText: "Thailand is ranked 47th for Technology (47th). More could be done to strengthen Digital Transformation Technologies (62nd) "
              },
              {
                score: 49.53,
                rank: 55,
                value: 49.53,
                key: "tr",
                name: "tr",
                customText: "Turkey finds itself in the third quartile in Technology (55th). The Technology component is weighed down by the state of Digital Transformation Technologies (67th) in the country."
              },
              {
                score: 43.23,
                rank: 73,
                value: 43.23,
                key: "ua",
                name: "ua",
                customText: "Ukraine is ranked 73rd in Technology. Ukraine primarily needs to address the level of Digital Transformation Technologies (84th) in the country."
              },
              {
                score: 64.18,
                rank: 26,
                value: 64.18,
                key: "ae",
                name: "ae",
                customText: "United Arab Emirates features in the top quartile in all four dimensions, with a rank of 26th in Technology. The country achieves its highest rank in the Technology component; more precisely, in the sub-pillar concerned with the use of digital technologies among its People (3rd)."
              },
              {
                score: 48.04,
                rank: 59,
                value: 48.04,
                key: "vn",
                name: "vn",
                customText: "Viet Nam finds itself in the upper half in the rankings with respect to Technology (59th). Viet Nam has a fairly developed Digital Economy (33rd), but needs to raise the level of Digital Transformation Technologies (76th), among others."
              }
            ],
          },
        ],
      },
      chartOptionsTalent: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #e2aa23;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#fff",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#FAFAFA",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#e2aa23",
          lineColor: "green",
        },
        series: [
          {
            name: "Talent",
            states: {
              hover: {
                color: "#e2aa23",
                borderColor: "#e2aa23",
              },
            },
            borderWidth: 0.2,
            allAreas: true,
            joinBy: ["hc-key", "key"],
            data: [
              {
                score: 46.81,
                rank: 51,
                value: 46.81,
                key: "ar",
                name: "ar",
                customText: "Argentina has a clear strength in Talent, ranking 51st. This includes one of the country’s top sub-pillar: Grow (39th)."
              },
              {
                score: 40.29,
                rank: 70,
                value: 40.29,
                key: "br",
                name: "br",
                customText: "Brazil finds itself in the second quartile with respect to Talent (70th). There is primarily a need to increase the level of Skills (70th) in Brazil."
              },
              {
                score: 49.67,
                rank: 43,
                value: 49.67,
                key: "cl",
                name: "cl",
                customText: "Chile’s ability to Grow (26th) human skills contribute to Talent (43rd) being its best pillar."
              },
              {
                score: 50.33,
                rank: 42,
                value: 50.33,
                key: "cn",
                name: "cn",
                customText: "China is positioned 42nd for Talent. It is weighed down by a weak capacity to Attract (86th) and Retain (54th) human skills."
              },
              {
                score: 39.25,
                rank: 74,
                value: 39.25,
                key: "co",
                name: "co",
                customText: "Colombia is ranked 66th for Talent. The greatest challenge facing Colombia is boosting its ability to Attract (97th) talent."
              },
              {
                score: 38.71,
                rank: 78,
                value: 38.71,
                key: "eg",
                name: "eg",
                customText: "The highest-ranked component in the case of Egypt is Talent (78th) and it is also a talent-related dimension that is its best-performing sub-pillar: Skills. "
              },
              {
                score: 36.57,
                rank: 86,
                value: 36.57,
                key: "in",
                name: "in",
                customText: "India is ranked 86th for Talent. India does relatively well in the sub-pillars Grow and Skills (both 66th), but needs to make further efforts to strengthen its ability to Attract (92nd) and Retain (98th) talent."
              },
              {
                score: 37.52,
                rank: 82,
                value: 37.52,
                key: "id",
                name: "id",
                customText: "Indonesia is ranked 82nd in terms of Talent. The country's greatest challenge is to improve the ability to Retain (97th) human skills."
              },
              {
                score: 57.86,
                rank: 25,
                value: 57.86,
                key: "il",
                name: "il",
                customText: "Israel ranks 25th with respect to Talent. The country finds itself in the top quartile in all but two of the sub-pillars, one of which is the ability to Attract (66th) talent."
              },
              {
                score: 45.15,
                rank: 58,
                value: 45.15,
                key: "jo",
                name: "jo",
                customText: "The highest-ranked component in the case of Jordan is Talent (58tht) and it is also two talent-related dimensions that are its best-performing sub-pillars: Skills (33rd) and Retain (48th)."
              },
              {
                score: 34.91,
                rank: 91,
                value: 34.91,
                key: "ke",
                name: "ke",
                customText: "Kenya's Talent (91st) places the country towards the bottom of the second quartile. Kenya’s strongest sub-pillar is Skills (50th), but the pool of talent in the country is offset by a weak ability to Attract (108th), Grow (90th), and Retain (99th) human capital."
              },
              {
                score: 42.37,
                rank: 66,
                value: 42.37,
                key: "kw",
                name: "kw",
                customText: "Kuwait is ranked just below the median when it comes to Talent (66th). Kuwait features in the top quartile in the Attract (30th) sub-pillars. Its ability to attract talent, is however offset by lower levels of growing and retaining talent (81st and 74th, respectively)"
              },
              {
                score: 38.3,
                rank: 79,
                value: 38.3,
                key: "lb",
                name: "lb",
                customText: "Lebanon is ranked 79th in terms of Talent. The country’s best performance with respect to sub-pillars relates to Skills (41st), but the Talent pillar is hampered by a weak ability to Attract (88th), Grow (93rd), and Retain (92nd) human capital."
              },
              {
                score: 39.06,
                rank: 75,
                value: 39.06,
                key: "mx",
                name: "mx",
                customText: "Mexico is ranked 75th in terms of Talent."
              },
              {
                score: 31.92,
                rank: 103,
                value: 31.92,
                key: "ma",
                name: "ma",
                customText: "The greatest challenge for Morocco relates to Talent, where it finds itself in the bottom quartile. Attract (101st) and Skills (114th) are the areas that need to be addressed most urgently."
              },
              {
                score: 29.29,
                rank: 108,
                value: 29.29,
                key: "ng",
                name: "ng",
                customText: "Nigeria is positioned in the bottom quartile in all four key dimensions, with a Talent ranking of 108th. The country’s third-best sub-pillar relates to Growing Talent (102nd), even though it is admittedly only a few ranks higher than the other sub-pillars in the Talent component."
              },
              {
                score: 37.9,
                rank: 80,
                value: 37.9,
                key: "pe",
                name: "pe",
                customText: "Peru's highest rank relates to Talent (80th)."
              },
              {
                score: 53,
                rank: 35,
                value: 53,
                key: "qa",
                name: "qa",
                customText: "Qatar performs well in the Talent (35th) component. The country’s best performance with respect to sub-pillars relates to attracting talent, where it is one of the world’s top-ranked countries. This stands in contrast to Qatar’s performance in the other three talent-related sub-pillars (Grow, 61st; Retain, 44th; Skills, 57th)."
              },
              {
                score: 49.52,
                rank: 44,
                value: 49.52,
                key: "ru",
                name: "ru",
                customText: "Russia is ranked 44th for Talent. A strong pool of Skills (16th) and a solid ability to Retain (36th) and Grow (37th) talent is offset by the country’s disappointingly low rank in Attract (115th)."
              },
              {
                score: 46.89,
                rank: 50,
                value: 46.89,
                key: "sa",
                name: "sa",
                customText: "Saudi Arabia is positioned in the upper half of the global rankings when it comes to Talent (50th). The greatest scope for improvement relates to the country’s ability to Attract (68th) human skills."
              },
              {
                score: 72.87,
                rank: 1,
                value: 72.87,
                key: "sg",
                name: "sg",
                customText: "Singapore is the world’s most future-ready country and is ranked in the top 10 in all four core pillars. The city-state is the global leader in Talent."
              },
              {
                score: 37.33,
                rank: 84,
                value: 37.33,
                key: "za",
                name: "za",
                customText: "South Africa is ranked 84th for Talent. The most urgent need is to boost the ability to Retain (109th) human skills."
              },
              {
                score: 36.79,
                rank: 85,
                value: 36.79,
                key: "th",
                name: "th",
                customText: "Thailand's weakest pillar relates to Talent (85th). Thailand’s greatest challenge is to boost the talent dimension by addressing shortcomings in all sub-pillars."
              },
              {
                score: 38.75,
                rank: 77,
                value: 38.75,
                key: "tr",
                name: "tr",
                customText: "Turkey’s weakest dimension is Talent (77th), especially as it relates to attracting (114th) human skills."
              },
              {
                score: 45.54,
                rank: 56,
                value: 45.54,
                key: "ua",
                name: "ua",
                customText: "Ukraine makes it into the top quartile in one of the sub-pillars of the TTI triangle: Skills (18th) in the Talent component. Growing talent (52nd) also counts among the country’s strengths, whereas retaining and, above all, attracting talent (68th and 95th, respectively) are the main challenges facing Ukraine in human skills."
              },
              {
                score: 63.98,
                rank: 16,
                value: 63.98,
                key: "ae",
                name: "ae",
                customText: "United Arab Emirates features in the top quartile in all four dimensions, with the highest rank achieved in Talent (16th). The country makes it into the top 10 in two of the sub-pillars related to Talent: Attract (4th) and Grow (6th)."
              },
              {
                score: 34.02,
                rank: 96,
                value: 34.02,
                key: "vn",
                name: "vn",
                customText: "Viet Nam's most disappointing TTI component is Talent (96th), where Viet Nam is close to the bottom quartile. There is a lack of Skills (105th) and a weak ability to Attract (90th) and Retain (95th) talent."
              }
            ],
          },
        ],
      },
      chartOptionsInnovation: {
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return (
                  '<div class="tooltipPointWrapper text-center"><span class="country-name">' + this.point.name + "</span></br>" +
                  '<span class="tooltipPoint">' + this.point.customText + "</span>" +
                  '<span class="tooltipLine"></span> <br/>' + '<div class="series-name py-2" style="background-color: #66ad5d;padding: 0px 2px 5px 2px">' +
                  '<span class="stat-name text-white">' + this.series.name + '</span>' +
                  '<div class="row mt-2">' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.rank + '</span></br><span class="stat-field text-white">Rank</span></div>' +
                  '<div class="col"><span class="stat-value text-white">' + this.point.score + '</span></br><span class="stat-field text-white">Score</span></div>' +
                  '</div>'
              );
            } else {
              return false;
            }
          },
          className: "mapTooltip",
          useHTML: true,
          headerFormat: '<span class="tooltipHeader">{point.key}</span>',
          style: {
            color: "#fff",
          },
          valueDecimals: 0,
          backgroundColor: "#FFFFFF",
          borderColor: "#FAFAFA",
          borderRadius: 10,
          shadow: false,
        },
        chart: {
          height: 600,
          map: worldMap,
          events: {
            load: function () {
              this.mapZoom(0.3, 5100, -8000);
            },
          },
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          max: 50,
          type: "linear",
          minColor: "#FFFFFF",
          maxColor: "#66ad5d",
          lineColor: "green",
        },
        series: [
          {
            name: "Innovation",
            states: {
              hover: {
                color: "#66ad5d",
                borderColor: "#66ad5d",
              },
            },
            borderWidth: 0.2,
            allAreas: true,
            joinBy: ["hc-key", "key"],
            data: [
              {
                score: 17.57,
                rank: 63,
                value: 17.57,
                key: "ar",
                name: "ar",
                customText: "Argentina is ranked 63rd in Innovation. It is primarily weighed down by a disappointing Market Sophistication (117th)."
              },
              {
                score: 25.92,
                rank: 42,
                value: 25.92,
                key: "br",
                name: "br",
                customText: "Brazil’s best performance relates to Innovation (42nd), the only pillar where it is positioned in the third quartile. Brazil’s relative strength in Innovation is reflected by the fact that two of its top-3 sub-pillars are in that component: Business Sophistication (29th) and Research & Development (34th). "
              },
              {
                score: 20.32,
                rank: 55,
                value: 20.32,
                key: "cl",
                name: "cl",
                customText: "Innovation (55th) is Chile’s lowest-ranked pillar. There is primarily scope to boost innovative outputs (71st)."
              },
              {
                score: 47.96,
                rank: 17,
                value: 47.96,
                key: "cn",
                name: "cn",
                customText: "China’s strongest TTI component—Innovation (17th)—includes two of its best-performing sub-pillars: Knowledge, Technology, and Creative Outputs (10th) and Research & Development (16th)."
              },
              {
                score: 17.75,
                rank: 61,
                value: 17.75,
                key: "co",
                name: "co",
                customText: "Colombia does well with respect to Innovation (61st); the only pillar where it is positioned in the upper half of the global rankings. This includes a top-3 sub-pillar Business Sophistication (53rd)."
              },
              {
                score: 12.2,
                rank: 97,
                value: 12.2,
                key: "eg",
                name: "eg",
                customText: "Egypt finds itself in the bottom quartile with respect to the third TTI component—Innovation (97th)."
              },
              {
                score: 29.28,
                rank: 38,
                value: 29.28,
                key: "in",
                name: "in",
                customText: "India’s best performance in the TTI components is, by far, in Innovation (38th). The country finds itself in the upper half of the global rankings. In fact, the four innovation-related sub-pillars are also the best sub-pillars of the three components."
              },
              {
                score: 16.95,
                rank: 65,
                value: 16.95,
                key: "id",
                name: "id",
                customText: "Indonesia’s ranks 65th for Innovation. Two highest-ranked sub-pillars are both in the Innovation component (Knowledge, Technology and Creative Outputs, 50th, and Research & Development, 59th). At the same time, the pillar also includes the worst-performing sub-pillar of the TTI triangle: Business Sophistication (99th)."
              },
              {
                score: 62.59,
                rank: 5,
                value: 62.59,
                key: "il",
                name: "il",
                customText: "Israel is a top-10 country when it comes to Innovation (5th), where it is the global leader in terms of Business Sophistication and also has an impressive R&D environment (3rd)."
              },
              {
                score: 14.86,
                rank: 76,
                value: 14.86,
                key: "jo",
                name: "jo",
                customText: "Jordan ranks at 76th in Innovation and is well behind the global average"
              },
              {
                score: 14.14,
                rank: 83,
                value: 14.14,
                key: "ke",
                name: "ke",
                customText: "Kenya is 83rd for Innovation. This is their strongest component, which can primarily be attributed to the country’s Business Sophistication (61st). "
              },
              {
                score: 14.3,
                rank: 82,
                value: 14.3,
                key: "kw",
                name: "kw",
                customText: "Kuwait's weakest dimension is Innovation (82nd). It is well behind the global average."
              },
              {
                score: 19.86,
                rank: 57,
                value: 19.86,
                key: "lb",
                name: "lb",
                customText: "Lebanon's main strength relates to Innovation (57th), where it makes it into the upper half in the global ranking. This includes two of the country’s top-3 sub-pillars: Knowledge, Technology and Creative Outputs (54th) and Research & Development (55th)."
              },
              {
                score: 23.87,
                rank: 48,
                value: 23.87,
                key: "mx",
                name: "mx",
                customText: "Mexico’s strongest TTI component—Innovation—contains highly varied performances. On the one hand, the country finds itself in the top quartile with respect to Knowledge, Technology and Creative Outputs (24th) and in the third quartile in Research & Development (41st). On the other hand, the pillar includes Mexico’s two weakest sub-pillars: Market Sophistication (90th) and Business Sophistication (103rd)."
              },
              {
                score: 15.18,
                rank: 74,
                value: 15.18,
                key: "ma",
                name: "ma",
                customText: "Morocco’s strongest TTI component—Innovation (74th)—includes three of the country’s top performances at the sub-pillar level (Market Sophistication, 61st; Research & Development, 71st; Business Sophistication 73rd)."
              },
              {
                score: 7.18,
                rank: 119,
                value: 7.18,
                key: "ng",
                name: "ng",
                customText: "Nigeria's weakest TTI component is Innovation (119th)."
              },
              {
                score: 13.61,
                rank: 87,
                value: 13.61,
                key: "pe",
                name: "pe",
                customText: "Peru is ranked 87th for Innovation. Peru's third-best sub-pillar concerns Market Sophistication (72nd) in the Innovation component, where the main challenge is to translate the innovative-related inputs to Knowledge, Technology and Creative Outputs (91st)."
              },
              {
                score: 17.2,
                rank: 64,
                value: 17.2,
                key: "qa",
                name: "qa",
                customText: "Qatar's weakest dimension, meanwhile, relates to Innovation (64th), where it is positioned just below the global median. The greatest challenge is to improve the Market Sophistication (95th)."
              },
              {
                score: 24.67,
                rank: 45,
                value: 24.67,
                key: "ru",
                name: "ru",
                customText: "Russia is ranked 45th in Innovation (45th). Russia’s strength in Innovation lies in Research & Development (33rd), whereas there is considerable scope for improvement in the other three sub-pillars)."
              },
              {
                score: 26,
                rank: 41,
                value: 26,
                key: "sa",
                name: "sa",
                customText: "Saudi Arabia is positioned in the upper half of the global rankings when it comes to Innovation (41st) but is hampered by a weak level of Business Sophistication (88th)."
              },
              {
                score: 58.61,
                rank: 10,
                value: 58.61,
                key: "sg",
                name: "sg",
                customText: "Singapore is the world’s most future-ready country and is ranked in the top 10 in all four core pillars. Their Innovation (10th) benefits primarily from high Market Sophistication (2nd). The country could improve on Business Sophistication (22nd) for Innovation"
              },
              {
                score: 31.6,
                rank: 34,
                value: 31.6,
                key: "za",
                name: "za",
                customText: "South Africa’s best dimension is, by far, Innovation (34th), where it is just a shy of a position in the top quartile. The strong performance of South Africa in Innovation is reflected in the showings of all its sub-pillars. In fact, the Innovation-related dimensions are the highest-ranked sub-pillars in the TTI triangle, with Market Sophistication (9th) particularly impressive."
              },
              {
                score: 31.81,
                rank: 33,
                value: 31.81,
                key: "th",
                name: "th",
                customText: "Thailand’s strength in the TTI triangle clearly relates to Innovation (33rd) and it is also in this pillar that the country has its best sub-pillar performances (Market Sophistication, 16th; Knowledge, Technology and Creative Outputs, 26th; Business Sophistication, 33rd)."
              },
              {
                score: 27.9,
                rank: 39,
                value: 27.9,
                key: "tr",
                name: "tr",
                customText: "Innovation is clearly Turkey’s strongest TTI component with a rank of 39th. Not only is it the pillar with the highest ranking, it also includes all top-3 sub-pillars (Market Sophistication, 33rd; Knowledge, Technology and Creative Outputs, 38th; Research & Development, 40th)."
              },
              {
                score: 17.99,
                rank: 59,
                value: 17.99,
                key: "ua",
                name: "ua",
                customText: "Ukraine is ranked 59th in Innovation. The Innovation component includes two stronger sub-pillars (Research & Development, 44th and Business Sophistication, 58th) and two weaker sub-pillars (Knowledge, Technology and Creative Outputs, 82nd and Market Sophistication, 99th)."
              },
              {
                score: 40.88,
                rank: 22,
                value: 40.88,
                key: "ae",
                name: "ae",
                customText: "United Arab Emirates features in the top quartile in all four dimensions, with the fourth highest rank achieved in Innovation (22nd)."
              },
              {
                score: 25.51,
                rank: 44,
                value: 25.51,
                key: "vn",
                name: "vn",
                customText: "Viet Nam’s main strength relates to Innovation (44th). Viet Nam finds itself in the top quartile in one sub-pillar of the TTI triangle: Knowledge, Technology and Creative Outputs (20th) in the Innovation component."
              }
            ],
          },
        ],
      },
    };
  },
};
</script>

<style>

.stat-name {
  font-size: large;
  font-weight: bold;
}

.stat-field {
  font-size: medium;
  font-weight: normal;
}

.stat-value {
  font-size: x-large;
  font-weight: bold;
}

.mapTooltip {
  font-family: 'Google Sans', sans-serif;
}

.mapTooltip .tooltipHeader {
  font-size: 1.2rem;
  font-weight: bold;
}

.mapTooltip .tooltipHeader::after {
  float: none;
}

.mapTooltip .tooltipPointWrapper {
  font-family: 'Google Sans', sans-serif !important;
  color: #4A4A4A !important;
  display: block;
  text-align: left;
  padding: 0;
  width: 400px;
  white-space: pre-wrap;
}

.mapTooltip .tooltipPoint {
  font-size: 1rem;
  padding-left: 5px;
}

.mapTooltip .tooltipValueSuffix {
  padding-left: 5px;
  color: #1bc9a8;
}

.mapTooltip .tooltipLine {
  display: block;
  opacity: 0.5;
  background-color: #fff;
  width: 100%;
  height: 1px;
  padding: 0;
  margin: 0;
}

.mapTooltip .country-name {
  border-bottom: solid 1px black;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.mapTooltip .series-name {
  font-size: larger;
}
</style>
