<template>
  <div class="row align-middle">
    <div class="text-center mw-100 px-5" style="margin: auto" v-if="isMobile">
      <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
      <br>
      <p class="font-weight-500">This section is best viewed in landscape mode</p>
    </div>

    <div class="col-12 text-center animate__animated animate__fadeIn mb-5 mt-3">
      <p class="font-weight-500 help-text-green text-center"><i class="fa fa-lightbulb-o mr-2"/>Use the buttons to toggle between readiness pillars. Mouse over each country to see its score and rank. Click on a country to see its full profile.</p>
    </div>

    <div class="col-12">
      <Map :mapData="selected"></Map>
    </div>
    <div class="col-12 map-buttons text-center">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
            class="btn-group d-block"
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            buttons
            button-variant="outline-secondary"
            name="buttons2"
        ></b-form-radio-group>
      </b-form-group>
    </div>

  </div>
</template>

<script>
import Map from "@/components-2023/charts/Map";
export default {
  name: "MapView",
  components: {
    Map,
  },
  data() {
    return {
      selected: 'FutureReadinessScore',
      options: [
        {text: 'FREI', value: 'FutureReadinessScore'},
        {text: 'Physical Capital', value: 'PhysicalCapital'},
        {text: 'Human Capital', value: 'HumanCapital'},
        {text: 'Technology', value: 'Technology'},
        {text: 'Competitiveness', value: 'Competitiveness'}
      ]
    }
  },
  computed: {
    chartData() {
      const labels = ["Future Readiness Score", "Physical Capital", "Technology", "Human Capital", "Competitiveness"];
      const chartDataSet = {
        labels: labels,
        datasets: this.selected
      };
      return chartDataSet;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>

.map-buttons {
  margin: auto;
}

.map-buttons .form-group {
  width: 100%;
}

.btn-group.special {
  display: flex !important;
}

.special .btn {
  flex: 1 !important;
}

.map-buttons .btn-group .btn.active:first-child {
  background-color: #3ac7ffFF !important;
  border-color: #3ac7ffFF !important;
}

.map-buttons .btn-group .btn:first-child:hover {
  background-color: #3ac7ffFF !important;
  border-color: #3ac7ffFF !important;
}

.map-buttons .btn-group .btn.active:nth-child(2) {
  background-color: #4e81c4 !important;
  color: #fff !important;
  border-color: #4e81c4 !important;
}

.map-buttons .btn-group .btn:nth-child(2):hover {
  background-color: #4e81c4 !important;
  color: #fff !important;
  border-color: #4e81c4 !important;
}

.map-buttons .btn-group .btn.active:nth-child(3) {
  background-color: #e2aa23 !important;
  color: #fff !important;
  border-color: #e2aa23 !important;
}

.map-buttons .btn-group .btn:nth-child(3):hover {
  background-color: #e2aa23 !important;
  color: #fff !important;
  border-color: #e2aa23 !important;
}

.map-buttons .btn-group .btn.active:nth-child(4) {
  background-color: #cc4c2a !important;
  color: #fff !important;
  border-color: #cc4c2a !important;
}

.map-buttons .btn-group .btn:nth-child(4):hover {
  background-color: #cc4c2a !important;
  color: #fff !important;
  border-color: #cc4c2a !important;
}

.map-buttons .btn-group .btn.active:nth-child(5) {
  background-color: #66ad5d !important;
  color: #fff !important;
  border-color: #66ad5d !important;
}

.map-buttons .btn-group .btn:nth-child(5):hover {
  background-color: #66ad5d !important;
  color: #fff !important;
  border-color: #66ad5d !important;
}

</style>
