<template>
  <div>
    <hero></hero>
  </div>
</template>
<script>

import Hero from "./components-2023/Hero";

export default {
  name: "components",
  components: {
    Hero
  },
  data() {
    return {

    }
  }
};
</script>
