<template>
  <section class="section-hero section-shaped my-0 bg-google" style="background-image: url('/img/brand/bg-hero-2022.png')">
    <div class="shape shape-style-1 shape-portulans">
    </div>
    <div class="container shape-container d-flex align-items-center" style="z-index: 100 !important;">
      <div class="col px-0">
        <div class="row" style="margin-top: 5em">
          <div class="col-lg-5 text-left">
            <div class="animate__animated animate__fadeIn" style="transform: rotate(-45deg);display: inline-block;">
              <div style="width: 100%">
                <div @mouseout="noCompassHover" @mouseover="compassHover('what-if')" class="compass-piece top" style="float: left; width: 50%;">
                  <router-link to="/2022/what-ifs" title="What Ifs">
                    <img style="width: 100%" src="/img/green.png">
                  </router-link>
                </div>
                <div @mouseout="noCompassHover" @mouseover="compassHover('about')" class="compass-piece left" style="float: left; width: 50%;">
                  <router-link to="/2022/about" title="About Page">
                    <img style="width: 100%" src="/img/blue.png">
                  </router-link>
                </div>
                <div @mouseout="noCompassHover" @mouseover="compassHover('countries')" class="compass-piece bottom" style="float: left; width: 50%;">
                  <router-link to="/2022/report" title="Report Page">
                    <img style="width: 100%" src="/img/red.png">
                  </router-link>
                </div>
                <div @mouseout="noCompassHover" @mouseover="compassHover('contact')" class="compass-piece right" style="float: left; width: 50%;">
                  <router-link to="/2022/contact-us" title="Contact Us">
                    <img style="width: 100%" src="/img/yellow.png">
                  </router-link>
                </div>
              </div>
            </div>
            <h4 :class="checkHover('none')" class="compass-text text-center text-white">.</h4>
            <h4 :class="checkHover('what-if')" class="compass-text text-center">"What If" Scenarios</h4>
            <h4 :class="checkHover('about')" class="compass-text text-center">About FREI</h4>
            <h4 :class="checkHover('countries')" class="compass-text text-center">Countries</h4>
            <h4 :class="checkHover('contact')" class="compass-text text-center">Contact</h4>
          </div>
          <div class="col-lg-7 text-left">
            <a href="https://portulansinstitute.org/" target="_blank">
              <img src="/img/brand/portulans.png" style="width: 200px;" class="img-fluid animate__animated animate__fadeIn animate__delay-3s">
            </a>
            <h1 style="color:#111440" class="font-weight-700 mt-4 animate__animated animate__fadeInUp">FUTURE READINESS ECONOMIC INDEX
            </h1>
            <h4 style="color: #777b95; font-weight: normal; font-size: 22px" class="animate__animated animate__fadeIn animate__delay-1s">How digital sprinters can quantify, monitor, and accelerate their transformation</h4>

            <div class="animate__animated animate__fadeIn animate__delay-3s">

              <a @click.prevent
                 v-tooltip="{
          content: 'The contents of this report and joint website reflect the views of the authors, who are responsible for the facts and accuracy of the information presented herein. They do not represent the views of Google. Portulans Institute bears the full responsibility of country rankings.',
          class: 'tooltip-custom tooltip-other-custom'
      }"><p class="mt-3 d-inline-flex">Commissioned by</p></a>

              <br>

              <img src="/img/brand/google-logo-colour.png" style="width:150px;margin-bottom: 40px;opacity: 1">

            </div>

          </div>
        </div>

        <div class="btn-wrapper text-right animate__animated animate__fadeIn animate__delay-3s">
          <base-button tag="a"
                       href="/pdfs/Global Future Readiness FREI Report Portulans Institute 2022.pdf"
                       class="mb-3 mb-sm-0"
                       type="primary">
            Read Our Report
          </base-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "Hero",
  components: {
  },
  metaInfo: {
    title: 'Portulans Institute FREI 2022 | Home',
  },
  data() {
    return {
      compassHoverActive: 'none',
      countries: [
        'Argentina?',
        'Brazil?',
        'Chile?',
        'China?',
        'Colombia?',
        'Egypt?',
        'India?',
        'Indonesia?',
        'Israel?',
        'Jordan?',
        'Kenya?',
        'Kuwait?',
        'Lebanon?',
        'Mexico?',
        'Morocco?',
        'Nigeria?',
        'Peru?',
        'Qatar?',
        'Russia?',
        'Saudi Arabia?',
        'Singapore?',
        'South Africa?',
        'Thailand?',
        'Turkey?',
        'Ukraine?',
        'United Arab Emirates?',
        'Viet Nam?',
      ]
    }
  },
  methods: {
    compassHover(segment) {
      this.compassHoverActive = segment
    },
    noCompassHover() {
      this.compassHoverActive = 'none'
      // console.log('no hover')
      // let self = this
      // setTimeout(function() {
      //   console.log('hover cleared')
      //   self.compassHoverActive = null
      // }, 200);
    },
    checkHover(segment) {
      if (this.compassHoverActive === segment) {
        return 'd-block'
      } else {
        return 'd-none'
      }

    }
  }
}
</script>
<style>
.vue-typer .custom.char.typed {
  color: #0c7abf;
}
.vue-typer .custom.char.selected {
  color: #4a4a4a;
}
.compass-piece {
  transition: all 100ms;
}
.compass-piece.top:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(-10px, -10px)
}
.compass-piece.right:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(10px, 10px)
}
.compass-piece.bottom:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(-10px, 10px)
}
.compass-piece.left:hover {
  opacity: 0.9 !important;
  cursor: pointer;
  transition: all 500ms;
  transform: scale(1.1);
  transform: translate(10px, -10px)
}
.compass-text {
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px 0 10px 0;
  color: rgb(17, 20, 64);
}
</style>
