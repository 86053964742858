<template>
  <div class="row align-middle">

    <div class="text-center mw-100 px-5" style="margin: auto" v-if="isMobile">
      <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
      <br>
      <p class="font-weight-500">This section is best viewed in landscape mode</p>
    </div>

    <div class="col-12">
      <p class="lead" style="letter-spacing: -0.5px">
        This tool allows a clear, visual comparison of the future readiness of up to four countries by means of a radar chart. Five axes are considered here: the overall FREI score and the four pillars i.e. Physical Capital, Human Capital, Technology, and Competitiveness.
      </p>
    </div>

    <div class="col-12 text-center animate__animated animate__fadeIn mb-5 mt-3">
      <p class="font-weight-500 help-text"><i class="fa fa-lightbulb-o mr-2"/>Select up to four countries to compare their future
        readiness scores</p>
    </div>

    <div class="col-12">
      <div class="radar-container">
        <RadarComparison :chartData="this.chartData"></RadarComparison>
      </div>
    </div>

    <div class="col-12 pb-5">
      <div class="row">
        <div class="col-3 text-center">
          <h5 style="border-bottom: solid #65CBDA">Country A</h5>
          <b-form-select size="md" v-model="countryA" :options="options"></b-form-select>
        </div>
        <div class="col-3 text-center">
          <h5 style="border-bottom: solid #8635D5">Country B</h5>
          <b-form-select size="md" v-model="countryB" :options="options"></b-form-select>
        </div>
        <div class="col-3 text-center">
          <h5 style="border-bottom: solid #F24982">Country C</h5>
          <b-form-select size="md" v-model="countryC" :options="options"></b-form-select>
        </div>
        <div class="col-3 text-center">
          <h5 style="border-bottom: solid #2DC574">Country D</h5>
          <b-form-select size="md" v-model="countryD" :options="options"></b-form-select>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import countryComparisonData from '../../data_2022/comparison-chart/countryComparisons.js'

import RadarComparison from "@/components-2022/charts/RadarComparison";

export default {
  name: "CountryComparison",
  components: {RadarComparison},
  data() {
    return {
      countryA: '',
      countryB: '',
      countryC: '',
      countryD: '',
      options: countryComparisonData
    };
  },
  computed: {
    selected() {
      const selectedCountries = []
      if (this.countryA) {
        const firstCountry = this.countryA
        firstCountry.borderColor = "#65CBDA"
       selectedCountries.push(firstCountry)
      }
      if (this.countryB) {
        const secondCountry = this.countryB
        secondCountry.borderColor = "#8635D5"
       selectedCountries.push(secondCountry)
      }
      if (this.countryC) {
        const thirdCountry = this.countryC
        thirdCountry.borderColor = "#F24982"
       selectedCountries.push(thirdCountry)
      }
      if (this.countryD) {
        const forthCountry = this.countryD
        forthCountry.borderColor = "#2DC574"
       selectedCountries.push(forthCountry)
      }
      return selectedCountries
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    chartData() {
      const labels = [["FREI", "Score"], ["Physical Capital"], "Human Capital", "Technology", "Competitiveness"]
      const chartDataSet = {
        labels: labels,
        datasets: this.selected,
      };
      return chartDataSet;
    },
  },
};
</script>

<style>

.radar-container {
  max-width: 600px;
  margin: auto;
}

.country-compare-buttons .btn-group .btn:first-child {
  border-bottom: solid 3px #88D8B0 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:first-child {
  background-color: #88D8B0 !important;
  color: #fff !important;
  border-color: #88D8B0 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(2) {
  border-bottom: solid 3px #ce5cc5 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(2) {
  background-color: #ce5cc5 !important;
  color: #fff !important;
  border-color: #ce5cc5 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(3) {
  border-bottom: solid 3px #FED06A !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(3) {
  background-color: #FED06A !important;
  color: #fff !important;
  border-color: #FED06A !important;
}

.country-compare-buttons .btn-group .btn:nth-child(4) {
  border-bottom: solid 3px #F96E5A !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(4) {
  background-color: #F96E5A !important;
  color: #fff !important;
  border-color: #F96E5A !important;
}

.country-compare-buttons .btn-group .btn:nth-child(5) {
  border-bottom: solid 3px #1583D1 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(5) {
  background-color: #1583D1 !important;
  color: #fff !important;
  border-color: #1583D1 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(6) {
  border-bottom: solid 3px #65CBDA !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(6) {
  background-color: #65CBDA !important;
  color: #fff !important;
  border-color: #65CBDA !important;
}

.country-compare-buttons .btn-group .btn:nth-child(7) {
  border-bottom: solid 3px #8635D5 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(7) {
  background-color: #8635D5 !important;
  color: #fff !important;
  border-color: #8635D5 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(8) {
  border-bottom: solid 3px #F24982 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(8) {
  background-color: #F24982 !important;
  color: #fff !important;
  border-color: #F24982 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(9) {
  border-bottom: solid 3px #F98617 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(9) {
  background-color: #F98617 !important;
  color: #fff !important;
  border-color: #F98617 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(10) {
  border-bottom: solid 3px #F9C823 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(10) {
  background-color: #F9C823 !important;
  color: #fff !important;
  border-color: #F9C823 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(11) {
  border-bottom: solid 3px #2DC574 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(11) {
  background-color: #2DC574 !important;
  color: #fff !important;
  border-color: #2DC574 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(12) {
  border-bottom: solid 3px #006CDC !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(12) {
  background-color: #006CDC !important;
  color: #fff !important;
  border-color: #006CDC !important;
}

.country-compare-buttons .btn-group .btn:nth-child(13) {
  border-bottom: solid 3px #E65F50 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(13) {
  background-color: #E65F50 !important;
  color: #fff !important;
  border-color: #E65F50 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(14) {
  border-bottom: solid 3px #FF8D5C !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(14) {
  background-color: #FF8D5C !important;
  color: #fff !important;
  border-color: #FF8D5C !important;
}

.country-compare-buttons .btn-group .btn:nth-child(15) {
  border-bottom: solid 3px #FDC85E !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(15) {
  background-color: #FDC85E !important;
  color: #fff !important;
  border-color: #FDC85E !important;
}

.country-compare-buttons .btn-group .btn:nth-child(16) {
  border-bottom: solid 3px #FDEA8B !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(16) {
  background-color: #FDEA8B !important;
  color: #fff !important;
  border-color: #FDEA8B !important;
}

.country-compare-buttons .btn-group .btn:nth-child(17) {
  border-bottom: solid 3px #7EA157 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(17) {
  background-color: #7EA157 !important;
  color: #fff !important;
  border-color: #7EA157 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(18) {
  border-bottom: solid 3px #37446C !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(18) {
  background-color: #37446C !important;
  color: #fff !important;
  border-color: #37446C !important;
}

.country-compare-buttons .btn-group .btn:nth-child(19) {
  border-bottom: solid 3px #F9D98E !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(19) {
  background-color: #F9D98E !important;
  color: #fff !important;
  border-color: #F9D98E !important;
}

.country-compare-buttons .btn-group .btn:nth-child(20) {
  border-bottom: solid 3px #F0876C !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(20) {
  background-color: #F0876C !important;
  color: #fff !important;
  border-color: #F0876C !important;
}

.country-compare-buttons .btn-group .btn:nth-child(21) {
  border-bottom: solid 3px #5E3D63 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(21) {
  background-color: #5E3D63 !important;
  color: #fff !important;
  border-color: #5E3D63 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(22) {
  border-bottom: solid 3px #5FC6BA !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(22) {
  background-color: #5FC6BA !important;
  color: #fff !important;
  border-color: #5FC6BA !important;
}

.country-compare-buttons .btn-group .btn:nth-child(23) {
  border-bottom: solid 3px #D5B96A !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(23) {
  background-color: #D5B96A !important;
  color: #fff !important;
  border-color: #D5B96A !important;
}

.country-compare-buttons .btn-group .btn:nth-child(24) {
  border-bottom: solid 3px #727670 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(24) {
  background-color: #727670 !important;
  color: #fff !important;
  border-color: #727670 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(25) {
  border-bottom: solid 3px #9BA385 !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(25) {
  background-color: #9BA385 !important;
  color: #fff !important;
  border-color: #9BA385 !important;
}

.country-compare-buttons .btn-group .btn:nth-child(26) {
  border-bottom: solid 3px #D7C59F !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(26) {
  background-color: #D7C59F !important;
  color: #fff !important;
  border-color: #D7C59F !important;
}

.country-compare-buttons .btn-group .btn:nth-child(27) {
  border-bottom: solid 3px #34215E !important;
  margin: 5px;
}

.country-compare-buttons .btn-group .btn.active:nth-child(27) {
  background-color: #34215E !important;
  color: #fff !important;
  border-color: #34215E !important;
}
</style>
