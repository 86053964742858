<template>
    <div id="app">
        <router-view name="header"></router-view>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>

        <router-view name="footer" v-if="this.$route.name !== 'home'"></router-view>
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  },
  metaInfo() {
      return {
          title: 'Portulans Institute - Future Readiness Economic Index',
          meta: [
              { name: 'google-site-verification', content:  'bgrJ-0CpJHbXwPR-lc0QoyDE8L4Ft6wIRxCpTLF743w'},
              { name: 'description', content: 'How digital sprinters can quantify, monitor, and accelerate their transformation.' },
          ]
      }
  }
};
</script>
