import Vue from "vue";
import Router from "vue-router";

// 2023
import AppHeader2023 from "./layout-2023/AppHeader";
import AppFooter2023 from "./layout-2023/AppFooter";
import Report2023 from "./views-2023/Report.vue";
import About2023 from "./views-2023/About.vue";
import Home2023 from "./views-2023/Home.vue";
import WhatIfs2023 from "./views-2023/WhatIfs.vue";
import ContactUs2023 from "./views-2023/ContactUs.vue";


// 2022
import AppHeader2022 from "./layout-2022/AppHeader";
import AppFooter2022 from "./layout-2022/AppFooter";
import Report2022 from "./views-2022/Report.vue";
import About2022 from "./views-2022/About.vue";
import Home2022 from "./views-2022/Home.vue";
import WhatIfs2022 from "./views-2022/WhatIfs.vue";
import ContactUs2022 from "./views-2022/ContactUs.vue";

// 2021
import AppHeader2021 from "./layout-2021/AppHeader";
import AppFooter2021 from "./layout-2021/AppFooter";
import Report2021 from "./views-2021/Report.vue";
import About2021 from "./views-2021/About.vue";
import Home2021 from "./views-2021/Home.vue";
import WhatIfs2021 from "./views-2021/WhatIfs.vue";
import ContactUs2021 from "./views-2021/ContactUs.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: "active",
  routes: [
    // 2023
    {
      path: '/',
      redirect: '/2023',
      name: 'home',
    },
    {
      path: '*',
      redirect: '/2023',
      name: 'home',
    },
    {
      path: "/2023/report",
      name: "components",
      components: {
        header: AppHeader2023,
        default: Report2023,
        footer: AppFooter2023
      }
    },
    {
      path: "/2023/about",
      name: "about",
      components: {
        header: AppHeader2023,
        default: About2023,
        footer: AppFooter2023
      }
    },
    {
      path: "/2023/",
      name: "home",
      components: {
        header: AppHeader2023,
        default: Home2023,
        footer: AppFooter2023
      }
    },
    {
      path: "/2023/what-ifs",
      name: "what-ifs",
      components: {
        header: AppHeader2023,
        default: WhatIfs2023,
        footer: AppFooter2023
      }
    },
    {
      path: "/2023/contact-us",
      name: "contact",
      components: {
        header: AppHeader2023,
        default: ContactUs2023,
        footer: AppFooter2023
      }
    },
    // 2022
    {
      path: "/2022/report",
      name: "components",
      components: {
        header: AppHeader2022,
        default: Report2022,
        footer: AppFooter2022
      }
    },
    {
      path: "/2022/about",
      name: "about",
      components: {
        header: AppHeader2022,
        default: About2022,
        footer: AppFooter2022
      }
    },
    {
      path: "/2022/",
      name: "home",
      components: {
        header: AppHeader2022,
        default: Home2022,
        footer: AppFooter2022
      }
    },
    {
      path: "/2022/what-ifs",
      name: "what-ifs",
      components: {
        header: AppHeader2022,
        default: WhatIfs2022,
        footer: AppFooter2022
      }
    },
    {
      path: "/2022/contact-us",
      name: "contact",
      components: {
        header: AppHeader2022,
        default: ContactUs2022,
        footer: AppFooter2022
      }
    },
    // 2021
    {
      path: "/2021/report",
      name: "components",
      components: {
        header: AppHeader2021,
        default: Report2021,
        footer: AppFooter2021
      }
    },
    {
      path: "/2021/about",
      name: "about",
      components: {
        header: AppHeader2021,
        default: About2021,
        footer: AppFooter2021
      }
    },
    {
      path: "/2021/",
      name: "home",
      components: {
        header: AppHeader2021,
        default: Home2021,
        footer: AppFooter2021
      }
    },
    {
      path: "/2021/what-ifs",
      name: "what-ifs",
      components: {
        header: AppHeader2021,
        default: WhatIfs2021,
        footer: AppFooter2021
      }
    },
    {
      path: "/2021/contact-us",
      name: "contact",
      components: {
        header: AppHeader2021,
        default: ContactUs2021,
        footer: AppFooter2021
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
