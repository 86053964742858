<template>
  <div class="row">

    <div class="text-center mw-100 px-5" style="margin: auto" v-if="isMobile">
      <i class="fa fa-mobile-phone fa-rotate-90 fa-3x"></i>
      <br>
      <p class="font-weight-500">This section is best viewed in landscape mode</p>
    </div>

    <div class="col-12">
      <p class="lead" style="letter-spacing: -0.5px">
        The country rankings table below summarizes the future readiness positions of the 124 countries in the FREI 2022 Report. It lists countries in order of decreasing overall performance (column 1). Data inside the table (columns 2 to 5) refer to the scores and ranks in each of the four FREI pillars.</p>
      <p>How to read this table: within each pillar, different shades correspond to different relative levels of performance: darker shades indicate areas in which the country considered performs relatively well, whereas the lightest shade point at areas requiring priority attention. Intermediate shading corresponds to areas for which improvement remains important.</p>
      <hr>
    </div>

    <div class="col-lg-6 col-12 mb-5 ranking-toggle">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
            class="btn-group special"
            id="checkbox-group-1"
            v-model="mode"
            :options="options"
            :aria-describedby="ariaDescribedby"
            buttons
            button-variant="outline-secondary"
            name="buttons2"
        ></b-form-radio-group>
      </b-form-group>
    </div>

    <div class="col-12" style="overflow: scroll">
      <template>
        <div>
          <b-table id="table-transition"
                   v-if="mode ==='score'"
                   class="rankingsTable"
                   :items="sortedArray"
                   :fields="fields"
                   small
                   fixed
                   primary-key="Country"
                   :tbody-transition-props="transProps">
                    <template #cell(Rank)="data">
                      <span class="font-weight-bold">{{ data.index + 1 }}</span>
                    </template>
                    <template #cell(Country)="data">
                      <span class="country-name" @click="goToProfile(data.value)">{{ data.value }}</span>
                    </template>
                    <template #cell(FREI)="data">
                      <div class="py-3">
                        <transition name="fade" mode="out-in">
                          <span key="FREIScore" >{{ data.value.score.toFixed(2) }}</span>
                        </transition>
                      </div>
                    </template>
                    <template #cell(PhysicalCapital)="data" >
                      <div class="py-3">
                      <transition name="fade" mode="out-in">
                        <span  key="PhysicalCapitalScore">{{ data.value.score.toFixed(2) }}</span>
                      </transition>
                      </div>
                    </template>
                    <template #cell(HumanCapital)="data" >
                      <div class="py-3">
                        <transition name="fade" mode="out-in">
                          <span  key="HumanCapitalScore">{{ data.value.score.toFixed(2) }}</span>
                        </transition>
                      </div>
                    </template>
                    <template #cell(Technology)="data" >
                      <div class="py-3">
                      <transition name="fade" mode="out-in">
                        <span  key="TechnologyScore">{{ data.value.score.toFixed(2) }}</span>
                      </transition>
                      </div>
                    </template>
                    <template #cell(Competitiveness)="data" >
                      <div class="py-3">
                        <transition name="fade" mode="out-in">
                          <span  key="CompetitivenessScore">{{ data.value.score.toFixed(2) }}</span>
                        </transition>
                      </div>
                    </template>
          </b-table>
          <b-table id="table-transition"
                   v-if="mode ==='rank'"
                   class="rankingsTable"
                   :items="sortedArray"
                   :fields="fields"
                   small
                   fixed
                   primary-key="Country"
                   :tbody-transition-props="transProps">
            <template #cell(Rank)="data">
              <span class="font-weight-bold">{{ data.index + 1 }}</span>
            </template>
            <template #cell(Country)="data">
              <span class="country-name" @click="goToProfile(data.value)">{{ data.value }}</span>
            </template>
            <template #cell(FREI)="data">
              <div class="py-3">
                <transition name="fade" mode="out-in">
                  <span key="FREI">{{ data.value.rank }}</span>
                </transition>
              </div>
            </template>
            <template #cell(PhysicalCapital)="data" >
              <div class="py-3">
                <transition name="fade" mode="out-in">
                  <span key="physicalCapitalRank">{{ data.value.rank }}</span>
                </transition>
              </div>
            </template>
            <template #cell(HumanCapital)="data" >
              <div class="py-3">
                <transition name="fade" mode="out-in">
                  <span key="humanCapitalRank">{{ data.value.rank }}</span>
                </transition>
              </div>
            </template>
            <template #cell(Technology)="data" >
              <div class="py-3">
                <transition name="fade" mode="out-in">
                  <span key="technologyRank">{{ data.value.rank }}</span>
                </transition>
              </div>
            </template>
            <template #cell(Competitiveness)="data" >
              <div class="py-3">
                <transition name="fade" mode="out-in">
                  <span key="competitivenessRank">{{ data.value.rank }}</span>
                </transition>
              </div>
            </template>
          </b-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import countryRankingData from '../../data_2022/rankings-table/countryRankings.js'
import countryRankingDataMaximumRanks from '../../data_2022/rankings-table/countryRankingDataMaximums.json'
import { BTable } from 'bootstrap-vue'

export default {
  name: "RankingsTable",
  components: {
    BTable
  },
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      mode: 'rank',
      showFutureReadiness: false,
      showPhysicalCapital: false,
      showTechnology: false,
      showHumanCapital: false,
      showCompetitiveness: false,
      countryRankingData: countryRankingData,
      countryRankingDataMaximums: countryRankingDataMaximumRanks,
      fields: [
        { key: 'Rank', sortable: false, tdClass: "countryColumn" , thClass: 'comparisonHeader text-white', sortDirection: 'desc' },
        { key: 'Country', sortable: false, tdClass: "countryColumn" , thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'FREI', sortable: false, thClass: 'comparisonHeader', tdClass: "backgroundOpacity", sortDirection: 'desc' },
        { key: 'PhysicalCapital', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'HumanCapital', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'Technology', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' },
        { key: 'Competitiveness', sortable: false , tdClass: "backgroundOpacity", thClass: 'comparisonHeader', sortDirection: 'desc' }
      ],
      options: [
        {text: 'Show pillar ranks', value: 'rank'},
        {text: 'Show pillar scores', value: 'score'},
      ]
    }
  },
  computed: {
    totalQuantity() {
      return this.items.reduce((total, item) => {
        return total + item.quantity
      }, 0)
    },
    sortedArray: function() {
      let arrays = countryRankingData
      function compare(a, b) {
        if (a.FREI.rank < b.FREI.rank)
          return -1;
        if (a.FREI.rank > b.FREI.rank)
          return 1;
        return 0;
      }
      return arrays.sort(compare);
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goToProfile(country) {
      let countryForUrl = country.replace(" ", "_").toLowerCase();
      const query = { ...this.$route.query, page: 'profiles', country: countryForUrl };
      this.$router.replace({ query });
      window.location.reload();
    },
    toggleMode(mode) {
      this.mode = mode
    },
    backgroundOpacity(value, key) {
      const maxValue = this.countryRankingDataMaximums[key]
      const opacityValue = (maxValue - value.rank)/maxValue
      if (Math.round(opacityValue * 10) == 0) {
        return 'comparisonCellNoPadding ' + key + 'Opacity1'
      } else {
        return 'comparisonCellNoPadding ' + key + 'Opacity' + Math.round(opacityValue * 10)
      }
    }
  }
}
</script>

<style>

.ranking-toggle {
  margin: auto;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.rankingsTable th:focus {
  outline: 0;
}

.rankingsTable th {
  border-top: solid 5px #FFF !important;
  text-align: center;
}

.rankingsTable th:nth-child(2)[aria-sort='descending'] {
  border-top: solid 5px #4285F4 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(2)[aria-sort='ascending'] {
  border-top: solid 5px #4285F4 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='descending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(3)[aria-sort='ascending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='descending'] {
  border-top: solid 5px #F4B400 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(4)[aria-sort='ascending'] {
  border-top: solid 5px #F4B400 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='descending'] {
  border-top: solid 5px #0F9D58 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(5)[aria-sort='ascending'] {
  border-top: solid 5px #0F9D58 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='descending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.rankingsTable th:nth-child(6)[aria-sort='ascending'] {
  border-top: solid 5px #DB4437 !important;
  background: #fbfbfb;
}

.country-name:hover {
  cursor: pointer;
  text-decoration: underline;
}

</style>
