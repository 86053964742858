import data from '../data.json'

const countryProfilesData = {
    "countries": {
        "albania": {
            "radar_data": [
                {
                    "label": "Albania",
                    "data": [
                        data.albania.future_readiness_score,
                        data.albania.physical_capital,
                        data.albania.human_capital,
                        data.albania.technology,
                        data.albania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Albania",
            "code": "al",
            "stats": {
                "country": "Albania",
                "future_readiness_rank": "62",
                "future_readiness_score": "42.64742775",
                "future_readiness_ranking": data.albania.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "2.775634",
                "gdp_per_capita": "18551.71648",
                "gdp": "18.88209552",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "algeria": {
            "radar_data": [
                {
                    "label": "Algeria",
                    "data": [
                        data.algeria.future_readiness_score,
                        data.algeria.physical_capital,
                        data.algeria.human_capital,
                        data.algeria.technology,
                        data.algeria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Algeria",
            "code": "dz",
            "stats": {
                "country": "Algeria",
                "future_readiness_rank": "93",
                "future_readiness_score": "33.3795514",
                "future_readiness_ranking": data.algeria.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Middle East and North Africa",
                "population": "44.903225",
                "gdp_per_capita": "13209.59677",
                "gdp": "191.9128894",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "angola": {
            "radar_data": [
                {
                    "label": "Angola",
                    "data": [
                        data.angola.future_readiness_score,
                        data.angola.physical_capital,
                        data.angola.human_capital,
                        data.angola.technology,
                        data.angola.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Angola",
            "code": "ao",
            "stats": {
                "country": "Angola",
                "future_readiness_rank": "115",
                "future_readiness_score": "21.37663261",
                "future_readiness_ranking": data.angola.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "35.588987",
                "gdp_per_capita": "6973.696299",
                "gdp": "106.7136187",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "argentina": {
            "radar_data": [
                {
                    "label": "Argentina",
                    "data": [
                        data.argentina.future_readiness_score,
                        data.argentina.physical_capital,
                        data.argentina.human_capital,
                        data.argentina.technology,
                        data.argentina.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Argentina",
            "code": "ar",
            "stats": {
                "country": "Argentina",
                "future_readiness_rank": "52",
                "future_readiness_score": "45.4150368",
                "future_readiness_ranking": data.argentina.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "46.23483",
                "gdp_per_capita": "26504.59056",
                "gdp": "632.7702844",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "armenia": {
            "radar_data": [
                {
                    "label": "Armenia",
                    "data": [
                        data.armenia.future_readiness_score,
                        data.armenia.physical_capital,
                        data.armenia.human_capital,
                        data.armenia.technology,
                        data.armenia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Armenia",
            "code": "am",
            "stats": {
                "country": "Armenia",
                "future_readiness_rank": "49",
                "future_readiness_score": "46.50474004",
                "future_readiness_ranking": data.armenia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Middle East and North Africa",
                "population": "2.780469",
                "gdp_per_capita": "18941.52681",
                "gdp": "19.50278399",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "australia": {
            "radar_data": [
                {
                    "label": "Australia",
                    "data": [
                        data.australia.future_readiness_score,
                        data.australia.physical_capital,
                        data.australia.human_capital,
                        data.australia.technology,
                        data.australia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Australia",
            "code": "au",
            "stats": {
                "country": "Australia",
                "future_readiness_rank": "10",
                "future_readiness_score": "69.9414551",
                "future_readiness_ranking": data.australia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Asia Pacific",
                "population": "25.978935",
                "gdp_per_capita": "62625.35764",
                "gdp": "1675.418665",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "austria": {
            "radar_data": [
                {
                    "label": "Austria",
                    "data": [
                        data.austria.future_readiness_score,
                        data.austria.physical_capital,
                        data.austria.human_capital,
                        data.austria.technology,
                        data.austria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Austria",
            "code": "at",
            "stats": {
                "country": "Austria",
                "future_readiness_rank": "18",
                "future_readiness_score": "63.58558161",
                "future_readiness_ranking": data.austria.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "9.042528",
                "gdp_per_capita": "67935.84797",
                "gdp": "471.4000661",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "azerbaijan": {
            "radar_data": [
                {
                    "label": "Azerbaijan",
                    "data": [
                        data.azerbaijan.future_readiness_score,
                        data.azerbaijan.physical_capital,
                        data.azerbaijan.human_capital,
                        data.azerbaijan.technology,
                        data.azerbaijan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Azerbaijan",
            "code": "az",
            "stats": {
                "country": "Azerbaijan",
                "future_readiness_rank": "74",
                "future_readiness_score": "39.36419003",
                "future_readiness_ranking": data.azerbaijan.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Middle East and North Africa",
                "population": "10.175016",
                "gdp_per_capita": "17764.44559",
                "gdp": "78.72105882",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bahrain": {
            "radar_data": [
                {
                    "label": "Bahrain",
                    "data": [
                        data.bahrain.future_readiness_score,
                        data.bahrain.physical_capital,
                        data.bahrain.human_capital,
                        data.bahrain.technology,
                        data.bahrain.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bahrain",
            "code": "bh",
            "stats": {
                "country": "Bahrain",
                "future_readiness_rank": "45",
                "future_readiness_score": "49.50164169",
                "future_readiness_ranking": data.bahrain.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "1.472233",
                "gdp_per_capita": "61227.89722",
                "gdp": "44.39082048",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bangladesh": {
            "radar_data": [
                {
                    "label": "Bangladesh",
                    "data": [
                        data.bangladesh.future_readiness_score,
                        data.bangladesh.physical_capital,
                        data.bangladesh.human_capital,
                        data.bangladesh.technology,
                        data.bangladesh.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bangladesh",
            "code": "bd",
            "stats": {
                "country": "Bangladesh",
                "future_readiness_rank": "101",
                "future_readiness_score": "27.70506129",
                "future_readiness_ranking": data.bangladesh.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "171.186372",
                "gdp_per_capita": "7395.095987",
                "gdp": "460.2010001",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "belarus": {
            "radar_data": [
                {
                    "label": "Belarus",
                    "data": [
                        data.belarus.future_readiness_score,
                        data.belarus.physical_capital,
                        data.belarus.human_capital,
                        data.belarus.technology,
                        data.belarus.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Belarus",
            "code": "by",
            "stats": {
                "country": "Belarus",
                "future_readiness_rank": "60",
                "future_readiness_score": "43.7269483",
                "future_readiness_ranking": data.belarus.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "9.208701",
                "gdp_per_capita": "22590.59092",
                "gdp": "72.79345759",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "belgium": {
            "radar_data": [
                {
                    "label": "Belgium",
                    "data": [
                        data.belgium.future_readiness_score,
                        data.belgium.physical_capital,
                        data.belgium.human_capital,
                        data.belgium.technology,
                        data.belgium.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Belgium",
            "code": "be",
            "stats": {
                "country": "Belgium",
                "future_readiness_rank": "17",
                "future_readiness_score": "64.07601299",
                "future_readiness_ranking": data.belgium.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "11.669446",
                "gdp_per_capita": "65027.2949",
                "gdp": "578.6041031",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "benin": {
            "radar_data": [
                {
                    "label": "Benin",
                    "data": [
                        data.benin.future_readiness_score,
                        data.benin.physical_capital,
                        data.benin.human_capital,
                        data.benin.technology,
                        data.benin.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Benin",
            "code": "bj",
            "stats": {
                "country": "Benin",
                "future_readiness_rank": "108",
                "future_readiness_score": "23.51014015",
                "future_readiness_ranking": data.benin.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "13.352864",
                "gdp_per_capita": "4056.108341",
                "gdp": "17.40174631",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bolivia": {
            "radar_data": [
                {
                    "label": "Bolivia",
                    "data": [
                        data.bolivia.future_readiness_score,
                        data.bolivia.physical_capital,
                        data.bolivia.human_capital,
                        data.bolivia.technology,
                        data.bolivia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bolivia",
            "code": "bo",
            "stats": {
                "country": "Bolivia, Plurinational St.",
                "future_readiness_rank": "86",
                "future_readiness_score": "36.27122162",
                "future_readiness_ranking": data.bolivia.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "12.22411",
                "gdp_per_capita": "9683.617497",
                "gdp": "43.06888567",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bosnia_and_herzegovina": {
            "radar_data": [
                {
                    "label": "Bosnia_and_herzegovina",
                    "data": [
                        data.bosnia_and_herzegovina.future_readiness_score,
                        data.bosnia_and_herzegovina.physical_capital,
                        data.bosnia_and_herzegovina.human_capital,
                        data.bosnia_and_herzegovina.technology,
                        data.bosnia_and_herzegovina.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bosnia_and_herzegovina",
            "code": "ba",
            "stats": {
                "country": "Bosnia and Herzegovina",
                "future_readiness_rank": "72",
                "future_readiness_score": "39.67070361",
                "future_readiness_ranking": data.bosnia_and_herzegovina.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "3.233526",
                "gdp_per_capita": "20376.89219",
                "gdp": "24.52750729",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "botswana": {
            "radar_data": [
                {
                    "label": "Botswana",
                    "data": [
                        data.botswana.future_readiness_score,
                        data.botswana.physical_capital,
                        data.botswana.human_capital,
                        data.botswana.technology,
                        data.botswana.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Botswana",
            "code": "bw",
            "stats": {
                "country": "Botswana",
                "future_readiness_rank": "89",
                "future_readiness_score": "36.11869297",
                "future_readiness_ranking": data.botswana.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "2.630296",
                "gdp_per_capita": "18323.0457",
                "gdp": "20.35232216",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "brazil": {
            "radar_data": [
                {
                    "label": "Brazil",
                    "data": [
                        data.brazil.future_readiness_score,
                        data.brazil.physical_capital,
                        data.brazil.human_capital,
                        data.brazil.technology,
                        data.brazil.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Brazil",
            "code": "br",
            "stats": {
                "country": "Brazil",
                "future_readiness_rank": "54",
                "future_readiness_score": "45.11357464",
                "future_readiness_ranking": data.brazil.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "215.313498",
                "gdp_per_capita": "17821.73726",
                "gdp": "1920.095561",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "bulgaria": {
            "radar_data": [
                {
                    "label": "Bulgaria",
                    "data": [
                        data.bulgaria.future_readiness_score,
                        data.bulgaria.physical_capital,
                        data.bulgaria.human_capital,
                        data.bulgaria.technology,
                        data.bulgaria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Bulgaria",
            "code": "bg",
            "stats": {
                "country": "Bulgaria",
                "future_readiness_rank": "46",
                "future_readiness_score": "49.4730423",
                "future_readiness_ranking": data.bulgaria.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "6.465097",
                "gdp_per_capita": "33582.28261",
                "gdp": "89.04039841",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "burkina_faso": {
            "radar_data": [
                {
                    "label": "Burkina Faso",
                    "data": [
                        data.burkina_faso.future_readiness_score,
                        data.burkina_faso.physical_capital,
                        data.burkina_faso.human_capital,
                        data.burkina_faso.technology,
                        data.burkina_faso.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Burkina Faso",
            "code": "bf",
            "stats": {
                "country": "Burkina Faso",
                "future_readiness_rank": "118",
                "future_readiness_score": "19.3697366",
                "future_readiness_ranking": data.burkina_faso.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "22.673762",
                "gdp_per_capita": "2545.863549",
                "gdp": "18.88461961",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cambodia": {
            "radar_data": [
                {
                    "label": "Cambodia",
                    "data": [
                        data.cambodia.future_readiness_score,
                        data.cambodia.physical_capital,
                        data.cambodia.human_capital,
                        data.cambodia.technology,
                        data.cambodia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Cambodia",
            "code": "kh",
            "stats": {
                "country": "Cambodia",
                "future_readiness_rank": "97",
                "future_readiness_score": "29.14921355",
                "future_readiness_ranking": data.cambodia.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "16.767842",
                "gdp_per_capita": "5349.463794",
                "gdp": "29.95676953",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cameroon": {
            "radar_data": [
                {
                    "label": "Cameroon",
                    "data": [
                        data.cameroon.future_readiness_score,
                        data.cameroon.physical_capital,
                        data.cameroon.human_capital,
                        data.cameroon.technology,
                        data.cameroon.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Cameroon",
            "code": "cm",
            "stats": {
                "country": "Cameroon",
                "future_readiness_rank": "113",
                "future_readiness_score": "21.99107237",
                "future_readiness_ranking": data.cameroon.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "27.914536",
                "gdp_per_capita": "4408.046274",
                "gdp": "44.34164651",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "canada": {
            "radar_data": [
                {
                    "label": "Canada",
                    "data": [
                        data.canada.future_readiness_score,
                        data.canada.physical_capital,
                        data.canada.human_capital,
                        data.canada.technology,
                        data.canada.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Canada",
            "code": "ca",
            "stats": {
                "country": "Canada",
                "future_readiness_rank": "11",
                "future_readiness_score": "68.93183869",
                "future_readiness_ranking": data.canada.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Northern America",
                "population": "38.929902",
                "gdp_per_capita": "58399.54548",
                "gdp": "2139.840024",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "chile": {
            "radar_data": [
                {
                    "label": "Chile",
                    "data": [
                        data.chile.future_readiness_score,
                        data.chile.physical_capital,
                        data.chile.human_capital,
                        data.chile.technology,
                        data.chile.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Chile",
            "code": "cl",
            "stats": {
                "country": "Chile",
                "future_readiness_rank": "43",
                "future_readiness_score": "49.60131269",
                "future_readiness_ranking": data.chile.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "19.603733",
                "gdp_per_capita": "30208.80553",
                "gdp": "301.0252494",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "china": {
            "radar_data": [
                {
                    "label": "China",
                    "data": [
                        data.china.future_readiness_score,
                        data.china.physical_capital,
                        data.china.human_capital,
                        data.china.technology,
                        data.china.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "China",
            "code": "cn",
            "stats": {
                "country": "China",
                "future_readiness_rank": "38",
                "future_readiness_score": "52.49567986",
                "future_readiness_ranking": data.china.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Asia Pacific",
                "population": "1412.175",
                "gdp_per_capita": "21475.61053",
                "gdp": "17963.17052",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "colombia": {
            "radar_data": [
                {
                    "label": "Colombia",
                    "data": [
                        data.colombia.future_readiness_score,
                        data.colombia.physical_capital,
                        data.colombia.human_capital,
                        data.colombia.technology,
                        data.colombia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Colombia",
            "code": "co",
            "stats": {
                "country": "Colombia",
                "future_readiness_rank": "67",
                "future_readiness_score": "41.72503039",
                "future_readiness_ranking": data.colombia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "51.874024",
                "gdp_per_capita": "20287.40026",
                "gdp": "343.9394453",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "costa_rica": {
            "radar_data": [
                {
                    "label": "Costa Rica",
                    "data": [
                        data.costa_rica.future_readiness_score,
                        data.costa_rica.physical_capital,
                        data.costa_rica.human_capital,
                        data.costa_rica.technology,
                        data.costa_rica.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Costa Rica",
            "code": "cr",
            "stats": {
                "country": "Costa Rica",
                "future_readiness_rank": "57",
                "future_readiness_score": "44.48623382",
                "future_readiness_ranking": data.costa_rica.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "5.180829",
                "gdp_per_capita": "24922.65927",
                "gdp": "68.38083832",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cote_divoire": {
            "radar_data": [
                {
                    "label": "Côte d'Ivoire",
                    "data": [
                        data.cote_divoire.future_readiness_score,
                        data.cote_divoire.physical_capital,
                        data.cote_divoire.human_capital,
                        data.cote_divoire.technology,
                        data.cote_divoire.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Côte d'Ivoire",
            "code": "ci",
            "stats": {
                "country": "Côte d’Ivoire",
                "future_readiness_rank": "106",
                "future_readiness_score": "25.93336136",
                "future_readiness_ranking": data.cote_divoire.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "28.160542",
                "gdp_per_capita": "6538.29642",
                "gdp": "70.01871502",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "croatia": {
            "radar_data": [
                {
                    "label": "Croatia",
                    "data": [
                        data.croatia.future_readiness_score,
                        data.croatia.physical_capital,
                        data.croatia.human_capital,
                        data.croatia.technology,
                        data.croatia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Croatia",
            "code": "hr",
            "stats": {
                "country": "Croatia",
                "future_readiness_rank": "35",
                "future_readiness_score": "52.93561543",
                "future_readiness_ranking": data.croatia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "3.854",
                "gdp_per_capita": "40379.57244",
                "gdp": "70.96460646",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "cyprus": {
            "radar_data": [
                {
                    "label": "Cyprus",
                    "data": [
                        data.cyprus.future_readiness_score,
                        data.cyprus.physical_capital,
                        data.cyprus.human_capital,
                        data.cyprus.technology,
                        data.cyprus.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Cyprus",
            "code": "cy",
            "stats": {
                "country": "Cyprus",
                "future_readiness_rank": "28",
                "future_readiness_score": "57.92623596",
                "future_readiness_ranking": data.cyprus.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "1.251488",
                "gdp_per_capita": "49930.86719",
                "gdp": "28.43905274",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "czech_republic": {
            "radar_data": [
                {
                    "label": "Czech Republic",
                    "data": [
                        data.czech_republic.future_readiness_score,
                        data.czech_republic.physical_capital,
                        data.czech_republic.human_capital,
                        data.czech_republic.technology,
                        data.czech_republic.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Czech Republic",
            "code": "cz",
            "stats": {
                "country": "Czech Republic",
                "future_readiness_rank": "31",
                "future_readiness_score": "55.74629373",
                "future_readiness_ranking": data.czech_republic.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.526073",
                "gdp_per_capita": "49945.5001",
                "gdp": "290.9235347",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "denmark": {
            "radar_data": [
                {
                    "label": "Denmark",
                    "data": [
                        data.denmark.future_readiness_score,
                        data.denmark.physical_capital,
                        data.denmark.human_capital,
                        data.denmark.technology,
                        data.denmark.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Denmark",
            "code": "dk",
            "stats": {
                "country": "Denmark",
                "future_readiness_rank": "2",
                "future_readiness_score": "75.08019413",
                "future_readiness_ranking": data.denmark.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.903037",
                "gdp_per_capita": "74005.47852",
                "gdp": "395.4039066",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "dominican_republic": {
            "radar_data": [
                {
                    "label": "Dominican Republic",
                    "data": [
                        data.dominican_republic.future_readiness_score,
                        data.dominican_republic.physical_capital,
                        data.dominican_republic.human_capital,
                        data.dominican_republic.technology,
                        data.dominican_republic.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Dominican Republic",
            "code": "do",
            "stats": {
                "country": "Dominican Republic",
                "future_readiness_rank": "90",
                "future_readiness_score": "34.90472726",
                "future_readiness_ranking": data.dominican_republic.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "11.228821",
                "gdp_per_capita": "22833.52575",
                "gdp": "113.6418574",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ecuador": {
            "radar_data": [
                {
                    "label": "Ecuador",
                    "data": [
                        data.ecuador.future_readiness_score,
                        data.ecuador.physical_capital,
                        data.ecuador.human_capital,
                        data.ecuador.technology,
                        data.ecuador.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ecuador",
            "code": "ec",
            "stats": {
                "country": "Ecuador",
                "future_readiness_rank": "69",
                "future_readiness_score": "41.50799206",
                "future_readiness_ranking": data.ecuador.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "18.001",
                "gdp_per_capita": "12822.11378",
                "gdp": "115.049476",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "egypt": {
            "radar_data": [
                {
                    "label": "Egypt",
                    "data": [
                        data.egypt.future_readiness_score,
                        data.egypt.physical_capital,
                        data.egypt.human_capital,
                        data.egypt.technology,
                        data.egypt.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Egypt",
            "code": "eg",
            "stats": {
                "country": "Egypt",
                "future_readiness_rank": "76",
                "future_readiness_score": "39.04697261",
                "future_readiness_ranking": data.egypt.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Middle East and North Africa",
                "population": "110.990103",
                "gdp_per_capita": "15090.99088",
                "gdp": "476.7477204",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "el_salvador": {
            "radar_data": [
                {
                    "label": "El Salvador",
                    "data": [
                        data.el_salvador.future_readiness_score,
                        data.el_salvador.physical_capital,
                        data.el_salvador.human_capital,
                        data.el_salvador.technology,
                        data.el_salvador.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "El Salvador",
            "code": "sv",
            "stats": {
                "country": "El Salvador",
                "future_readiness_rank": "94",
                "future_readiness_score": "31.8867127",
                "future_readiness_ranking": data.el_salvador.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "6.336392",
                "gdp_per_capita": "11096.2132",
                "gdp": "32.48872",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "estonia": {
            "radar_data": [
                {
                    "label": "Estonia",
                    "data": [
                        data.estonia.future_readiness_score,
                        data.estonia.physical_capital,
                        data.estonia.human_capital,
                        data.estonia.technology,
                        data.estonia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Estonia",
            "code": "ee",
            "stats": {
                "country": "Estonia",
                "future_readiness_rank": "19",
                "future_readiness_score": "63.48703828",
                "future_readiness_ranking": data.estonia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "1.344768",
                "gdp_per_capita": "46697.35974",
                "gdp": "38.10081296",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ethiopia": {
            "radar_data": [
                {
                    "label": "Ethiopia",
                    "data": [
                        data.ethiopia.future_readiness_score,
                        data.ethiopia.physical_capital,
                        data.ethiopia.human_capital,
                        data.ethiopia.technology,
                        data.ethiopia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ethiopia",
            "code": "et",
            "stats": {
                "country": "Ethiopia",
                "future_readiness_rank": "123",
                "future_readiness_score": "16.54399603",
                "future_readiness_ranking": data.ethiopia.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "123.379924",
                "gdp_per_capita": "2811.581744",
                "gdp": "126.7834725",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "finland": {
            "radar_data": [
                {
                    "label": "Finland",
                    "data": [
                        data.finland.future_readiness_score,
                        data.finland.physical_capital,
                        data.finland.human_capital,
                        data.finland.technology,
                        data.finland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Finland",
            "code": "fi",
            "stats": {
                "country": "Finland",
                "future_readiness_rank": "6",
                "future_readiness_score": "72.33328726",
                "future_readiness_ranking": data.finland.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.55688",
                "gdp_per_capita": "59026.70734",
                "gdp": "280.8259578",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "france": {
            "radar_data": [
                {
                    "label": "France",
                    "data": [
                        data.france.future_readiness_score,
                        data.france.physical_capital,
                        data.france.human_capital,
                        data.france.technology,
                        data.france.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "France",
            "code": "fr",
            "stats": {
                "country": "France",
                "future_readiness_rank": "21",
                "future_readiness_score": "62.17789985",
                "future_readiness_ranking": data.france.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "67.93566",
                "gdp_per_capita": "55492.56555",
                "gdp": "2782.905326",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "georgia": {
            "radar_data": [
                {
                    "label": "Georgia",
                    "data": [
                        data.georgia.future_readiness_score,
                        data.georgia.physical_capital,
                        data.georgia.human_capital,
                        data.georgia.technology,
                        data.georgia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Georgia",
            "code": "ge",
            "stats": {
                "country": "Georgia",
                "future_readiness_rank": "56",
                "future_readiness_score": "44.80956756",
                "future_readiness_ranking": data.georgia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Middle East and North Africa",
                "population": "3.712502",
                "gdp_per_capita": "20113.37724",
                "gdp": "24.60537542",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "germany": {
            "radar_data": [
                {
                    "label": "Germany",
                    "data": [
                        data.germany.future_readiness_score,
                        data.germany.physical_capital,
                        data.germany.human_capital,
                        data.germany.technology,
                        data.germany.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Germany",
            "code": "de",
            "stats": {
                "country": "Germany",
                "future_readiness_rank": "16",
                "future_readiness_score": "65.51868153",
                "future_readiness_ranking": data.germany.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "84.079811",
                "gdp_per_capita": "63149.59869",
                "gdp": "4072.191736",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ghana": {
            "radar_data": [
                {
                    "label": "Ghana",
                    "data": [
                        data.ghana.future_readiness_score,
                        data.ghana.physical_capital,
                        data.ghana.human_capital,
                        data.ghana.technology,
                        data.ghana.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ghana",
            "code": "gh",
            "stats": {
                "country": "Ghana",
                "future_readiness_rank": "99",
                "future_readiness_score": "28.14660553",
                "future_readiness_ranking": data.ghana.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "33.47587",
                "gdp_per_capita": "6498.385727",
                "gdp": "72.83879879",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "greece": {
            "radar_data": [
                {
                    "label": "Greece",
                    "data": [
                        data.greece.future_readiness_score,
                        data.greece.physical_capital,
                        data.greece.human_capital,
                        data.greece.technology,
                        data.greece.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Greece",
            "code": "gr",
            "stats": {
                "country": "Greece",
                "future_readiness_rank": "33",
                "future_readiness_score": "55.11490098",
                "future_readiness_ranking": data.greece.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.566531",
                "gdp_per_capita": "36834.87109",
                "gdp": "219.0658725",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "guatemala": {
            "radar_data": [
                {
                    "label": "Guatemala",
                    "data": [
                        data.guatemala.future_readiness_score,
                        data.guatemala.physical_capital,
                        data.guatemala.human_capital,
                        data.guatemala.technology,
                        data.guatemala.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Guatemala",
            "code": "gl",
            "stats": {
                "country": "Guatemala",
                "future_readiness_rank": "98",
                "future_readiness_score": "28.33567637",
                "future_readiness_ranking": data.guatemala.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "17.357886",
                "gdp_per_capita": "10818.17172",
                "gdp": "95.00333338",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "guinea": {
            "radar_data": [
                {
                    "label": "Guinea",
                    "data": [
                        data.guinea.future_readiness_score,
                        data.guinea.physical_capital,
                        data.guinea.human_capital,
                        data.guinea.technology,
                        data.guinea.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Guinea",
            "code": "gn",
            "stats": {
                "country": "Guinea",
                "future_readiness_rank": "120",
                "future_readiness_score": "18.30530002",
                "future_readiness_ranking": data.guinea.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "13.859341",
                "gdp_per_capita": "3187.019343",
                "gdp": "21.22774939",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "honduras": {
            "radar_data": [
                {
                    "label": "Honduras",
                    "data": [
                        data.honduras.future_readiness_score,
                        data.honduras.physical_capital,
                        data.honduras.human_capital,
                        data.honduras.technology,
                        data.honduras.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Honduras",
            "code": "hn",
            "stats": {
                "country": "Honduras",
                "future_readiness_rank": "95",
                "future_readiness_score": "30.89303927",
                "future_readiness_ranking": data.honduras.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "10.43286",
                "gdp_per_capita": "6741.096947",
                "gdp": "31.71770012",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "hungary": {
            "radar_data": [
                {
                    "label": "Hungary",
                    "data": [
                        data.hungary.future_readiness_score,
                        data.hungary.physical_capital,
                        data.hungary.human_capital,
                        data.hungary.technology,
                        data.hungary.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Hungary",
            "code": "hu",
            "stats": {
                "country": "Hungary",
                "future_readiness_rank": "39",
                "future_readiness_score": "51.38500446",
                "future_readiness_ranking": data.hungary.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "9.683505",
                "gdp_per_capita": "41906.65557",
                "gdp": "178.7885721",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "iceland": {
            "radar_data": [
                {
                    "label": "Iceland",
                    "data": [
                        data.iceland.future_readiness_score,
                        data.iceland.physical_capital,
                        data.iceland.human_capital,
                        data.iceland.technology,
                        data.iceland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Iceland",
            "code": "is",
            "stats": {
                "country": "Iceland",
                "future_readiness_rank": "13",
                "future_readiness_score": "66.57444527",
                "future_readiness_ranking": data.iceland.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "0.3819",
                "gdp_per_capita": "69081.26167",
                "gdp": "27.84164804",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "india": {
            "radar_data": [
                {
                    "label": "India",
                    "data": [
                        data.india.future_readiness_score,
                        data.india.physical_capital,
                        data.india.human_capital,
                        data.india.technology,
                        data.india.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "India",
            "code": "in",
            "stats": {
                "country": "India",
                "future_readiness_rank": "88",
                "future_readiness_score": "36.13956381",
                "future_readiness_ranking": data.india.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "1417.173173",
                "gdp_per_capita": "8379.062487",
                "gdp": "3385.089882",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "indonesia": {
            "radar_data": [
                {
                    "label": "Indonesia",
                    "data": [
                        data.indonesia.future_readiness_score,
                        data.indonesia.physical_capital,
                        data.indonesia.human_capital,
                        data.indonesia.technology,
                        data.indonesia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Indonesia",
            "code": "id",
            "stats": {
                "country": "Indonesia",
                "future_readiness_rank": "73",
                "future_readiness_score": "39.59625007",
                "future_readiness_ranking": data.indonesia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Asia Pacific",
                "population": "275.501339",
                "gdp_per_capita": "14652.92828",
                "gdp": "1319.10022",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "iran": {
            "radar_data": [
                {
                    "label": "Iran",
                    "data": [
                        data.iran.future_readiness_score,
                        data.iran.physical_capital,
                        data.iran.human_capital,
                        data.iran.technology,
                        data.iran.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Iran",
            "code": "ir",
            "stats": {
                "country": "Iran, Islamic Rep.",
                "future_readiness_rank": "87",
                "future_readiness_score": "36.18835418",
                "future_readiness_ranking": data.iran.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "88.55057",
                "gdp_per_capita": "18075.05497",
                "gdp": "388.5444681",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ireland": {
            "radar_data": [
                {
                    "label": "Ireland",
                    "data": [
                        data.ireland.future_readiness_score,
                        data.ireland.physical_capital,
                        data.ireland.human_capital,
                        data.ireland.technology,
                        data.ireland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ireland",
            "code": "ie",
            "stats": {
                "country": "Ireland",
                "future_readiness_rank": "15",
                "future_readiness_score": "66.17209823",
                "future_readiness_ranking": data.ireland.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.086988",
                "gdp_per_capita": "126905.1985",
                "gdp": "529.2448702",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "israel": {
            "radar_data": [
                {
                    "label": "Israel",
                    "data": [
                        data.israel.future_readiness_score,
                        data.israel.physical_capital,
                        data.israel.human_capital,
                        data.israel.technology,
                        data.israel.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Israel",
            "code": "il",
            "stats": {
                "country": "Israel",
                "future_readiness_rank": "24",
                "future_readiness_score": "61.263944",
                "future_readiness_ranking": data.israel.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "9.5506",
                "gdp_per_capita": "49509.12899",
                "gdp": "522.0334462",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "italy": {
            "radar_data": [
                {
                    "label": "Italy",
                    "data": [
                        data.italy.future_readiness_score,
                        data.italy.physical_capital,
                        data.italy.human_capital,
                        data.italy.technology,
                        data.italy.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Italy",
            "code": "it",
            "stats": {
                "country": "Italy",
                "future_readiness_rank": "29",
                "future_readiness_score": "56.46625928",
                "future_readiness_ranking": data.italy.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "58.856847",
                "gdp_per_capita": "51864.97774",
                "gdp": "2010.431598",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "jamaica": {
            "radar_data": [
                {
                    "label": "Jamaica",
                    "data": [
                        data.jamaica.future_readiness_score,
                        data.jamaica.physical_capital,
                        data.jamaica.human_capital,
                        data.jamaica.technology,
                        data.jamaica.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Jamaica",
            "code": "jm",
            "stats": {
                "country": "Jamaica",
                "future_readiness_rank": "83",
                "future_readiness_score": "38.05405494",
                "future_readiness_ranking": data.jamaica.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "2.827377",
                "gdp_per_capita": "11821.58495",
                "gdp": "17.09776075",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "japan": {
            "radar_data": [
                {
                    "label": "Japan",
                    "data": [
                        data.japan.future_readiness_score,
                        data.japan.physical_capital,
                        data.japan.human_capital,
                        data.japan.technology,
                        data.japan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Japan",
            "code": "jp",
            "stats": {
                "country": "Japan",
                "future_readiness_rank": "22",
                "future_readiness_score": "61.56169885",
                "future_readiness_ranking": data.japan.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Asia Pacific",
                "population": "125.124989",
                "gdp_per_capita": "45572.72382",
                "gdp": "4231.141202",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "jordan": {
            "radar_data": [
                {
                    "label": "Jordan",
                    "data": [
                        data.jordan.future_readiness_score,
                        data.jordan.physical_capital,
                        data.jordan.human_capital,
                        data.jordan.technology,
                        data.jordan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Jordan",
            "code": "jo",
            "stats": {
                "country": "Jordan",
                "future_readiness_rank": "70",
                "future_readiness_score": "40.89653934",
                "future_readiness_ranking": data.jordan.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Middle East and North Africa",
                "population": "11.285869",
                "gdp_per_capita": "11003.12452",
                "gdp": "47.45149986",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kazakhstan": {
            "radar_data": [
                {
                    "label": "Kazakhstan",
                    "data": [
                        data.kazakhstan.future_readiness_score,
                        data.kazakhstan.physical_capital,
                        data.kazakhstan.human_capital,
                        data.kazakhstan.technology,
                        data.kazakhstan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kazakhstan",
            "code": "kz",
            "stats": {
                "country": "Kazakhstan",
                "future_readiness_rank": "66",
                "future_readiness_score": "42.05672299",
                "future_readiness_ranking": data.kazakhstan.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Asia Pacific",
                "population": "19.621972",
                "gdp_per_capita": "30809.87943",
                "gdp": "220.623002",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kenya": {
            "radar_data": [
                {
                    "label": "Kenya",
                    "data": [
                        data.kenya.future_readiness_score,
                        data.kenya.physical_capital,
                        data.kenya.human_capital,
                        data.kenya.technology,
                        data.kenya.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kenya",
            "code": "ke",
            "stats": {
                "country": "Kenya",
                "future_readiness_rank": "96",
                "future_readiness_score": "29.66718437",
                "future_readiness_ranking": data.kenya.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "54.027487",
                "gdp_per_capita": "5763.910092",
                "gdp": "113.4200082",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kuwait": {
            "radar_data": [
                {
                    "label": "Kuwait",
                    "data": [
                        data.kuwait.future_readiness_score,
                        data.kuwait.physical_capital,
                        data.kuwait.human_capital,
                        data.kuwait.technology,
                        data.kuwait.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kuwait",
            "code": "kw",
            "stats": {
                "country": "Kuwait",
                "future_readiness_rank": "58",
                "future_readiness_score": "44.39098709",
                "future_readiness_ranking": data.kuwait.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "4.268873",
                "gdp_per_capita": "58056.23269",
                "gdp": "184.5582743",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "kyrgyzstan": {
            "radar_data": [
                {
                    "label": "Kyrgyzstan",
                    "data": [
                        data.kyrgyzstan.future_readiness_score,
                        data.kyrgyzstan.physical_capital,
                        data.kyrgyzstan.human_capital,
                        data.kyrgyzstan.technology,
                        data.kyrgyzstan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Kyrgyzstan",
            "code": "kg",
            "stats": {
                "country": "Kyrgyzstan",
                "future_readiness_rank": "81",
                "future_readiness_score": "38.22718337",
                "future_readiness_ranking": data.kyrgyzstan.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "6.8033",
                "gdp_per_capita": "6132.514721",
                "gdp": "10.93064492",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "laos": {
            "radar_data": [
                {
                    "label": "Laos",
                    "data": [
                        data.laos.future_readiness_score,
                        data.laos.physical_capital,
                        data.laos.human_capital,
                        data.laos.technology,
                        data.laos.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Laos",
            "code": "la",
            "stats": {
                "country": "Lao PDR",
                "future_readiness_rank": "103",
                "future_readiness_score": "26.68215645",
                "future_readiness_ranking": data.laos.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "7.529475",
                "gdp_per_capita": "9384.265695",
                "gdp": "15.72438378",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "latvia": {
            "radar_data": [
                {
                    "label": "Latvia",
                    "data": [
                        data.latvia.future_readiness_score,
                        data.latvia.physical_capital,
                        data.latvia.human_capital,
                        data.latvia.technology,
                        data.latvia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Latvia",
            "code": "lv",
            "stats": {
                "country": "Latvia",
                "future_readiness_rank": "36",
                "future_readiness_score": "52.81375341",
                "future_readiness_ranking": data.latvia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "1.883379",
                "gdp_per_capita": "39956.19048",
                "gdp": "41.15391266",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "lebanon": {
            "radar_data": [
                {
                    "label": "Lebanon",
                    "data": [
                        data.lebanon.future_readiness_score,
                        data.lebanon.physical_capital,
                        data.lebanon.human_capital,
                        data.lebanon.technology,
                        data.lebanon.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Lebanon",
            "code": "lb",
            "stats": {
                "country": "Lebanon",
                "future_readiness_rank": "77",
                "future_readiness_score": "38.83108375",
                "future_readiness_ranking": data.lebanon.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Middle East and North Africa",
                "population": "5.489739",
                "gdp_per_capita": "14330.51563",
                "gdp": "23.13194156",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "lithuania": {
            "radar_data": [
                {
                    "label": "Lithuania",
                    "data": [
                        data.lithuania.future_readiness_score,
                        data.lithuania.physical_capital,
                        data.lithuania.human_capital,
                        data.lithuania.technology,
                        data.lithuania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Lithuania",
            "code": "lt",
            "stats": {
                "country": "Lithuania",
                "future_readiness_rank": "32",
                "future_readiness_score": "55.40659702",
                "future_readiness_ranking": data.lithuania.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "2.833",
                "gdp_per_capita": "48396.69354",
                "gdp": "70.33429901",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "luxembourg": {
            "radar_data": [
                {
                    "label": "Luxembourg",
                    "data": [
                        data.luxembourg.future_readiness_score,
                        data.luxembourg.physical_capital,
                        data.luxembourg.human_capital,
                        data.luxembourg.technology,
                        data.luxembourg.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Luxembourg",
            "code": "lu",
            "stats": {
                "country": "Luxembourg",
                "future_readiness_rank": "12",
                "future_readiness_score": "67.00899774",
                "future_readiness_ranking": data.luxembourg.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "0.650774",
                "gdp_per_capita": "142213.8517",
                "gdp": "82.27481225",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "madagascar": {
            "radar_data": [
                {
                    "label": "Madagascar",
                    "data": [
                        data.madagascar.future_readiness_score,
                        data.madagascar.physical_capital,
                        data.madagascar.human_capital,
                        data.madagascar.technology,
                        data.madagascar.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Madagascar",
            "code": "mg",
            "stats": {
                "country": "Madagascar",
                "future_readiness_rank": "122",
                "future_readiness_score": "16.81650821",
                "future_readiness_ranking": data.madagascar.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "29.611714",
                "gdp_per_capita": "1774.068709",
                "gdp": "14.9549676",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "malawi": {
            "radar_data": [
                {
                    "label": "Malawi",
                    "data": [
                        data.malawi.future_readiness_score,
                        data.malawi.physical_capital,
                        data.malawi.human_capital,
                        data.malawi.technology,
                        data.malawi.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Malawi",
            "code": "mw",
            "stats": {
                "country": "Malawi",
                "future_readiness_rank": "119",
                "future_readiness_score": "19.01629341",
                "future_readiness_ranking": data.malawi.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "20.405317",
                "gdp_per_capita": "1732.030343",
                "gdp": "13.16466763",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "malaysia": {
            "radar_data": [
                {
                    "label": "Malaysia",
                    "data": [
                        data.malaysia.future_readiness_score,
                        data.malaysia.physical_capital,
                        data.malaysia.human_capital,
                        data.malaysia.technology,
                        data.malaysia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Malaysia",
            "code": "my",
            "stats": {
                "country": "Malaysia",
                "future_readiness_rank": "40",
                "future_readiness_score": "51.2857322",
                "future_readiness_ranking": data.malaysia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Asia Pacific",
                "population": "33.938221",
                "gdp_per_capita": "33433.6174",
                "gdp": "406.3059247",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mali": {
            "radar_data": [
                {
                    "label": "Mali",
                    "data": [
                        data.mali.future_readiness_score,
                        data.mali.physical_capital,
                        data.mali.human_capital,
                        data.mali.technology,
                        data.mali.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mali",
            "code": "ml",
            "stats": {
                "country": "Mali",
                "future_readiness_rank": "117",
                "future_readiness_score": "20.40324639",
                "future_readiness_ranking": data.mali.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "22.59359",
                "gdp_per_capita": "2517.147263",
                "gdp": "18.82717653",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "malta": {
            "radar_data": [
                {
                    "label": "Malta",
                    "data": [
                        data.malta.future_readiness_score,
                        data.malta.physical_capital,
                        data.malta.human_capital,
                        data.malta.technology,
                        data.malta.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Malta",
            "code": "mt",
            "stats": {
                "country": "Malta",
                "future_readiness_rank": "23",
                "future_readiness_score": "61.32228464",
                "future_readiness_ranking": data.malta.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "0.523417",
                "gdp_per_capita": "55927.85938",
                "gdp": "17.76527001",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mauritius": {
            "radar_data": [
                {
                    "label": "Mauritius",
                    "data": [
                        data.mauritius.future_readiness_score,
                        data.mauritius.physical_capital,
                        data.mauritius.human_capital,
                        data.mauritius.technology,
                        data.mauritius.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mauritius",
            "code": "mu",
            "stats": {
                "country": "Mauritius",
                "future_readiness_rank": "59",
                "future_readiness_score": "44.11854145",
                "future_readiness_ranking": data.mauritius.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "1.262523",
                "gdp_per_capita": "26905.91862",
                "gdp": "12.89830709",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mexico": {
            "radar_data": [
                {
                    "label": "Mexico",
                    "data": [
                        data.mexico.future_readiness_score,
                        data.mexico.physical_capital,
                        data.mexico.human_capital,
                        data.mexico.technology,
                        data.mexico.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mexico",
            "code": "mx",
            "stats": {
                "country": "Mexico",
                "future_readiness_rank": "68",
                "future_readiness_score": "41.53708396",
                "future_readiness_ranking": data.mexico.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "127.504125",
                "gdp_per_capita": "21512.26954",
                "gdp": "1414.187194",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "moldova": {
            "radar_data": [
                {
                    "label": "Moldova",
                    "data": [
                        data.moldova.future_readiness_score,
                        data.moldova.physical_capital,
                        data.moldova.human_capital,
                        data.moldova.technology,
                        data.moldova.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Moldova",
            "code": "md",
            "stats": {
                "country": "Moldova, Rep.",
                "future_readiness_rank": "71",
                "future_readiness_score": "40.67617393",
                "future_readiness_ranking": data.moldova.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "2.592477",
                "gdp_per_capita": "15238.14821",
                "gdp": "14.42094788",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mongolia": {
            "radar_data": [
                {
                    "label": "Mongolia",
                    "data": [
                        data.mongolia.future_readiness_score,
                        data.mongolia.physical_capital,
                        data.mongolia.human_capital,
                        data.mongolia.technology,
                        data.mongolia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mongolia",
            "code": "mn",
            "stats": {
                "country": "Mongolia",
                "future_readiness_rank": "75",
                "future_readiness_score": "39.23416177",
                "future_readiness_ranking": data.mongolia.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "3.398366",
                "gdp_per_capita": "14230.23355",
                "gdp": "16.81088336",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "morocco": {
            "radar_data": [
                {
                    "label": "Morocco",
                    "data": [
                        data.morocco.future_readiness_score,
                        data.morocco.physical_capital,
                        data.morocco.human_capital,
                        data.morocco.technology,
                        data.morocco.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Morocco",
            "code": "ma",
            "stats": {
                "country": "Morocco",
                "future_readiness_rank": "92",
                "future_readiness_score": "33.94339834",
                "future_readiness_ranking": data.morocco.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Middle East and North Africa",
                "population": "37.457971",
                "gdp_per_capita": "9518.706055",
                "gdp": "134.1815878",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "mozambique": {
            "radar_data": [
                {
                    "label": "Mozambique",
                    "data": [
                        data.mozambique.future_readiness_score,
                        data.mozambique.physical_capital,
                        data.mozambique.human_capital,
                        data.mozambique.technology,
                        data.mozambique.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Mozambique",
            "code": "mz",
            "stats": {
                "country": "Mozambique",
                "future_readiness_rank": "121",
                "future_readiness_score": "17.4600518",
                "future_readiness_ranking": data.mozambique.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "32.969518",
                "gdp_per_capita": "1467.769766",
                "gdp": "17.85149143",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "myanmar": {
            "radar_data": [
                {
                    "label": "Myanmar",
                    "data": [
                        data.myanmar.future_readiness_score,
                        data.myanmar.physical_capital,
                        data.myanmar.human_capital,
                        data.myanmar.technology,
                        data.myanmar.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Myanmar",
            "code": "mm",
            "stats": {
                "country": "Myanmar",
                "future_readiness_rank": "110",
                "future_readiness_score": "23.33394565",
                "future_readiness_ranking": data.myanmar.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "54.179306",
                "gdp_per_capita": "4870.021499",
                "gdp": "59.36436254",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "nepal": {
            "radar_data": [
                {
                    "label": "Nepal",
                    "data": [
                        data.nepal.future_readiness_score,
                        data.nepal.physical_capital,
                        data.nepal.human_capital,
                        data.nepal.technology,
                        data.nepal.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Nepal",
            "code": "np",
            "stats": {
                "country": "Nepal",
                "future_readiness_rank": "105",
                "future_readiness_score": "26.49614044",
                "future_readiness_ranking": data.nepal.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "30.54758",
                "gdp_per_capita": "4725.041244",
                "gdp": "40.8282473",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "netherlands": {
            "radar_data": [
                {
                    "label": "Netherlands",
                    "data": [
                        data.netherlands.future_readiness_score,
                        data.netherlands.physical_capital,
                        data.netherlands.human_capital,
                        data.netherlands.technology,
                        data.netherlands.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Netherlands",
            "code": "nl",
            "stats": {
                "country": "Netherlands",
                "future_readiness_rank": "8",
                "future_readiness_score": "70.58475244",
                "future_readiness_ranking": data.netherlands.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "17.70309",
                "gdp_per_capita": "69577.40458",
                "gdp": "991.1146355",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "new_zealand": {
            "radar_data": [
                {
                    "label": "New Zealand",
                    "data": [
                        data.new_zealand.future_readiness_score,
                        data.new_zealand.physical_capital,
                        data.new_zealand.human_capital,
                        data.new_zealand.technology,
                        data.new_zealand.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "New Zealand",
            "code": "nz",
            "stats": {
                "country": "New Zealand",
                "future_readiness_rank": "20",
                "future_readiness_score": "63.05468496",
                "future_readiness_ranking": data.new_zealand.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Asia Pacific",
                "population": "5.1241",
                "gdp_per_capita": "51966.86258",
                "gdp": "247.2340537",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "niger": {
            "radar_data": [
                {
                    "label": "Niger",
                    "data": [
                        data.niger.future_readiness_score,
                        data.niger.physical_capital,
                        data.niger.human_capital,
                        data.niger.technology,
                        data.niger.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Niger",
            "code": "ne",
            "stats": {
                "country": "Niger",
                "future_readiness_rank": "124",
                "future_readiness_score": "11.49438012",
                "future_readiness_ranking": data.niger.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "26.207977",
                "gdp_per_capita": "1505.242676",
                "gdp": "13.96960558",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "nigeria": {
            "radar_data": [
                {
                    "label": "Nigeria",
                    "data": [
                        data.nigeria.future_readiness_score,
                        data.nigeria.physical_capital,
                        data.nigeria.human_capital,
                        data.nigeria.technology,
                        data.nigeria.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Nigeria",
            "code": "ng",
            "stats": {
                "country": "Nigeria",
                "future_readiness_rank": "107",
                "future_readiness_score": "25.20267623",
                "future_readiness_ranking": data.nigeria.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "218.541212",
                "gdp_per_capita": "5860.293736",
                "gdp": "477.3861206",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "north_macedonia": {
            "radar_data": [
                {
                    "label": "North Macedonia",
                    "data": [
                        data.north_macedonia.future_readiness_score,
                        data.north_macedonia.physical_capital,
                        data.north_macedonia.human_capital,
                        data.north_macedonia.technology,
                        data.north_macedonia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "North Macedonia",
            "code": "mk",
            "stats": {
                "country": "North Macedonia",
                "future_readiness_rank": "64",
                "future_readiness_score": "42.10957447",
                "future_readiness_ranking": data.north_macedonia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "2.057679",
                "gdp_per_capita": "20161.75091",
                "gdp": "13.56313206",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "norway": {
            "radar_data": [
                {
                    "label": "Norway",
                    "data": [
                        data.norway.future_readiness_score,
                        data.norway.physical_capital,
                        data.norway.human_capital,
                        data.norway.technology,
                        data.norway.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Norway",
            "code": "no",
            "stats": {
                "country": "Norway",
                "future_readiness_rank": "7",
                "future_readiness_score": "71.55313139",
                "future_readiness_ranking": data.norway.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.457127",
                "gdp_per_capita": "114898.7599",
                "gdp": "579.2673659",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "oman": {
            "radar_data": [
                {
                    "label": "Oman",
                    "data": [
                        data.oman.future_readiness_score,
                        data.oman.physical_capital,
                        data.oman.human_capital,
                        data.oman.technology,
                        data.oman.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Oman",
            "code": "om",
            "stats": {
                "country": "Oman",
                "future_readiness_rank": "63",
                "future_readiness_score": "42.61107571",
                "future_readiness_ranking": data.oman.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "4.576298",
                "gdp_per_capita": "41724.3388",
                "gdp": "114.6673602",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "pakistan": {
            "radar_data": [
                {
                    "label": "Pakistan",
                    "data": [
                        data.pakistan.future_readiness_score,
                        data.pakistan.physical_capital,
                        data.pakistan.human_capital,
                        data.pakistan.technology,
                        data.pakistan.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Pakistan",
            "code": "pk",
            "stats": {
                "country": "Pakistan",
                "future_readiness_rank": "104",
                "future_readiness_score": "26.54077855",
                "future_readiness_ranking": data.pakistan.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "235.824862",
                "gdp_per_capita": "6437.162663",
                "gdp": "376.5327518",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "panama": {
            "radar_data": [
                {
                    "label": "Panama",
                    "data": [
                        data.panama.future_readiness_score,
                        data.panama.physical_capital,
                        data.panama.human_capital,
                        data.panama.technology,
                        data.panama.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Panama",
            "code": "pa",
            "stats": {
                "country": "Panama",
                "future_readiness_rank": "78",
                "future_readiness_score": "38.43295097",
                "future_readiness_ranking": data.panama.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "4.408581",
                "gdp_per_capita": "39279.67538",
                "gdp": "76.52251178",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "paraguay": {
            "radar_data": [
                {
                    "label": "Paraguay",
                    "data": [
                        data.paraguay.future_readiness_score,
                        data.paraguay.physical_capital,
                        data.paraguay.human_capital,
                        data.paraguay.technology,
                        data.paraguay.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Paraguay",
            "code": "py",
            "stats": {
                "country": "Paraguay",
                "future_readiness_rank": "85",
                "future_readiness_score": "37.55024458",
                "future_readiness_ranking": data.paraguay.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "6.780744",
                "gdp_per_capita": "15977.31165",
                "gdp": "41.72229536",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "peru": {
            "radar_data": [
                {
                    "label": "Peru",
                    "data": [
                        data.peru.future_readiness_score,
                        data.peru.physical_capital,
                        data.peru.human_capital,
                        data.peru.technology,
                        data.peru.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Peru",
            "code": "pe",
            "stats": {
                "country": "Peru",
                "future_readiness_rank": "79",
                "future_readiness_score": "38.36618656",
                "future_readiness_ranking": data.peru.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Latin America and the Caribbean",
                "population": "34.049588",
                "gdp_per_capita": "15047.51833",
                "gdp": "242.6315496",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "philippines": {
            "radar_data": [
                {
                    "label": "Philippines",
                    "data": [
                        data.philippines.future_readiness_score,
                        data.philippines.physical_capital,
                        data.philippines.human_capital,
                        data.philippines.technology,
                        data.philippines.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Philippines",
            "code": "ph",
            "stats": {
                "country": "Philippines",
                "future_readiness_rank": "82",
                "future_readiness_score": "38.15020186",
                "future_readiness_ranking": data.philippines.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "115.559009",
                "gdp_per_capita": "10133.19589",
                "gdp": "404.2843273",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "poland": {
            "radar_data": [
                {
                    "label": "Poland",
                    "data": [
                        data.poland.future_readiness_score,
                        data.poland.physical_capital,
                        data.poland.human_capital,
                        data.poland.technology,
                        data.poland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Poland",
            "code": "pl",
            "stats": {
                "country": "Poland",
                "future_readiness_rank": "37",
                "future_readiness_score": "52.73535859",
                "future_readiness_ranking": data.poland.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "37.561599",
                "gdp_per_capita": "43268.54371",
                "gdp": "688.176606",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "portugal": {
            "radar_data": [
                {
                    "label": "Portugal",
                    "data": [
                        data.portugal.future_readiness_score,
                        data.portugal.physical_capital,
                        data.portugal.human_capital,
                        data.portugal.technology,
                        data.portugal.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Portugal",
            "code": "pt",
            "stats": {
                "country": "Portugal",
                "future_readiness_rank": "26",
                "future_readiness_score": "59.09991264",
                "future_readiness_ranking": data.portugal.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.379007",
                "gdp_per_capita": "41451.61487",
                "gdp": "251.9453775",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "qatar": {
            "radar_data": [
                {
                    "label": "Qatar",
                    "data": [
                        data.qatar.future_readiness_score,
                        data.qatar.physical_capital,
                        data.qatar.human_capital,
                        data.qatar.technology,
                        data.qatar.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Qatar",
            "code": "qa",
            "stats": {
                "country": "Qatar",
                "future_readiness_rank": "34",
                "future_readiness_score": "54.58249324",
                "future_readiness_ranking": data.qatar.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "2.695122",
                "gdp_per_capita": "114648.0316",
                "gdp": "237.2955752",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "romania": {
            "radar_data": [
                {
                    "label": "Romania",
                    "data": [
                        data.romania.future_readiness_score,
                        data.romania.physical_capital,
                        data.romania.human_capital,
                        data.romania.technology,
                        data.romania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Romania",
            "code": "ro",
            "stats": {
                "country": "Romania",
                "future_readiness_rank": "50",
                "future_readiness_score": "46.48740761",
                "future_readiness_ranking": data.romania.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "18.956666",
                "gdp_per_capita": "41887.9219",
                "gdp": "301.2615829",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "russia": {
            "radar_data": [
                {
                    "label": "Russia",
                    "data": [
                        data.russia.future_readiness_score,
                        data.russia.physical_capital,
                        data.russia.human_capital,
                        data.russia.technology,
                        data.russia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Russia",
            "code": "ru",
            "stats": {
                "country": "Russian Federation",
                "future_readiness_rank": "48",
                "future_readiness_score": "47.96134535",
                "future_readiness_ranking": data.russia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "143.555736",
                "gdp_per_capita": "36484.67969",
                "gdp": "2240.422438",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "rwanda": {
            "radar_data": [
                {
                    "label": "Rwanda",
                    "data": [
                        data.rwanda.future_readiness_score,
                        data.rwanda.physical_capital,
                        data.rwanda.human_capital,
                        data.rwanda.technology,
                        data.rwanda.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Rwanda",
            "code": "rw",
            "stats": {
                "country": "Rwanda",
                "future_readiness_rank": "100",
                "future_readiness_score": "27.82613649",
                "future_readiness_ranking": data.rwanda.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "13.776698",
                "gdp_per_capita": "2792.421625",
                "gdp": "13.31279677",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "saudi_arabia": {
            "radar_data": [
                {
                    "label": "Saudi Arabia",
                    "data": [
                        data.saudi_arabia.future_readiness_score,
                        data.saudi_arabia.physical_capital,
                        data.saudi_arabia.human_capital,
                        data.saudi_arabia.technology,
                        data.saudi_arabia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Saudi Arabia",
            "code": "sa",
            "stats": {
                "country": "Saudi Arabia",
                "future_readiness_rank": "42",
                "future_readiness_score": "50.43566732",
                "future_readiness_ranking": data.saudi_arabia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "36.40882",
                "gdp_per_capita": "59065.00464",
                "gdp": "1108.148978",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "senegal": {
            "radar_data": [
                {
                    "label": "Senegal",
                    "data": [
                        data.senegal.future_readiness_score,
                        data.senegal.physical_capital,
                        data.senegal.human_capital,
                        data.senegal.technology,
                        data.senegal.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Senegal",
            "code": "sn",
            "stats": {
                "country": "Senegal",
                "future_readiness_rank": "102",
                "future_readiness_score": "27.52632357",
                "future_readiness_ranking": data.senegal.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "17.316449",
                "gdp_per_capita": "4208.964979",
                "gdp": "27.68443024",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "serbia": {
            "radar_data": [
                {
                    "label": "Serbia",
                    "data": [
                        data.serbia.future_readiness_score,
                        data.serbia.physical_capital,
                        data.serbia.human_capital,
                        data.serbia.technology,
                        data.serbia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Serbia",
            "code": "rs",
            "stats": {
                "country": "Serbia",
                "future_readiness_rank": "44",
                "future_readiness_score": "49.58800864",
                "future_readiness_ranking": data.serbia.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Europe",
                "population": "6.760087",
                "gdp_per_capita": "23911.20122",
                "gdp": "63.50174865",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "singapore": {
            "radar_data": [
                {
                    "label": "Singapore",
                    "data": [
                        data.singapore.future_readiness_score,
                        data.singapore.physical_capital,
                        data.singapore.human_capital,
                        data.singapore.technology,
                        data.singapore.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Singapore",
            "code": "sg",
            "stats": {
                "country": "Singapore",
                "future_readiness_rank": "1",
                "future_readiness_score": "78.0133809",
                "future_readiness_ranking": data.singapore.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Asia Pacific",
                "population": "5.637022",
                "gdp_per_capita": "127564.5566",
                "gdp": "466.7885397",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "slovakia": {
            "radar_data": [
                {
                    "label": "Slovakia",
                    "data": [
                        data.slovakia.future_readiness_score,
                        data.slovakia.physical_capital,
                        data.slovakia.human_capital,
                        data.slovakia.technology,
                        data.slovakia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Slovakia",
            "code": "sk",
            "stats": {
                "country": "Slovakia",
                "future_readiness_rank": "41",
                "future_readiness_score": "50.90920834",
                "future_readiness_ranking": data.slovakia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "5.431752",
                "gdp_per_capita": "37459.47384",
                "gdp": "115.468804",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "slovenia": {
            "radar_data": [
                {
                    "label": "Slovenia",
                    "data": [
                        data.slovenia.future_readiness_score,
                        data.slovenia.physical_capital,
                        data.slovenia.human_capital,
                        data.slovenia.technology,
                        data.slovenia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Slovenia",
            "code": "si",
            "stats": {
                "country": "Slovenia",
                "future_readiness_rank": "30",
                "future_readiness_score": "56.21840175",
                "future_readiness_ranking": data.slovenia.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "2.108732",
                "gdp_per_capita": "50031.65618",
                "gdp": "62.11776801",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "south_africa": {
            "radar_data": [
                {
                    "label": "South Africa",
                    "data": [
                        data.south_africa.future_readiness_score,
                        data.south_africa.physical_capital,
                        data.south_africa.human_capital,
                        data.south_africa.technology,
                        data.south_africa.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "South Africa",
            "code": "za",
            "stats": {
                "country": "South Africa",
                "future_readiness_rank": "65",
                "future_readiness_score": "42.05717882",
                "future_readiness_ranking": data.south_africa.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "59.893885",
                "gdp_per_capita": "15904.84836",
                "gdp": "405.8697185",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "south_korea": {
            "radar_data": [
                {
                    "label": "South Korea",
                    "data": [
                        data.south_korea.future_readiness_score,
                        data.south_korea.physical_capital,
                        data.south_korea.human_capital,
                        data.south_korea.technology,
                        data.south_korea.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "South Korea",
            "code": "kr",
            "stats": {
                "country": "South Korea",
                "future_readiness_rank": "14",
                "future_readiness_score": "66.26858174",
                "future_readiness_ranking": data.south_korea.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Asia Pacific",
                "population": "51.628117",
                "gdp_per_capita": "50069.82339",
                "gdp": "1665.245539",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "spain": {
            "radar_data": [
                {
                    "label": "Spain",
                    "data": [
                        data.spain.future_readiness_score,
                        data.spain.physical_capital,
                        data.spain.human_capital,
                        data.spain.technology,
                        data.spain.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Spain",
            "code": "es",
            "stats": {
                "country": "Spain",
                "future_readiness_rank": "25",
                "future_readiness_score": "59.12994433",
                "future_readiness_ranking": data.spain.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "47.615034",
                "gdp_per_capita": "45825.19563",
                "gdp": "1397.509272",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "sri_lanka": {
            "radar_data": [
                {
                    "label": "Sri Lanka",
                    "data": [
                        data.sri_lanka.future_readiness_score,
                        data.sri_lanka.physical_capital,
                        data.sri_lanka.human_capital,
                        data.sri_lanka.technology,
                        data.sri_lanka.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Sri Lanka",
            "code": "lk",
            "stats": {
                "country": "Sri Lanka",
                "future_readiness_rank": "91",
                "future_readiness_score": "34.58886781",
                "future_readiness_ranking": data.sri_lanka.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "22.181",
                "gdp_per_capita": "14405.41595",
                "gdp": "74.40357836",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "sweden": {
            "radar_data": [
                {
                    "label": "Sweden",
                    "data": [
                        data.sweden.future_readiness_score,
                        data.sweden.physical_capital,
                        data.sweden.human_capital,
                        data.sweden.technology,
                        data.sweden.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Sweden",
            "code": "se",
            "stats": {
                "country": "Sweden",
                "future_readiness_rank": "5",
                "future_readiness_score": "72.51194032",
                "future_readiness_ranking": data.sweden.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "10.486941",
                "gdp_per_capita": "64578.39633",
                "gdp": "585.9391701",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "switzerland": {
            "radar_data": [
                {
                    "label": "Switzerland",
                    "data": [
                        data.switzerland.future_readiness_score,
                        data.switzerland.physical_capital,
                        data.switzerland.human_capital,
                        data.switzerland.technology,
                        data.switzerland.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Switzerland",
            "code": "ch",
            "stats": {
                "country": "Switzerland",
                "future_readiness_rank": "3",
                "future_readiness_score": "74.33302229",
                "future_readiness_ranking": data.switzerland.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "8.769741",
                "gdp_per_capita": "83598.45336",
                "gdp": "807.7060354",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "tanzania": {
            "radar_data": [
                {
                    "label": "Tanzania",
                    "data": [
                        data.tanzania.future_readiness_score,
                        data.tanzania.physical_capital,
                        data.tanzania.human_capital,
                        data.tanzania.technology,
                        data.tanzania.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Tanzania",
            "code": "tz",
            "stats": {
                "country": "Tanzania, United Rep.",
                "future_readiness_rank": "116",
                "future_readiness_score": "21.27978399",
                "future_readiness_ranking": data.tanzania.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "65.497748",
                "gdp_per_capita": "3096.884521",
                "gdp": "75.70928906",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "thailand": {
            "radar_data": [
                {
                    "label": "Thailand",
                    "data": [
                        data.thailand.future_readiness_score,
                        data.thailand.physical_capital,
                        data.thailand.human_capital,
                        data.thailand.technology,
                        data.thailand.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Thailand",
            "code": "th",
            "stats": {
                "country": "Thailand",
                "future_readiness_rank": "55",
                "future_readiness_score": "44.88675208",
                "future_readiness_ranking": data.thailand.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Asia Pacific",
                "population": "71.69703",
                "gdp_per_capita": "20671.68492",
                "gdp": "495.3405928",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "togo": {
            "radar_data": [
                {
                    "label": "Togo",
                    "data": [
                        data.togo.future_readiness_score,
                        data.togo.physical_capital,
                        data.togo.human_capital,
                        data.togo.technology,
                        data.togo.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Togo",
            "code": "tg",
            "stats": {
                "country": "Togo",
                "future_readiness_rank": "109",
                "future_readiness_score": "23.49462058",
                "future_readiness_ranking": data.togo.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "8.848699",
                "gdp_per_capita": "2607.927681",
                "gdp": "8.126439481",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "trinidad_and_tobago": {
            "radar_data": [
                {
                    "label": "Trinidad and Tobago",
                    "data": [
                        data.trinidad_and_tobago.future_readiness_score,
                        data.trinidad_and_tobago.physical_capital,
                        data.trinidad_and_tobago.human_capital,
                        data.trinidad_and_tobago.technology,
                        data.trinidad_and_tobago.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Trinidad and Tobago",
            "code": "tt",
            "stats": {
                "country": "Trinidad and Tobago",
                "future_readiness_rank": "84",
                "future_readiness_score": "37.93036336",
                "future_readiness_ranking": data.trinidad_and_tobago.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "1.531044",
                "gdp_per_capita": "27778.1806",
                "gdp": "27.89908234",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "tunisia": {
            "radar_data": [
                {
                    "label": "Tunisia",
                    "data": [
                        data.tunisia.future_readiness_score,
                        data.tunisia.physical_capital,
                        data.tunisia.human_capital,
                        data.tunisia.technology,
                        data.tunisia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Tunisia",
            "code": "tn",
            "stats": {
                "country": "Tunisia",
                "future_readiness_rank": "80",
                "future_readiness_score": "38.27463979",
                "future_readiness_ranking": data.tunisia.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Middle East and North Africa",
                "population": "12.356117",
                "gdp_per_capita": "12489.71807",
                "gdp": "46.66494895",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "turkey": {
            "radar_data": [
                {
                    "label": "Türkiye",
                    "data": [
                        data.turkey.future_readiness_score,
                        data.turkey.physical_capital,
                        data.turkey.human_capital,
                        data.turkey.technology,
                        data.turkey.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Türkiye",
            "code": "tr",
            "stats": {
                "country": "Türkiye",
                "future_readiness_rank": "53",
                "future_readiness_score": "45.17983743",
                "future_readiness_ranking": data.turkey.future_readiness_rank,
                "income_group": "Upper middle income",
                "regional_group": "Middle East and North Africa",
                "population": "85.341241",
                "gdp_per_capita": "37273.69976",
                "gdp": "905.9878241",
                "future_readiness_score_income_group_average": "42.287802",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "uganda": {
            "radar_data": [
                {
                    "label": "Uganda",
                    "data": [
                        data.uganda.future_readiness_score,
                        data.uganda.physical_capital,
                        data.uganda.human_capital,
                        data.uganda.technology,
                        data.uganda.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Uganda",
            "code": "ug",
            "stats": {
                "country": "Uganda",
                "future_readiness_rank": "114",
                "future_readiness_score": "21.90945267",
                "future_readiness_ranking": data.uganda.future_readiness_rank,
                "income_group": "Low income",
                "regional_group": "Sub-Saharan Africa",
                "population": "47.249585",
                "gdp_per_capita": "2693.815286",
                "gdp": "45.55920205",
                "future_readiness_score_income_group_average": "19.43344223",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "ukraine": {
            "radar_data": [
                {
                    "label": "Ukraine",
                    "data": [
                        data.ukraine.future_readiness_score,
                        data.ukraine.physical_capital,
                        data.ukraine.human_capital,
                        data.ukraine.technology,
                        data.ukraine.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Ukraine",
            "code": "ua",
            "stats": {
                "country": "Ukraine",
                "future_readiness_rank": "51",
                "future_readiness_score": "46.18217533",
                "future_readiness_ranking": data.ukraine.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Europe",
                "population": "38",
                "gdp_per_capita": "12671.23926",
                "gdp": "160.5027392",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "united_arab_emirates": {
            "radar_data": [
                {
                    "label": "United Arab Emirates",
                    "data": [
                        data.united_arab_emirates.future_readiness_score,
                        data.united_arab_emirates.physical_capital,
                        data.united_arab_emirates.human_capital,
                        data.united_arab_emirates.technology,
                        data.united_arab_emirates.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "United Arab Emirates",
            "code": "ae",
            "stats": {
                "country": "United Arab Emirates",
                "future_readiness_rank": "27",
                "future_readiness_score": "59.04397828",
                "future_readiness_ranking": data.united_arab_emirates.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Middle East and North Africa",
                "population": "9.441129",
                "gdp_per_capita": "87729.19126",
                "gdp": "507.5349217",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "united_kingdom": {
            "radar_data": [
                {
                    "label": "United Kingdom",
                    "data": [
                        data.united_kingdom.future_readiness_score,
                        data.united_kingdom.physical_capital,
                        data.united_kingdom.human_capital,
                        data.united_kingdom.technology,
                        data.united_kingdom.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "United Kingdom",
            "code": "gb",
            "stats": {
                "country": "United Kingdom",
                "future_readiness_rank": "9",
                "future_readiness_score": "70.22739972",
                "future_readiness_ranking": data.united_kingdom.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Europe",
                "population": "66.971411",
                "gdp_per_capita": "54602.54438",
                "gdp": "3070.667732",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "united_states": {
            "radar_data": [
                {
                    "label": "United States",
                    "data": [
                        data.united_states.future_readiness_score,
                        data.united_states.physical_capital,
                        data.united_states.human_capital,
                        data.united_states.technology,
                        data.united_states.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "United States",
            "code": "us",
            "stats": {
                "country": "United States of America",
                "future_readiness_rank": "4",
                "future_readiness_score": "73.50102867",
                "future_readiness_ranking": data.united_states.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Northern America",
                "population": "333.287557",
                "gdp_per_capita": "76398.59174",
                "gdp": "25462.7",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "uruguay": {
            "radar_data": [
                {
                    "label": "Uruguay",
                    "data": [
                        data.uruguay.future_readiness_score,
                        data.uruguay.physical_capital,
                        data.uruguay.human_capital,
                        data.uruguay.technology,
                        data.uruguay.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Uruguay",
            "code": "uy",
            "stats": {
                "country": "Uruguay",
                "future_readiness_rank": "47",
                "future_readiness_score": "48.94401127",
                "future_readiness_ranking": data.uruguay.future_readiness_rank,
                "income_group": "High income",
                "regional_group": "Latin America and the Caribbean",
                "population": "3.422794",
                "gdp_per_capita": "28841.9857",
                "gdp": "71.1771462",
                "future_readiness_score_income_group_average": "59.72776575",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "vietnam": {
            "radar_data": [
                {
                    "label": "Vietnam",
                    "data": [
                        data.vietnam.future_readiness_score,
                        data.vietnam.physical_capital,
                        data.vietnam.human_capital,
                        data.vietnam.technology,
                        data.vietnam.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Vietnam",
            "code": "vn",
            "stats": {
                "country": "Viet Nam",
                "future_readiness_rank": "61",
                "future_readiness_score": "42.96616855",
                "future_readiness_ranking": data.vietnam.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Asia Pacific",
                "population": "98.186856",
                "gdp_per_capita": "13456.55143",
                "gdp": "408.8023791",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "zambia": {
            "radar_data": [
                {
                    "label": "Zambia",
                    "data": [
                        data.zambia.future_readiness_score,
                        data.zambia.physical_capital,
                        data.zambia.human_capital,
                        data.zambia.technology,
                        data.zambia.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Zambia",
            "code": "zm",
            "stats": {
                "country": "Zambia",
                "future_readiness_rank": "111",
                "future_readiness_score": "22.94287689",
                "future_readiness_ranking": data.zambia.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "20.017675",
                "gdp_per_capita": "3894.310259",
                "gdp": "29.78445406",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        },
        "zimbabwe": {
            "radar_data": [
                {
                    "label": "Zimbabwe",
                    "data": [
                        data.zimbabwe.future_readiness_score,
                        data.zimbabwe.physical_capital,
                        data.zimbabwe.human_capital,
                        data.zimbabwe.technology,
                        data.zimbabwe.competitiveness
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#0d5938",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                },
                {
                    "label": "Global average",
                    "data": [
                        42.13718495,
                        50.69937791,
                        47.11518882,
                        44.68200002,
                        26.05217305
                    ],
                    "fill": true,
                    "lineTension": 0.2,
                    "borderColor": "#a7a7a7",
                    "backgroundColor": "#FFFFFF00",
                    "borderWidth": 3
                }
            ],
            "id": 1,
            "name": "Zimbabwe",
            "code": "zw",
            "stats": {
                "country": "Zimbabwe",
                "future_readiness_rank": "112",
                "future_readiness_score": "22.06168087",
                "future_readiness_ranking": data.zimbabwe.future_readiness_rank,
                "income_group": "Lower middle income",
                "regional_group": "Sub-Saharan Africa",
                "population": "16.320537",
                "gdp_per_capita": "2530.645987",
                "gdp": "20.6780556",
                "future_readiness_score_income_group_average": "30.91364873",
            },
            "overall_readiness": "Placeholder text",
            "tti_performance": "Placeholder text",
            "global_performance": "Placeholder text"
        }
    }
}

export default countryProfilesData
