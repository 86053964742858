<template>
  <div>

    <section class="bg-lightgrey mt-5">
      <div class="container shape-container d-flex">
        <div class="col px-0">
          <div class="row pb-100 pt-100 mt-5">
            <div class="col-lg-12">
              <h1 class="display-3">
                Countries
                <span>Future Readiness Economic Index</span>
              </h1>
              <p class="lead">For information on our methodology and how scoring is calculated, please visit our <router-link to="/2023/about" class="helper-text-green">About FREI page</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="">
        <div>
          <b-tabs v-model="tabIndex" content-class="pt-4 bg-white" class="report-tabs-descartes" justified>
            <b-tab>
              <template #title>
                <i class="ni ni-single-copy-04 mr-2"></i>Profiles
              </template>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <CountryProfilesMobile v-if="isMobile"></CountryProfilesMobile>
                      <CountryProfiles v-else></CountryProfiles>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>
            <b-tab>
              <template #title>
                <i class="ni ni-bullet-list-67 mr-2"></i>Rankings
              </template>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <RankingsTable></RankingsTable>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>
            <b-tab>
              <template #title>
                <i class="ni ni-chart-bar-32 mr-2"></i>Graph View
              </template>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <graph-view></graph-view>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>
            <b-tab>
              <template #title>
                <i class="ni ni-world mr-2"></i>Map View
              </template>
              <section class="mt-0">
                <div class="container-fluid">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <MapView></MapView>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>
            <b-tab>
              <template #title>
                <i class="ni ni-curved-next mr-2"></i>Compare
              </template>
              <section class="mt-0">
                <div class="container">
                  <div class="row row-grid align-items-center">
                    <div class="col-12">
                      <CountryComparison></CountryComparison>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import RankingsTable from "./components-2023/RankingsTable";
import CountryComparison from "@/views-2023/components-2023/CountryComparison";
import GraphView from "@/components-2023/GraphView";
import MapView from "@/components-2023/MapView";
import CountryProfiles from "@/components-2023/CountryProfiles";
import CountryProfilesMobile from "@/components-2023/CountryProfilesMobile";

export default {
  name: "components",
  components: {
    CountryProfiles,
    CountryProfilesMobile,
    MapView,
    GraphView,
    CountryComparison,
    RankingsTable
  },
  data() {
    return {
      selected: null
    }
  },
  metaInfo: {
    title: 'Descartes Institute FREI 2023 | Report',
  },
  ready() {
    this.$nextTick(function () {
      this.drawChart();
    })
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    tabIndex() {
      if (this.$route.query.page === 'profiles') {
        return 0
      } else if (this.$route.query.page === 'rankings') {
        return 1
      } else if (this.$route.query.page === 'graph') {
        return 2
      } else if (this.$route.query.page === 'map') {
        return 3
      } else if (this.$route.query.page === 'compare') {
        return 4
      } else {
        return 0
      }
    }
  },
  watch: {
    tabIndex() {
      this.$router.push({query: { page: this.selected }})
    }
  }
};
</script>

<style>
.report-tabs-descartes {
  width: 100%;
  background: #fafafa;
}

.report-tabs-descartes .nav-item .nav-link {
  padding: 1rem 1.25rem;
  color: #4A4A4A;
  font-weight: 500;
  font-size: 1rem;
  box-shadow: none;
  transition: all 0.15s ease;
  background: #fafafa;
  border-radius: 0;
}

.report-tabs-descartes .nav-item .nav-link:hover {
  border: none !important;
  background: #f5f5f5;
  color: #0D5938;
}

.report-tabs-descartes .nav-item .nav-link {
  border: none !important;
}

.report-tabs-descartes .nav-item .nav-link.active {
  background: #0D5938;
  color: white;
}

</style>
