<template>
  <div>

    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-portulans shape-skew"></div>
        <div class="container shape-container d-flex" style="padding-bottom: 7rem;">
          <div class="col px-0">
            <div class="row">
              <div class="col-12">
                <img src="/img/brand/portulans.png" style="width: 250px;margin-bottom: 40px;margin-right: 40px"
                     class="float-left">
                <img src="/img/brand/google-logo-colour.png" style="width: 200px;margin-bottom: 40px"
                     class="float-left">
              </div>
              <div class="col-lg-12">
                <h1 class="font-weight-700  mt-4">Assessing Future Readiness</h1>
                <h4 class="">Future Readiness Economic Index</h4>
                <hr>
                <p class="lead  ">
                  When historians look back on the early part of the 21st century, it is likely that one of the distinguishing features they identify will be the rapid wave of digitalisation that swept the planet.
                  <br>
                  <br>
                  Recognising that our current time presents a unique chance to widen and deepen digital transformation throughout the world, Google launched an initiative called Digital Sprinters in November 2020. In it, the company proposed a novel framework that can facilitate digital transformation in emerging markets and assist them in becoming digital sprinters.
                  <br>
                  <br>
                  The present report builds on that initiative to provide countries with a tool for action: the Future Readiness Economic Index (FREI). With the Digital Sprinters framework as its foundation, FREI has been developed to support countries in assessing the state of their future readiness and their efforts to stimulate greater sustainable, inclusive growth with digital transformation as an integral factor. Countries will be able to use the tool to measure themselves against certain benchmarks or objectives and evaluate their progress towards reaching those goals.
                </p>
              </div>
            </div>
            <br>
            <div class="col-lg-12 text-center mt-4">
              <div class="btn-wrapper">
                <base-button tag="a"
                             href="/pdfs/Global Future Readiness FREI Report Portulans Institute 2022.pdf"
                             target="_blank"
                             class="mb-3 mb-sm-0"
                             type="primary"
                             icon="fa fa-download">
                  Future Readiness Economic Index Report
                </base-button>
                <base-button tag="a"
                             @click="modalShow = !modalShow"
                             class="mb-3 mb-sm-0 text-white"
                             type="primary"
                             icon="fa fa-file">
                  View Executive Summary
                </base-button>
               <base-button tag="a"
                            href="/pdfs/FREI Infographics.pdf"
                            target="_blank"
                            class="mb-3 mb-sm-0"
                            type="primary"
                            icon="fa fa-download">
                  View Infrographics
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <template>
      <div>
        <b-modal v-model="modalShow" hide-footer size="xl" dialog-class="document-modal" title="Executive Summary">

          <p>The pandemic has undoubtedly underscored
          the importance of being prepared and ready to
          adapt in the face of uncertainty. Integral to our
          ability to strengthen future readiness is digital
          transformation. The past couple of decades have
          seen technological advances on an unprecedented
          scale that have brought us closer together
          and made us more interconnected. This digital
          revolution has brought with it new possibilities for
          tackling long-standing challenges and the promise
          of a more equitable and sustainable world. Still, its
          potential is yet to be realised and a host of issues
            needs to be addressed.</p>

          <p>In this context, Google launched its <span style="font-weight:600 !important">Digital Sprinters</span> initiative in November 2020, where
          it presented a framework to advance digital
          transformation in emerging markets. This approach
          makes the case that countries can achieve digital
          transformation and inclusive, sustainable growth
          by focusing their efforts on four policy areas:
          Physical Capital, Human Capital, Technology, and
            Competitiveness.</p>

          <p>This report builds on the Digital Sprinters
          framework to provide countries with a
          benchmarking tool—the <span style="font-weight:600 !important">Future Readiness
          Economic Index (FREI)</span>—that can be used to
          assess how well they are placed to achieve faster
          digital transformation and enhance their future
          readiness. In this first global version of the FREI
          report, 124 countries have been assessed and
          ranked along a combination of 73 indicators,
          distributed across the four pillars that correspond
            to the four areas of the Digital Sprinters framework.</p>

          <h4 class="my-4 font-weight-700 text-primary">Key results and findings from the FREI 2022
            report</h4>

          <p>Overall, <span style="font-weight:600 !important">Singapore</span> is the world’s most future-ready
            country, followed by <span style="font-weight:600 !important">Denmark</span> (2nd) and
            <span style="font-weight:600 !important">Switzerland</span> (3rd). The top of the rankings is
          dominated by European countries (especially
          the Nordics) and the two Northern American
          neighbours, the United States (6th) and Canada
            (10th).</p>

            <p>The <span style="font-weight:600 !important">Physical Capital</span> pillar measures the
          performances of countries across three types
          of infrastructure: Digital Infrastructure, Transport
          Infrastructure, and Energy Infrastructure. Small countries tend to do well in this dimension, with
          Norway being the global leader. It is also notable
          that three Middle Eastern countries, namely Qatar
          (2nd), the United Arab Emirates (6th), and Bahrain
          (9th), are also among the top performers. <span style="font-weight:600 !important">The data
          indicate that investment in physical capital is a
          prerequisite to digital acceleration, but it is not
              enough on its own.</span></p>

          <p>The <span style="font-weight:600 !important">Human Capital</span> pillar is concerned with
          countries’ abilities to Attract, Grow, and Retain
          talent, and puts a particular emphasis on digital
          skills. Australia is the top performer in this pillar,
          while Iceland and Denmark are ranked 2nd
          and 3rd, respectively. <span style="font-weight:600 !important">Available indicators
          suggest that this human component of digital
          transformation is the pillar most in need of
          urgent efforts to allow emerging economies to
              be more future-ready.</span> Providing due attention to
          ways in which vibrant ecosystems can be offered
            to start-ups is a priority in this context.</p>

          <p>The <span style="font-weight:600 !important">Technology</span> pillar focuses on the integration of
          digital technologies—traditional and emerging—in
          societies and economies along three categories:
          Digital Usage, Digital Content Creation, and
          Industry 4.0. These indicators (for which
          comparable data are available) aim to describe the
          key technology ingredients of the Digital Sprinters
          framework, such as innovative uses of data
          (artificial intelligence/machine learning, or AI/ML;
          big data; Internet of Things, or IoT), inclusive digital
          payments, and movement to the cloud. Sweden,
          Switzerland, and the Netherlands make up the
          top three countries in this regard. <span style="font-weight:600 !important">This is an area
          in which emerging countries have ample room
          to improve their scores</span>. For instance, several
          countries in the Middle East and North Africa have
          excellent levels of Digital Usage, but they have
          not yet been able to fully build on this advantage
          to achieve equally high levels of digital knowledge
          creation or adoption and development of emerging
          technologies. Overall, in the Technology pillar,
          regional leaders include Sweden (1st), the United
          States (4th), China (42nd), Chile (47th), and South
          Africa (70th). It is important to note that, in spite of
          strong (and sometimes spectacular) performance,
          most regional readers outside of Europe and
          Northern America are still not in the first league of
          4 Future Readiness Economic Index 2022
          future-ready economies. Moving to such status is
            clearly one of the objectives of the digital sprinters.</p>

          <p>The <span style="font-weight:600 !important">Competitiveness</span> pillar highlights the
          ecosystem that is necessary to achieve future
          readiness. In particular, digital policies (including
          regulatory conditions) are emphasised, along
          with the innovation capacities of countries,
          by considering their Digital Policies, Market
          Environment, Research & Development (R&D),
          and Innovation capabilities and performance.
          This is clearly the pillar that remains the most
          difficult to quantify, especially with regard to the
          critical area of digital policies. Largely because
          such policies focus on new considerations and
          areas, comparable data remain elusive, and the
          indicators used are proxies at best. It should be
          noted especially that ‘regulatory environment’
          is broader than ‘digital regulatory environment’,
          although it is only one of the many components
          of digital policies. With these caveats in mind, the
          top-ranked countries in this pillar are Switzerland
          (1st), Singapore (2nd), and Denmark (3rd), followed
            by the United States (4th).</p>

          <p><span style="font-weight:600 !important">From data to action:</span> Beyond these
          results, several key messages and possible
          recommendations emerge. Specific data and
          indicators can be found in the discussion on the
            FREI results and in the country profiles in Section II.</p>

          <h4 class="my-4 font-weight-700 text-primary">Key messages</h4>

          <p>One of the overall key messages arising from
          the report is that <span style="font-weight:600 !important">future readiness is highly
          dependent on the pace and breadth of
              digital transformation.</span> Above all, the analyses
          and data contained in the report highlight the
          importance of digital transformation for future
          readiness, emphasising that getting digital
          policies and actions right is one of the central
          challenges facing all types of economies. Indeed,
          the report convincingly demonstrates that digital
          infrastructure, digital technologies, and digital
          policies are among the dimensions of future
          readiness that not only exhibit the highest potential
          to boost growth and competitiveness but that
          also display the greatest variation around the
          world. Addressing the digital divides is crucial. It is
          also a requirement for a sustainable global post-pandemic
            recovery.</p>

          <p>Another important conclusion stemming from this
          first global FREI report is that <span style="font-weight:600 !important">there is no cookiecutter
              approach to future readiness.</span> Each
          national economy should remain responsible for its
          own ability to design and pursue its own strategies.
          Such strategies should themselves be based on
          a careful consideration of local advantages and
          constraints, and must adequately account for local
          cultures, history, and aspirations. All this suggests
          that countries should set themselves a future-readiness
          challenge and that digital transformation
          tailored to their specific circumstances should
          be at the heart of their individual solutions.
          Aligning such approaches with efforts made to
          address global challenges (climate change, health
          resilience, inequalities, for example) will help
          national economies to garner local and global
            support around them.</p>

          <p>Whatever their respective sizes, individual national
          economies alone cannot generate the full benefits
          of being more future-ready. <span style="font-weight:600 !important">Global cooperation
          and multi-stakeholder approaches are key
          to enhancing collective and individual future
              readiness.</span> Metrics (such as those contained
          in this report) have the power to help each
          economy monitor its own efforts and compare
          its performance to that of other economies.
          Altogether, however, a broader approach to the
          merits of international cooperation (as opposed
          to protectionist and nationalistic rhetoric) is now
          urgently required.1 At both the international
          and local levels, multi-stakeholder approaches
          (involving public and private entities as well as civil
          society) should be encouraged as a way to create
            a sustainable momentum of cooperation.</p>

          <h4 class="my-4 font-weight-700 text-primary">Looking forward</h4>

          <p>The value of the present report cannot be fully
          appreciated without due consideration of its
          companion online benchmarking tool <a href="http://frei-2022.portulansinstitute.org/" style="font-weight:600 !important">frei-2022.portulansinstitute.org</a>. By allowing users to
          assess the potential impact of various policies
          and scenarios, this tool is expected to trigger
          thought-provoking constructive discussions about
          what elements of the Digital Sprinters pillars a
          government could potentially focus its attention on
            and invest in to make a difference.</p>

          <b-button class="mt-3" variant="primary" block @click="modalShow = !modalShow">Close</b-button>

        </b-modal>

      </div>
    </template>

    <section class="section section-lg digital-sprinters bg-google" data-aos="fade-in" data-aos-offset="400">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="row">
              <div class="col-12 p-0 text-center">
                <img src="/img/brand/digital_sprinters_logo.png"
                     style="width:90%;max-width:300px;margin: 0 auto 40px auto"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0">
                <h2 class="display-3">The initial stage, Digital Sprinters, a Google initiative</h2>
              </div>
            </div>
            <p class="lead"><span class="font-weight-600">Google</span> and <span
                class="font-weight-600">Portulans Institute</span> are proud to announce their collaboration on this
              FREI
              study, following the publication of the Digital Sprinters report by Google on November 16, 2020 revealing
              that emerging markets are “facing a digital crossroad that was apparent even prior to the pandemic”.</p>
            <img src="/img/brand/digital-sprinters-diagram.png" style="width:100%; max-width: 600px;" class="mb-5 mt-5">
          </div>
          <div class="col-lg-12 mt-4">
            <ul>
              <li>
                <p class="lead">The two initiatives (Digital Sprinters and Future Readiness) share a number of common
                  principles and objectives. They also have distinctive traits, which reflect their respective origins
                  and rationales. Their combination hence offers exceptional possibilities in terms of impact and
                  visibility.</p>
              </li>
              <li>
                <p class="lead">Both highlight the critical role of technology and human capital / talent, and the
                  importance of imagining how they will mesh with one another in the future.</p>
              </li>
            </ul>
          </div>
          <div class="col-lg-12 text-center mt-4">
            <div class="btn-wrapper">
              <base-button tag="a"
                           href="https://blog.google/outreach-initiatives/public-policy/the-opportunity-for-digital-sprinters/"
                           target="_blank"
                           class="mb-3 mb-sm-0"
                           type="primary"
                           icon="fa fa-google">
                Read more about Digital Sprinters
              </base-button>
              <base-button tag="a"
                           href="https://blog.google/documents/96/Google-Whitepaper_V5.pdf"
                           class="mb-3 mb-sm-0"
                           type="primary"
                           icon="fa fa-download">
                Download the Digital Sprinters Report
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="row google-colour-bar">
        <div class="col google-red"></div>
        <div class="col google-blue"></div>
        <div class="col google-green"></div>
        <div class="col google-yellow"></div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-9 order-md-2" data-aos="fade-in" data-aos-offset="300">
            <div class="pr-md-5">
              <h3>"Emerging markets have some of the greatest entrepreneurial energy in the world — and are poised to be
                the biggest beneficiaries of new technology in the coming years. From enabling Indian farmers to improve
                crop yields to helping Tanzanian small businesses sell to customers around the world, technology
                supports progress and economic empowerment. And with the right policy frameworks, emerging markets can
                become launching pads for future innovation."</h3>
              <p class="lead"><span class="font-weight-600">Kent Walker</span><br>President, Global Affairs, Google</p>
            </div>
          </div>
          <div class="col-md-3 order-md-1" data-aos="fade-right" data-aos-offset="300"
               style="margin: auto;text-align: center">
            <img src="/img/photos/kent_walker.png" class="img-fluid" style="max-width: 220px;margin: auto">
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center" data-aos="fade-in" data-aos-offset="300">
          <div class="col-md-9 order-md-1" style="text-align: right;">
            <div class="pr-md-5">
              <h3>"Measuring digital performance today tells only a part of the story. Assessing the future readiness of
                national economies is critical to identifying who will lead in to-morrow's interconnected economy."</h3>
              <p class="lead"><span class="font-weight-600">Bruno Lanvin</span><br>Founder and CEO, Portulans Institute
                <br>Geneva, Switzerland</p>
            </div>
          </div>
          <div class="col-md-3 order-md-2" data-aos="fade-left" data-aos-offset="300"
               style="margin: auto;text-align: center">
            <img src="/img/photos/bruno_lanvin.png" class="img-fluid" style="max-width: 220px;margin: auto">
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <CountDownTimer></CountDownTimer>
    </section>

  </div>
</template>

<script>
import CountDownTimer from "../components-2022/CountDownTimer";

export default {
  name: "home",
  components: {
    CountDownTimer
  },
  metaInfo: {
    title: 'Portulans Institute FREI 2022 | About',
  },
  data() {
    return {
      modalShow: false,
      visible: false,
    }
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  }
};
</script>

<style scoped>

.methodology-box {
  background: white;
  padding: 1em;
  border-radius: 5px;
}

.google-colour-bar {
  height: 10px;
}

.google-red {
  background-color: #DB4437
}

.google-blue {
  background-color: #4285F4
}

.google-green {
  background-color: #0F9D58
}

.google-yellow {
  background-color: #F4B400
}


</style>
