<template>
  <div>

    <div v-if="selectedPillar && showFixedSlider" class="fixed-slider">
      <label>Percentage Increase</label>
      <vue-slider v-model="percentageIncrease" :marks="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
      />
    </div>


    <div class="row">

      <div class="col">

        <div class="col-12 text-center animate__animated animate__fadeIn">
          <p class="font-weight-500 help-text text-center">
            Choose a Country from the dropdown to begin <br>then move the cursor to the level you want to consider, and see the table change
          </p>
        </div>

        <div class="row what-if-buttons mb-5 text-center" style="max-width: 600px">
          <div class="col-12 mb-3">
            <b-form-select size="md" v-model="selectedCountry" :options="countries"></b-form-select>
          </div>
          <div v-if="selectedCountry !== null" class="col-12">
            <b-form-select size="md" v-model="selectedPillar" :options="pillars"></b-form-select>
          </div>
          <div v-if="selectedCountry !== null" class="col-12 pt-3 mb-5">
            <label>Percentage Increase</label>
            <vue-slider v-model="percentageIncrease" :marks="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
            />
          </div>
        </div>

        <div class="col text-center">
          <div class="d-inline-flex">
            <h1 class="font-weight-300 mt-4 animate__animated animate__fadeInUp" style="font-size: 30px">

              <span>What if </span>
              <span v-if="getCountryName !== 'none'"><span class="font-weight-700 text-underline link-to-country"
                                                           @click="selectCountry(getCountryName)">{{ getCountryName }}</span></span>
              <span v-if="getCountryName !== 'none' && selectedPillar === 'FutureReadiness'"> improved <span
                  class="font-weight-700">all pillars</span></span>
              <span
                  v-if="getCountryName !== 'none' && selectedPillar === 'InstitutionsAndInfrastructure'"> improved <span
                  class="font-weight-700">Institutions and Infrastructure</span></span>
              <span
                  v-if="getCountryName !== 'none' && selectedPillar && selectedPillar !== 'FutureReadiness' && selectedPillar !== 'InstitutionsAndInfrastructure'"> improved <span
                  class="font-weight-700">{{ selectedPillar }}</span></span>
              <span v-if="getCountryName !== 'none' && selectedPillar && percentageIncrease"> by <span
                  class="font-weight-700">{{ percentageIncrease }}%</span></span>

            </h1>
          </div>
        </div>

        <div class="col-12 mt-5" style="overflow: scroll" id="sliderContainer">
          <template>
            <div>
              <b-table id="table-transition"
                       class="rankingsTable2021"
                       :sortBy="sortBy"
                       :sortDesc="sortDesc"
                       :sortCompare="myCompare"
                       :items="countryWhatIfDataSetForGivenScenario"
                       :fields="fields"
                       small
                       fixed
                       primary-key="Country"
                       :tbody-transition-props="transProps">
                <template #cell()="data">

                  <div v-if="data.field.key === 'Country'" class="countryName">
                    <span :class="{'selectedCountry': data.value === getCountryName}">{{ data.value }}</span>
                  </div>

                  <div v-else>
                    <span v-if="data.value.value < 100" style="margin-bottom: 5px">
                      {{ parseFloat(data.value.value).toFixed(2) }}
                    </span>
                    <span v-else style="margin-bottom: 5px">100</span><br>
                  </div>

                </template>
              </b-table>
            </div>
          </template>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

import countryWhatIfData from '../../data-2021/countryWhatIfData.json'
import countryWhatIfDataMaximums from '../../data-2021/countryWhatIfDataMaximums.json'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {BTable} from 'bootstrap-vue'
import _ from 'lodash';

export default {
  name: "WhatIfsTable",
  components: {
    BTable,
    VueSlider
  },
  data() {
    return {
      showFixedSlider: false,
      sliderStartPosition: null,
      sliderPosition: null,
      scrollPosition: null,
      sortDesc: true,
      percentageIncrease: 0,
      selectedCountry: null,
      selectedPillar: 'FutureReadiness',
      sortBy: "FutureReadiness",
      countries: [
        {value: null, text: 'Select a country', disabled: true},
        {value: 0, text: 'Argentina'},
        {value: 1, text: 'Brazil'},
        {value: 2, text: 'Chile'},
        {value: 3, text: 'China'},
        {value: 4, text: 'Colombia'},
        {value: 5, text: 'Egypt'},
        {value: 6, text: 'India'},
        {value: 7, text: 'Indonesia'},
        {value: 8, text: 'Israel'},
        {value: 9, text: 'Jordan'},
        {value: 10, text: 'Kenya'},
        {value: 11, text: 'Kuwait'},
        {value: 12, text: 'Lebanon'},
        {value: 13, text: 'Mexico'},
        {value: 14, text: 'Morocco'},
        {value: 15, text: 'Nigeria'},
        {value: 16, text: 'Peru'},
        {value: 17, text: 'Qatar'},
        {value: 18, text: 'Russia'},
        {value: 19, text: 'Saudi Arabia'},
        {value: 20, text: 'Singapore'},
        {value: 21, text: 'South Africa'},
        {value: 22, text: 'Thailand'},
        {value: 23, text: 'Turkey'},
        {value: 24, text: 'Ukraine'},
        {value: 25, text: 'United Arab Emirates'},
        {value: 26, text: 'Viet Nam'}
      ],
      pillars: [
        {value: 'FutureReadiness', text: 'Future Readiness'},
        {value: 'Innovation', text: 'Innovation'},
        {value: 'InstitutionsAndInfrastructure', text: 'Institutions and Infrastructure'},
        {value: 'Talent', text: 'Talent'},
        {value: 'Technology', text: 'Technology'}
      ],
      options: [
        {text: 'Current Scores', value: 'current'},
        {text: '1% Improvement', value: '1_percent'},
        {text: '2% Improvement', value: '2_percent'},
        {text: '3% Improvement', value: '3_percent'}
      ],
      transProps: {
        name: 'flip-list'
      },
      scenario: 'current',
      countryWhatIfDataMaximums: countryWhatIfDataMaximums,
      countryWhatIfData: countryWhatIfData,
      fields: [
        {key: 'Country', sortable: false, tdClass: "countryColumn", thClass: 'comparisonHeader', sortDirection: 'desc'},
        {
          key: 'FutureReadiness',
          sortable: false,
          thClass: 'comparisonHeader',
          tdClass: "backgroundOpacity",
          sortDirection: 'desc'
        },
        {
          key: 'InstitutionsAndInfrastructure',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        },
        {
          key: 'Technology',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        },
        {
          key: 'Talent',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        },
        {
          key: 'Innovation',
          sortable: false,
          tdClass: "backgroundOpacity",
          thClass: 'comparisonHeader',
          sortDirection: 'desc'
        }
      ],
      countryWhatIfDataSetForGivenScenarioStartValues: countryWhatIfData,
      startValues: _.cloneDeep(countryWhatIfData)
    }
  },
  watch: {
    percentageIncrease() {
      if (this.selectedPillar === 'FutureReadiness') {

        const percentage = 0.01 * this.percentageIncrease

        const startValue = this.startValues[this.scenario][this.selectedCountry][this.selectedPillar].value
        const newValue = startValue * (1 + percentage)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry][this.selectedPillar].value = (Math.round(newValue * 100) / 100)

        // Get Starting Pillar Values

        const startValueInstitutionsAndInfrastructure = this.startValues[this.scenario][this.selectedCountry].InstitutionsAndInfrastructure.value
        const startValueTechnology = this.startValues[this.scenario][this.selectedCountry].Technology.value
        const startValueTalent = this.startValues[this.scenario][this.selectedCountry].Talent.value
        const startValueInnovation = this.startValues[this.scenario][this.selectedCountry].Innovation.value

        // Calculate New Pillar Values

        const newStartValueInstitutionsAndInfrastructure = startValueInstitutionsAndInfrastructure * (1 + percentage)
        const newStartValueTechnology = startValueTechnology * (1 + percentage)
        const newStartValueTalent = startValueTalent * (1 + percentage)
        const newStartValueInnovation = startValueInnovation * (1 + percentage)

        // Set New Pillar Values

        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].InstitutionsAndInfrastructure.value = (Math.round(newStartValueInstitutionsAndInfrastructure * 100) / 100).toFixed(2)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Technology.value = (Math.round(newStartValueTechnology * 100) / 100).toFixed(2)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Talent.value = (Math.round(newStartValueTalent * 100) / 100).toFixed(2)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Innovation.value = (Math.round(newStartValueInnovation * 100) / 100).toFixed(2)

        // Calculate New Future Readiness Value

        const newFutureReadiness = ((newStartValueInstitutionsAndInfrastructure + newStartValueTechnology + newStartValueTalent + newStartValueInnovation) / 4)

        // Set New Future Readiness Value

        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].FutureReadiness.value = Number.parseFloat((Math.round(newFutureReadiness * 100) / 100))

        console.log('FutureReadiness', newFutureReadiness)

      } else {

        // Set New Value for Selected Pillar

        const percentage = 0.01 * this.percentageIncrease
        const startValue = this.startValues[this.scenario][this.selectedCountry][this.selectedPillar].value
        const newValue = startValue * (1 + percentage)
        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry][this.selectedPillar].value = Number.parseFloat((Math.round(newValue * 100) / 100))

        // Get New Pillar Values

        const newInstitutionsAndInfrastructure = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].InstitutionsAndInfrastructure.value)
        const newTechnology = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Technology.value)
        const newTalent = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Talent.value)
        const newInnovation = Number.parseFloat(this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].Innovation.value)

        const newFutureReadiness = ((newInstitutionsAndInfrastructure + newTechnology + newTalent + newInnovation) / 4)

        // Set New Future Readiness Value

        this.countryWhatIfDataSetForGivenScenarioStartValues[this.scenario][this.selectedCountry].FutureReadiness.value = (Math.round(newFutureReadiness * 100) / 100).toFixed(2)

        console.log('notFutureReadiness', newFutureReadiness)
      }
    },
    selectedPillar() {
      this.sortDesc = true
      this.sortBy = 'FutureReadiness'
      this.percentageIncrease = 0
    },
    selectedCountry() {
      this.percentageIncrease = 0
    }
  },
  computed: {
    getCountryName() {
      let selectedCountryName = 'none'
      if (this.selectedCountry !== null) {
        const countries = [
          {name: "Argentina"},
          {name: "Brazil"},
          {name: "Chile"},
          {name: "China"},
          {name: "Colombia"},
          {name: "Egypt"},
          {name: "India"},
          {name: "Indonesia"},
          {name: "Israel"},
          {name: "Jordan"},
          {name: "Kenya"},
          {name: "Kuwait"},
          {name: "Lebanon"},
          {name: "Mexico"},
          {name: "Morocco"},
          {name: "Nigeria"},
          {name: "Peru"},
          {name: "Qatar"},
          {name: "Russia"},
          {name: "Saudi Arabia"},
          {name: "Singapore"},
          {name: "South Africa"},
          {name: "Thailand"},
          {name: "Turkey"},
          {name: "Ukraine"},
          {name: "United Arab Emirates"},
          {name: "Viet Nam"}
        ]
        selectedCountryName = countries[this.selectedCountry].name
      }
      return selectedCountryName
    },
    countryWhatIfDataSetForGivenScenario() {
      return this.countryWhatIfData[this.scenario]
    },
    countryWhatIfDataMaximumsForGivenScenario() {
      return this.countryWhatIfDataMaximums[this.scenario]
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    getCenteredDivPosition() {
      this.sliderStartPosition = this.sliderPosition
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
      this.setSliderState()
    },
    setSliderState() {
      if (this.scrollPosition > 750) {
        this.showFixedSlider = true
      } else {
        this.showFixedSlider = false
      }
    },
    myCompare(aRow, bRow, key) {
      const aValue = aRow[key].value
      const bValue = bRow[key].value
      if (aValue > bValue) {
        return 1
      }
      if (aValue < bValue) {
        return -1
      }
      return 0
    },
    backgroundOpacity(value, key) {
      const maxValue = this.countryWhatIfDataMaximumsForGivenScenario[key]
      const opacityValue = value.value / maxValue

      if (Math.round(opacityValue * 10) >= 10) {
        return 'comparisonCell ' + key + 'Opacity10'
      } else {
        return 'comparisonCell ' + key + 'Opacity' + Math.round(opacityValue * 10)
      }
    },
    getFutureReadiness(countryPillars) {
      const countryTotal = Number(countryPillars.Innovation.value) + Number(countryPillars.InstitutionsAndInfrastructure.value) + Number(countryPillars.Talent.value) + Number(countryPillars.Technology.value)
      // return Math.round(countryTotal/4 * 100) / 100
      return (countryTotal / 4).toFixed(2)
    },
    selectCountry(country) {
      this.$emit('clickedCountry', country)
    }
  }
}

</script>

<style>

.what-if-buttons .form-group {
  width: 100%;
}

.rankingsTable2021 th:focus {
  outline: 0;
}

.rank {
  background: none !important;
  font-weight: bold;
  margin-top: 5px;
}

.rankingsTable2021 th {
  border-top: solid 5px #FFF !important;
  text-align: center;
}

.rankingsTable2021 th:nth-child(3)[aria-sort='descending'] {
  border-top: solid 5px #cc4c2a !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(3)[aria-sort='ascending'] {
  border-top: solid 5px #cc4c2a !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(4)[aria-sort='descending'] {
  border-top: solid 5px #66ad5d !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(4)[aria-sort='ascending'] {
  border-top: solid 5px #66ad5d !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(5)[aria-sort='descending'] {
  border-top: solid 5px #e2aa23 !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(5)[aria-sort='ascending'] {
  border-top: solid 5px #e2aa23 !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(6)[aria-sort='descending'] {
  border-top: solid 5px #73339b !important;
  background: #fbfbfb;
}

.rankingsTable2021 th:nth-child(6)[aria-sort='ascending'] {
  border-top: solid 5px #73339b !important;
  background: #fbfbfb;
}

.countryName {
  padding: 1em !important;
}

.countryName:hover {
  color: white;
  cursor: initial;
}

.countryName span {
  font-weight: bold;
  padding: 12px;
  border-radius: 5px;
  margin-left: -10px;
  color: #4b4b4b;
}

.countryName span.selectedCountry {
  font-weight: bold;
  background: #4b4b4b;
  padding: 12px;
  border-radius: 5px;
  margin-left: -10px;
  color: white;
}

.in-text-select {
  border: none !important;
  box-shadow: none !important;
  font-size: 30px !important;
  color: black
}

.link-to-country:hover {
  color: #0c7abf;
  cursor: pointer;
}

.fixed-slider {
  background: white;
  position: fixed;
  top: 0;
  width: 1024px;
  margin: auto;
  z-index: 100000;
  padding: 1em 2em 3em 2em;
  border-radius: 0px 0px 5px 5px;
  box-shadow: #00000033 -1px 5px 9px 0px;
}

</style>
